import React from 'react'
import { Search, X } from 'react-feather';

type TableSearchBarProps = {
    onSearch: (keywordValue: string) => void;
    keyword?: string;
    setKeyword: (value: any) => void;
    _handleKeyDown: (value: any) => void;
    containerClass?: string;
}

const TableSearchBar = ({onSearch,
    keyword,
    setKeyword,
    _handleKeyDown,
    containerClass} : TableSearchBarProps) => {
  return (
    <div className={`relative ${containerClass}`}>
        <input 
        type="text" 
        className='form-input' 
        onChange={(e: any) => setKeyword(e.target.value)}
        onKeyDown={_handleKeyDown}
        value={keyword}
        />
        {keyword &&
            <button 
            className='button absolute right-[40px] top-[6px]'
            onClick={() => {
                setKeyword('');
                setTimeout(() => {
                  onSearch('');
                }, 200);
              }}
            >
                <X size={20} className='text-[#F2B84F]'/>
            </button>
        }
        <button 
        className='button absolute right-[10px] top-[6px] flex items-center justify-center'
        onClick={() => {
          onSearch(keyword || '');
        }}
        >
            <Search size={20} className='text-[#F2B84F]' />
        </button>
    </div>
  )
}

export default TableSearchBar