import React, {useState, useEffect, useCallback} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import moment from 'moment';
import {AdminService} from 'core/services/admin.service';
import {Users, Edit} from 'react-feather';
import {AdminDetailData} from "../../../core/types/user.type";
import { PaginationOwn } from 'components/uiComponents/Pagination';
import { CircleSpin } from 'components/spinner/CircleSpin';

const AdminList = () => {
    const [admins, setAdmins] = useState<AdminDetailData[]>([]);
    const [totalRecords, setTotalRecords] = useState<any>();
    const navigate = useNavigate();
    const [itemPerPage, setItemPerPage] = useState<number>(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    const page = searchParams.get('page') || 1;

    useEffect(() => {
        let params = {
            PageNumber: currentPage,
            PageSize: itemPerPage
        }
        if (page) {
        setCurrentPage(Number(page));
        params = {
            ...params,
            PageNumber: Number(page)
        }
        }
        fetchAdminList(params);
    }, [page]);

    const fetchAdminList = (params: any) => {
        AdminService.getList(params).then((res) => {
            if (res?.data?.isSuccess === true) {
                setAdmins(res?.data?.data?.records);
                setTotalRecords(res?.data?.data?.totalCount);
                setLoading(false);
            }
        }).catch((err) => {
            console.error(err);
        });
    };

    const onPageChange = useCallback(async (event: number) => {
        navigate(`/quan-ly/admin?page=${event}`);
      }, []);

    return (
        <>
            <div className=' flex justify-start'>
                <div className='table-title'>
                    <Users size={24} color={`#461511`}/>
                    <p className='text-base font-semibold leading-5 text-bg_brown'>{"Quản lý admin"}</p>
                </div>
            </div>
            <div className='table-container min-h-[500px]'>
                <div className='flex items-center justify-between form-normal-text mb-2'>
                    <div className='text-[#595959 text-sm]'>
                        {`${admins?.length || 0} admin`}
                    </div>
                    <div>
                        <button
                            onClick={() => navigate(`/quan-ly/admin-detail`)}
                            className="table-primary-btn">
                            Thêm mới
                        </button>
                    </div>
                </div>
                {admins?.length > 0 && !loading &&
                    <table className='table w-full'>
                        <thead>
                        <tr>
                            <th className='w-[20%]'>
                                <span>Họ và tên</span>
                            </th>
                            <th className='w-[15%]'>
                                <span>Email</span>
                            </th>
                            <th className='w-[15%]'>
                                <span>Vai trò</span>
                            </th>
                            <th className='w-[10%]'>
                                <span>Trạng thái</span>
                            </th>
                            <th className='w-[15%]'>
                                <span>Ngày tạo</span>
                            </th>
                            <th className='w-[15%]'>
                                <span>Người tạo</span>
                            </th>
                            <th className='flex justify-center w-[10%]'>
                                <span></span>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {admins?.map((admin: AdminDetailData) => (
                            <tr key={admin?.id}>
                                <td className='w-[20%]'>
                                    <span>{`${admin.lastName} ${admin.firstName}`}</span>
                                </td>
                                <td className='w-[15%]'>
                                    <span>{admin.email}</span>
                                </td>
                                <td className='w-[15%]'>
                                    <span>{admin.roleName}</span>
                                </td>
                                <td className='w-[10%]'>
                                    <span>{admin?.status || "--"}</span>
                                </td>
                                <td className='w-[15%]'>
                                    <span>{moment(admin?.createdDate).format("DD/MM/YYYY hh:mm")}</span>
                                </td>
                                <td className='w-[15%]'>
                                    <span>{(!admin.creatorFirstName || !admin.creatorLastName) ? 'Admin' : `${admin.creatorLastName} ${admin.creatorFirstName}`}</span>
                                </td>
                                <td className='w-[10%]'>
                                    <div className='flex justify-center  cursor-pointer'
                                            onClick={(event) => {
                                                navigate(`/quan-ly/admin-detail/${admin.id}`);
                                                event.stopPropagation();
                                            }}>
                                        <Edit size={20} className="text-[#461511]"/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                }
                {loading && (
                    <>
                        <div className="flex justify-center mt-4 items-center text-table_thead_text">
                        <CircleSpin color="text-table_primary_btn mr-2" /> Loading...
                        </div>
                    </>
                )}
                {totalRecords > itemPerPage && !loading && (
                    <div className="my-6 d-flex text-right">
                        <PaginationOwn
                        totalItems={totalRecords}
                        itemPerPage={itemPerPage}
                        pageChange={onPageChange}
                        pageCurrent={currentPage}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

export default AdminList;
