import { ADMIN } from "core/constant/api.contant"
import axiosClient from "utilities/axios-instance"


const getList = (params: any) => {
    return axiosClient.get(ADMIN.LIST, { params});
}

const createAdmin = (params: any) => {
    return axiosClient.post(ADMIN.CREATE_ADMIN(), params);
}

const getAdminById = (id: string) => {
    return axiosClient.get(ADMIN.DETAIL_BY_ID(id));
}

const updateAdminById = (id: string, params: any) => {
    return axiosClient.put(ADMIN.UPDATE_BY_ID(id), params);
}

const updateIPassword = (params: any) => {
    return axiosClient.post(ADMIN.UPDATE_I_PASSWORD, params);
}

const getIPassword = () => {
    return axiosClient.get(ADMIN.GET_I_PASSWORD);
}

export const AdminService = {
    getList,
    createAdmin,
    getAdminById,
    updateAdminById,
    updateIPassword,
    getIPassword
}