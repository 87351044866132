import { AdminService } from 'core/services/admin.service';
import React, { useEffect, useState } from 'react';
import { AlertCircle, AlertTriangle, CheckCircle, Eye, EyeOff } from 'react-feather';
import { toast } from 'react-toastify';

const IPassword = () => {
    const [showiPassword, setShowiPassword] = useState(false);
    const [iPassValue, setIPassValue] = useState<string>();
    const [curIPassValue, setCurIPassValue] = useState<string>();
    const [isDisabledSubmitBtn, setIsDisabledSubmitButton] = useState<boolean>(false);

    const toggleiPasswordVisibility = () => {
        setShowiPassword(!showiPassword);
    };

    const getIPassword = async () => {
        const res = await AdminService.getIPassword();
        if (res?.data?.isSuccess) {
            setIPassValue(res?.data?.data);
            setCurIPassValue(res?.data?.data);
        }
    };

    const handleUpdateIPassword = async () => {
        const params = {
            password: iPassValue
        }
        const res = await AdminService.updateIPassword(params);
        if (res?.data?.isSuccess) {
            toast('Cập nhật mật khẩu chữ i thành công', {
                type: 'success',
                icon: <CheckCircle className="text-[#007B40]" />
            });
        }else {
            toast(res?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
            });
        }
    };

    useEffect(() => {
        getIPassword();
    }, []);

    useEffect(() => {
        if (!iPassValue) {
            setIsDisabledSubmitButton(true);
            return;
        }
        if ((iPassValue !== curIPassValue) && isDisabledSubmitBtn) {
            setIsDisabledSubmitButton(false);
        }
    }, [iPassValue]);

  return (
    <>
        <div className=' flex justify-between'>
            <div className='flex items-center gap-[11px]'>
                <AlertCircle size={24} color={`#461511`}/>
                <p className=' text-base font-semibold leading-5 text-bg_brown'>Quản lý mật khẩu chữ i</p>
            </div>
            <div className=' text-sm font-normal leading-5 text-color_text'></div>
        </div>
        <div className='flex mt-5'>
            <div className='w-1/2'>
                <div className="flex items-center relative">
                    <label htmlFor="iPassword" style={{
                        width: '25%'
                    }} className="text-sm font-medium text-gray-900  required-after">Mật
                        khẩu chữ i </label>
                    <div className='relative flex-1'>
                        <input
                            type={showiPassword ? 'text' : 'password'}
                            id="iPassword" name="iPassword"
                            className="form-input  w-full pr-12"
                            placeholder="Nhập mật khẩu"
                            value={iPassValue}
                            onChange={(e) => setIPassValue(e.target.value)}
                        />
                        <div
                            className="absolute inset-y-0 right-[14px] flex items-center text-sm leading-5"
                            onClick={(event) => {
                                event.stopPropagation();
                                toggleiPasswordVisibility();
                            }}
                        >
                            {showiPassword ? <EyeOff size={20} color="gray" className=' cursor-pointer'/> :
                                <Eye size={20} color="gray" className=' cursor-pointer'/>}
                        </div>
                    </div>
                </div>
                <div className={"flex gap-[35px] items-center justify-start mt-5"}>
                    <button 
                    type="submit"
                    className="table-primary-btn w-[105px] h-[42px]"
                    disabled={isDisabledSubmitBtn}
                    onClick={() => handleUpdateIPassword()}
                    >Lưu
                    </button>
                    {/* <button
                        type="button"
                        className="table-second-btn w-[127px] h-[42px]"
                    >
                        Hủy
                    </button> */}
                    
                </div>
            </div>
            <div className='w-1/2'></div>
        </div>
    </>
  )
}

export default IPassword