import FormCard from 'components/uiComponents/form/FormCard'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AlertCircle, AlertTriangle, CheckCircle, Edit, Eye, EyeOff, FileText, PlusCircle, Trash } from 'react-feather'
import AvatarDefault from 'assets/images/avatar_default.png';
import DatePicker from "react-datepicker";
import DatePickerIcon from 'assets/images/datepickerIcon.png';
import PaperClipIcon from 'assets/images/PaperClipIcon.png';
import { Tooltip } from 'react-tooltip';
import { FamilyCouncilTitleOptions, FamilyTitleOptions, RelationshipType, UserRoleOptions, UserTooltipData } from 'core/types/user.type';
import Select from 'react-select';
import { convertSolar2Lunar } from 'assets/javacripts/lunarCalendar';
import { useForm } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import { UserService } from 'core/services/user.service';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import RelationshipSection from './components/RelationshipSection';
import AddGenerationModal from './components/AddGenerationModal';
import moment from 'moment';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getFullName } from 'core/utils/ultils';
import AddUserSide from 'assets/icons/AddUserSide';
import { useAuth } from 'core/contexts/auth/AuthContext';
import imageCompression from 'browser-image-compression';
import handleErrorToast from 'utilities/handleErrorToast';

const MAX_FILE_SIZE_MB = 5; 
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const ALLOWED_FILE_TYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];


const DetailUser = () => {
  const paramsUrl = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const auth = useAuth();

  const [selectedGender, setSelectedGender] = useState<string>('Male');
  const [dob, setDob] = useState<any>();
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string>('Active');
  const [isMartyrs, setIsMartyrs] = useState<boolean>(false);

  const [userRole, setUserRole] = useState<any>();
  const [userDetail, setUserDetail] = useState<any>();

  const [currentBirtCertificate, setCurrentBirtCertificate] = useState<any>();
  const [birthDocumentUrl, setBirthDocumentUrl] = useState<any>();
  const [birthDocumentName, setBirthDocumentName] = useState<any>();

  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [currentAvatar, setCurrentAvatar] = useState<any>();
  const [hoveredAvatar, setHoveredAvatar] = useState<boolean>();
  const [isAvatarError, setIsAvatarError] = useState<boolean>(false);

  const [selectedFamilyTitle, setSelectedFamilyTitle] = useState<any>();
  const [selectedFamilyCouncilTitle, setSelectedFamilyCouncilTitle] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [identityDocument, setIdentityDocument] = useState<any>();

  // const [femaleUsers, setFemaleUsers] = useState<any>([]);
  // const [allUsers, setAllUsers] = useState<any>([]);

  const [cityOptions, setCityOptions] = useState<any>([]);
  const [districtOptions, setDistrictOptions] = useState<any>([]);
  const [wardOptions, setWardOptions] = useState<any>([]);
  const [branchOptions, setBranchOptions] = useState<any>([]);
  const [clanOptions, setClanOptions] = useState<any>([]);
  const [lineageOptions, setLineageOptions] = useState<any>([]);
  const [GenerationOptions, setGenerationOptions] = useState<any>([]);

  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [selectedWard, setSelectedWard] = useState<any>();
  const [selectedBranch, setSelectedBranch] = useState<any>();
  const [selectedClan, setSelectedClan] = useState<any>();
  const [selectedLineage, setSelectedLineage] = useState<any>();
  const [selectedGeneration, setSelectedGeneration] = useState<any>();
  const [selectedpositionInFamily, setSelectedPositionInFamily] = useState<any>();

  const [selectedFather, setSelectedFather] = useState<any>();
  // const [selectedMother, setSelectedMother] = useState<any>();
  const [motherData, setMotherData] = useState<any>();
  const [selectedHusbandORWife, setSelectedHusbandORWife] = useState<any>(['']);
  const [selectedOlderBrother, setSelectedOlderBrother] = useState<any>(['']);
  const [selectedYoungerBrother, setSelectedYoungerBrother] = useState<any>(['']);
  const [selectedChildrent, setSelectedChildrent] = useState<any>(['']);
  const [selectedSister, setSelectedSister] = useState<any>(['']);

  const [lunarDateOfDeath, setLunarDateOfDeath] = useState<any>();
  const [solarDateOfDeath, setSolarDateOfDeath] = useState<any>();

  const [isShowAddGenerationModal, setIsShowAddGenerationModal] = useState<boolean>(false);
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);

  const isReview = searchParams.get('type');

  const [onSubmitReview, setOnSubmitReview] = useState<boolean>(false);
  

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setError,
    clearErrors,
    watch,
    formState: { errors }
  } = useForm({
    mode: 'onChange'
  });

  // const watchEmail = watch('email');
  const watchFirstName = watch('firstName');
  const watchLastName = watch('lastName');
  const watchPassword = watch('password');
  
  

  const PositionInFamilyList = useMemo(() => {
    return Array.from({ length: 20 }, (_, index) => ({
      value: `${index + 1}`,
      label: `${index + 1}`
    }));
  }, []); 

  const getListUsers = async (inputValue: string, type: string) => {
    let params: any = {
      PageNumber: 1,
      PageSize: 20,
      Status: 'Active'
    };
    if (inputValue) {
      params = {
        ...params,
        Keyword: inputValue
      }
    };
    if (type === 'male') {
      params = {
        ...params,
        Gender: 'Male'
      }
    }
    if (type === 'female') {
      params = {
        ...params,
        Gender: 'Female'
      }
    }
    const response = await UserService.getList(params);

    if (response?.data?.isSuccess === true) {
      if (response?.data?.data) {
        return response?.data?.data?.map((user: any) => {
          return { value: user.id, label: `${user.lastName} ${user.firstName} ${user.personCode}`, data: user}
        });
      }else {
        return [];
      }
      // if (type ==='male') {
      //   setMaleUsers(usersOption);
      // }
      // if (type === 'female') {
      //   setFemaleUsers(usersOption);
      // }
      // if (type === 'all') {
      //   setAllUsers(usersOption);
      // }
    }else {
      return [];
    }
  };

  const _SelectLoadMaleUsersOption = (inputValue: any) => {
    return getListUsers(inputValue, 'male');
  };

  const _SelectLoadFemaleUsersOption = (inputValue: any) => {
    return getListUsers(inputValue, 'female');
  };

  const _SelectLoadAllUsersOption = (inputValue: any) => {
    return getListUsers(inputValue, 'all');
  };

  // const SelectLoadMaleUsersOption = debounce(_SelectLoadMaleUsersOption, 300);
  // const SelectLoadFemaleUsersOption = debounce(_SelectLoadFemaleUsersOption, 300);
  // const SelectLoadAllUsersOption = debounce(_SelectLoadAllUsersOption, 300);

  // const handleCreateMother = (inputValue: string) => {
  //   const newOption = {
  //     value: 'create',
  //     label: inputValue
  //   };
  //   setFemaleUsers((prev: any) => [...prev, newOption]);
  //   setSelectedMother(newOption);
  // };

  // const handleMotherInputchange = (inputValue: string) => {
  //   if (inputValue) {
  //     const res = _SelectLoadFemaleUsersOption(inputValue);
  //     res?.then((result: any) => {
  //       setFemaleUsers(result);
  //     })
  //   }else {
  //     setFemaleUsers([]);
  //   }
  // };

  // const handleAllUserInputChange = (inputValue: string) => {
  //   if (inputValue) {
  //     const res = _SelectLoadAllUsersOption(inputValue);
  //     res?.then((result: any) => {
  //       setAllUsers(result);
  //     })
  //   }else {
  //     setAllUsers([]);
  //   }
  // };

  const handleHusbandORWifeChange = (data: any, index: number) => {
    let _selectedHusbandORWife = [...selectedHusbandORWife];
    _selectedHusbandORWife[index] = data;
    setSelectedHusbandORWife(_selectedHusbandORWife);
    clearErrors('husbandAndWife');
  };

  // const handleAddHusbandORWifeName = (inputValue: string) => {
  //   let _selectedHusbandORWife = [...selectedHusbandORWife];
  //   _selectedHusbandORWife.push({
  //     value: 'create',
  //     label: inputValue
  //   });
  //   _selectedHusbandORWife = [..._selectedHusbandORWife.filter(item => item !== '' && item !== null)];
  //   setSelectedHusbandORWife(_selectedHusbandORWife);
  //   clearErrors('husbandAndWife');
  // };

  const handleAddHusbandORWife = () => {
    if (selectedHusbandORWife[selectedHusbandORWife.length - 1] !== '') {
      let _selectedHusbandORWife = [...selectedHusbandORWife];
      _selectedHusbandORWife.push('');
      setSelectedHusbandORWife(_selectedHusbandORWife);
    }else {
      setError('husbandAndWife', {
        type: 'custom',
        message: 'Vui lòng chọn người hiện tại'
      });
    }
  };
  const handleDeleteHusbandORWife = () => {
    let _selectedHusbandORWife = [...selectedHusbandORWife];
    _selectedHusbandORWife.pop();
    setSelectedHusbandORWife(_selectedHusbandORWife);
    clearErrors('husbandAndWife');
  };

  const getDistricts = async (provinceId: string) => {
    const response = await UserService.getDistricts(provinceId);
    if (response?.data?.isSuccess === true) {
      const districtsOption = response?.data?.data?.map((item: any) => {
        return { value: item?.code, label: item?.name };
      })
      setDistrictOptions(districtsOption || []);
    }
  };

  const getWards = async (districtId: string) => {
    const response = await UserService.getWards(districtId);
    if (response?.data?.isSuccess === true) {
      const wardsOption = response?.data?.data?.map((item: any) => {
        return { value: item?.code, label: item?.name };
      })
      setWardOptions(wardsOption || []);
    }
  };

  const getOrganizations = async (params: any, type: string) => {
    const response = await UserService.getOrganization(params);
    const organizationsOption = response?.data?.data?.map((item: any) => {
      return { value: item?.id, label: item?.name, data: item };
    })
    if (response?.data?.isSuccess === true) {
      if (type === 'Branch') {
        setBranchOptions(organizationsOption || []);
      }
      if (type === 'Clan') {
        setClanOptions(organizationsOption || []);
      }
      if (type === 'Lineage') {
        setLineageOptions(organizationsOption || []);
      }
    }
  };

  const getGeneration = async () => {
    const params = {
      PageNumber: 1,
      PageSize: 1000,
    };

    const response = await UserService.getGeneration(params);
    if (response?.data?.isSuccess === true) {
      const generationsOption = response?.data?.data?.map((item: any) => {
        return { value: item?.id, label: item?.name };
      })
      setGenerationOptions(generationsOption || []);
    }
  };

  const UploadFile = async (file: any, params: any) => {
    return await UserService.uploadFile(file, params);
  };

  const getDetailUser = async (userId: string) => {
    const response = await UserService.getDetailById(userId);

    if (response?.data?.isSuccess === true) {
      setUserDetail(response?.data?.data);
    }else {
      toast(response?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
  };

  const getPersonCode = useCallback(async (params: any) => {
    const response = await UserService.getPersonCode(params);

    if (response?.data?.isSuccess === true) {
      setValue('personCode', response?.data?.data);
    }
  }, []);

  const handleSelectOrganizations = (organizations: any) => {
    
    const organizationsMap = organizations?.map((item: any) => {
      return {
        id: item.id,
        name: item?.name || item?.organizationName,
        type: item?.type || item?.organizationType
      }
    })
    const Lineage = organizationsMap?.find((org: any) => org.type === 'Lineage');
    const Clan = organizationsMap?.find((org: any) => org.type === 'Clan');
    const Branch = organizationsMap?.find((org: any) => org.type === 'Branch');

    if (Branch) {
      setSelectedBranch({value: Branch.id, label: Branch.name});
      setValue('branch', Branch.id, {shouldValidate: true});
      getOrganizations({
        ParentId: Branch.id,
        PageNumber: 1,
        PageSize: 1000
      }, 'Clan');
    };
    if (Clan) {
      setSelectedClan({value: Clan.id, label: Clan.name});
      setValue('clan', Clan.id, {shouldValidate: true});
      getOrganizations({
        ParentId: Clan.id,
        PageNumber: 1,
        PageSize: 1000
      }, 'Lineage');
    };
    if (Lineage) setSelectedLineage({value: Lineage.id, label: Lineage.name});
  };

  useEffect(() => {

    const getProvinces = async () => {
      const response = await UserService.getProvinces();
      if (response?.data?.isSuccess === true) {
        const provincesOption = response?.data?.data?.map((item: any) => {
          return { value: item?.code, label: item?.name };
        })
        setCityOptions(provincesOption || []);
      }
    };
    getProvinces();
    getGeneration();
    getOrganizations({
      Type: 'Branch',
      PageNumber: 1,
      PageSize: 1000
    }, 'Branch');
  }, []);

  useEffect(() => {
    if (paramsUrl?.id) {
      getDetailUser(paramsUrl?.id);
    }
  }, [paramsUrl?.id]);

  useEffect(() => {
    if (selectedFather && selectedGeneration && selectedpositionInFamily) {
      const params = {
        FatherPersonId: selectedFather.value,
        GenerationId: selectedGeneration.value,
        PositionInFamily: selectedpositionInFamily.value
      }
      getPersonCode(params);
    }

  }, [selectedFather, selectedGeneration, selectedpositionInFamily, getPersonCode]);

  useEffect(() => {
    if (userDetail) {
      setValue('firstName', userDetail.firstName);
      setValue('lastName', userDetail.lastName);
      setValue('personCode', userDetail.personCode);
      setValue('email', userDetail.email);
      setValue('street', userDetail.street);
      setValue('ward', userDetail.ward);
      setValue('district', userDetail.district);
      setValue('city', userDetail.city);
      setValue('career', userDetail.career);
      
      setValue('personalHistory', userDetail.personalHistory);
      setValue('password', userDetail.password);
      setValue('dateOfBirth', userDetail.dateOfBirth);
      setSelectedStatus(userDetail.status);
      if (userDetail.status === 'Active') {
        setValue('isActive', true);
      }else {
        setValue('isActive', false);
      }
      setSelectedGeneration({
        value: userDetail.generation?.id,
        label: userDetail.generation?.name
      });
      setValue('generationId', userDetail.generation?.id, {shouldValidate: true});
      setSelectedPositionInFamily({
        value: userDetail.positionInFamily,
        label: userDetail.positionInFamily
      });
      setValue('positionInFamily', userDetail.positionInFamily, {shouldValidate: true})
      setIsMartyrs(userDetail.isMartyr);
      setPhoneNumber(userDetail.phoneNumber);
      setSelectedGender(userDetail.gender);
      if (userDetail.dateOfBirth) setDob(new Date(userDetail.dateOfBirth));
      if (userDetail.dateOfDeath) {
        setSolarDateOfDeath(new Date(userDetail.dateOfDeath));
        const lunarDay = handleConvertSolarToLunar(new Date(userDetail.dateOfDeath));
        if (lunarDay?.length > 0) {
          setLunarDateOfDeath(`${lunarDay[0]}/${lunarDay[1]}/${lunarDay[2]}`);
        }
      } 

      if(userDetail.relationships?.length > 0) {
        const father = userDetail.relationships?.find((user: any) => user.type === RelationshipType.Father);
        const mother = userDetail.relationships?.find((user: any) => user.type === RelationshipType.Mother);
        const husbandORWife = userDetail.relationships?.filter((user: any) => user.type === (userDetail.gender === 'Male' ? RelationshipType.Wife : RelationshipType.Husband));
        const olderBrother = userDetail.relationships?.filter((user: any) => user.type === RelationshipType.OlderBrother);
        const youngerBrother = userDetail.relationships?.filter((user: any) => user.type === RelationshipType.YoungerBrother);
        const olderSister = userDetail.relationships?.filter((user: any) => user.type === RelationshipType.OlderSister);
        const children = userDetail.relationships?.filter((user: any) => user.type === RelationshipType.Children);
        if (father) setSelectedFather({value: father.personWithId, label: getFullName(father.firstName, father.lastName), data: father});
        if (mother) setMotherData({value: mother.personWithId || 'create', label: mother.fullname});
        if (husbandORWife?.length > 0) {
          setSelectedHusbandORWife(
            husbandORWife?.map((item: any) => {
              return {
                value: item.personWithId || 'create',
                label: item.firstName ? getFullName(item.firstName, item.lastName) : item.fullname,
                data: item
              }
            })
          )
        }
        if (olderBrother?.length > 0) {
          setSelectedOlderBrother(
            olderBrother?.map((item: any) => {
              return {
                value: item.personWithId,
                label: getFullName(item.firstName, item.lastName),
                data: item
              }
            })
          )
        }
        if (youngerBrother?.length > 0) {
          setSelectedYoungerBrother(
            youngerBrother?.map((item: any) => {
              return {
                value: item.personWithId,
                label: getFullName(item.firstName, item.lastName),
                data: item
              }
            })
          )
        }
        if (olderSister?.length > 0) {
          setSelectedSister(
            olderSister?.map((item: any) => {
              return {
                value: item.personWithId,
                label: getFullName(item.firstName, item.lastName),
                data: item
              }
            })
          )
        }
        if (children?.length > 0) {
          setSelectedChildrent(
            children?.map((item: any) => {
              return {
                value: item.personWithId,
                label: getFullName(item.firstName, item.lastName),
                data: item
              }
            })
          )
        }
      }

      if (userDetail.organizations?.length > 0) {
        handleSelectOrganizations(userDetail.organizations);
      }

      if (userDetail.familyTitle) {
        setSelectedFamilyTitle(FamilyTitleOptions?.find((familyTitle: any) => familyTitle.value === userDetail.familyTitle));
      }

      if (userDetail.familyCouncilTitle) {
        setSelectedFamilyCouncilTitle(FamilyCouncilTitleOptions?.find((familyCouncilTitle: any) => familyCouncilTitle.value === userDetail.familyCouncilTitle));
      }

      if (userDetail.role) {
        setUserRole(UserRoleOptions?.find((userRole: any) => userRole.value === userDetail.role));
      }

      if (userDetail.avatar) {
        setAvatarUrl(userDetail.avatar);
      }

      if (userDetail.birthDocument) {
        setBirthDocumentUrl(userDetail.birthDocument);
      }

      if (userDetail.birthDocumentName) {
        setBirthDocumentName(userDetail.birthDocumentName);
      }

      if (userDetail.identityDocument) {
        setIdentityDocument(userDetail.identityDocument);
        setValue('identityDocument', userDetail.identityDocument);
      }

      //relationships
    }
  }, [userDetail]);

  useEffect(() => {
    if (userDetail?.city && cityOptions?.length > 0) {
      const cityValue = cityOptions?.find((city: any) => city.label === userDetail.city);
      if (cityValue) {
        setSelectedCity(cityValue);
        getDistricts(cityValue.value);
      }
    }
  }, [userDetail, cityOptions]);

  useEffect(() => {
    if (userDetail?.district && districtOptions.length > 0) {
      const districtValue = districtOptions?.find((district: any) => district.label === userDetail.district);
      if (districtValue) {
        setSelectedDistrict(districtValue);
        getWards(districtValue.value);
      }
    }
  }, [userDetail, districtOptions]);

  useEffect(() => {
    if (userDetail?.ward && wardOptions.length > 0) {
      setSelectedWard(wardOptions?.find((ward: any) => ward.label === userDetail.ward));
    }
  }, [userDetail, wardOptions]);

  useEffect(() => {
    if (userRole) {
      if (watchFirstName && watchPassword && watchLastName && selectedGender && dob && selectedBranch && selectedClan && selectedGeneration && selectedpositionInFamily && (Object.keys(errors).length === 0)) {
        setIsDisabledSubmitBtn(false);
      }else {
        setIsDisabledSubmitBtn(true);
      }
    }else {
      if (watchFirstName && watchLastName && selectedGender && dob && selectedBranch && selectedClan && selectedGeneration && selectedpositionInFamily && (Object.keys(errors).length === 0)) {
        setIsDisabledSubmitBtn(false);
      }else {
        setIsDisabledSubmitBtn(true);
      }
    }
  }, [userRole, selectedGender, dob, selectedBranch, 
    selectedClan, selectedGeneration, selectedpositionInFamily, watchFirstName, watchLastName, watchPassword, errors
  ]);

  const handleGenderSelect = (gender: any) => {
    setSelectedGender(gender);
    setValue('gender', gender);
  };

  const handleUserRoleChange = (role: string) => {
    setUserRole(role);
  };

  const handleBirthCertificateChange = (e: any) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    if (!ALLOWED_FILE_TYPES.includes(file.type)) {
      toast('Chỉ cho phép upload file hình ảnh (JPEG, PNG), file PDF, và file DOC!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
      return;
    }

    if (file.size > MAX_FILE_SIZE_BYTES) {
      toast('File không được vượt quá 5mb!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
      return;
    }

    setCurrentBirtCertificate(file);
    setBirthDocumentName(file.name);
    if (paramsUrl?.id) {
      setBirthDocumentUrl(undefined);
      setBirthDocumentName(undefined);
    }
  };

  const handleDeleteBirthCertificate = () => {
    setBirthDocumentUrl(undefined);
    setBirthDocumentName(undefined);
  }

  const handleFamilyTitleChange = (familyTitle: any) => {
    if (!familyTitle) {
      setSelectedFamilyTitle('');
      setValue('familyTitle', undefined);
      return;
    }
    setSelectedFamilyTitle(familyTitle);
    setValue('familyTitle', familyTitle.value);
  };

  const handleFamilyCouncilTitleChange = (familyCouncilTitle: any) => {
    if (!familyCouncilTitle) {
      setSelectedFamilyCouncilTitle('');
      setValue('familyCouncilTitle', undefined);
      return;
    }
    setSelectedFamilyCouncilTitle(familyCouncilTitle);
    setValue('familyCouncilTitle', familyCouncilTitle.value);
  };

  const handleDateOfBirth = (date: any) => {
    setDob(date);
    setValue('dateOfBirth', date);
  };

  const handleAvatarChange = (e: any) => {
    setCurrentAvatar(e.target.files[0]);
    setIsAvatarError(false);

    const img = new Image();
    img.src = window.URL.createObjectURL(e.target.files[0]);

    img.onload = () => {
      setAvatarUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleAvatarOnError = (e: any) => {
    e.target.src = AvatarDefault;
    setIsAvatarError(true);
  };

  const handleLunarDateOfDeathChange = (date: any) => { 
    setSolarDateOfDeath(date);
    if (date) {
      const lunarDay = handleConvertSolarToLunar(date);
      if (lunarDay?.length > 0) {
        setLunarDateOfDeath(`${lunarDay[0]}/${lunarDay[1]}/${lunarDay[2]}`);
      }
    }else {
      setLunarDateOfDeath(undefined);
    }
  };

  const handleConvertSolarToLunar = (date: any) => {
    const solarDay = date.getDate();
    const solarMonth = date.getMonth() + 1;
    const solarYear = date.getFullYear();
    const formattedDay = solarDay < 10 ? `0${solarDay}` : solarDay.toString();
    const formattedMonth = solarMonth < 10 ? `0${solarMonth}` : solarMonth.toString();
    return convertSolar2Lunar(parseInt(formattedDay), parseInt(formattedMonth), parseInt(solarYear), 7);
  }

  const renderDayContents = (day: any, date: any) => {
      const lunarDay = handleConvertSolarToLunar(date);
      const renderLunarDay = (lunarDay?.length > 0 && parseInt(lunarDay[0]) === 1) ? `${lunarDay[0]}/${lunarDay[1]}` : 
      (lunarDay?.length > 0 && parseInt(lunarDay[0]) > 1) ? `${lunarDay[0]}` : '';

    return <div className='relative border border-gray-300'>
      <div>{day}</div>
      <div className='w-2 h-2 absolute right-[3px] bottom-3 text-[9px]'>{renderLunarDay}</div>
    </div>;
  };

  const compressImage = async (imageFile: any) => {
    try {
      const options = {
        maxSizeMB: 0.9, // kích thước tối đa sau khi nén, ở đây là 1MB
        maxWidthOrHeight: 1920, // chiều rộng hoặc chiều cao tối đa
        useWebWorker: true // sử dụng web worker để tăng hiệu suất (khuyến nghị)
      };
  
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Lỗi khi nén ảnh:', error);
      return null;
    }
  };

  const onSubmit = async (data: any) => {
    try {
      let lunarDay: any;

      if (solarDateOfDeath) {
        lunarDay = handleConvertSolarToLunar(new Date(solarDateOfDeath));
      }

      let params: any = {
        ...data,
        organizationId: selectedLineage?.value || selectedClan?.value,
        generationId: selectedGeneration?.value,
        positionInFamily: parseInt(selectedpositionInFamily?.value),
        isMartyr: isMartyrs,
        familyTitle: selectedFamilyTitle?.value,
        familyCouncilTitle: selectedFamilyCouncilTitle?.value,
        gender: selectedGender,
        dateOfBirth: moment(dob).format('YYYY-MM-DDT12:12:12'),
        role: userRole?.value,
        identityDocument: identityDocument,
        phoneNumber: phoneNumber
      }
      let relationships: any = [];

      if (currentAvatar) {
        let paramsAvatar: any = {
          folder: 'Avatar'
        }
        if(paramsUrl?.id) {
          paramsAvatar = {
            ...paramsAvatar,
            userId: paramsUrl?.id
          }
        }
        const compressAvatar = await compressImage(currentAvatar);
        const responseAvatar = await UploadFile(compressAvatar, paramsAvatar);
        if (responseAvatar?.data?.isSuccess === true) {
          params = {
            ...params,
            avatar: responseAvatar?.data?.data?.path,
            transactionId: responseAvatar?.data?.data?.transactionId
          };
  
          if(currentBirtCertificate) {
            const paramsBirtCertificate = {
              folder: 'BirthDocument',
              userId: paramsUrl?.id || responseAvatar?.data?.data?.transactionId
            }
            const responseBirtCertificate = await UploadFile(currentBirtCertificate, paramsBirtCertificate);
            if (responseBirtCertificate?.data?.isSuccess === true) {
              params = {
                ...params,
                birthDocument: responseBirtCertificate?.data?.data?.path,
                birthDocumentName: currentBirtCertificate.name
              };
            }
          }else {
            params = {
              ...params,
              birthDocument: birthDocumentUrl,
              birthDocumentName: birthDocumentName
            };
          }
        }
      }else {
        params = {
          ...params,
          avatar: avatarUrl,
        };

        if(currentBirtCertificate) {
          let paramsBirtCertificate: any = {
            folder: 'BirthDocument'
          }
          if (paramsUrl?.id) {
            paramsBirtCertificate = {
              ...paramsBirtCertificate,
              userId: paramsUrl?.id
            }
          }
          const responseBirtCertificate = await UploadFile(currentBirtCertificate, paramsBirtCertificate);
          if (responseBirtCertificate?.data?.isSuccess === true) {
            params = {
              ...params,
              birthDocument: responseBirtCertificate?.data?.data?.path,
              transactionId: responseBirtCertificate?.data?.data?.transactionId,
              birthDocumentName: currentBirtCertificate.name
            };
          }
        }else {
          params = {
            ...params,
            birthDocument: birthDocumentUrl,
            birthDocumentName: birthDocumentName
          };
        }
      }

      if (lunarDay?.length > 0) {
        const formattedDay = lunarDay[0] < 10 ? `0${lunarDay[0]}` : lunarDay[0].toString();
        const formattedYear = lunarDay[2] < 10 ? `0${lunarDay[2]}` : lunarDay[2].toString();
        const formattedMonth = lunarDay[1] < 10 ? `0${lunarDay[1]}` : lunarDay[1].toString();
        params = {
          ...params,
          lunarDateOfDeath: `${formattedYear}-${formattedMonth}-${formattedDay}T12:12:12`,
        }
      }

      if (userRole) {
        params = {
          ...params,
          role: userRole.value
        }
      }
      
      if (selectedFather) {
        relationships = [
          {
            personId: selectedFather.value,
            fullName: `${selectedFather.data.lastName} ${selectedFather.data.firstName}`,
            type: "Father",
            notes: ""
          }
        ]
      }

      if (motherData) {
        relationships = [
          ...relationships,
          {
            personId: motherData.value === 'create' ? null : motherData.value,
            fullName: motherData.label,
            type: "Mother",
            notes: ""
          }
        ]
      }
      if (selectedHusbandORWife?.length > 0 && selectedHusbandORWife[0]) {
        selectedHusbandORWife?.filter((item: any) => item !== '').forEach((itemMap: any) => {
          relationships = [
            ...relationships,
            {
              personId: itemMap.value === 'create' ? null : itemMap.value,
              fullName: itemMap.label,
              type: selectedGender === 'Male' ? 'Wife' : 'Husband',
              notes: ""
            }
          ]
        })
      }

      if (selectedOlderBrother?.length > 0 && selectedOlderBrother[0]) {
        selectedOlderBrother?.filter((item: any) => item !== '').forEach((itemMap: any) => {
          relationships = [
            ...relationships,
            {
              personId: itemMap.value,
              fullName: `${itemMap.data.lastName} ${itemMap.data.firstName}`,
              type: 'OlderBrother',
              notes: ""
            }
          ]
        })
      }

      if (selectedYoungerBrother?.length > 0 && selectedYoungerBrother[0]) {
        selectedYoungerBrother?.filter((item: any) => item !== '').forEach((itemMap: any) => {
          relationships = [
            ...relationships,
            {
              personId: itemMap.value,
              fullName: `${itemMap.data.lastName} ${itemMap.data.firstName}`,
              type: 'YoungerBrother',
              notes: ""
            }
          ]
        })
      }
      
      if (selectedChildrent?.length > 0 && selectedChildrent[0]) {
        selectedChildrent?.filter((item: any) => item !== '').forEach((itemMap: any) => {
          relationships = [
            ...relationships,
            {
              personId: itemMap.value,
              fullName: `${itemMap.data.lastName} ${itemMap.data.firstName}`,
              type: 'Children',
              notes: ""
            }
          ]
        })
      }

      if (selectedSister?.length > 0 && selectedSister[0]) {
        selectedSister?.filter((item: any) => item !== '').forEach((itemMap: any) => {
          relationships = [
            ...relationships,
            {
              personId: itemMap.value,
              fullName: `${itemMap.data.lastName} ${itemMap.data.firstName}`,
              type: 'OlderSister',
              notes: ""
            }
          ]
        })
      }

      if (relationships?.length > 0) {
        params = {
          ...params,
          relationships: relationships
        }
      }

      if (paramsUrl?.id) {
        const response = await UserService.updateUser({
          ...params
        }, paramsUrl?.id);
    
        if (response?.data?.isSuccess === true) {
          toast('Đã cập nhật thông tin người thành công', {
            type: 'success',
            icon: <CheckCircle className="text-[#007B40]" />
          });
          if (!isReview) {
            navigate('/quan-ly/list-users');
          }
        }else {
          toast(response?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
            type: 'error',
            icon: <AlertTriangle className="text-[#F03D3E]" />
          });
        }

      }else {
        const response = await UserService.addUser({
          ...params
        });
    
        if (response?.data?.isSuccess === true) {
          toast('Đã thêm mới người thành công', {
            type: 'success',
            icon: <CheckCircle className="text-[#007B40]" />
          });
          resetForm();
        }else {
          toast(response?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
            type: 'error',
            icon: <AlertTriangle className="text-[#F03D3E]" />
          });
        }
      }

    } catch (error) {
      handleErrorToast(error);
      // toast('Lỗi kết nối mạng!', {
      //   type: 'error',
      //   icon: <AlertTriangle className="text-[#F03D3E]" />
      // });
    }
  };

  const handleStatusChange = (data: any) => {
    setSelectedStatus(data);
    if (data === 'Active') {
      setValue('isActive', true);
    }else {
      setValue('isActive', false);
    }
  };

  const handleIsMartyrsChange = (data: any) => {
    setIsMartyrs(data);
    setValue('isMartyr', data);
  };
  
  const handleCityChange = (data: any) => {
    setSelectedCity(data);
    setValue('city', data?.label);
    setSelectedDistrict('');
    setSelectedWard('');
    setWardOptions([]);
    if (data) {
      getDistricts(data.value);
    }else {
      setDistrictOptions([]);
    }
  };

  const handleDistrictChange = (data: any) => {
    setSelectedDistrict(data);
    setValue('district', data?.label);
    setSelectedWard('');
    if(data) {
      getWards(data.value);
    }
  };

  const handleWardChange = (data: any) => {
    setSelectedWard(data);
    setValue('ward', data?.label);
  };

  const handleBranchChange = (data: any) => {
    setSelectedBranch(data);
    setValue('branch', data?.value, {shouldValidate: true});
    setSelectedClan('');
    setSelectedLineage('');
    setLineageOptions([]);
    if(data) {
      getOrganizations({
        ParentId: data.value,
        PageNumber: 1,
        PageSize: 1000
      }, 'Clan');
    }
  };

  const handleClanChange = (data: any) => {
    setSelectedClan(data);
    setValue('clan', data?.value, {shouldValidate: true});
    setSelectedLineage('');
    if(data) {
      getOrganizations({
        ParentId: data.value,
        PageNumber: 1,
        PageSize: 1000
      }, 'Lineage');
    }
  };

  const handleGenerationChange = (data: any) => {
    setSelectedGeneration(data);
    setValue('generationId', data?.value, {shouldValidate: true});
  };

  const handleCloseGenerationModal = () => {
    setIsShowAddGenerationModal(false);
    getGeneration();
  };

  const handleAddGenerationSuccess = (data: any) => {
    setSelectedGeneration({
        value: data?.id,
        label: data?.name
    });
  };

  const handleFatherChange = (data: any) => {
    setSelectedFather(data);
    
    if (data?.data?.organizations?.length > 0) {
      handleSelectOrganizations(data?.data?.organizations);
    }
  };

  const handleClickCancelBtn = () => {
    if (paramsUrl?.id) {
      navigate('/quan-ly/list-users');
    }else {
      resetForm();
    }
  };

  const resetForm = () => {
    reset();
    setUserRole('');
    setSelectedGender('Male');
    setSelectedStatus('Active');
    setDob(undefined);
    setSelectedCity('');
    setSelectedDistrict('');
    setSelectedWard('');
    setSolarDateOfDeath(undefined);
    setLunarDateOfDeath(undefined);
    setSelectedFather('');
    // setSelectedMother('');
    setSelectedBranch('');
    setSelectedClan('');
    setSelectedLineage('');
    setSelectedGeneration('');
    setSelectedPositionInFamily('');
    setIsMartyrs(false);
    setSelectedFamilyTitle('');
    setSelectedFamilyCouncilTitle('');
    setSelectedHusbandORWife(['']);
    setSelectedOlderBrother(['']);
    setSelectedYoungerBrother(['']);
    setSelectedChildrent(['']);
    setSelectedSister(['']);
    setIdentityDocument('');
    setPhoneNumber('');
    setCurrentAvatar(undefined);
    setCurrentBirtCertificate(undefined);
    setAvatarUrl(undefined);
    setBirthDocumentUrl(undefined);
    setMotherData(undefined);
  };

  const handleSubmitReview = async (typeModal: string) => {
    setOnSubmitReview(true);
    let params: any;

    if (typeModal === 'confirm') {
      params = {
        isApprove: true,
        reason: ''
      }
    }else {
      params = {
        isApprove: false,
        reason: ''
      }
    };

    const response = await UserService.submitReview(paramsUrl?.id || '', params);

    if (response?.data?.isSuccess === true) {
      let mess: string;
      if (typeModal === 'confirm') {
        mess = 'Phê duyệt người mới thành công';
      }else {
        mess = 'Từ chối người mới thành công';
      }
      toast(mess, {
        type: 'success',
        icon: <CheckCircle className="text-[#007B40]" />
      });
      navigate('/quan-ly/pending-user-approval');
    }else {
      toast(response?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
    setOnSubmitReview(false); 
  };

  const handleDownload = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      
      if (!response.ok) {
        throw new Error('Network response was not ok.');
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
};

  return (
    <>
      <div className=' flex justify-between'>
        <div className='flex items-center gap-[11px]'>
          <AddUserSide fillColor={`#461511`}/>
          <p className=' text-base font-semibold leading-5 text-bg_brown'>Thêm người mới</p>
          {isReview &&
            <>
              <span className="font-medium">&gt;&gt;</span>
              <p className='text-base font-semibold leading-5 text-bg_brown'>{'Chi tiết người mới'}</p>
              <span className="font-medium">&gt;&gt;</span>
              <p className='text-base font-semibold leading-5 text-bg_brown'>{getValues('personCode')}</p>
            </>
          }
        </div>
        {/* {!paramsUrl.id &&
          <div className=' text-sm font-normal leading-5'>
            {(auth?.user?.Role === UserRoleEnum.SuperAdmin || auth?.user?.Role === UserRoleEnum.EditAdmin) &&
              <Fragment>
                <ImportFileBtn/>
              </Fragment>
            }
          </div>
        } */}
      </div>
      {isReview &&
        <div className='py-1 text-base font-normal text-[#595959]'>
          {userDetail?.userApprovals?.map((item: any, index: number) => (
            <div key={index}>
              {`${userDetail.lastName} ${userDetail.firstName} đã được phê duyệt bởi 
                ${item?.adminLastName} ${item?.adminFirstName} vào ngày ${moment(item?.approvalDate).format('DD/MM/YYYY')}. Người này cần thêm phê duyệt từ bạn để vào phả tộc.`}
            </div>
          ))}
        </div>
      }
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete='off'
      >
        <FormCard className='mt-[7px] rounded'>
          <div className='text-sm font-medium leading-4 text-bg_brown'>Thông tin cá nhân</div>
          <div className='flex gap-[74px] mt-[6px]'>
            <div className='w-1/2'>
              <div className='flex gap-[18px]'>
                <div className='flex items-center justify-center'>
                  <div className='w-[114px] h-[114px] rounded-2xl border border-bg_brown flex items-center justify-center relative'>
                    {!avatarUrl &&
                      <div className='flex items-center justify-center z-10'>
                        <img src={AvatarDefault} alt="AvatarDefault" width={51} height={72.39}/>
                        <input 
                          type="file"
                          title="Nhấn để chọn ảnh"
                          accept=".jpg, .jpeg, .png"
                          className=' opacity-0 absolute top-0 right-0 left-0 bottom-0 w-full cursor-pointer'
                          {...register('avatar_url', { 
                            required: false,
                            onChange: (event) => handleAvatarChange(event)
                          })}
                        />
                      </div>
                    }
                    {avatarUrl &&
                      <>
                        <img 
                        src={avatarUrl} 
                        alt="avatarUrl" 
                        className={`${isAvatarError ? 'w-[51px] h-[72.39px]' : 'w-[112px] h-[112px]'} rounded-2xl`}
                        onMouseEnter={() => setHoveredAvatar(true)}
                        onMouseLeave={() => setHoveredAvatar(false)}
                        onError={handleAvatarOnError}
                        />
                        {hoveredAvatar &&
                          <div 
                          className='absolute top-0 right-0 bottom-0 left-0 rounded-2xl bg-black bg-opacity-30 flex items-center justify-center gap-5'
                          onMouseEnter={() => setHoveredAvatar(true)}
                          onMouseLeave={() => setHoveredAvatar(false)}
                          >
                            <div className='w-5 h-5 relative'>
                              <div className='flex items-center justify-center'>
                                <Edit size={20} className=' text-table_primary_btn'/>
                                <input 
                                  type="file"
                                  title="Nhấn để chọn ảnh" 
                                  name="avatarEdit" 
                                  accept=".jpg, .jpeg, .png"
                                  className=' opacity-0 absolute top-0 left-0 w-full h-full z-10 cursor-pointer'
                                  onChange={(event) => {
                                    handleAvatarChange(event);
                                    event.stopPropagation();
                                  }}
                                />
                              </div>
                            </div>
                            <Trash 
                            size={20} 
                            className=' text-table_primary_btn cursor-pointer'
                            onClick={(event) => {
                              setCurrentAvatar(undefined);
                              setAvatarUrl(undefined);
                              event.stopPropagation();
                            }}
                            />
                          </div>
                        }
                      </>
                    }
                  </div>
                </div>
                <div className='flex-1 space-y-3'>
                  <div className='form-group'>
                    <div className="form-label min-w-[50px]">Mã số</div>
                    <input 
                    type="text" 
                    className='form-input flex-1' 
                    placeholder='Nhập mã số' 
                    disabled
                    {...register('personCode')}
                    />
                  </div>
                  <div className='form-group'>
                    <label className="form-label min-w-[50px] required-after">Tên</label>
                    <input 
                    type="text" 
                    className={`form-input flex-1 ${errors?.firstName ? 'form-error' : ''}`}
                    placeholder='Nhập tên người dùng'
                    {...register('firstName', {
                      required: 'Vui lòng nhập tên người dùng',
                    })}
                    />
                  </div>
                  {errors?.firstName &&
                    <div className="text-red-500 text-sm text-end">{errors?.firstName?.message as string}</div>}
                  <div className='form-group'>
                    <label className="form-label min-w-[50px] required-after">Họ</label>
                    <input 
                    type="text" 
                    className={`form-input flex-1 ${errors?.lastName ? 'form-error' : ''}`} 
                    placeholder='Nhập họ người dùng'
                    {...register('lastName', {
                      required: 'Vui lòng nhập họ người dùng',
                    })}
                    />
                  </div>
                  {errors?.lastName &&
                    <div className="text-red-500 text-sm text-end">{errors?.lastName?.message as string}</div>}
                </div>
              </div>
              <div className='space-y-3 mt-3'>
                <div className='form-group'>
                  <div className={`form-label min-w-[110px] required-after`}>Giới tính</div>
                  <div className='flex gap-[54px] items-center min-h-8'>
                    <div>
                      <label className='custom-radio'>
                        <input
                          id='accent'
                          type="radio"
                          name="gender"
                          value="male"
                          checked={selectedGender === 'Male'}
                          onChange={() => handleGenderSelect('Male')}
                          className='mr-2'
                        />
                        Nam
                      </label>
                    </div>
                    <div>
                      <label className='custom-radio'>
                        <input
                          type="radio"
                          name="gender"
                          value="female"
                          checked={selectedGender === 'Female'}
                          onChange={() => handleGenderSelect('Female')}
                          className='mr-2'
                        />
                        Nữ
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className={`form-label min-w-[110px] required-after`}>Ngày sinh</div>
                  <div className='relative flex-1'>
                    <DatePicker 
                      selected={dob} 
                      onChange={(date: any) => handleDateOfBirth(date)} 
                      className={`form-input flex-1 z-20 ${errors?.dateOfBirth ? 'form-error' : ''}`}
                      dateFormat={"dd/MM/yyyy"}
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText='Chọn ngày sinh'
                      id='dob-id'
                    />
                    <img 
                    src={DatePickerIcon} alt="DatePickerIcon" 
                    className=' absolute top-2 z-1 right-2 w-[16px] h-[16px]'
                    onClick={() => document?.getElementById('dob-id')?.click()}
                    />
                    <input 
                    type="hidden" 
                    className='form-input flex-1' 
                    {...register('dateOfBirth', {
                      required: 'Vui lòng nhập ngày sinh',
                    })}
                    />
                  </div>
                </div>
                {errors?.dateOfBirth &&
                    <div className="text-red-500 text-sm text-end">{errors?.dateOfBirth?.message as string}</div>}
                <div className='form-group'>
                  <div className="form-label min-w-[110px]">CMND / CCCD</div>
                  <NumericFormat
                  displayType="input"
                  thousandSeparator={false}
                  allowNegative={false}
                  allowLeadingZeros={true} 
                  value={identityDocument}
                  onValueChange={(value) => {setIdentityDocument(value?.value)}} 
                  className='form-input flex-1'
                  placeholder='Nhập CMND / CCCD'
                  />
                </div>
                <div className='flex'>
                  <div className="form-label min-w-[110px] items-start">Lịch sử cá nhân</div>
                  <textarea 
                  className='form-input-area flex-1 h-[126px]' 
                  placeholder='Nhập lịch sử cá nhân'
                  {...register('personalHistory', {
                    required: false,
                  })}
                  />
                </div>
                <div className='form-group'>
                  <div className="form-label min-w-[110px]">Giấy khai sinh</div>
                  <div className='flex items-center gap-[6px] relative z-10 cursor-pointer'>
                    {!currentBirtCertificate && !birthDocumentUrl &&
                      <>
                        <input 
                        type="file" 
                        title="Nhấn để chọn tệp"
                        name="birthCertificate" 
                        className=' opacity-0 absolute top-0 left-0 w-full h-full'
                        onChange={(event) => handleBirthCertificateChange(event)}
                        style={{cursor: 'pointer'}}
                        />
                        <img src={PaperClipIcon} alt="PaperClipIcon" width={20} height={20}/>
                        <div className='text-[#0031AF] text-sm font-normal leading-4'>Đính kèm file</div>
                      </>
                    }
                    {currentBirtCertificate && !birthDocumentUrl &&
                      <>
                        <FileText size={20} className=' text-table_primary_btn'/>
                        <button 
                        className='text-[#0031AF] text-sm font-normal leading-4 max-w-[240px] line-clamp-1'
                        onClick={() => handleDownload(currentBirtCertificate, currentBirtCertificate?.name)} type='button'
                        >
                          {currentBirtCertificate?.name || '--'}
                        </button>
                        <Trash size={20} 
                        className=' text-table_primary_btn cursor-pointer' 
                        onClick={() => setCurrentBirtCertificate(undefined)}/>
                      </>
                    }
                    {!currentBirtCertificate && birthDocumentUrl &&
                      <>
                      <FileText size={20} className=' text-table_primary_btn'/>
                      <button onClick={() => handleDownload(birthDocumentUrl, birthDocumentName)} type='button'
                      className='text-[#0031AF] text-sm font-normal leading-4 max-w-[240px] line-clamp-1' 
                      // href={birthDocumentUrl} 
                      // target='_blank' 
                      rel='noreferrer'>{birthDocumentName}</button>
                      <Trash size={20} className=' text-table_primary_btn cursor-pointer' onClick={() => handleDeleteBirthCertificate()}/>
                    </>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='w-1/2 space-y-3'>
              <div className='form-group'>
                <div className={`form-label min-w-[119px] ${!userRole?.value ? '' : 'required-after'}`}>Mật khẩu</div>
                <div className='flex-1 relative flex items-center gap-1'>
                  <input 
                  type={isShowPassword ? 'text' : 'password'} 
                  className='form-input flex-1' 
                  placeholder='Nhập mật khẩu'
                  {...register('password', {
                    required: userRole?.value ? 'Vui lòng nhập mật khẩu' : false,
                    validate: (value) => {
                      if (!value) return true;
                      const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.{8,})`);
                      if (!regex.test(value)) {
                        return 'Mật khẩu không đúng định dạng';
                      }
                      return true;
                    }
                  })}
                  />
                  <AlertCircle 
                  size={20} 
                  className='text-[#5A5A5A] cursor-pointer' 
                  data-tooltip-id="user-password-tooltip" 
                  data-tooltip-content={UserTooltipData.UserPasswordTip}
                  />
                  <Tooltip id="user-password-tooltip" place='bottom' className='z-10'/>
                  {isShowPassword && 
                    <Eye 
                    onClick={() => setIsShowPassword(!isShowPassword)} size={20} 
                    className='text-[#5A5A5A] absolute right-[34px] top-[6px] cursor-pointer'
                    />}
                  {!isShowPassword && 
                  <EyeOff 
                  onClick={() => setIsShowPassword(!isShowPassword)} 
                  size={20} 
                  className='text-[#5A5A5A] absolute right-[34px] top-[6px] cursor-pointer'
                  />}
                </div>
              </div>
              {errors?.password &&
                    <div className="text-red-500 text-sm text-end">{errors?.password?.message as string}</div>}
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Vai trò</div>
                {/* <input type="text" className='form-input flex-1'/> */}
                <Select
                  value={userRole}
                  onChange={handleUserRoleChange}
                  isClearable={true}
                  options={UserRoleOptions?.map((role: any) => {
                    return {
                      label: role.label,
                      value: role.value,
                      isDisabled: auth?.user?.Role === 'SuperAdmin' ? false : true
                    };
                  })}
                  className='form-select-32 z-1'
                  classNamePrefix={'select'}
                  placeholder='Chọn vai trò'
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Email</div>
                <input 
                type="text" 
                className={`form-input flex-1 ${errors?.email ? 'form-error' : ''}`} 
                placeholder='Nhập email'
                {...register('email', {
                  // required: 'Vui lòng nhập email',
                  validate: (value) => {
                    if (value) {
                      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                      if (!regex.test(value)) {
                        return 'Email không đúng, vui lòng nhập địa chỉ email khác';
                      }
                    }
                  }
                })}
                />
              </div>
              {errors?.email &&
                  <div className="text-red-500 text-sm text-end">{errors?.email?.message as string}</div>}
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Trạng thái</div>
                <div className='flex gap-[17px] items-center min-h-8'>
                  <div>
                    <label className='custom-radio'>
                      <input
                        id='accent'
                        type="radio"
                        name="statusActive"
                        value="active"
                        checked={selectedStatus === 'Active'}
                        onChange={() => handleStatusChange('Active')}
                        className='mr-2'
                      />
                      Đang hoạt động
                    </label>
                  </div>
                  <div>
                    <label className='custom-radio'>
                      <input
                        type="radio"
                        name="statusInactive"
                        value="inactive"
                        checked={selectedStatus === 'Deactive'}
                        onChange={() => handleStatusChange('Deactive')}
                        className='mr-2'
                      />
                      Ngừng hoạt động
                    </label>
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Số điện thoại</div>
                <NumericFormat
                displayType="input"
                thousandSeparator={false}
                allowNegative={false}
                allowLeadingZeros={true} 
                value={phoneNumber}
                onValueChange={(value) => {setPhoneNumber(value?.value)}} 
                className='form-input flex-1'
                placeholder='Nhập số điện thoại'
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Nghề nghiệp</div>
                <input 
                type="text" 
                className='form-input flex-1' 
                placeholder='Nhập nghề nghiệp'
                {...register('career', {
                  required: false,
                })}
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Địa chỉ</div>
                <input 
                type="text" 
                className='form-input flex-1' 
                placeholder='Nhập địa chỉ'
                {...register('street', {
                  required: false,
                })}
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Tỉnh / Thành phố</div>
                <Select
                  value={selectedCity}
                  onChange={handleCityChange}
                  options={cityOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn tỉnh / thành phố'
                  isDisabled={cityOptions?.length > 0 ? false : true}
                  isClearable={true}
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Quận / Huyện</div>
                <Select
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  options={districtOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn quận / huyện'
                  isDisabled={districtOptions?.length > 0 ? false : true}
                  isClearable={true}
                />
              </div>
              <div className='form-group'>
                <div className="form-label min-w-[119px]">Phường / Xã</div>
                <Select
                  value={selectedWard}
                  onChange={handleWardChange}
                  options={wardOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn phường / xã'
                  isDisabled={wardOptions?.length > 0 ? false : true}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </FormCard>
        <FormCard className='mt-[7px] rounded'>
          <div className='text-sm font-medium leading-4 text-bg_brown'>Thông tin tộc phả và các mối quan hệ</div>
          <div className='flex gap-[33px] mt-[6px]'>
            <div className='w-1/2 space-y-3'>

              <div className='form-group'>
                <div className="form-label min-w-[119px]">Bố</div>
                <AsyncSelect
                  value={selectedFather}
                  onChange={(data) => handleFatherChange(data)}
                  loadOptions={_SelectLoadMaleUsersOption}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn người bố'
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                  isClearable={true}
                />
              </div>

              <div className='form-group'>
                <div className={`form-label min-w-[119px] required-after`}>Nhánh</div>
                <Select
                  value={selectedBranch}
                  onChange={handleBranchChange}
                  options={branchOptions}
                  className={`${errors?.branch ? 'form-select-32-error' : 'form-select-32'}  `}
                  classNamePrefix={'select'}
                  placeholder='Chọn nhánh'
                  isDisabled={branchOptions?.length > 0 ? false : true}
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                  isClearable={true}
                />
                <input 
                  type="hidden" 
                  className='form-input flex-1' 
                  {...register('branch', {
                    required: 'Vui lòng chọn nhánh',
                  })}
                />
              </div>
              {errors?.branch &&
                    <div className="text-red-500 text-sm text-end">{errors?.branch?.message as string}</div>}

              <div className='form-group'>
                <div className={`form-label min-w-[119px] required-after`}>Chi</div>
                <Select
                  value={selectedClan}
                  onChange={handleClanChange}
                  options={clanOptions}
                  className={`${errors?.clan ? 'form-select-32-error' : 'form-select-32'}  `}
                  classNamePrefix={'select'}
                  placeholder='Chọn chi'
                  isDisabled={clanOptions?.length > 0 ? false : true}
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                  isClearable={true}
                />
                <input 
                  type="hidden" 
                  className='form-input flex-1' 
                  {...register('clan', {
                    required: 'Vui lòng chọn chi',
                  })}
                />
              </div>
              {errors?.clan &&
                    <div className="text-red-500 text-sm text-end">{errors?.clan?.message as string}</div>}

              <div className='form-group'>
                <div className="form-label min-w-[119px]">Phái</div>
                <Select
                  value={selectedLineage}
                  onChange={(data) => setSelectedLineage(data)}
                  options={lineageOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn phái'
                  isDisabled={lineageOptions?.length > 0 ? false : true}
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                  isClearable={true}
                />
              </div>

              <div className='form-group'>
                <div className={`form-label min-w-[119px] required-after`}>Đời thứ</div>
                <div className='flex-1 flex items-center gap-[7px]'>
                  <Select
                    value={selectedGeneration}
                    onChange={handleGenerationChange}
                    options={GenerationOptions}
                    className={`${errors?.generationId ? 'form-select-32-error' : 'form-select-32'}  `}
                    classNamePrefix={'select'}
                    placeholder='Chọn đời thứ mấy trong dòng họ'
                    noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                    isClearable={true}
                  />
                  <PlusCircle 
                  size={20} 
                  className=' text-table_primary_btn cursor-pointer'
                  data-tooltip-id="lineage-tooltip" 
                  data-tooltip-content={UserTooltipData.LineageTip}
                  onClick={() => setIsShowAddGenerationModal(true)}
                  />
                  <Tooltip id="lineage-tooltip" place='bottom' className='z-10'/>
                </div>
                <input 
                  type="hidden" 
                  className='form-input flex-1' 
                  {...register('generationId', {
                    required: 'Vui lòng chọn đời thứ',
                  })}
                />
              </div>
              {errors?.generationId &&
                    <div className="text-red-500 text-sm text-end">{errors?.generationId?.message as string}</div>}

              <div className='form-group'>
                <div className={`form-label min-w-[119px] required-after`}>Con thứ</div>
                <Select
                  value={selectedpositionInFamily}
                  onChange={(data) => {setSelectedPositionInFamily(data); setValue('positionInFamily', data?.value, {shouldValidate: true})}}
                  options={PositionInFamilyList}
                  className={`${errors?.positionInFamily ? 'form-select-32-error' : 'form-select-32'}  `}
                  classNamePrefix={'select'}
                  placeholder='Chọn con thứ mấy trong gia đình'
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                  isClearable={true}
                />
                <input 
                  type="hidden" 
                  className='form-input flex-1' 
                  {...register('positionInFamily', {
                    required: 'Vui lòng chọn con thứ',
                  })}
                />
              </div>
              {errors?.positionInFamily &&
                    <div className="text-red-500 text-sm text-end">{errors?.positionInFamily?.message as string}</div>}
            </div>

            <div className='w-1/2 space-y-3'>
              
              <div className='form-group'>
                <div className="form-label min-w-[159px]">Mẹ</div>
                <input 
                type="text" 
                className='form-input flex-1' 
                placeholder='Nhập tên người Mẹ'
                value={motherData?.label || ''}
                onChange={(event) => setMotherData({
                  valua: 'create',
                  label: event.target.value
                })}
                />
                {/* <CreatableSelect
                  value={selectedMother}
                  onChange={(data) => setSelectedMother(data)}
                  onInputChange={handleMotherInputchange}
                  options={femaleUsers}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Nhập tên người mẹ'
                  blurInputOnSelect={true}
                  onCreateOption={handleCreateMother}
                  formatCreateLabel={(value) => <i>{`Thêm mới: ${value}`}</i>}
                  noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                /> */}
              </div>

              <div className='form-group'>
                <div className="form-label min-w-[159px]">Ngày mất</div>
                <div className='relative flex-1'>
                  <DatePicker 
                    selected={solarDateOfDeath} 
                    onChange={(date: any) => handleLunarDateOfDeathChange(date)} 
                    className="form-input w-full"
                    dateFormat={"dd/MM/yyyy"}
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText='Chọn ngày mất'
                    renderDayContents={renderDayContents}
                    id='deathdate-id'
                  />
                  {lunarDateOfDeath && <div className=' absolute top-[6px] right-[55px] text-sm font-normal text-color_text'>{`(${lunarDateOfDeath} ÂL)`}</div>}
                  <img 
                  src={DatePickerIcon} 
                  alt="DatePickerIcon" 
                  className=' absolute top-2 right-2 w-[16px] h-[16px]'
                  onClick={() => document?.getElementById('deathdate-id')?.click()}
                  />
                </div>
              </div>

              <div className='form-group'>
                <div className="form-label min-w-[159px]">Liệt sĩ</div>
                <div className='flex gap-[17px] items-center min-h-8'>
                  <div>
                    <label className='custom-radio'>
                      <input
                        type="radio"
                        name="NotMartyrs"
                        checked={!isMartyrs}
                        onChange={() => handleIsMartyrsChange(false)}
                        className='mr-2'
                      />
                      Không
                    </label>
                  </div>
                  <div>
                    <label className='custom-radio'>
                      <input
                        type="radio"
                        name="Martyrs"
                        checked={isMartyrs}
                        onChange={() => handleIsMartyrsChange(true)}
                        className='mr-2'
                      />
                      Liệt sĩ
                    </label>
                  </div>
                </div>
              </div>

              <div className='form-group'>
                <div className="form-label min-w-[159px]">Chức năng trong họ</div>
                <Select
                  value={selectedFamilyTitle}
                  onChange={handleFamilyTitleChange}
                  options={FamilyTitleOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn chức năng trong họ'
                  isClearable={true}
                />
              </div>

              <div className='form-group'>
                <div className="form-label min-w-[159px]">Chức năng trong gia tộc</div>
                <Select
                  value={selectedFamilyCouncilTitle}
                  onChange={handleFamilyCouncilTitleChange}
                  options={FamilyCouncilTitleOptions}
                  className='form-select-32 '
                  classNamePrefix={'select'}
                  placeholder='Chọn chức năng trong HĐ gia tộc'
                  isClearable={true}
                />
              </div>
            </div>
          </div>
        </FormCard>
        <FormCard className='mt-[7px] rounded mb-10'>
          <div className='text-sm font-medium leading-4 text-bg_brown'>Mối quan hệ khác</div>
          <div className='flex gap-[33px] mt-[6px]'>
            <div className='w-1/2 space-y-3'>
              <div className='form-group'>
                <div 
                className={`form-label min-w-[119px] 
                  ${selectedHusbandORWife?.length > 1 ? ' self-start mt-1' : ''}`}
                >Chồng / Vợ</div>
                <div className='flex-1 space-y-3'>
                  {selectedHusbandORWife?.map((husbandORWife: any, index: any) => (
                    <div className='flex items-center gap-[7px]' key={`husbandORWife_${index}`}>
                      {/* <CreatableSelect
                        value={husbandORWife}
                        onChange={(data) => handleHusbandORWifeChange(data, index)}
                        onInputChange={handleAllUserInputChange}
                        options={allUsers}
                        className='form-select-32 '
                        classNamePrefix={'select'}
                        placeholder='Nhập tên người chồng / vợ'
                        blurInputOnSelect={true}
                        onCreateOption={handleAddHusbandORWifeName}
                        formatCreateLabel={(value) => <i>{`Thêm mới: ${value}`}</i>}
                        noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                      /> */}
                      <input 
                      type="text" 
                      className='form-input flex-1' 
                      placeholder='Nhập tên người chồng / vợ'
                      value={husbandORWife?.label || ''}
                      onChange={(event) => handleHusbandORWifeChange({
                        valua: 'create',
                        label: event.target.value
                      }, index)}
                      />
                      <div className='w-6 h-8 relative'>
                        {index === (selectedHusbandORWife?.length - 1) &&
                          <div className='absolute top-[7px] left-0 flex gap-1'>
                            {selectedHusbandORWife?.length > 1 &&
                              <Trash
                                size={20} 
                                className=' text-table_primary_btn cursor-pointer'
                                onClick={() => handleDeleteHusbandORWife()}
                              />
                            }
                            <PlusCircle 
                            size={20} 
                            className=' text-table_primary_btn cursor-pointer'
                            data-tooltip-id="husband-and-wife-tooltip" 
                            data-tooltip-content={UserTooltipData.HusbandORWife}
                            onClick={() => handleAddHusbandORWife()}
                            />
                            <Tooltip id="husband-and-wife-tooltip" place='bottom' className='z-10'/>
                          </div>
                        }
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {errors?.husbandAndWife &&
                    <div className="text-red-500 text-sm text-end pr-[30px]">{errors?.husbandAndWife?.message as string}</div>}
              <div className='form-group'>
                <div className={`form-label min-w-[119px] ${selectedOlderBrother?.length > 1 ? ' self-start mt-1' : ''}`}>Anh</div>
                <div className='flex-1 space-y-3'>
                  <RelationshipSection
                    selectedItem={selectedOlderBrother}
                    setSelectedItem={setSelectedOlderBrother}
                    LoadUser={_SelectLoadMaleUsersOption}
                    placeholder={'Chọn người anh'}
                    tooltipId={'olderBother'}
                    tooltipText={UserTooltipData.OlderBrotherTip}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
              {errors?.olderBother &&
                    <div className="text-red-500 text-sm text-end pr-[30px]">{errors?.olderBother?.message as string}</div>}

              <div className='form-group'>
                <div className={`form-label min-w-[119px] ${selectedYoungerBrother?.length > 1 ? ' self-start mt-1' : ''}`}>Em</div>
                <div className='flex-1 space-y-3'>
                  <RelationshipSection
                    selectedItem={selectedYoungerBrother}
                    setSelectedItem={setSelectedYoungerBrother}
                    LoadUser={_SelectLoadAllUsersOption}
                    placeholder={'Chọn người em'}
                    tooltipId={'youngerBother'}
                    tooltipText={UserTooltipData.YoungerBrotherTip}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
              {errors?.youngerBother &&
                    <div className="text-red-500 text-sm text-end pr-[30px]">{errors?.youngerBother?.message as string}</div>}
            </div>
            <div className='w-1/2 space-y-3'>
              <div className='form-group'>
                <div className={`form-label min-w-[119px] ${selectedChildrent?.length > 1 ? ' self-start mt-1' : ''}`}>Con</div>
                <div className='flex-1 space-y-3'>
                  <RelationshipSection
                    selectedItem={selectedChildrent}
                    setSelectedItem={setSelectedChildrent}
                    LoadUser={_SelectLoadAllUsersOption}
                    placeholder={'Chọn người con'}
                    tooltipId={'childrent'}
                    tooltipText={UserTooltipData.ChildrentTip}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
              {errors?.childrent &&
                    <div className="text-red-500 text-sm text-end pr-[30px]">{errors?.childrent?.message as string}</div>}

              <div className='form-group'>
                <div className={`form-label min-w-[119px] ${selectedSister?.length > 1 ? ' self-start mt-1' : ''}`}>Chị</div>
                <div className='flex-1 space-y-3'>
                  <RelationshipSection
                    selectedItem={selectedSister}
                    setSelectedItem={setSelectedSister}
                    LoadUser={_SelectLoadFemaleUsersOption}
                    placeholder={'Chọn người chị'}
                    tooltipId={'sister'}
                    tooltipText={UserTooltipData.SisterTip}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
              {errors?.sister &&
                    <div className="text-red-500 text-sm text-end pr-[30px]">{errors?.sister?.message as string}</div>}

            </div>
          </div>
        </FormCard>
        {!isReview &&
          <div className='flex items-center justify-center'>
            <div className='flex gap-[39px] mb-8'>
              <button type='submit' disabled={isDisabledSubmitBtn} className='table-primary-btn w-[132px] h-[42px]'>Lưu</button>
              <button type='button' onClick={() => handleClickCancelBtn()} className='table-second-btn w-[132px] h-[42px]'>Hủy</button>
            </div>
          </div>
        }
        {isReview &&
          <div className='flex items-center justify-center'>
            <div className='flex gap-[39px] mb-8'>
              <button type='button' disabled={onSubmitReview} onClick={() => handleSubmitReview('confirm')} className='table-primary-btn w-[132px] h-[42px]'>Chấp nhận</button>
              <button type='button' disabled={onSubmitReview} onClick={() => handleSubmitReview('reject')} className='table-second-btn w-[132px] h-[42px]'>Từ chối</button>
              <button type='submit' disabled={isDisabledSubmitBtn} className='table-second-btn w-[162px] h-[42px]'>Chỉnh sửa thông tin</button>
            </div>
          </div>
        }
      </form>
      <AddGenerationModal
        isOpen={isShowAddGenerationModal}
        closeModal={() => handleCloseGenerationModal()}
        modalClass=' w-[40vw]'
        handleAddGenerationSuccess={handleAddGenerationSuccess}
      />
    </>
  )
}

export default DetailUser