import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ResetPasswordPage from "../../pages/resetPassword/ResetPasswordPage";

const ResetPasswordRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ResetPasswordPage />}>
            </Route>
        </Routes>
    )
}

export default ResetPasswordRoutes