import { useRef, useState, useEffect } from "react";
import { Breadcrumbs, TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import DropdownIcon from "../../assets/icons/DropdownIcon";
import ImageItemsAllowEdit from "./components/ImageItemsAllowEdit";
import ImageDefaultIcon from "assets/icons/ImageDefaultIcon";
import imageCompression from 'browser-image-compression';

const breadcrumbs = [
  <Link to="/images" className='flex gap-1' key={'breadcrumbs_1'}>
    <ImageDefaultIcon/>
    <p className={"text-[#461511] font-[600] text-[16px]"}>
      Hình ảnh phả tộc Nguyễn Đức
    </p>
  </Link>,
  <Link to="/images/images-add" key={'breadcrumbs_2'}>
    <p className={"text-[#461511] font-[600] text-[16px]"}>
      Thêm album hình ảnh
    </p>
  </Link>,
];

const AddImagePage = () => {
  const methods = useForm({
    defaultValues: {
      albumName: "",
      files: [],
    },
  });
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [fileArray, setFileArray] = useState<any>([]);
  const albumName = useWatch({ control: methods.control, name: "albumName" });
  const [images, setImages] = useState<any>([]);
  const {setError, clearErrors ,formState: { errors }} = methods;
  const [chooseImageError, setChooseImageError] = useState<any>('');

  const openImageChooser = () => {
    document?.getElementById('files-albums')?.click();
  };

  useEffect(() => {
    if (errors?.albumName) {
      clearErrors('albumName');
    }
  }, [albumName]);

  const compressImage = async (imageFile: any) => {
    try {
      const options = {
        maxSizeMB: 0.9, // kích thước tối đa sau khi nén, ở đây là 1MB
        maxWidthOrHeight: 1920, // chiều rộng hoặc chiều cao tối đa
        useWebWorker: true // sử dụng web worker để tăng hiệu suất (khuyến nghị)
      };
  
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Lỗi khi nén ảnh:', error);
      return null;
    }
  };

  const handleInputFilesChange = async (event: any) => {
    setChooseImageError('');
    
    if ((images.length + event.target.files.length) > 30) {
      setChooseImageError('Đã vượt quá 30 hình ảnh, vui lòng chọn lại.');
      return;
    }
    
    if (event.target.files.length) {
      const files = [...event.target.files];
      const compressFiles = await Promise.all(files.map(async (file: any) => {
        return await compressImage(file);
      }));
      let _fileArray = [...fileArray, ...compressFiles];
      setFileArray(_fileArray);
      const imagesInputUrl = [...images];

      for (let i = 0; i < event.target.files.length; i++) {
        const file = event.target.files[i];
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        
        img.onload = () => {
          imagesInputUrl.push({
            imageUrl: URL.createObjectURL(file)
          });
          setImages([...imagesInputUrl]);
        }
      }
    }
  }

  const handleDeleteImage = (indexImage: number) => {
    const _images = [...images];
    const _fileArray = [...fileArray];
    _images.splice(indexImage, 1);
    _fileArray.splice(indexImage, 1);
    setImages(_images);
    setFileArray(_fileArray);
  };


  return (
    <div className="flex flex-col gap-[24px] min-h-[600px]">
      <div>
        <Breadcrumbs separator={">>"} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </div>
      <div>
        <FormProvider {...methods}>
          <form>
            <div className="flex flex-col gap-[18px]">
              <div className={"grid xs:grid-cols-1 md:grid-cols-12"}>
                <div className="col-span-1">
                  <label
                    htmlFor={"albumName"}
                    className="text-[#461511] text-[14px] font-[400] required-after"
                  >
                    Tên album
                  </label>
                </div>
                <div className="col-span-11 flex gap-[20px]">
                  <input 
                  type="text" 
                  className="form-input !w-[398px]"
                  {...methods.register('albumName')}
                  />
                </div>
              </div>
              {errors?.albumName &&
                  <div className="text-red-500 text-sm text-start">{errors?.albumName?.message as string}</div>}
              <div className={"grid xs:grid-cols-1 md:grid-cols-12"}>
                <div className="col-span-1">
                  <label
                    htmlFor={"files-albums"}
                    className=" text-[#461511] text-[14px] font-[400] required-after"
                  >
                    Hình ảnh
                  </label>
                </div>

                <div className="col-span-11 flex gap-[20px]">
                  <label
                    htmlFor={"files-albums"}
                    className=" text-[#461511] text-[14px] font-[400]"
                  >
                    <TextField
                      disabled
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "white",
                        width: "398px",
                        height: "32px",
                        border: "1px solid #E1E1E1",
                        borderRadius: "8px",
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            border: "1px solid #E1E1E1",
                            cursor: "pointer",
                          },
                          "&:hover fieldset": {
                            border: "1px solid #E1E1E1",
                            cursor: "pointer",
                          },
                        },
                      }}
                      placeholder={"Chọn hình ảnh"}
                      InputProps={{
                        endAdornment: <DropdownIcon />,
                      }}
                    />
                  </label>
                  <>
                    {/*<Controller*/}
                    {/*  name="files"*/}
                    {/*  control={methods.control}*/}
                    {/*  rules={{ required: "This field is required" }}*/}
                    {/*  render={({ field }) => (*/}
                    <input
                      ref={fileInput}
                      accept="image/*"
                      type="file"
                      title="Nhấn để chọn ảnh"
                      id="files-albums"
                      style={{ display: "none" }}
                      multiple={true}
                      onChange={(e) => handleInputFilesChange(e)}
                    />
                    {/*/>*/}

                    <button
                      onClick={openImageChooser}
                      type="button"
                      className="table-primary-btn w-[88px] h-[32px] disabled:opacity-50 disabled:cursor-not-allowed "
                    >
                      Chọn
                    </button>
                  </>
                </div>
              </div>
              
            </div>
          </form>
        </FormProvider>
        <div className="grid xs:grid-cols-1 md:grid-cols-12 mt-1">
          <div className="col-span-1"></div>
          <div className="col-span-11">
            <i className="text-[#461511] text-[14px] ">Chọn ít nhất 1 hình ảnh cho album, tối đa 30 hình ảnh</i>
            {chooseImageError &&
                    <div className="text-red-500 text-sm text-start">{chooseImageError}</div>}
          </div>
        </div>
       
      </div>
      <div className={"mt-[50px]"}>
        {images?.length > 0 && (
          <div>
            <ImageItemsAllowEdit 
            albumName={albumName} 
            album={images || []} 
            fileArray={fileArray}
            setError={setError}
            handleDeleteImage={handleDeleteImage}
            setChooseImageError={setChooseImageError}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddImagePage;
