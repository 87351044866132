import AdminManagementLayout from "components/layouts/AdminManagementLayout";
import MasterLayout from "components/layouts/MasterLayout";
import {LoadingBarProcess} from "components/loadingProcess/LoadingBarProcess";
import AdminList from "pages/adminManagement/admin/AdminList";
import EventList from "pages/adminManagement/event/EventList";
import LogList from "pages/adminManagement/log/LogList";
import PendingUserApprovalList from "pages/adminManagement/user/PendingUserApprovalList";
import React from "react";
import {Route, Routes} from "react-router-dom";
import {RequireAuth} from "./Routes";
import ListUsers from "pages/adminManagement/user/ListUsers";
import DetailAdmin from "pages/adminManagement/admin/DetailAdmin";
import DetailUser from "pages/adminManagement/user/DetailUser";
import ListGeneration from "pages/adminManagement/generation/ListGeneration";
import IPassword from "pages/adminManagement/iPassword/IPassword";
import ImportUser from "pages/adminManagement/importUser/ImportUser";

const AdminManagementRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<MasterLayout/>}>
                <Route
                    path='/user-detail'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailUser/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/user-detail/:id'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailUser/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/pending-user-approval/user-detail/:id'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailUser/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/pending-user-approval'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <PendingUserApprovalList/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/list-users'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <ListUsers/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/list-users/user-detail/:id'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailUser/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/admin-detail/:id'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailAdmin/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/admin-detail'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <DetailAdmin/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path='/log'
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <LogList/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/event"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <EventList/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/event/:id"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <EventList/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <AdminList/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/gerenation"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <ListGeneration/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                <Route
                    path="/ipassword"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <IPassword/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
                 <Route
                    path="/import-user"
                    element={
                        <React.Suspense fallback={<LoadingBarProcess/>}>
                            <RequireAuth>
                                <AdminManagementLayout>
                                    <ImportUser/>
                                </AdminManagementLayout>
                            </RequireAuth>
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
};

export default AdminManagementRoutes;
