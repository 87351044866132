import { useQuery } from "react-query";
import { GenealogyService } from "../../core/services/genealogy.service";

export const useGetArticle = (key: string | undefined) => {
  return useQuery({
    queryKey: [`article_${key}`, key],
    queryFn: async () => {
      return await GenealogyService.getArticle();
    },
    refetchOnWindowFocus: false,
  });
};
