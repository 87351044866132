import React from 'react';
import { cloneElement } from "react";

const hasRole = (roleUser: string, scopes: string[]) => {
  return scopes.includes(roleUser);
}

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
  user
}: {children: any, RenderError?: any, errorProps?: any, scopes: any, user?: any}) {

  if (user?.Role) {
    const permissionGranted = hasRole(user?.Role, scopes);
    if (!permissionGranted && !errorProps) return <RenderError />;
    if (!permissionGranted && errorProps)
      return cloneElement(children, { ...errorProps });
    return <>{children}</>;
  }else {
    return <RenderError />
  }
  
}