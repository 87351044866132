import React, { useEffect, useState } from 'react';
import GiaPhaTitle from 'assets/images/giapha_title.png';
import { GenealogyService } from 'core/services/genealogy.service';
import { PinchZoomPan } from './pinchZoomPan/PinchZoomPan';
import { Tree, TreeNode } from 'react-organizational-chart';
import { GenealogyItemType } from 'core/types/genealogy.type';
import GenealogyItem from './GenealogyItem';
import ConfirmSuperPassword from './components/ConfirmSuperPassword';
import OrganizationModal from './components/OrganizationModal';
import { toast } from 'react-toastify';
import { AlertTriangle } from 'react-feather';
import PersonModal from './components/PersonModal';
// import ReactZoomPanPinch from './components/ReactZoomPanPinch';
import LevelBackground from './components/LevelBackground';
import { CircleSpin } from 'components/spinner/CircleSpin';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import AuthService from 'core/services/auth.service';

export const TreeNodeRender = (
  { node, handleClickItem, handleClickGererationItem, isCollapsedAll }: 
  {
    node: GenealogyItemType, 
    handleClickItem: (item: any) => void,
    handleClickGererationItem: (generationId: string) => void;
    isCollapsedAll: boolean;
  }) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = (item: GenealogyItemType) => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (node.level >= 17) {
      if (isCollapsedAll) {
        setCollapsed(true);
      }else {
        setCollapsed(false);
      }
    }else {
      setCollapsed(false);
    }
  }, [node.level, isCollapsedAll]);

  return (
    <>
      {(node?.children?.length || 0) > 0 ? !collapsed ? 
        <TreeNode 
          label={
            <GenealogyItem 
            ItemData={node} 
            handleClickItem={handleClickItem}
            toggleCollapsed={toggleCollapsed}
            collapsed={collapsed}
            isShowcollapse={true}
            isAloneNode={node?.children?.length === 1 ? true : false}
            handleClickGererationItem={handleClickGererationItem}
            />
          } 
        >
          {node?.children?.map((child: GenealogyItemType) => (
            <TreeNodeRender 
            key={child.personId || child.organizationId} 
            node={child} 
            handleClickItem={handleClickItem}
            handleClickGererationItem={handleClickGererationItem}
            isCollapsedAll={isCollapsedAll}
            />
          ))}
        </TreeNode> :
        <TreeNode 
          label={
            <GenealogyItem 
            ItemData={node} 
            handleClickItem={handleClickItem}
            toggleCollapsed={toggleCollapsed}
            collapsed={collapsed}
            isShowcollapse={true}
            handleClickGererationItem={handleClickGererationItem}
            /> 
            } 
          /> : 
          <TreeNode 
          label={
            <GenealogyItem 
            ItemData={node} 
            handleClickItem={handleClickItem}
            toggleCollapsed={toggleCollapsed}
            collapsed={collapsed}
            isShowcollapse={false}
            handleClickGererationItem={handleClickGererationItem}
            /> 
          }
          // className={`${isSevenGenealogy(node)}`}
          />
      }
      
    </>
  );
};

const GenealogyPage = () => {
  const [loading, setLoading] = useState<any>(true);
  // const [genealogyData, setGenealogyData] = useState<any>();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<any>(false);
  const [isShowOrganizationModal, setIsShowOrganizationModal] = useState<any>(false);
  const [isShowPersonModal, setIsShowPersonModal] = useState<any>(false);
  const [organizationPasswordData, setOrganizationPasswordData] = useState<any>();
  const [personPasswordData, setPersonPasswordData] = useState<any>();
  const [generationPasswordData, setGenerationPasswordData] = useState<any>();
  const [currentOrganizationPassword, setCurrentOrganizationPassword] = useState<any>();
  const [currentPersonPassword, setCurrentPersonPassword] = useState<any>();
  const [currentGenerationIdPassword, setCurrentGenerationIdPassword] = useState<any>();
  const [maxLevel, setMaxLevel] = useState<any>();
  const [branchData, setBranchData] = useState<GenealogyItemType[]>([]);
  const [browserID, setBrowserID] = useState<any>();
  const [isCollapsedAll, setIsCollapsedAll] = useState<boolean>(true);
  const [isResetZoom, setIsResetZoom] = useState<boolean>(false);
  
  const handleHardClan4 = (item: any) => {
    if (item.organizationId === "903333b6-a278-458f-bea0-214f8e23acdc" && item?.children?.length === 4) {
      const _data = {...item};
      const index = _data?.children?.findIndex((itemIndexOf: any) => {
        return itemIndexOf.organizationId === "5c8bb7f4-2e36-43c1-a50f-a03572ebbda6"
      });
      if (index > -1) {
        _data?.children.push(..._data?.children.splice(index, 1));
      }
      return _data;
    }else {
      return [];
    }
  }

  const buildTree = (items: any) => {
    const tree: any = {};
    const levelArray: any = [];
    items.forEach((item: any) => {
      if (item.personFirstName && !item.organizationId) {
        tree[item.personId] = { ...item, children: [] };
      }else if (item.organizationId && item.personFirstName && item.personLastName) {
        tree[item.personId] = { ...item, children: [] };
      }else if (item.organizationId && !item.personFirstName && !item.personLastName) {
        tree[item.organizationId] = { ...item, children: [] };
      }else {
        tree[item.personId] = { ...item, children: [] };
      }
      const { generationNumber, parentId, generationId } = item;
      if (generationNumber) {
        const existingLevel = levelArray.find((entry: any) => entry.level === generationNumber);
        if (generationNumber < 2) {
          if (existingLevel) {
            existingLevel.numberOfItemsInLevel++;
          } else {
            levelArray.push({ level: generationNumber, numberOfItemsInLevel: 1, generationId: generationId });
          }
        }else if (generationNumber >=2 && parentId) {
          if (existingLevel) {
            existingLevel.numberOfItemsInLevel++;
          } else {
            levelArray.push({ level: generationNumber, numberOfItemsInLevel: 1, generationId: generationId });
          }
        }
      }
    });
    
    setMaxLevel(levelArray);
    items.forEach((item: any) => {
      if (item.parentId && (item.personFirstName && item.personLastName) && !item.organizationId) {
        tree[item.parentId]?.children?.push(tree[item.personId || item.organizationId]);
      }else if (item.parentId && (item.personFirstName && item.personLastName) && item.organizationId) {
        const filterPrevLevel = items.filter((itemPrev: any) => itemPrev.level === (item.level - 1));;
        let filterPrev2Level = undefined;
        let organizationPrev = undefined;
        let organization2Prev = undefined;
        if (item.level === 14 || item.level === 15 || item.level === 16 || item.level === 17) {
          filterPrev2Level = items.filter((itemPrev: any) => itemPrev.level === (item.level - 2));
          
          organizationPrev = filterPrevLevel.find((itemPrev: any) => itemPrev.organizationId === item.organizationId && !itemPrev.personId);
          organization2Prev = filterPrev2Level.find((itemPrev: any) => itemPrev.organizationId === item.organizationId && !itemPrev.personId && itemPrev.organizationType === "Clan");
        }
        
        const fatherPrev = filterPrevLevel.find((itemPrev: any) => itemPrev.personId === item.parentId);
        if (organizationPrev ) {
          tree[item.organizationId]?.children?.push(tree[item.personId]);
          const checkHard14 = handleHardClan4(tree[item.organizationId]);
          if (checkHard14.length > 0 && tree[item.organizationId]?.children.length > 0) {
            tree[item.organizationId] = checkHard14;
          } 
        }else if (fatherPrev) {
          tree[item.parentId]?.children?.push(tree[item.personId]);
        }else if (organization2Prev) {
          tree[item.organizationId]?.children?.push(tree[item.personId]);
          const checkHard14 = handleHardClan4(tree[item.organizationId]);
          if (checkHard14.length > 0 && tree[item.organizationId]?.children.length > 0) {
            tree[item.organizationId] = checkHard14;
          } 
        }else {
          tree[item.parentId]?.children?.push(tree[item.personId]);
        }
      }else if (item.organizationId && !item.personFirstName && !item.personLastName) {
        tree[item.parentId]?.children?.push(tree[item.organizationId]);
      }
      
    });
    return Object.values(tree).filter((item: any) => item.parentId === null && item.level === 1);
  };

  const filterAndAddChildren = (tree: any, minLevel: number, maxLevel: number) => {
    const traverse = (node: any) => {
      const children = node?.children?.filter((child: any) => {
        return child?.level >= minLevel && child?.level <= maxLevel
      });
      if (children?.length > 0) {
        node.children = children.map((child: any) => traverse(child));
      } else {
        delete node.children;
      }
      return node;
    }
  
    return tree.map((node: any) => traverse(node));
  }

  const getGenealogyTree = async (id?: string) => {
    setLoading(true);
    const response = await GenealogyService.getGenealogyTree({id: id});

    if (response?.data?.isSuccess === true) {
      const items = response?.data?.data;
      
      const maxLevel = Math.max(...items.map((item: GenealogyItemType) => item.level));
      const tree = buildTree(items);
      
      const filteredMainTree = filterAndAddChildren(tree, 2, maxLevel);
      setBranchData(filteredMainTree);
      setLoading(false);
    }
  };

  const postOrganizationPassword = async (Item: GenealogyItemType, deviceId: string) => {
    const params = {
      organizationId: Item.organizationId,
      deviceId: deviceId
    };
    const res = await GenealogyService.postOrganizationPassword(params);
    if (res?.data?.isSuccess) {
      setOrganizationPasswordData(res?.data?.data);
      setIsShowConfirmModal(false);
      setTimeout(() => {
        setIsShowOrganizationModal(true);
      }, 300);
    }else {
      setIsShowConfirmModal(true);
    }
  };

  const postGenerationPassword = async (generationId: string, deviceId: string) => {
    const params = {
      generationId: generationId,
      deviceId: deviceId
    };
    const res = await GenealogyService.postGenerationPassword(params);
    if (res?.data?.isSuccess) {
      setGenerationPasswordData(res?.data?.data);
      setIsShowConfirmModal(false);
      setTimeout(() => {
        setIsShowOrganizationModal(true);
      }, 300);
    }else {
      setIsShowConfirmModal(true);
    }
  };

  const postPersonPassword = async (Item: GenealogyItemType, deviceId: string) => {
    const params = {
      personId: Item.personId,
      deviceId: deviceId
    }
    const res = await GenealogyService.postPersonPassword(params);
    if (res?.data?.isSuccess) {
      setPersonPasswordData(res?.data?.data);
      setIsShowPersonModal(true);
      setIsShowConfirmModal(false);
      setTimeout(() => {
        setIsShowPersonModal(true);
      }, 300);
    }else {
      setIsShowConfirmModal(true);
    }
  };

  const handleLoginIPass = async (password: string) => {
    try {
      const res = await AuthService.loginIPass(browserID.toString(), password);
      if (res?.isSuccess) {
        if (currentOrganizationPassword) {
          postOrganizationPassword(currentOrganizationPassword, browserID.toString());
        }
        if (currentPersonPassword) {
          postPersonPassword(currentPersonPassword, browserID.toString());
        }
        if (currentGenerationIdPassword) {
          postGenerationPassword(currentGenerationIdPassword, browserID.toString());
        }
      }else {
        toast(res?.errors[0]?.message || 'Lỗi kết nối mạng!', {
          type: 'error',
          icon: <AlertTriangle className="text-[#F03D3E]" />
        });
      }
    } catch (error) {
      console.log('error', error);
      toast('Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
  }
  
  useEffect(() => {
    getGenealogyTree();
    getCurrentBrowserFingerPrint().then((fingerprint) => {
      setBrowserID(fingerprint);
    });
  }, []);

  const handleClickItem = (Item: GenealogyItemType) => {
    if (!Item.personId) {
      setCurrentOrganizationPassword(Item);
      postOrganizationPassword(Item, browserID.toString());
    }else {
      setCurrentPersonPassword(Item);
      postPersonPassword(Item, browserID.toString());
    }
    
  };

  const handleClickGererationItem = (generationId: string) => {
    setCurrentGenerationIdPassword(generationId);
    postGenerationPassword(generationId, browserID.toString());
  }

  const handleClickSubmitPassword = (password: string) => {
    handleLoginIPass(password);
  };

  const handleCloseConfirmPasswordModal = () => {
    setIsShowConfirmModal(false);
    setCurrentOrganizationPassword(undefined);
    setCurrentPersonPassword(undefined);
    setOrganizationPasswordData(undefined);
    setPersonPasswordData(undefined);
  };

  const handleCloseOrganizationModal = () => {
    setIsShowOrganizationModal(false);
    setCurrentOrganizationPassword(undefined);
    setOrganizationPasswordData(undefined);
    setCurrentGenerationIdPassword(undefined);
    setGenerationPasswordData(undefined);
  };

  const handleClosePersonModal = () => {
    setIsShowPersonModal(false);
    setCurrentPersonPassword(undefined);
    setPersonPasswordData(undefined);
  };

  const handleClickNoCollapsedAll = () => {
    setIsCollapsedAll(false);
    setIsResetZoom(false);
  };

  const handleClickCollapsedAll = () => {
    setIsCollapsedAll(true);
    setIsResetZoom(true);
  };

  return (
    <div className='genealogy-tree w-auto flex items-start justify-center overflow-hidden pb-20 relative'>
      {loading && (
          <>
            <div className="flex justify-center mt-4 items-center text-table_thead_text min-h-[200px]">
              <CircleSpin color="text-table_primary_btn mr-2" /> Loading...
            </div>
          </>
      )}
      {!loading &&
      <>
        <div className=' absolute z-50 top-0 left-0 flex justify-center gap-3'>
            <button 
            type="button"
            className="table-primary-btn w-[145px] h-[42px]"
            onClick={() => handleClickNoCollapsedAll()}
            >
              <span>{'Hiện tất cả nhánh'}</span>
            </button>
            <button 
            type="button"
            className="table-primary-btn w-[145px] h-[42px]"
            onClick={() => handleClickCollapsedAll()}
            >
              <span>{'Ẩn tất cả nhánh'}</span>
            </button>
          </div>
        <PinchZoomPan 
        min={0.2} 
        max={2.8} 
        captureWheel 
        className='min-h-[800px] min-w-[800px] w-full h-full flex-1 relative'
        isReset={isResetZoom}
        >
          
          {maxLevel?.length > 0 &&
            <LevelBackground maxLevel={maxLevel} handleClickGererationItem={handleClickGererationItem}/>
          }
          <Tree
            lineWidth={'2px'}
            lineColor={'#461511'}
            lineBorderRadius={'0px'}
            lineStyle='solid'
            nodePadding='20px'
            label={<div className='mt-[90px] genealogy-tree-container flex items-center justify-center'>
                    <img src={GiaPhaTitle} alt="GiaPhaTitle" className='w-[380px] h-[102.7px]'/>
                  </div>}
          >
            {branchData?.map((branch: GenealogyItemType, index: number) => (
              <TreeNodeRender 
              key={`TreeNodeRender_${index}`} 
              node={branch} 
              handleClickItem={handleClickItem}
              handleClickGererationItem={handleClickGererationItem}
              isCollapsedAll={isCollapsedAll}
              />
            ))}
            {/* {subData?.map((branch: GenealogyItemType, index: number) => (
              <TreeNodeRender 
              key={`TreeNodeRender_${index}`} 
              node={branch} 
              handleClickItem={handleClickItem}
              handleClickGererationItem={handleClickGererationItem}
              />
            ))} */}
          </Tree>
        </PinchZoomPan>
      </>
      }
      {/* <ReactZoomPanPinch>
      </ReactZoomPanPinch> */}
      {isShowConfirmModal &&
          <ConfirmSuperPassword 
          isOpen={isShowConfirmModal} 
          closeModal={() => handleCloseConfirmPasswordModal()}
          handleClickSubmitPassword={handleClickSubmitPassword}
          />
      }
      {isShowOrganizationModal &&
        <OrganizationModal 
        isOpen={isShowOrganizationModal}
        closeModal={() => handleCloseOrganizationModal()}
        organizationData={organizationPasswordData}
        currentOrganizationData={currentOrganizationPassword}
        generationData={generationPasswordData}
        />
      }
      {isShowPersonModal &&
        <PersonModal
          isOpen={isShowPersonModal}
          closeModal={() => handleClosePersonModal()}
          personData={personPasswordData}
        />
      }
    </div>
  )
}

export default GenealogyPage