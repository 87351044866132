import { EVENTS, MEDIA, ORG, USER } from "core/constant/api.contant";
import axiosClient from "utilities/axios-instance";
import { AxiosResponse } from "axios";
import { pickBy } from "lodash";
const removeEmptyNewV2 = (obj: any) => {
  return pickBy(obj);
};
const addEvent = (params: any) => {
  return axiosClient.post(EVENTS.ADD_EVENT, params);
};
const editEvent = (body: any) => {
  return axiosClient.put(EVENTS.EDIT_EVENT(body.id), body?.dataSend);
};
const uploadFileImage = (
  files: any,
  transactionId: string | any,
): Promise<AxiosResponse<any>> => {
  // Tạo một đối tượng FormData mới
  const formData = new FormData();

  // Thêm từng tệp vào FormData
  for (let i = 0; i < files?.length; i++) {
    formData.append("files", files[i]);
  }

  // Gửi yêu cầu POST sử dụng axios
  return axiosClient.post(MEDIA.POST_MEDIA_IMAGE_FOLDER("Event"), formData, {
    headers: {
      "Content-Type": "multipart/form-data", // Đảm bảo định dạng Content-Type là multipart/form-data
    },
    params: removeEmptyNewV2({
      transactionId,
    }),
  });
};
const getOrg = (type: any) => {
  return axiosClient.get(ORG.GET_ORGANIZATION, {
    params: {
      type,
    },
  });
};
const getAllEvents = (params: any) => {
  return axiosClient.get(EVENTS.GET_EVENTS, {
    params,
  });
};
const removeEvent = (id: string) => {
  return axiosClient.delete(EVENTS.REMOVE_EVENT(id));
};

const getDetailEvent = (id: string) => {
  return axiosClient.get(EVENTS.EVENTDETAIL(id), {
    params: {
      id,
    },
  });
};
export const EventService = {
  addEvent,
  uploadFileImage,
  getOrg,
  getAllEvents,
  removeEvent,
  getDetailEvent,
  editEvent,
};
