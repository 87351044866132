import ConfirmModal from 'components/modal/ConfirmModal';
import AvatarImage from 'components/uiComponents/AvatarImage';
import TableSearchBar from 'components/uiComponents/TableSearchBar';
import { useAuth } from 'core/contexts/auth/AuthContext';
import { UserService } from 'core/services/user.service';
import { UserDetailData, UserRoleEnum } from 'core/types/user.type';
import React, { useState } from 'react'
import { AlertTriangle, CheckCircle, Edit, Trash, Users } from 'react-feather'
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ListUsers = () => {
    const [users, setUsers] = useState<UserDetailData[]>([]);
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState<string>('');
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
    const [isOnDelete, setIsOnDelete] = useState<boolean>(false);
    const [selectedDelete, setSelectedDelete] = useState<UserDetailData>();
    const auth = useAuth();

    const getListUser = async (keywordData: string) => {
        let params: any = {
            PageNumber: 1,
            PageSize: 100,
        }
        
        if (keywordData) {
            params = {
                ...params,
                Keyword: keywordData,
            }
        }else {
            setUsers([]);
            setIsSearch(false);
            return;
        }
        
        const response = await UserService.getList(params);
        
        if (response?.data?.isSuccess === true) {
            setUsers(response?.data?.data || []);
            setIsSearch(true);
        }
    };

    const onSearch = (keyword: string) => {
        getListUser(keyword);
    };

    const _handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            setKeyword(e.target.value?.trim());
            onSearch(e.target.value?.trim());
        }
    };

    const handleClickDeleteUser = async (e: any, user: any) => {
        e.stopPropagation();
        setSelectedDelete(user);
        setIsShowConfirmModal(true);
    };
    
    const handleSubmitDelete = async () => {
        if (selectedDelete) {
            setIsOnDelete(true);
            try {
                const response = await UserService.deleteUser(selectedDelete?.id);
    
                if (response?.data?.isSuccess === true) {
                    toast(`Xóa ${selectedDelete.lastName} ${selectedDelete.firstName} thành công`, {
                        type: 'success',
                        icon: <CheckCircle className="text-[#007B40]" />
                    });
                    getListUser(keyword);
                }else {
                    toast(response?.data?.errors || 'Lỗi kết nối mạng!', {
                        type: 'error',
                        icon: <AlertTriangle className="text-[#F03D3E]" />
                    });
                }
            } catch (error) {
                toast('Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            }
            setIsOnDelete(false);
        }else {
            toast('Vui lòng chọn người cần xóa!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
            });
        }
        setIsShowConfirmModal(false);
    }
      
  return (
    <>
        <div className=' flex justify-between'>
            <div className='flex items-center gap-[11px]'>
                <Users size={24} color={`#461511`}/>
                <p className=' text-base font-semibold leading-5 text-bg_brown'>Thay đổi thông tin về người</p>
            </div>
            <div className=' text-sm font-normal leading-5 text-color_text'></div>
        </div>
        <div className='table-container min-h-[500px]'>
            <div className='mt-[11px] mb-[9px]'>
                <TableSearchBar keyword={keyword} setKeyword={setKeyword} _handleKeyDown={_handleKeyDown} onSearch={onSearch} containerClass='w-[600px]'/>
            </div>
            {users?.length > 0 &&
                <>
                    {users?.length > 0 && <div className='form-normal-text mb-2'>{`Tìm thấy ${users?.length} người`}</div>}
                    <table className='table w-full'>
                        <thead>
                            <tr>
                                <th className='w-[30%]'>
                                    <span>Ảnh cá nhân</span>
                                </th>
                                <th className='w-[10%]'>
                                    <span>CNMD / CCCD</span>
                                </th>
                                <th className='w-[10%]'>
                                    <span>Nhánh</span>
                                </th>
                                <th className='w-[10%]'>
                                    <span>Chi</span>
                                </th>
                                <th className='w-[10%]'>
                                    <span>Phái</span>
                                </th>
                                <th className='w-[10%]'>
                                    <span>Đời</span>
                                </th>
                                <th className='flex justify-center w-[10%]'>
                                    <span></span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user: UserDetailData) => (
                            <tr key={user?.id} >
                                <td className='w-[30%]'>
                                    <div className='flex gap-[10px]'>
                                        <div>
                                            <div className='w-[50px] h-[50px] flex items-center justify-center rounded-2xl border border-yellow_F9D876'>
                                                <AvatarImage
                                                    imageUrl={user.avatar}
                                                    className='rounded-2xl'
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-between'>
                                            <div className=' text-sm font-bold leading-5 text-[#595959]'>{user.personCode || '-'}</div>
                                            <div>{`${user.lastName} ${user.firstName}`}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className='w-[10%]'>
                                    <span>{user.identityDocument || '-'}</span>
                                </td>
                                <td className='w-[10%]'>
                                    <span>{user.organizations?.find((item) => item.organizationType === 'Branch')?.organizationName}</span>
                                </td>
                                <td className='w-[10%]'>
                                    <span>{user.organizations?.find((item) => item.organizationType === 'Clan')?.organizationName}</span>
                                </td>
                                <td className='w-[10%]'>
                                    <span>{user.organizations?.find((item) => item.organizationType === 'Lineage')?.organizationName}</span>
                                </td>
                                <td className='w-[10%]'>
                                    {user.generationName || '-'}
                                </td>
                                <td className='w-[10%]'>
                                    <div className='flex justify-center items-center w-full gap-3'>
                                        <div 
                                        className=' cursor-pointer'
                                        onClick={(event) => {navigate(`/quan-ly/list-users/user-detail/${user.id}`); event.stopPropagation();}}
                                        >
                                            <Edit size={20} className="text-[#461511]"/>
                                        </div>
                                        {auth?.user?.Role === UserRoleEnum.SuperAdmin &&
                                            <div 
                                            className=' cursor-pointer'
                                            onClick={(event) => handleClickDeleteUser(event, user)}
                                            >
                                                <Trash size={20} className="text-[#461511]"/>
                                            </div>
                                        }
                                    </div>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            }
            {users?.length === 0 && !keyword &&
                <div className='form-normal-text'>Tìm kiếm người mà bạn muốn cập nhật thông tin</div>
            }
            {users?.length === 0 && keyword && isSearch &&
                <div className='form-normal-text'>{`Không có kết quả cho ${keyword}`}</div>
            }
            <ConfirmModal 
                isOpen={isShowConfirmModal} 
                closeModal={() => setIsShowConfirmModal(false)}
                title={selectedDelete ? `Bạn có chắc muốn xóa ${selectedDelete.lastName} ${selectedDelete.firstName} không?` : `Bạn có chắc muốn xóa người này không?`}
                onSubmit={isOnDelete}
                handleSubmit={() => handleSubmitDelete()}
            />
        </div>    
    </>
  )
}

export default ListUsers