
import { ModalContainer } from 'components/modal/ModalContainer';
import { useAuth } from 'core/contexts/auth/AuthContext';
import { UserTooltipData } from 'core/types/user.type';
import React, { useEffect, useState } from 'react'
import { AlertTriangle, CheckCircle, Eye, EyeOff, Info } from 'react-feather';
import { useForm } from 'react-hook-form';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

type ChangePasswordModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  modalClass: string;
}

const ChangePasswordModal = ({isOpen, closeModal, modalClass} : ChangePasswordModalProps) => {
  const methods = useForm({
      defaultValues: {
          oldPassword: '',
          newPassword: '',
          confirmPassword: '',
      },
      mode: 'onChange'
  });
  const {formState: {errors}, handleSubmit, reset, watch, clearErrors} = methods;
  const {resetPassword, error, setError} = useAuth();
  const auth = useAuth();
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);

  const watchOldPassword = watch('oldPassword');
  const watchNewPassword = watch('newPassword');
  const watchConfirmPassword = watch('confirmPassword');

  useEffect(() => {
    if (isOpen) {
      clearErrors();
    }
  }, [isOpen]);

  useEffect(() => {
    if (error) {
      setError(undefined);
    }
  }, [watchOldPassword]);

  useEffect(() => {
    if (watchOldPassword && watchNewPassword && watchConfirmPassword && (Object.keys(errors).length === 0)) {
      setIsDisabledSubmitBtn(false);
    }else {
      setIsDisabledSubmitBtn(true);
    }
  }, [watchOldPassword, watchNewPassword, watchConfirmPassword, errors])

  const onSubmit = async (data: any) => {
      const {newPassword, confirmPassword} = data;
      if (newPassword !== confirmPassword) {
          methods.setError('confirmPassword', {
              type: 'manual',
              message: "Mật khẩu không khớp. Vui lòng nhập lại",
          });
          return;
      }
      methods.clearErrors('oldPassword');
      await resetPassword(data?.oldPassword, data?.newPassword).then((response: any) => {
        
          if (response) {
            toast('Bạn đã thay đổi mật khẩu thành công', {
                type: 'success',
                icon: <CheckCircle className="text-[#007B40]" />
            });
            handleCloseModal();
            auth.logout();
          }
          // }else {
          //   toast(response?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
          //       type: 'error',
          //       icon: <AlertTriangle className="text-[#F03D3E]" />
          //   });
          // }
      }).catch((error) => {
        toast('Lỗi kết nối mạng!', {
          type: 'error',
          icon: <AlertTriangle className="text-[#F03D3E]" />
        });
      })
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };
  const [showNewPassword, setShowNewPassword] = useState(false);

  const toggleNewPasswordVisibility = () => {
      setShowNewPassword(!showNewPassword);
  };
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      reset();
      clearErrors();
    }, 500);
    closeModal();
  };
  
  return (
    <ModalContainer isOpen={isOpen} className={modalClass}>
      <form className="container-base mx-auto space-y-6 py-10" style={{
          width: '445px'
      }} onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
              <div className=' text-2xl font-medium text-[#5A5A5A]'>Thay đổi mật khẩu</div>
              <div className="relative">
                  <input
                      {...methods.register('oldPassword', {
                        required: 'Vui lòng nhập mật khẩu hiện tại',
                      })}
                      id="oldPassword"
                      placeholder="Mật khẩu hiện tại"
                      name="oldPassword"
                      type={showPassword ? 'text' : 'password'}
                      className={`form-input !h-[46px] ${errors?.oldPassword ? 'form-error' : ''}`}
                  />
                  <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      onClick={togglePasswordVisibility}
                  >
                      {showPassword ? <EyeOff size={20} color="gray"/> :
                          <Eye size={20} color="gray"/>}
                  </div>
                  
              </div>
              {errors.oldPassword && (
                  <span className="text-red-500 text-sm">{errors.oldPassword.message}</span>
              )}
              {error && <span className="text-red-500 text-sm">{error?.error?.message}</span>}
              <div className="relative">
                  <input
                      {...methods.register('newPassword', {
                        required: 'Vui lòng nhập mật khẩu mới',
                        validate: (value) => {
                          if (!value) return true;
                          const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.{8,})`);
                          if (!regex.test(value)) {
                          return "Mật khẩu có mức độ bảo mật chưa đủ mạnh. Vui lòng nhập lại";
                          }
                          return true;
                        }
                      })}
                      id="newPassword"
                      placeholder="Mật khẩu mới"
                      name="newPassword"
                      type={showNewPassword ? 'text' : 'password'}
                      className={`form-input !h-[46px] ${errors?.newPassword ? 'form-error' : ''}`}
                  />
                  <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      onClick={toggleNewPasswordVisibility}
                  >
                      {showNewPassword ? <EyeOff size={20} color="gray"/> :
                          <Eye size={20} color="gray"/>}
                  </div>
                  <div className=' absolute right-[-24px] top-3 w-[33px] flex justify-end'>
                    <Info 
                    size={20} 
                    className={"ml-1 cursor-pointer"} 
                    color="gray"
                    data-tooltip-id="admin-password-tooltip" 
                    data-tooltip-content={UserTooltipData.UserPasswordTip}
                    />
                    <Tooltip id="admin-password-tooltip" place='bottom' className='z-10'/>
                    </div>
                  
              </div>
              {errors.newPassword && (
                  <span className="text-red-500 text-sm">{errors.newPassword.message}</span>
              )}
              <div className="relative">
                  <input
                      {...methods.register('confirmPassword')}
                      id="confirmPassword"
                      placeholder="Nhập lại mật khẩu mới"
                      name="confirmPassword"
                      type={showConfirmPassword ? 'text' : 'password'}
                      className={`form-input !h-[46px] ${errors?.confirmPassword ? 'form-error' : ''}`}
                  />
                  <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                      onClick={toggleConfirmPasswordVisibility}
                  >
                      {showConfirmPassword ? <EyeOff size={20} color="gray"/> :
                          <Eye size={20} color="gray"/>}
                  </div>
                  
              </div>
              {errors.confirmPassword && (
                  <span className="text-red-500 text-sm">{errors.confirmPassword.message}</span>
              )}
          </div>
          <div className="flex gap-2">
              <button 
              type='button'
              onClick={() => {
                handleCloseModal();
              }}
              className="table-second-btn w-full h-[46px] !text-lg"
              >
                Hủy
              </button>
              <button 
              type="submit"
              className="table-primary-btn w-full h-[46px] !text-lg"
              disabled={isDisabledSubmitBtn}
              >
                Lưu
              </button>
          </div>
      </form>
    </ModalContainer>
  )
}

export default ChangePasswordModal