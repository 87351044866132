import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import LoginRoutes from "./LoginRoutes";
import GenealogyRoutes from "./GenealogyRoutes";
import HistoryRoutes from "./HistoryRoutes";
import EventRoutes from "./EventRoutes";
import MasterLayout from "components/layouts/MasterLayout";
import { LoadingBarProcess } from "components/loadingProcess/LoadingBarProcess";
import { PageNotFound } from "pages/errorPage/PageNotFound";
import { Page505 } from "pages/errorPage/Page505";
import AdminManagementRoutes from "./AdminManagementRoutes";
import HomeRoutes from "./HomeRoutes";
import { useAuth } from "core/contexts/auth/AuthContext";
import ForgotPasswordRoutes from "./ForgotPasswordRoutes";
import ResetPasswordRoutes from "./ResetPasswordRoutes";
import ImageRoutes from "./ImageRoutes";
import ProfileRoutes from "./ProfileRoutes";
import { PageForbidden } from "pages/errorPage/PageForbidden";
import SearchPage from "pages/searchPage/SearchPage";

export const RequireAuth: React.FC<{ children: JSX.Element }> = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user) {
      return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

const PageRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeRoutes />} />
        <Route path="/images/*" element={<ImageRoutes />} />
        <Route path="/pha-do/*" element={<GenealogyRoutes />} />
        <Route path="/lich-su/*" element={<HistoryRoutes />} />
        <Route path="/su-kien/*" element={<EventRoutes />} />
        <Route path="/quan-ly/*" element={<AdminManagementRoutes />} />
        <Route path="/login/*" element={<LoginRoutes />} />
        <Route path="/reset-password/*" element={<ResetPasswordRoutes />} />
        <Route path="/forgot-password/*" element={<ForgotPasswordRoutes />} />
        <Route path="/thong-tin-tai-khoan/*" element={<ProfileRoutes />} />
        <Route path="/search" element={<MasterLayout />}>
          <Route
            index
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <SearchPage />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/404"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="*"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/505"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <Page505 />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/forbidden"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageForbidden />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default PageRoutes;
