export enum UserRoleEnum {
    PeopleAdmin = 'PeopleAdmin',
    EditAdmin = 'EditAdmin',
    SiteAdmin = 'SiteAdmin',
    SuperAdmin = 'SuperAdmin',
}

export enum UserGendersEnum {
    Male = 'Male',
    Female = 'Female',
    Other = 'Other',
}

export type UserDetailData = {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    gender: string;
    isAdmin: boolean;
    organizationId: string;
    organizationName: string;
    organizationType: string;
    personCode: string;
    phoneNumber: string | null;
    identityDocument: string | null;
    avatar: string;
    generationName?: string;
    organizations?: IdentityDocumentItem[];
    numberOfApprovals?: number;
}

export type IdentityDocumentItem = {
    id: string;
    organizationName: string;
    organizationType: string;
}

export type AdminDetailData = {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roleName: string;
    status: string;
    creatorFirstName: string;
    creatorLastName: string;
    createdDate: string;
}

export const UserTooltipData = {
    UserPasswordTip: 'Mật khẩu phải ít nhất 8 ký tự, bao gồm chữ in hoa, chữ in thường, số và ký tự đặc biệt.',
    LineageTip: 'Thêm đời.',
    OlderBrotherTip: 'Thêm anh.',
    YoungerBrotherTip: 'Thêm em.',
    ChildrentTip: 'Thêm con.',
    SisterTip: 'Thêm chị.',
    HusbandORWife: 'Thêm vợ/chồng.'
}

export enum FamilyTitleType {
    ClanLeader = 'ClanLeader', //'Tộc trưởng chi'
    SurnameLeader = 'SurnameLeader', //'Tộc trưởng họ'
    LineageLeader = 'LineageLeader', //Tộc trưởng phái
}

export enum FamilyCouncilTitleType {
    CouncilMember = 'CouncilMember', //Ủy viên Hội đồng
    CouncilChairman = 'CouncilChairman', //Chủ tịch Hội đồng gia tộc
    ViceCouncilChairman = 'ViceCouncilChairman', //Phó Chủ tịch Hội đồng gia tộc
    StandingMemberCouncil = 'StandingMemberCouncil', //Ủy viên Thường trực Hội đồng gia tộc
    FinancialOfficer = 'FinancialOfficer', //Ủy viên tài chính, thủ quỹ
    ChurchManager = 'ChurchManager', //Quản lý nhà thờ
    CemeteryManager = 'CemeteryManager', //Quản lý nghĩa trang
    GeneralSecretary = 'GeneralSecretary', //Thư ký tổng hợp 
}

export enum RelationshipType {
    Father = 'Father',
    Mother = 'Mother',
    Husband = 'Husband',
    Wife = 'Wife',
    Children = 'Children',
    OlderBrother = 'OlderBrother',
    OlderSister = 'OlderSister',
    YoungerBrother = 'YoungerBrother',
    YoungerSister = 'YoungerSister'
}

export const FamilyTitleOptions = [
    { value: FamilyTitleType.ClanLeader, label: 'Tộc trưởng chi' },
    { value: FamilyTitleType.SurnameLeader, label: 'Tộc trưởng họ' },
    { value: FamilyTitleType.LineageLeader, label: 'Tộc trưởng phái' }
];

export const FamilyCouncilTitleOptions = [
    { value: FamilyCouncilTitleType.CouncilMember, label: 'Ủy viên Hội đồng' },
    { value: FamilyCouncilTitleType.CouncilChairman, label: 'Chủ tịch Hội đồng gia tộc' },
    { value: FamilyCouncilTitleType.ViceCouncilChairman, label: 'Phó Chủ tịch Hội đồng gia tộc' },
    { value: FamilyCouncilTitleType.StandingMemberCouncil, label: 'Ủy viên Thường trực Hội đồng gia tộc' },
    { value: FamilyCouncilTitleType.FinancialOfficer, label: 'Ủy viên tài chính, thủ quỹ' },
    { value: FamilyCouncilTitleType.ChurchManager, label: 'Quản lý nhà thờ' },
    { value: FamilyCouncilTitleType.CemeteryManager, label: 'Quản lý nghĩa trang' },
    { value: FamilyCouncilTitleType.GeneralSecretary, label: 'Thư ký tổng hợp' },
]

export const UserRoleOptions = [
    { value: 'PeopleAdmin', label: 'People Admin' },
    { value: 'EditAdmin', label: 'Edit Admin' },
    { value: 'SiteAdmin', label: 'Site Admin' },
    { value: 'SuperAdmin', label: 'Super Admin' },
]