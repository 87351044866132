import { ModalContainer } from 'components/modal/ModalContainer';
import React, { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';

type ConfirmSuperPasswordProps = {
    isOpen: boolean;
    closeModal: () => void;
    handleClickSubmitPassword: (password: string) => void;
}

const ConfirmSuperPassword = ({isOpen, closeModal, handleClickSubmitPassword} : ConfirmSuperPasswordProps) => {
    const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    
  return (
    <ModalContainer isOpen={isOpen} className='w-[617px] h-[323px] !bg-[#F9D876] rounded-[10px]'>
        <div className='py-5 px-10'>
            <div className='text-[24px] text-[#461511] font-medium'>Nhập mật khẩu</div>
            <div className='mt-4 text-sm text-[#461511]'><i>Bạn cần nhập mật khẩu để xem được thông tin lịch sử gia phả họ Nguyễn Đức.
                Nếu bạn không có mật khẩu, vui lòng liên hệ với ban quản trị trang web của dòng họ để lấy thông tin.</i></div>
            <div className='relative'>
                <input 
                className='mt-5 w-full form-input !h-[46px]' 
                type={isShowPassword ? 'text' : 'password'}
                placeholder='Nhập mật khẩu'
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                />
                {isShowPassword && 
                    <Eye 
                    onClick={() => setIsShowPassword(!isShowPassword)} size={20} 
                    className='text-[#5A5A5A] absolute right-[11px] top-[12px] cursor-pointer'
                    />
                }
                {!isShowPassword && 
                    <EyeOff 
                    onClick={() => setIsShowPassword(!isShowPassword)} 
                    size={20} 
                    className='text-[#5A5A5A] absolute right-[11px] top-[12px] cursor-pointer'
                    />
                }
            </div>
            <div className='mt-5 flex gap-3 items-center justify-end'>
                <button onClick={closeModal} type="button" className='mt-5 table-second-btn w-[135px] h-[46px] hover:!bg-[#FFEAAE]'>Hủy</button>
                <button 
                onClick={() => handleClickSubmitPassword(password)} 
                type="button" 
                className='mt-5 table-primary-btn w-[135px] h-[46px] !bg-[AD752A] hover:bg-[#FFEAAE] disabled:bg-table_primary_btn/50'
                disabled={!password}
                >OK</button>
            </div>
        </div>
    </ModalContainer>
  )
}

export default ConfirmSuperPassword