import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {FormProvider, useForm} from 'react-hook-form';
import {DevTool} from '@hookform/devtools';
import {FamilyBackground} from '../../assets/icons/FamilyBackground';
import {FamilyName} from '../../assets/icons/FamilyName';
import {useAuth} from 'core/contexts/auth/AuthContext';
import House from 'assets/images/house.png';
import HouseFooter from 'assets/images/house-footer.png';

const ForgotPasswordPage = () => {
    const methods = useForm({
        defaultValues: {
            emailReset: '',
        },
        mode: 'onChange'
    });
    const {formState: {errors}, handleSubmit} = methods;
    const {forgotPassword, error, setError} = useAuth();
    const [isSentPassword, setIsSentPassword] = useState(false);
    const navigate = useNavigate();
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);

    const watchEmail = methods.watch('emailReset');


    const onSubmit = async (data: any) => {
        await forgotPassword(data?.emailReset).then((response) => {
            if (response) {
                setIsSentPassword(true);
            }
        }).catch((error) => {
            setIsSentPassword(false);
            console.error("error", error);
        })
    };

    useEffect(() => {
        if (watchEmail && (Object.keys(errors).length === 0)) {
            setIsDisabledSubmitBtn(false);
        }else {
            setIsDisabledSubmitBtn(true);
        }
        if (error) {
            setError(undefined);
        }
    }, [watchEmail, errors]);

    return (
        <FormProvider {...methods}>
            <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen h-full bg-primary">
                <div className="grid-container hidden md:grid justify-items-center items-center">
                    <div className="relative h-full">
                        <FamilyBackground/>
                        <div className="absolute inset-0 flex justify-center items-center h-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/4 left-1/3 ">
                            <div className="flex flex-col justify-center items-center h-full w-full mt-28">
                                <FamilyName/>
                                <div className="flex flex-col items-center">
                                    <img src={House} alt="House"/>
                                    <img src={HouseFooter} alt="House Footer" style={{marginBottom: "10px", marginTop: "-30px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-base bg-white flex flex-col justify-start pt-6 items-center rounded-lg mx-auto"
                     style={{
                         maxWidth: '540px',
                         height: '434px',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                     }}>
                    {
                        !isSentPassword ? (<form className="container-base mx-auto space-y-6 " style={{
                            width: '445px'
                        }} onSubmit={handleSubmit(onSubmit)}>
                            <div className=' text-2xl font-medium text-[#5A5A5A]'>Quên mật khẩu</div>
                            
                            <div>
                                <label htmlFor="emailReset" className="block text-sm">Vui lòng nhập email của
                                    bạn</label>
                                <div className="mt-2">
                                    <input
                                        {...methods.register('emailReset', {
                                            required: 'Vui lòng nhập email',
                                            validate: (value) => {
                                                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                if (!regex.test(value)) {
                                                  return 'Vui lòng nhập email hợp lệ';
                                                }
                                            }
                                        })}
                                        id="emailReset"
                                        autoComplete="email"
                                        className="form-input !h-[46px]"
                                    />
                                    {errors.emailReset && (
                                        <span className="text-red-500 text-sm mt-1">{errors.emailReset.message}</span>
                                    )}
                                    {error && <span className="text-red-500 text-sm mt-1">{error?.error?.message}</span>}
                                </div>
                            </div>
                            <div className={"flex gap-2"}>
                                <button
                                    onClick={() => {
                                        navigate("/login");
                                    }}
                                    type="button"
                                    className="table-second-btn w-full h-[46px] !text-lg"
                                >
                                    Hủy
                                </button>
                                <button type="submit" disabled={isDisabledSubmitBtn}
                                        className="table-primary-btn w-full h-[46px] !text-lg">Tiếp
                                    tục
                                </button>
                            </div>
                        </form>) : (<div className="container-base mx-auto space-y-6 " style={{
                            width: '445px'
                        }}>
                            <label htmlFor="labelSent" className="block text-sm">Mật khẩu mới đã được gửi tới
                                email <span className='text-[#2D7DFF]'>{methods.getValues().emailReset}</span> của bạn. Vui lòng nhập mật khẩu mới để đăng nhập
                                vào hệ thống</label>
                            <div className="mt-2">
                                <button
                                    onClick={() => {
                                        navigate("/login")
                                    }}
                                    type="button"
                                    className="table-primary-btn w-full h-[46px] !text-lg">Xong
                                </button>
                            </div>
                        </div>)
                    }

                </div>
            </div>
            <DevTool control={methods.control} placement="top-left"/>
        </FormProvider>
    );
};

export default ForgotPasswordPage;
