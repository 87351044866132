import MasterLayout from 'components/layouts/MasterLayout'
import { LoadingBarProcess } from 'components/loadingProcess/LoadingBarProcess'
import LoginPage from 'pages/login/LoginPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />}>
      </Route>
    </Routes>
  )
}

export default LoginRoutes