import axios from "axios";
import { HOST_URL } from "core/constant/api.contant";

export const axiosClient = axios.create({
  baseURL: HOST_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.request.use(
  (config: any) => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const token = user.access_token;
      const auth = token ? `Bearer ${token}` : "";
      config.headers["Authorization"] = auth;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default axiosClient;
