import React, { useEffect, useState } from "react";
import "swiper/css";
import { TextField } from "@mui/material";
import { useChangeNameImageAlbum } from "../../../hooks/useImages/useChangeNameImageAlbum";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AlbumsService } from "core/services/albums.service";
import { AlertTriangle, CheckCircle, Trash } from "react-feather";
import { generateRandomString } from "core/utils/ultils";

const ImageItemsAllowEdit = ({ album, albumName, fileArray, setError, handleDeleteImage, setChooseImageError }: any) => {
  const [listName, setListName] = useState<any>([]);
  const [listCheck, setListCheck] = useState<any>([]);
  const [listHovered, setListHovered] = useState<any>([]);
  const { mutateAsync: updateNameMuation } = useChangeNameImageAlbum();
  const [albumData, setAlbumData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setAlbumData(album);
    if (album?.length > listCheck?.length) {
      setListName((prev: any) => {
        return prev.concat(
          Array.from({ length: album.length - listCheck.length }, (_) => ""),
        );
      });
      setListCheck((prev: any) => {
        return prev.concat(
          Array.from({ length: album.length - listCheck.length }, (_) => false),
        );
      });
      setListHovered((prev: any) => {
        return prev.concat(
          Array.from({ length: album.length - listCheck.length }, (_) => false),
        );
      });
    };
  }, [album]);

  const handleCreateAlbum = async () => {
    if (!fileArray || fileArray.length === 0) {
      setChooseImageError("Vui lòng chọn tí nhất 1 hình ảnh");
      return;
    }
    const res = await AlbumsService.createAlbum(albumName);

    if (res?.data?.isSuccess) {
      handleUploadImageToAlbum(res?.data?.data?.id);
    }else {
      toast(res?.data?.errors?.length > 0 ? res?.data?.errors[0]?.message : 'Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
  };

  const handleUploadImageToAlbum = async (albumId: string) => {
    const res = await AlbumsService.uploadFile(fileArray, albumId);
    
    if (res?.data?.isSuccess) {
      let albumMap = [...albumData];

      albumMap = albumMap?.map((albumImage: any, indexMap: number) => {
        const albumImageId = res?.data?.data?.find((albumImage: any, indexId: number) => indexMap === indexId);
        return {
         ...albumImage,
          id: albumImageId?.id,
        };
      });
      setAlbumData(albumMap);

      if(listCheck.some((value: any) => value === true)) {
        handleUpdateNameImage(albumId, albumMap);
      }else {
        toast('Lưu album thành công', {
          type: 'success',
          icon: <CheckCircle className="text-[#007B40]" />
        });
        navigate(`/images/${albumId}/${albumName}`);
      }
    }else {
      toast(res?.data?.errors?.length > 0 ? res?.data?.errors[0]?.message : 'Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
  };

  const handleUpdateNameImage = async (albumId: string, albumMap: any) => {
    try {
      const res = await Promise.all(
        listName.reduce((a: any, c: any, i: any) => {
          if (c)
            a.push(
              updateNameMuation({
                id: albumMap[i]?.id,
                name: listName[i],
              }),
            );
          return a;
        }, [] as any[]),
      );

      if (res.every((i) => !i?.data?.isSuccess))
        return toast.error("Có lối xảy ra");

      toast('Lưu album thành công', {
        type: 'success',
        icon: <CheckCircle className="text-[#007B40]" />
      });
      
      navigate(`/images/${albumId}/${albumName}`);
    } catch (error) {}
  };

  const handleClickSubmitBtn = () => {
    if (!albumName) {
      setError("albumName", {
        type: "manual",
        message: "Vui lòng nhập tên album",
      }, { shouldFocus: true });
      return;
    }
    handleCreateAlbum();
  };

  const handleClickDeleteImage = (imageIndex: any) => {
    let _listCheck = [...listCheck];
    let _listName = [...listName];
    let _listHovered = [...listHovered];
    _listCheck.splice(imageIndex, 1);
    _listName.splice(imageIndex, 1);
    _listHovered.splice(imageIndex, 1);
    setListCheck(_listCheck);
    setListName(_listName);
    setListHovered(_listHovered);
    handleDeleteImage(imageIndex);
  };

  const onMouseEnterImage = (imageIndex: number) => {
    let _listHovered = [...listHovered];
    if (_listHovered[imageIndex]) return;

    _listHovered[imageIndex] = true;
    setListHovered(_listHovered);
  };

  const onMouseLeaveImage = (imageIndex: number) => {
    let _listHovered = [...listHovered];
    if (!_listHovered[imageIndex]) return;

    _listHovered[imageIndex] = false;
    setListHovered(_listHovered);
  };

  const handleImageNameChange = (imageIndex: number, event: any): void => {
    let _listName = [...listName];
    _listName[imageIndex] = event.target.value;
    setListName(_listName);
  };

  return (
    <>
      <div
        className={
          "flex flex-wrap gap-x-[26px] gap-y-[46px]"
        }
      >
        {albumData &&
          albumData?.map((item: any, index: number) => (
            <div
              className="flex flex-col items-center"
              key={`${item.imageUrl}_${index}`}
            >
              <div
                className=" relative"
                onMouseEnter={() => onMouseEnterImage(index)}
                onMouseLeave={() => onMouseLeaveImage(index)}
              >
                <img
                  src={`${item.imageUrl}`}
                  className="rounded-[8px] w-[280px] h-[250px]  object-scale-down"
                  alt="album"
                />
                {listHovered[index]&&
                  <div 
                  className="absolute top-0 right-0 bottom-0 left-0 bg-gray-400 bg-opacity-40 rounded-[9.2px] flex items-center justify-center gap-[26px] cursor-pointer"
                  onClick={() => handleClickDeleteImage(index)}
                  >
                    <Trash color="#461511" size={24}/>
                  </div>
                }
              </div>
              {listCheck[index] ? (
                <TextField
                  value={listName[index]}
                  onChange={(e) => handleImageNameChange(index, e)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    width: "200px",
                    height: "30px",
                    marginTop: "10px",
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        border: "1px solid #E1E1E1",
                        cursor: "pointer",
                      },
                      "&:hover fieldset": {
                        border: "1px solid #E1E1E1",
                        cursor: "pointer",
                      },
                    },
                  }}
                  placeholder={"Nhập tên hình ảnh"}
                />
              ) : (
                <p
                  className="mt-2"
                  onClick={() => {
                    setListCheck(
                      listCheck.map((check: boolean, i: number) => {
                        if (i === index) return true;
                        return check;
                      }),
                    );
                  }}
                >{`<Nhập tên hình ảnh>`}</p>
              )}
            </div>
          ))}
      </div>
      <div className="flex items-center justify-center mt-10 mb-20">
        <div className="flex gap-[30px] mb-8">
          <button
            type="submit"
            className="table-primary-btn w-[132px] h-[42px]"
            onClick={async () => handleClickSubmitBtn()}
          >
            Lưu
          </button>

          <button
            type="button"
            className="table-second-btn w-[132px] h-[42px]"
            onClick={() => {
              navigate(-1);
            }}
          >
            Hủy
          </button>
        </div>
      </div>
    </>
  );
};
export default ImageItemsAllowEdit;
