import { ALBUMS } from "core/constant/api.contant";
import axiosClient from "utilities/axios-instance";

export const getAlbums = async () => {
  try {
    return axiosClient.get(ALBUMS.ALBUMS);
  } catch (error) {
    console.log(error);
  }
};
export const getAlbumsPaging = async ({
  Name,
  PageNumber = 1,
  PageSize,
}: {
  Name?: string;
  PageNumber: any;
  PageSize: any;
}) => {
  let params: any = {
    PageSize: PageSize,
    PageNumber: PageNumber,
  }
  if (Name) {
    params = {
      ...params,
      Name: Name,
    }
  }
  try {
    return axiosClient.get(ALBUMS.ALBUMPAGING, {params});
  } catch (error) {
    console.log(error);
  }
};
export const getImagesFromAlbum = async (idAlbum: string) => {
  try {
    return axiosClient.get(ALBUMS.IMAGES_FROM_ALBUM(idAlbum));
  } catch (error) {
    console.log(error);
  }
};

export const createAlbum = async (name: string | undefined) => {
  try {
    return axiosClient.post(ALBUMS.CREATE_ALBUM, { name });
  } catch (error) {
    console.log(error);
  }
};

export const uploadFile = async (files: any, idAlbum: string) => {
  const formData = new FormData();
  for (let i = 0; i < files.length; i++) {
    formData.append("images", files[i]);
  }
  return axiosClient.post(
    ALBUMS.UPLOAD_IMAGES(idAlbum),
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
};

export const changeNameImageAlbum = (id: string, name: string) => {
  return axiosClient.post(ALBUMS.CHANGE_IMAGE_NAME(id), { name });
};

export const AlbumsService = {
  getAlbums,
  createAlbum,
  uploadFile,
  getImagesFromAlbum,
  getAlbumsPaging,
  changeNameImageAlbum,
};
