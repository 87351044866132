import { useMutation, useQuery } from "react-query";
import { GenealogyService } from "../../core/services/genealogy.service";
import { EventService } from "../../core/services/event.service";

export const useUploadImage = () => {
  return useMutation({
    mutationKey: ["media-upload-image"],
    mutationFn: async (params: any) => {
      const res = await EventService.uploadFileImage(
          params?.files,
          params?.transactionId,
      );
      return res.data;
    },
  });
};
export const useRemoveEventById = () => {
  return useMutation({
    mutationKey: ["remove-event"],
    mutationFn: async (id: string) => {
      const res = await EventService.removeEvent(id);
      return res.data;
    },
  });
};

export const useAddEvent = () => {
  return useMutation({
    mutationKey: ["add-event"],
    mutationFn: async (body: any) => {
      const res = await EventService.addEvent(body);
      return res.data;
    },
  });
};
export const useEditEvent = () => {
  return useMutation({
    mutationKey: ["edit-event"],
    mutationFn: async (body: any) => {
      const res = await EventService.editEvent(body);
      return res.data;
    },
  });
};
export const useGetOrg = (params: any) => {
  return useQuery({
    queryKey: ["ORG", params],
    queryFn: async () => {
      const res = await EventService.getOrg(params?.type);
      return res.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!params?.type,
  });
};

export const useGetAllEvents = (params: any) => {
  return useQuery({
    queryKey: ["EVENTS"],
    queryFn: async () => {
      const res = await GenealogyService.getEvents(params);
      return res.data;
    },
    refetchOnWindowFocus: false,
    enabled: true,
  });
};

export const useGetDetailById = (id: string | undefined) => {
  return useQuery({
    queryKey: ["EVENTS", id],
    queryFn: async () => {
      const res = id ? await GenealogyService.getEventById(id) : undefined;
      return res?.data;
    },
    refetchOnWindowFocus: false,
    enabled: !!id,
  });
};