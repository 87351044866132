import { GENEALOGY } from "core/constant/api.contant";
import axiosClient from "utilities/axios-instance";

export const getArticle = async () => {
  try {
    return axiosClient.get(GENEALOGY.ARTICLE);
  } catch (error) {
    console.log(error);
  }
};

export const createArticle = async (content: string | undefined) => {
  try {
    return axiosClient.post(GENEALOGY.CREATE_ARTICLE, { content });
  } catch (error) {
    console.log(error);
  }
};

const getGenealogyTree = (params: any) => {
  return axiosClient.get(GENEALOGY.TREE, { params });
};

const postOrganizationPassword = (params: any) => {
  return axiosClient.post(GENEALOGY.ORGANIZATION, params);
};

const postPersonPassword = (params: any) => {
  return axiosClient.post(GENEALOGY.PERSON, params);
};

const postGenerationPassword = (params: any) => {
  return axiosClient.post(GENEALOGY.GENERATION, params);
};

const getEvents = (params: any) => {
  return axiosClient.get(GENEALOGY.EVENTS, {params});
};

const getEventById = (id: string) => {
  return axiosClient.get(GENEALOGY.EVENT_DETAIL_BY_ID(id));
};

const getEventUpcoming = (params: any) => {
  return axiosClient.get(GENEALOGY.EVENTS_UPCOMING, {params});
};

const searchUsers = (params: any) => {
  return axiosClient.get(GENEALOGY.SEARCH_USERS, {params});
};

export const GenealogyService = {
  getArticle,
  createArticle,
  getGenealogyTree,
  postOrganizationPassword,
  postPersonPassword,
  getEvents,
  getEventById,
  getEventUpcoming,
  postGenerationPassword,
  searchUsers
};
