import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
// material-ui
// third-party
import FullCalendar from "@fullcalendar/react";

// redux
// import { useDispatch, useSelector } from "store";
// import {
//   addEvent,
//   getEvents,
//   removeEvent,
//   updateEvent,
// } from "store/slices/calendar";
// project imports
import MainCard from "./MainCard";
import CalendarStyled from "./CalendarStyled";

// assets
// import { FormikValues } from "formik";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import moment from "moment/moment";
import {
  useFilterEventActionsContext,
  useFilterEventContext,
} from "../../context/FilterEventContextProvider";
import { ModalEventDetail } from "../ModalEventDetailComponent";

// ==============================|| APPLICATION CALENDAR ||============================== //

// type DateRange = { start: number | Date; end: number | Date };
export const Calendar = forwardRef((props, ref) => {
  // const dispatch = useDispatch();
  const calendarRef = useRef<FullCalendar>(null);
  const { updateDatePresent, updateDetailId } = useFilterEventActionsContext();
  const { events, detailEvent } = useFilterEventContext();

  // // fetch events data
  //
  const convertEvents = useMemo(() => {
    const arr = events?.data?.data?.records?.map(
      (i: any, idx: number) => ({
        id: i?.id,
        title: i?.name,
        color: "#F2B84C",
        start: moment(i?.fromDate).format("YYYY-MM-DD"),
        end: moment(i?.toDate).format("YYYY-MM-DD"),
        allDay: true,
      }),
    );
    return arr || [];
  }, [events]);

  useEffect(() => {
    // dispatch(getEvents());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [date, setDate] = useState(new Date());
  const [view, setView] = useState("dayGridMonth");

  useEffect(() => {
    updateDatePresent(date);
  }, [date]);

  // calendar toolbar events
  const handleDateToday = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(calendarApi.getDate());
    }
  };

  const handleViewChange = (newView: string) => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setView(newView);
    }
  };

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());
    }
  };

  const handleDateNext = () => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      setDate(calendarApi.getDate());
    }
  };
  useImperativeHandle(ref, () => ({
    next: () => {
      handleDateNext();
    },
    prev: () => {
      handleDatePrev();
    },
    toDay: () => {
      handleDateToday();
    },
    handleView: (modeView: string) => {
      handleViewChange(modeView);
    },
    getDate: () => moment(date).format("MM/YYYY"),
  }));

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [selectedRange, setSelectedRange] = useState<DateRange | null>(null);
  // const [selectedEvent, setSelectedEvent] = useState<FormikValues | null>(null);

  // calendar event select/add/edit/delete
  const handleRangeSelect = (arg: any) => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    // setSelectedRange({
    //   start: arg.start,
    //   end: arg.end,
    // });
    // setIsModalOpen(true);
  };

  const handleEventSelect = (arg: any) => {
    if (arg.event.id) {
      updateDetailId({
        eventId: arg.event.id,
        onSuccess: () => {
          setIsModalOpen(true);
        },
        onError: () => {
          toast.error("Có lỗi xảy ra.");
        },
      });
    }
    //   const selectEvent = events.find(
    //     (_event: FormikValues) => _event.id === arg.event.id,
    //   );
    //   setSelectedEvent(selectEvent as FormikValues[]);
    // } else {
    //   setSelectedEvent(null);
    // }
  };

  const handleEventUpdate = async ({ event }: any) => {
    try {
      // dispatch(
      //   updateEvent({
      //     eventId: event.id,
      //     update: {
      //       allDay: event.allDay,
      //       start: event.start,
      //       end: event.end,
      //     },
      //   }),
      // );
    } catch (err) {
      console.error(err);
    }
  };

  // const handleAddClick = () => {
  //   setIsModalOpen(true);
  // };

  const handleModalClose = () => {
    setIsModalOpen(false);
    // setSelectedEvent(null);
    // setSelectedRange(null);
  };

  return (
    <>
      <MainCard>
        <CalendarStyled>
          <FullCalendar
            weekends
            editable
            droppable
            selectable
            events={convertEvents}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            select={handleRangeSelect}
            eventDrop={handleEventUpdate}
            eventClick={handleEventSelect}
            eventResize={handleEventUpdate}
            height={700}
            plugins={[
              listPlugin,
              dayGridPlugin,
              multiMonthPlugin,
              timeGridPlugin,
              interactionPlugin,
            ]}
          />
        </CalendarStyled>
      </MainCard>
      {isModalOpen && (
        <ModalEventDetail
          open={isModalOpen}
          onClose={handleModalClose}
          data={detailEvent?.data?.data?.data}
        />
      )}
    </>
  );
});
