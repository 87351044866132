import React from 'react';
import { AlertTriangle, PlusCircle, Trash } from 'react-feather';
import { Tooltip } from 'react-tooltip';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';

type RelationshipSectionProps = {
    selectedItem: any;
    setSelectedItem: (data: any) => void;
    LoadUser: (data: any) => void;
    placeholder: string;
    tooltipId: string;
    tooltipText: string;
    setError: any;
    clearErrors: any;
}

const RelationshipSection = ({
    selectedItem,
    setSelectedItem,
    LoadUser,
    placeholder,
    tooltipId,
    tooltipText,
    setError,
    clearErrors
} : RelationshipSectionProps) => {
    const handleAddItem = () => {
        if (selectedItem[selectedItem.length - 1] !== '') {
          let _selectedItem = [...selectedItem];
          _selectedItem.push('');
          setSelectedItem(_selectedItem);
        }else {
        //   toast('Vui lòng chọn người hiện tại', {
        //     type: 'error',
        //     icon: <AlertTriangle className="text-[#F03D3E]" />
        //   });
          setError(tooltipId, {
            type: 'custom',
            message: 'Vui lòng chọn người hiện tại'
          });
        }
    };
    const handleDeleteItem = () => {
        let _selectedItem = [...selectedItem];
        _selectedItem.pop();
        setSelectedItem(_selectedItem);
        clearErrors(tooltipId);
    };

    const handleItemChange = (data: any, index: number) => {
        let _selectedItem = [...selectedItem];
        _selectedItem[index] = data;
        setSelectedItem(_selectedItem);
        clearErrors(tooltipId);
    };
  return (
    <>
        {selectedItem?.map((item: any, index: number) => (
            <div className=' flex items-center gap-[7px]' key={`${tooltipId}_${index}`}>
                <AsyncSelect
                    value={item}
                    onChange={(data) => handleItemChange(data, index)}
                    loadOptions={LoadUser}
                    className='form-select-32 '
                    classNamePrefix={'select'}
                    placeholder={placeholder}
                    noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                    blurInputOnSelect={true}
                    isClearable={true}
                />
                <div className='w-6 h-8 relative'>
                    {index === (selectedItem?.length - 1) &&
                        <div className='absolute top-[7px] left-0 flex gap-1'>
                        {selectedItem?.length > 1 &&
                            <Trash
                            size={20} 
                            className=' text-table_primary_btn cursor-pointer'
                            onClick={() => handleDeleteItem()}
                            />
                        }
                        <PlusCircle 
                        size={20} 
                        className=' text-table_primary_btn cursor-pointer'
                        data-tooltip-id="older-brother-tooltip" 
                        data-tooltip-content={tooltipText}
                        onClick={() => handleAddItem()}
                        />
                        <Tooltip id="older-brother-tooltip" place='bottom' className='z-10'/>
                        </div>
                    }
                    </div>
            </div>
        ))}
    </>
  )
}

export default RelationshipSection