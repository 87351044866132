import { FunctionComponent, memo, useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import FilterSelectDateComponent from "./FilterSelectDateComponent";
import {
  Box,
  Button,
  ButtonGroup,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { colorApp } from "../../../core/constant/app-constant";
import {
  useFilterEventActionsContext,
  useFilterEventContext,
} from "../context/FilterEventContextProvider";
import { Calendar } from "./calendar";
import moment from "moment";
import { ChevronLeft, ChevronRight } from "react-feather";

const EventCalendarScheduleComponent: FunctionComponent = () => {
  const { mode, dateView } = useFilterEventContext();
  const { updateCalendarMode } = useFilterEventActionsContext();
  const ref = useRef(null);
  const handleAlignment = (e: React.MouseEvent<HTMLElement>, newValue: any) => {
    switch (newValue) {
      case "month": {
        updateCalendarMode(newValue);
        // @ts-ignore
        ref?.current?.handleView("dayGridMonth");
        break;
      }
      case "year": {
        updateCalendarMode(newValue);
        // @ts-ignore
        ref?.current?.handleView("multiMonthYear");
        break;
      }
      default:
        break;
    }
  };
  const nextCalendar = () => {
    // @ts-ignore
    ref?.current?.next();
  };

  const preCalendar = () => {
    // @ts-ignore
    ref?.current?.prev();
  };

  const toDayCalendar = () => {
    // @ts-ignore
    ref?.current?.toDay();
  };
  return (
    <>
      <Grid columns={{ xs: 5 }} container>
        <Grid xs={4}>
          <FilterSelectDateComponent />
        </Grid>
        <Grid xs={1}>
          <Stack
            sx={{ color: "#595959", fontWeight: 500 }}
            display={"flex"}
            alignItems={"flex-end"}
          >
            <ToggleButtonGroup
              value={mode}
              exclusive
              onChange={handleAlignment}
              aria-label="text alignment"
              size="small"
              sx={{
                height: "32px !important",
                color: colorApp.colorFilter,
                backgroundColor: "#FFFFFF",
                "& .Mui-selected": {
                  color: colorApp.filterActive,
                  border: `2px solid ${colorApp.filterActive}`,
                  backgroundColor: "#FFFFFF",
                },
                "& .MuiButtonBase-root": {
                  textTransform: "none",
                },
              }}
            >
              <ToggleButton sx={{minWidth: "60px"}} value="month" aria-label="left aligned">
                {"Tháng"}
              </ToggleButton>
              <ToggleButton sx={{minWidth: "60px"}} value="year" aria-label="centered">
                {"Năm"}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Grid>
      </Grid>
      <Grid
        paddingTop={"10px"}
        paddingBottom={0}
        margin={0}
        spacing={1}
        columns={{ xs: 1 }}
        container
      >
        <Grid
          xs={1}
          sx={{
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ButtonGroup
            variant="outlined"
            aria-label="Basic button group"
            sx={{
              height: "32px !important",
              backgroundColor: "#FFFFFF",
              "& .MuiButtonBase-root": {
                textTransform: "none",
                color: colorApp.filterActive,
                // border: `1px solid ${colorApp.filterActive}`,
                ":hover": {
                  backgroundColor: "#FFFFFF",
                },
              },
            }}
          >
            {/* <Button variant="outlined" sx={{outlineColor: "#461511", borderBlockColor: "#461511"}} onClick={() => toDayCalendar()}>{"Hôm nay"}</Button> */}
            <button 
            className="border border-[#461511] text-[#461511] w-[88px] h-[32px] flex items-center justify-center"
            onClick={() => toDayCalendar()}
            >
              <span className=" text-sm">Hôm nay</span>
            </button>
            <div className=" bg-yellow_FFFBEE flex items-center cursor-pointer pl-3" onClick={() => preCalendar()}>
              <ChevronLeft color="#F2B84F" size={24}/>
            </div>
            <Typography sx={{ 
              color: colorApp.filterActive, 
              fontWeight: 700, 
              background: "#FFFBEE", 
              paddingLeft: "8px", 
              paddingRight: "8px", 
              display: "flex", 
              alignItems: "center", 
              }}>
              {mode === "month"
                ? `Tháng ${moment(dateView).format("M")}`
                : `Năm ${moment(dateView).format("YYYY")}`}
            </Typography>
            <div className=" bg-yellow_FFFBEE flex items-center cursor-pointer" onClick={() => nextCalendar()}>
              <ChevronRight color="#F2B84F" size={24}/>
            </div>
          </ButtonGroup>
          
        </Grid>
        <Grid xs={1} sx={{padding: 0, marginTop: "16px"}}>
          <Calendar ref={ref} />
        </Grid>
      </Grid>
    </>
  );
};

export default memo(EventCalendarScheduleComponent);
