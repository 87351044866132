import React from "react";
import HouseSample from "assets/images/vintage-house.jpg";
import "swiper/css";
import { useNavigate } from "react-router-dom";

const ImageItems = ({ images, isAlbums }: { images: any, isAlbums: boolean }) => {
  const navigate = useNavigate();
  const handleClickImage = (image: any) => {
    if (isAlbums) {
      navigate(`/images/${image?.id}/${image?.name}`)
    }else {
      return;
    }
  };
  return (
    <div
      className={
        "grid place-content-center xs:grid-cols-1 md:grid-cols-12  gap-[26px]"
      }
    >
      {images.map((item: any) => (
        <div 
        className={`col-span-3 flex flex-col items-center ${isAlbums ? 'cursor-pointer' : ''} `}
        onClick={() => handleClickImage(item)}
        key={item.id}>
          <img
            src={
              item?.imageUrl || item?.image?.imageUrl || HouseSample
            }
            className="rounded-[8px] w-[280px] h-[250px]  object-cover"
            alt="HouseSample"
          />
          <p className="font-light text-center mt-[10px]">
            {item.name || "No name"}
          </p>
        </div>
      ))}
    </div>
  );
};
export default ImageItems;
