import MasterLayout from 'components/layouts/MasterLayout'
import { LoadingBarProcess } from 'components/loadingProcess/LoadingBarProcess'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { RequireAuth } from './Routes'
import Profile from 'pages/profile/Profile'

const ProfileRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <RequireAuth>
                <Profile />
              </RequireAuth>
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default ProfileRoutes