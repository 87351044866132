import React, { useEffect, useState } from 'react';
import { User } from 'react-feather';
import SearchItem from './components/SearchItem';
import { GenealogyService } from 'core/services/genealogy.service';
import TableSearchBar from 'components/uiComponents/TableSearchBar';
import { useSearchParams } from 'react-router-dom';
import PersonModal from 'pages/genealogy/components/PersonModal';
import { getCurrentBrowserFingerPrint } from "@rajesh896/broprint.js";
import ConfirmSuperPassword from 'pages/genealogy/components/ConfirmSuperPassword';
import { toast } from 'react-toastify';
import { AlertTriangle } from 'react-feather';
import AuthService from 'core/services/auth.service';

const SearchPage = () => {
    const [searchParams] = useSearchParams();
    const keywordUrl = searchParams.get('keyword') || '';
    const [users, setUsers] = useState<any>([]);
    const [keyword, setKeyword] = useState<string>("");
    const [isSearch, setIsSearch] = useState<boolean>(false);
    const [isShowPersonModal, setIsShowPersonModal] = useState<any>(false);
    const [currentPersonPassword, setCurrentPersonPassword] = useState<any>();
    const [personPasswordData, setPersonPasswordData] = useState<any>();
    const [browserID, setBrowserID] = useState<any>();
    const [isShowConfirmModal, setIsShowConfirmModal] = useState<any>(false);

    useEffect(() => {
        if (keywordUrl) {
            setKeyword(keywordUrl);
            onSearch(keywordUrl);
        }
    }, [keywordUrl]);

    useEffect(() => {
        getCurrentBrowserFingerPrint().then((fingerprint) => {
            setBrowserID(fingerprint);
        });
    }, []);

    const getListUser = async (keywordData: string) => {
        let params: any = {
            PageNumber: 1,
            PageSize: 100,
        }
        
        if (keywordData) {
            params = {
                ...params,
                Keyword: keywordData,
            }
        }else {
            setUsers([]);
            setIsSearch(false);
            return;
        }
        
        const response = await GenealogyService.searchUsers(params);
        
        if (response?.data?.isSuccess === true) {
            setUsers(response?.data || []);
            setIsSearch(true);
        }
    };

    const onSearch = (keyword: string) => {
        getListUser(keyword);
    };

    const _handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            setKeyword(e.target.value?.trim());
            onSearch(e.target.value?.trim());
        }
    };

    const handleClosePersonModal = () => {
        setIsShowPersonModal(false);
        setCurrentPersonPassword(undefined);
        setPersonPasswordData(undefined);
    };

    const handleClickDetailBtn = (id: string) => {
        postPersonPassword(id, browserID?.toString());
    };

    const postPersonPassword = async (personId: string, deviceId: string) => {
        const params = {
          personId: personId,
          deviceId: deviceId
        }
        const res = await GenealogyService.postPersonPassword(params);
        if (res?.data?.isSuccess) {
          setPersonPasswordData(res?.data?.data);
          setIsShowPersonModal(true);
          setIsShowConfirmModal(false);
          setTimeout(() => {
            setIsShowPersonModal(true);
          }, 300);
        }else {
          setIsShowConfirmModal(true);
        }
    };

    const handleLoginIPass = async (password: string) => {
        try {
          const res = await AuthService.loginIPass(browserID.toString(), password);
          if (res?.isSuccess) {
            if (currentPersonPassword) {
              postPersonPassword(currentPersonPassword, browserID.toString());
            }
          }else {
            toast(res?.errors[0]?.message || 'Lỗi kết nối mạng!', {
              type: 'error',
              icon: <AlertTriangle className="text-[#F03D3E]" />
            });
          }
        } catch (error) {
          console.log('error', error);
          toast('Lỗi kết nối mạng!', {
            type: 'error',
            icon: <AlertTriangle className="text-[#F03D3E]" />
          });
        }
      }

    const handleCloseConfirmPasswordModal = () => {
        setIsShowConfirmModal(false);
        setCurrentPersonPassword(undefined);
        setPersonPasswordData(undefined);
    };

    const handleClickSubmitPassword = (password: string) => {
        handleLoginIPass(password);
    };

  return (
    <div className='min-h-[400px]'>
        <div className='flex mt-5'>
            <div className='w-1/2'>
                <div className='mt-[11px] mb-[9px]'>
                    <TableSearchBar keyword={keyword} setKeyword={setKeyword} _handleKeyDown={_handleKeyDown} onSearch={onSearch} containerClass='w-full'/>
                </div>
            </div>
            <div className='w-1/2'></div>
        </div>
        {isSearch &&
            <div className='flex gap-2'>
                <User size={24} className='text-[$461511]'/>
                <div>
                    {`Kết quả tìm kiếm: ${users?.data?.length || '0'}`}
                </div>
            </div>
        }
        {users?.data?.length > 0 &&
            <div className="mt-4">
                <div className="flex text-black/60 text-[15px]">
                    <div className="w-[25%] text-center">Họ và tên</div>
                    <div className="w-[12%] text-center">Ngày sinh</div>
                    <div className="w-[12%] text-center">Ngày mất</div>
                    <div className="w-[12%] text-center">Nhánh</div>
                    <div className="w-[10%] text-center">Chi</div>
                    <div className="w-[10%] text-center">Phái</div>
                    <div className="w-[9%] text-center">Đời</div>
                    <div className="w-[10%] text-center"></div>
                </div>
                <div className="w-full space-y-2">
                    {users?.data?.map((item: any, index: number) => (
                        <SearchItem key={index} data={item} handleClickDetailBtn={handleClickDetailBtn}/>
                    ))}
                </div>
            </div>
        }
        {users?.length === 0 && !keyword &&
            <div className='form-normal-text mt-4'>Nhập tên hoặc mã số để tìm kiếm</div>
        }
        {users?.length === 0 && keyword && isSearch &&
            <div className='form-normal-text mt-4'>{`Không có kết quả cho ${keyword}`}</div>
        }
        {isShowPersonModal &&
            <PersonModal
            isOpen={isShowPersonModal}
            closeModal={() => handleClosePersonModal()}
            personData={personPasswordData}
            />
        }
        {isShowConfirmModal &&
          <ConfirmSuperPassword 
          isOpen={isShowConfirmModal} 
          closeModal={() => handleCloseConfirmPasswordModal()}
          handleClickSubmitPassword={handleClickSubmitPassword}
          />
      }
    </div>
  )
}

export default SearchPage