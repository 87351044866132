import React, { useEffect, useRef, useState } from "react";
import { BsCalendarEvent } from "react-icons/bs";
import { Controller, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import DatePickerIcon from "../../../assets/images/datepickerIcon.png";
import Select from "react-select";
import { IoAddCircleOutline } from "react-icons/io5";
import {
  useGetDetailById,
  useAddEvent,
  useEditEvent,
  useRemoveEventById,
  useUploadImage,
} from "../../../hooks/useGetEvents";
import { LoadingBarProcess } from "../../../components/loadingProcess/LoadingBarProcess";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { isEmpty } from "lodash";
import { AlertTriangle, CheckCircle, Trash } from "react-feather";
import { findNullIndexesInObject } from "core/utils/ultils";
import { MediaService } from "core/services/media.service";
import { UserService } from "core/services/user.service";
import imageCompression from 'browser-image-compression';

const EventList = () => {
  const { id } = useParams();

  const [selectedClan, setSelectedClan] = useState<any>();
  const [selectedLineage, setSelectedLineage] = useState<any>();
  const [clanOptions, setClanOptions] = useState<any>([]);
  const [lineageOptions, setLineageOptions] = useState<any>([]);

  const { data: detailEvent, refetch } = useGetDetailById(id);

  const [preview, setPreview] = useState<any>({
    preview1: null,
    preview2: null,
    preview3: null,
    preview4: null,
    preview5: null,
  });
  const ref = useRef(null);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      name: null,
      fromDate: "",
      history: null,
      clan: null,
      lineage: null,
      isAnnualEvent: "No",
    },
  });
  const navigate = useNavigate();
  const [eventDate, setEventDate] = useState<any>();
  const [isAnnualEvent, setIsAnnualEvent] = useState<boolean>(false);
  const [imageFiles, setImageFiles] = useState<any>([]);

  const { mutate: handleAddEvent, isLoading: isLoadingSubmit } = useAddEvent();
  const { mutate: handleEditEvent, isLoading: isLoadingSubmitEdit } =
      useEditEvent();
  const { mutate: handleRemoveEvent } = useRemoveEventById();

  const [transactionId, setTransactionId] = useState(null);
  const [hoveredImage, setHoveredImage] = useState<any>({
    hoveredImage1: false,
    hoveredImage2: false,
    hoveredImage3: false,
    hoveredImage4: false,
    hoveredImage5: false,
  });
  const [imageDeleted, setImageDeleted] = useState<any>([]);
  const [chooseImageError, setChooseImageError] = useState<any>('');
  const [currentDetailImages, setCurrentDetailImages] = useState<any>();
  const [isUploadImageChange, setIsUploadImageChange] = useState<boolean>(false);
  const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);

  const watchName = watch('name');
  const watchFromDate = watch('fromDate');

  const deleteImages = async () => {
    const params = {
      imageIds: imageDeleted
    }
    
    const res = await MediaService.deleteImages(params);
    refetch();
    return res?.data?.isSuccess;
  };

  const getOrganizations = async (params: any, type: string) => {
    const response = await UserService.getOrganization(params);
    const organizationsOption = response?.data?.data?.map((item: any) => {
      return { value: item?.id, label: item?.name, data: item };
    })
    if (response?.data?.isSuccess === true) {
      if (type === 'Clan') {
        setClanOptions(organizationsOption || []);
      }
      if (type === 'Lineage') {
        setLineageOptions(organizationsOption || []);
      }
    }
  };

  useEffect(() => {
    setValue("name", detailEvent?.data?.name);
    setValue("fromDate", detailEvent?.data?.fromDate);
    setEventDate(detailEvent?.data?.fromDate);
    setValue("history", detailEvent?.data?.history);
    if (detailEvent?.data?.organizations?.length > 0) {
      const clanData = detailEvent?.data?.organizations?.find((i: any) => i?.organizationType === "Clan");
      const lineageData = detailEvent?.data?.organizations?.find((i: any) => i?.organizationType === "Lineage");
      if (clanData) {
        setValue(
            "clan",
            clanData,
        );
        setSelectedClan({
          value: clanData?.id,
          label: clanData?.organizationName,
          data: clanData
        });
        getOrganizations({
          ParentId: clanData.id,
          PageNumber: 1,
          PageSize: 1000
        }, 'Lineage');
      }
      if (lineageData) {
        setValue(
            "lineage",
            lineageData,
        );
        setSelectedLineage({
          value: lineageData?.id,
          label: lineageData?.organizationName,
          data: lineageData
        });
      }
    }
    if (detailEvent?.data?.numberOfAnnualEvent === 50) {
      setIsAnnualEvent(true);
    }else {
      setIsAnnualEvent(false);
    }

    if (!isEmpty(detailEvent?.data?.images)) {
      detailEvent?.data?.images.forEach((image: any, index: number) => {
        setPreview((prevState: any) => ({
          ...prevState,
          [`preview${index + 1}`]: {
            url: image.imageUrl,
            id: image.id
          },
        }));
      });
      setCurrentDetailImages(detailEvent?.data?.images);
    }
    
  }, [detailEvent]);

  useEffect(() => {
    if (watchName && watchFromDate && (Object.keys(errors).length === 0)) {
      setIsDisabledSubmitBtn(false);
    }
  }, [watchName, watchFromDate]);

  useEffect(() => {
    getOrganizations({
      Type: 'Clan',
      PageNumber: 1,
      PageSize: 1000
    }, 'Clan');
  }, []);

  const handleUploadSuccess = () => {
    toast('Cập nhật sự kiện thành công', {
      type: 'success',
      icon: <CheckCircle className="text-[#007B40]" />
    });
    refetch();
    navigate("/su-kien");
  };
  
  const onSubmit = (data: any) => {
    let dataSend = {
      ...data,
      organizationId: selectedLineage?.value || selectedClan?.value || null,
      numberOfAnnualEvent: isAnnualEvent ? 50 : null,
      fromDate: data?.fromDate
          ? moment(data?.fromDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      toDate: data?.fromDate
          ? moment(data?.fromDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
    };

    if (isEmpty(id)) {
      const params: any = {
        files: imageFiles,
        transactionId: transactionId,
      };
      handleUploadImageGetTransactionId(params, {
        onSuccess: (data) => {
          if (data?.isSuccess) {
            dataSend = {
              ...dataSend,
              transactionId: data?.data?.transactionId,
            }
            handleAddEvent(dataSend, {
              onSuccess: (data: any) => {
                if (data?.isSuccess) {
                  toast('Tạo mới sự kiện thành công', {
                    type: 'success',
                    icon: <CheckCircle className="text-[#007B40]" />
                  });
                  navigate("/su-kien");
                }else {
                  toast(data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                  });
                }
              },
              onError: () => {
                toast('Lỗi kết nối mạng!', {
                  type: 'error',
                  icon: <AlertTriangle className="text-[#F03D3E]" />
                });
              },
            })
          }
        },
        onError: () => {
          toast.error("Hình ảnh đã được tải lên thất bại.", {
            position: "bottom-right",
          });
        },
      });
      
    }else {
      handleEditEvent(
        { dataSend, id },
        {
          onSuccess: (data: any) => {
            if (data?.isSuccess) {
              if (isUploadImageChange) {
                const params: any = {
                  files: imageFiles,
                  transactionId: id,
                };
                handleUploadImageGetTransactionId(params, {
                  onSuccess: (data: any) => {
                    refetch();
                  }
                })
              } 
              
              if (imageDeleted?.length > 0) {
                deleteImages();
              }

              handleUploadSuccess();
            }else {
              toast(data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
              });
            }
          },
          onError: () => {
            toast('Lỗi kết nối mạng!', {
              type: 'error',
              icon: <AlertTriangle className="text-[#F03D3E]" />
            });
          },
        },
      );
    }
  };

  const compressImage = async (imageFile: any) => {
    try {
      const options = {
        maxSizeMB: 0.9, // kích thước tối đa sau khi nén, ở đây là 1MB
        maxWidthOrHeight: 1920, // chiều rộng hoặc chiều cao tối đa
        useWebWorker: true // sử dụng web worker để tăng hiệu suất (khuyến nghị)
      };
  
      const compressedFile = await imageCompression(imageFile, options);
      return compressedFile;
    } catch (error) {
      console.error('Lỗi khi nén ảnh:', error);
      return null;
    }
  };

  const {
    mutate: handleUploadImageGetTransactionId,
    isLoading: isloadingUploadImage,
  } = useUploadImage();

  const handleUploadImage = async (e: any, index: number) => {
    setChooseImageError('');

    const currentImageFile = imageFiles.filter((image: any) => image);
    const nullIndexes = findNullIndexesInObject(preview);
    
    if ((currentImageFile.length + e.target.files?.length) > 5) {
      setChooseImageError('Đã vượt quá 5 hình ảnh, vui lòng chọn lại.');
      e.target.value = null;
      return;
    }
    if(id) {
      setIsUploadImageChange(true);
    }
    const files = [...e.target.files];
    const compressFiles = await Promise.all(files.map(async (file: any) => {
      return await compressImage(file);
    }));
    const _imageFiles = [...imageFiles, ...compressFiles];

    setImageFiles(_imageFiles);

    for (let i = 0; i < e.target.files.length; i++) {
      const img = new Image();
      img.src = window.URL.createObjectURL(e.target.files[i]);
      img.onload = () => {
        setPreview((prev: any) => ({
          ...prev,
          [nullIndexes[i]]: {
            url: URL.createObjectURL(e.target.files[i])
          }}),
        );
      };
    }
  };

  const handleEventDateChange = (date: any) => {
    setEventDate(date);
    setValue("fromDate", date, {shouldValidate: true});
  };

  const handleDeleteImage = (currentImage: any, imageIndex: number) => {
    if (isEmpty(id)) {
      setPreview((prevState: any) => ({
       ...prevState,
        [`preview${imageIndex}`]: null,
      }));
      const _imageFiles = [...imageFiles];
      _imageFiles.splice((imageIndex - 1), 1);
      setImageFiles(_imageFiles);
    }else {
      if (currentImage?.id) {
        const detailImages = currentDetailImages?.map((image: any) => image.id !== currentImage.id);
        setCurrentDetailImages(detailImages);
        setImageDeleted((prevState: any) => ([
          ...prevState,
          currentImage.id
         ]));
      }
      setPreview((prevState: any) => ({
        ...prevState,
         [`preview${imageIndex}`]: null,
       }));
       const _imageFiles = [...imageFiles];
       _imageFiles.splice((imageIndex - 1), 1);
       setImageFiles(_imageFiles);
    }
  };

  const handleClanChange = (data: any) => {
    setSelectedClan(data);
    setValue('clan', data?.value, {shouldValidate: true});
    setSelectedLineage('');
    if (!data) {
     setLineageOptions(undefined); 
    }
    if(data) {
      getOrganizations({
        ParentId: data.value,
        PageNumber: 1,
        PageSize: 1000
      }, 'Lineage');
    }
  };

  return (
      <div>
        {isloadingUploadImage && <LoadingBarProcess />}
        <div className="table-title !gap-[11px]">
          <BsCalendarEvent size={24} color={`#461511`} />
          <h1 className=" text-base font-semibold leading-5 text-bg_brown">
            {!isEmpty(id) ? "Chỉnh sửa sự kiện" : "Thêm mới sự kiện"}
          </h1>
        </div>
        <div className={"mt-5"}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <div className="flex gap-[30px] mt-[6px]">
              <div className="w-1/2">
                <div className="form-group">
                  <label className="form-label min-w-[110px] required-after">
                    Tên sự kiện
                  </label>
                  <input
                      type="text"
                      className={`form-input flex-1 ${errors?.name ? "form-error" : ""}`}
                      placeholder="Nhập tên sự kiện"
                      {...register("name", {
                        required: "Vui lòng nhập tên sự kiện",
                      })}
                  />
                </div>
                {errors?.name && (
                    <div className="text-red-500 text-sm text-end">
                      {errors?.name?.message as string}
                    </div>
                )}
                <div className="form-group mt-3">
                  <div className={`form-label min-w-[110px] required-after`}>
                    Ngày sự kiện
                  </div>
                  <div className="relative flex-1">
                    <DatePicker
                        selected={eventDate}
                        onChange={(date: any) => handleEventDateChange(date)}
                        className={`form-input flex-1 ${errors?.fromDate ? "form-error" : ""}`}
                        dateFormat={"dd/MM/yyyy"}
                        showYearDropdown
                        dropdownMode="select"
                        placeholderText="Chọn ngày sự kiện"
                        id="event-datepicker"
                    />
                    <img
                        src={DatePickerIcon}
                        alt="DatePickerIcon"
                        className=" absolute top-2 right-2 w-[16px] h-[16px] z-10 cursor-pointer"
                        onClick={() => document?.getElementById('event-datepicker')?.click()}
                    />
                    <input
                        type="hidden"
                        className="form-input flex-1"
                        {...register("fromDate", {
                          required: "Vui lòng nhập ngày sự kiện",
                        })}
                    />
                  </div>
                </div>
                {errors?.fromDate && (
                    <div className="text-red-500 text-sm text-end">
                      {errors?.fromDate?.message as string}
                    </div>
                )}
                <div className="form-group mt-3">
                  <div className={`form-label min-w-[110px]`}>Chi</div>
                  <Select
                    value={selectedClan}
                    onChange={handleClanChange}
                    options={clanOptions}
                    className={`${errors?.clan ? 'form-select-32-error' : 'form-select-32'}  `}
                    classNamePrefix={'select'}
                    placeholder='Chọn chi'
                    isDisabled={clanOptions?.length > 0 ? false : true}
                    noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                    isClearable={true}
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="form-label min-w-[110px]">Phái</div>
                  <Select
                    value={selectedLineage}
                    onChange={(data) => setSelectedLineage(data)}
                    options={lineageOptions}
                    className='form-select-32 '
                    classNamePrefix={'select'}
                    placeholder='Chọn phái'
                    isDisabled={lineageOptions?.length > 0 ? false : true}
                    noOptionsMessage={(data) => <span>{`Không có lựa chọn`}</span>}
                    isClearable={true}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex">
                  <div className="form-label min-w-[130px] items-start">
                    Thông tin lịch sử
                  </div>
                  <textarea
                      className="form-input-area flex-1 h-[126px]"
                      placeholder="Nhập thông tin lịch sử"
                      {...register("history", {
                        required: false,
                      })}
                  />
                </div>
                <div className="mt-3 flex items-start">
                  <div className={`form-label min-w-[136px]`}>
                    Là sự kiện hàng năm
                  </div>
                  <div>
                    <div className="flex gap-[30px] items-center min-h-4">
                      <div>
                        <label className="custom-radio">
                          <input
                              id="accent"
                              type="radio"
                              value="Yes"
                              className="mr-2"
                              checked={isAnnualEvent}
                              onChange={() => setIsAnnualEvent(true)}
                          />
                          Có
                        </label>
                      </div>
                      <div>
                        <label className="custom-radio">
                          <input
                              type="radio"
                              value="No"
                              className="mr-2"
                              checked={!isAnnualEvent}
                              onChange={() => setIsAnnualEvent(false)}
                          />
                          Không
                        </label>
                      </div>
                    </div>
                    <p className="text-xs mt-1 font-normal italic text-[#595959]">
                      Chọn <span className="font-semibold">Có</span> sẽ tạo sự
                      kiện này cho 50 năm tới
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className={"w-full"}>
              <div className="form-group">
                <label className="form-label min-w-[130px] ">
                  Hình ảnh sự kiện
                </label>
                <p className="text-xs mt-1 font-normal italic text-[#595959]">
                  Tối đa 5 ảnh về sự kiện
                </p>
              </div>
              <div className="flex items-center justify-center gap-[26px] my-5">
                {[1, 2, 3, 4, 5].map((i, idx) => {
                  return (
                      <div
                          key={idx}
                          className="w-[134px] h-[134px] rounded-[9.2px] flex items-center justify-center relative"
                          onMouseEnter={() =>
                              setHoveredImage((prev: any) => ({
                                ...prev,
                                [`hoveredImage${idx + 1}`]: true,
                              }))
                          }
                          onMouseLeave={() =>
                              setHoveredImage((prev: any) => ({
                                ...prev,
                                [`hoveredImage${idx + 1}`]: false,
                              }))
                          }
                      >
                        <div className="w-full h-full flex items-center justify-center z-1 rounded-[9.2px] border-[0.5px] border-[#461511]">
                          {!isEmpty(preview[`preview${idx + 1}`]) &&
                            <>
                              <img
                                src={preview[`preview${idx + 1}`]?.url}
                                alt="preview"
                                className="w-[134px] h-[134px] rounded-[9.2px]"
                              />
                            </>
                          }
                          {hoveredImage[`hoveredImage${idx + 1}`] && !isEmpty(preview[`preview${idx + 1}`]) &&
                            <div 
                            className="absolute top-0 right-0 bottom-0 left-0 bg-gray-400 bg-opacity-40 rounded-[9.2px] flex items-center justify-center gap-[26px] cursor-pointer"
                            onClick={() => handleDeleteImage(preview[`preview${idx + 1}`], idx + 1)}
                            >
                              <Trash color="#461511" size={24}/>
                            </div>
                          }
                          {isEmpty(preview[`preview${idx + 1}`]) && (
                            <div className="absolute top-0 right-0 bottom-0 left-0 rounded-[9.2px] flex items-center justify-center gap-[26px]">
                              <div className="w-5 h-5 relative cursor-pointer">
                                <div className="flex items-center justify-center cursor-pointer">
                                  <IoAddCircleOutline
                                    size={24}
                                    className=" text-table_primary_btn cursor-pointer"
                                  />
                                </div>
                              </div>
                              <input
                                id={`inputFile`}
                                type="file"
                                title="Nhấn để chọn ảnh"
                                ref={ref}
                                accept=".jpg, .jpeg, .png"
                                disabled={isloadingUploadImage}
                                className=" opacity-0 absolute top-0 right-0 left-0 bottom-0 w-full cursor-pointer"
                                multiple={true}
                                onChange={(event) => {
                                  handleUploadImage(event, idx + 1);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                  );
                })}
              </div>
              {chooseImageError &&
                    <div className="text-red-500 text-sm text-start">{chooseImageError}</div>}
            </div>
            <div className="flex items-center justify-center">
              <div className="flex gap-[30px] mb-8 mt-[10px]">
                <button
                    type="submit"
                    className="table-primary-btn w-[132px] h-[42px]"
                    disabled={
                        isloadingUploadImage || isLoadingSubmit || isLoadingSubmitEdit || isDisabledSubmitBtn
                    }
                >
                  Lưu
                </button>
                {!isEmpty(id) && (
                    <button
                        type="button"
                        className="table-second-btn w-[132px] h-[42px]"
                        disabled={
                            isloadingUploadImage ||
                            isLoadingSubmit ||
                            isLoadingSubmitEdit
                        }
                        onClick={() => {
                          const params: any = {
                            id,
                          };
                          handleRemoveEvent(params.id, {
                            onSuccess: (data: any) => {
                              if (data?.isSuccess) {
                                toast.success("Xoá sự kiện thành công.", {
                                  position: "bottom-right",
                                });
                                navigate("/su-kien");
                              }
                            },
                            onError: () => {
                              toast.error("Xoá sự kiện thất bại.", {
                                position: "bottom-right",
                              });
                            },
                          });
                        }}
                    >
                      Xóa
                    </button>
                )}
                <button
                    type="button"
                    className="table-second-btn w-[132px] h-[42px]"
                    disabled={
                        isloadingUploadImage || isLoadingSubmit || isLoadingSubmitEdit
                    }
                    onClick={() => {
                      reset();
                      setEventDate(undefined);
                      navigate("/su-kien");
                    }}
                >
                  Hủy
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
  );
};

export default EventList;