import React from 'react'
import { ModalContainer } from './ModalContainer'

type ConfirmModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    modalClass?: string;
    contentClass?: string;
    title: string;
    handleSubmit: () => void;
    onSubmit?: boolean;
}

const ConfirmModal = ({isOpen, closeModal, modalClass, contentClass, title, handleSubmit, onSubmit} : ConfirmModalProps) => {
  return (
    <ModalContainer isOpen={isOpen} className={modalClass}>
        <div className={`flex flex-col items-center h-full min-h-[200px] p-6 ${contentClass}`}>
            <div className='max-w-[80%] font-medium text-center flex-1 flex items-center justify-center'>{title}</div>
            <div className='flex gap-5 w-full'>
                <button className='button table-second-btn w-1/2 h-[44px]' type='button' onClick={() => closeModal()}>Hủy</button>
                <button className='button table-primary-btn w-1/2 h-[44px]' type='button' disabled={onSubmit || false} onClick={() => handleSubmit()}>Chấp nhận</button>
            </div>
        </div>
    </ModalContainer>
  )
}

export default ConfirmModal