import React from 'react';
import { X } from 'react-feather';
import { Link, useLocation } from 'react-router-dom';
import BgIconBottom from 'assets/images/bg_icon_bottom.png';
import NhaThoImg from 'assets/images/nha-tho-ho-tren-ban-ve.png';

const menuData = [
    {
        id: 1,
        title: 'Trang chủ',
        path: '/',
        roles: []
    },
    {
        id: 2,
        title: 'Phả đồ',
        path: '/pha-do',
        roles: []
    },
    {
        id: 3,
        title: 'Lịch sử',
        path: '/lich-su',
        roles: []
    },
    {
        id: 4,
        title: 'Sự kiện',
        path: '/su-kien',
        roles: []
    }
]

type props = {
    handleClose: () => void;
}

const NavBarMobile = ({handleClose} : props) => {
    let location = useLocation();
  return (
    <div className=' absolute top-0 left-0 right-0 bg-bg_brown h-[495px] z-20'>
        <div className=' relative w-full h-full flex items-center justify-center'>
            <div 
            className=' absolute top-2 right-2 p-3 flex items-center justify-center'
            onClick={() => handleClose()}
            >
                <X size={24} color='#F2B84F'/>
            </div>
            <div className=' text-secondary flex flex-col gap-y-10 mt-[200px] h-full'>
                {menuData?.map((item: any) => (
                    <Link to={item.path} key={item.id} className=' cursor-pointer'>
                        <p 
                        className={`${location?.pathname === item.path? 'text-white font-bold' : ''}`}>
                            {item.title}
                        </p>
                    </Link>
                ))}
            </div>
            <img src={BgIconBottom} alt="BgIconBottom" className='w-full h-[127px] absolute bottom-0 right-0 left-0 z-[6] bg-bg_brown'/>
        </div>
    </div>
  )
}

export default NavBarMobile