import React, { ReactNode } from 'react'

type FormCardProps = {
    children: ReactNode;
    className?: string;
}

const FormCard = ({children, className} : FormCardProps) => {
  return (
    <div className={`border border-opacity-40 border-[#461511] px-[23px] py-[16px] ${className}`}>
        {children}
    </div>
  )
}

export default FormCard