import React, { useEffect, useState } from "react";
import { Calendar, Image } from "react-feather";
import { useNavigate } from "react-router-dom";
import CardEvents from "./components/CardEvent";
import ImageCarousel from "./components/ImageCarousel";
import AppEditor from "./components/Editor";
import { useGetArticle, useUpdateArticle } from "../../hooks/useGenealogy";
import { CircleSpin } from "../../components/spinner/CircleSpin";
import { v4 as uuidv4 } from "uuid";
import { useForm, Controller, useWatch } from "react-hook-form";
import { useAuth } from "core/contexts/auth/AuthContext";
import FamilyBanner from 'assets/images/family-banner.png';
import { AlbumsService } from "core/services/albums.service";

const HomePage = () => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      content: "",
    },
  });
  const [key, setKey] = useState<string>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [listAlbum, setListAlbum] = useState<any>([]);

  const { data: article } = useGetArticle(key);
  const content = useWatch({ control: methods.control, name: "content" });
  const dirtyContent = methods.formState.isDirty;

  const auth = useAuth();

  const {
    mutateAsync: mutateArticle,
    data: dataArticle,
    isLoading: isLoadingMutationArticle,
  } = useUpdateArticle({
    content: content,
    callback: (data) => {
      setKey(uuidv4());
    },
  });

  const getAlbum = async () => {
      const res = await AlbumsService.getAlbumsPaging({
          PageNumber: 1,
          PageSize: auth?.user?.Role ? 4 : 5
      });

      if (res?.data?.isSuccess) {
          setListAlbum(res?.data?.data?.records);
      }
  };

  const updateArticle = async () => {
    if (isEdit && dirtyContent) {
      await mutateArticle();
      setIsEdit(false);
    } else {
      setIsEdit(true);
    }
  };

  useEffect(()=> {
    getAlbum();
  }, [])

  useEffect(() => {
    methods.reset({
      content: article?.data?.data,
    });
  }, [article]);

  const handleClickCancelBtn = async () => {
    setIsEdit(false);
    methods.reset({
      content: article?.data?.data,
    });
  };


  return (
    <>
      {isLoadingMutationArticle && (
        <>
          <div className="flex justify-center mt-4 items-center text-table_thead_text">
            <CircleSpin color="text-table_primary_btn mr-2" /> Loading...
          </div>
        </>
      )}
      <div className={"mt-[19px]"}>
        <div className="flex mb-[18px] px-4 md:px-0">
          <div className="w-[30%] h-[400px] hidden md:block">
            <img src={FamilyBanner} alt="family-banner" className="ml-5"/>
          </div>
          {isEdit ? (
            <div className="w-[70%] gap-4 flex flex-col items-end">
              <Controller
                name="content"
                control={methods.control}
                defaultValue={article?.data?.data}
                render={({ field }) => (
                  <AppEditor
                    {...field}
                    setContent={field.onChange}
                    initialContent={field.value}
                  />
                )}
              />
            </div>
          ) : (
            <div className="w-[70%] gap-4 flex-1">
              <div
                className={"home-history font-sans mb-4 flex flex-col gap-1 whitespace-pre-line"}
                dangerouslySetInnerHTML={{ __html: content}}
              />
            </div>
          )}
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-5 mt-5 mb-20 relative">
            <button
              disabled={isEdit && !dirtyContent}
              onClick={() => navigate("/pha-do")}
              type="button"
              className="table-primary-btn w-[248px] h-[48px] disabled:opacity-50 disabled:cursor-not-allowed "
            >
              {"Phả đồ dòng họ Nguyễn Đức"}
            </button>
            <button
              disabled={isEdit && !dirtyContent}
              onClick={() => navigate("/lich-su")}
              type="button"
              className="table-primary-btn w-[248px] h-[48px] disabled:opacity-50 disabled:cursor-not-allowed "
            >
              {"Lịch sử dòng họ Nguyễn Đức"}
            </button>
            <button
              disabled={isEdit && !dirtyContent}
              onClick={() => navigate("/images")}
              type="button"
              className="table-primary-btn w-[248px] h-[48px] disabled:opacity-50 disabled:cursor-not-allowed "
            >
              {"Hình ảnh dòng họ Nguyễn Đức"}
            </button>
          <div className={" relative md:absolute right-0 top-3 flex gap-2"}>
            {isEdit && (
              <button
                onClick={() => handleClickCancelBtn()}
                type="button"
                className="bg-amber-50 font[500]  text-[0.875rem] border-[#F2B84C] border-[1px] rounded-[10px] w-[88px]  h-[32px] disabled:opacity-50 disabled:cursor-not-allowed "
              >
                Hủy
              </button>
            )}
            {auth?.user?.Role &&
              <button
                disabled={isEdit && !dirtyContent}
                onClick={updateArticle}
                type="button"
                className="table-primary-btn w-[88px] h-[32px] disabled:opacity-50 disabled:cursor-not-allowed "
              >
                {isEdit ? "Lưu" : "Cập nhật"}
              </button>
            }
          </div>
        </div>
        {/* <div>
          <div className="flex justify-between mb-3">
            <div className="flex items-center gap-2">
              <Calendar size={24} color={`#461511`} />
              <p className="text-base font-semibold leading-5 text-bg_brown">
                Các sự kiện sắp tới
              </p>
            </div>
            <div>
              <a className="text-blue-700 cursor-pointer" href="/su-kien">Xem thêm</a>
            </div>
          </div>
          <CardEvents />
        </div> */}
        {/* <div className="mt-[34px] mb-20">
          <div className="flex justify-between mb-3">
            <div className="flex items-center gap-2">
              <Image size={24} color={`#461511`} />
              <p className="text-base font-semibold leading-5 text-bg_brown">
                Hình ảnh phả tộc Nguyễn Đức
              </p>
            </div>
            <div>
              {listAlbum?.length > 0 &&
                <a
                  className="text-blue-700 cursor-pointer"
                  href="/images"
                >
                  Xem thêm
                </a>
              }
            </div>
          </div>
          <ImageCarousel
            albums={listAlbum || []}
            onclick={() => navigate("/images/images-add")}
          />
        </div> */}
      </div>
      
    </>
  );
};

export default HomePage;
