
import { useQuery } from "react-query";
import { GenealogyService } from "core/services/genealogy.service";

export interface EventParams {
    PageNumber?: number;
    PageSize?: number;
    FromDate?: string;
    ToDate?: string;
}

export interface GetDetailParams {
    eventId: string;
    onSuccess?: () => void;
    onError?: () => void;
}
export const useGetEvents = (params: EventParams) => {
    return useQuery({
        queryKey: ["getAllEvent", params],
        queryFn: async ({ queryKey }) => {
            const [, params] = queryKey;
            return await GenealogyService.getEvents(params);
        },
        refetchOnWindowFocus: false,
        enabled: true,
    });
};

export const useGetDetailEvent = (body: GetDetailParams) =>
    useQuery({
        queryKey: ["getEventDetail", body?.eventId],
        queryFn: async () => await GenealogyService.getEventById(body?.eventId),
        onSuccess: (data) => {
            body?.onSuccess && body?.onSuccess();
        },
        onError: (e) => {
            console.log(e);
            body?.onError && body?.onError();
        },
        enabled: !!body?.eventId,
        refetchOnWindowFocus: false
    });
