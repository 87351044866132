import React, { useState } from 'react'
import NavBar from './NavBar'
import PhaTocText from 'assets/images/text_pha_toc.png'
import NguyenDucText from 'assets/images/text_nguyen_duc.png'
import NhaThoImg from 'assets/images/nha-tho-ho-tren-ban-ve.png'
import BannerLeft from 'assets/images/banner_left.png'
import BannerRight from 'assets/images/banner_right.png'
import BgIconBottom from 'assets/images/bg_icon_bottom.png'
import BgTrongDong from 'assets/images/vector-trong-dong-3.png'
import { Menu } from 'react-feather'
import NavBarMobile from './NavBarMobile'

const Header = () => {
    const [isShowMobileMenu, setIsShowMobileMenu] = useState<boolean>(false);
  return (
    <header className=' bg-bg_brown w-full h-[458px] flex flex-col items-center relative'>
        <div className='container-base relative z-[11]'>
          <NavBar/>
        </div>
        <div className='flex md:hidden absolute top-5 items-center justify-center'>
          <span className='text-secondary text-sm font-light'>Huyện Yên Thành, Nghệ An</span>
        </div>
        <div 
        className='flex md:hidden absolute top-4 right-0 pr-4 items-center justify-center'
        onClick={() => setIsShowMobileMenu(true)}
        >
          <Menu size={24} color='#F2B84F'/>
        </div>
        {isShowMobileMenu &&
          <NavBarMobile handleClose={() => setIsShowMobileMenu(false)}/>
        }
        <div className='container-base relative z-[6]'>
            <div className='flex flex-col items-center z-10 absolute top-[83px] mx-auto left-0 right-0'>
                <img src={PhaTocText} alt="PhaTocText" className='w-[140px] lg:w-[206px] h-[21px] lg:h-[30.69px]'/>
                <img src={NguyenDucText} alt="NguyenDucText" className='w-[345px] sm:w-[400px] sm:h-auto lg:w-[671.63px] h-[69px] lg:h-[136.1px] mt-[9.31px]'/>
                
            </div>
            <img src={BgTrongDong} alt="BgTrongDong" className='w-full h-full z-[1] absolute top-0 hidden md:block'/>
            <div className=' home-bg w-full h-full z-[2] absolute top-0'></div>
        </div>
        <img 
        src={BannerLeft} 
        alt="BannerLeft" 
        className='w-[11vw] h-auto min-[1598px]:w-[197.91px] min-[1598px]:h-[468px] absolute top-[87px] left-[66px] z-10 
          hidden md:block min-[1200px]:left-0 min-[1680px]:left-[10px] min-[1728px]:left-[66px]'/>
        <img 
        src={BannerRight} 
        alt="BannerRight" 
        className='w-[11vw] h-auto min-[1598px]:w-[197.91px] min-[1598px]:h-[468px] absolute top-[87px] right-[66px] z-10 
          hidden md:block min-[1200px]:right-0 min-[1680px]:right-[10px] min-[1728px]:right-[66px]'/>
        <img src={BgIconBottom} alt="BgIconBottom" className=' hidden md:block w-1/2 h-[127px] absolute bottom-0 left-0 z-[6] bg-bg_brown'/>
        <img src={BgIconBottom} alt="BgIconBottom" className='hidden md:block w-1/2 h-[127px] absolute bottom-0 right-[-10px] z-[6] bg-bg_brown'/>
        <img src={BgIconBottom} alt="BgIconBottom" className='block md:hidden w-full h-[127px] absolute bottom-0 right-0 left-0 z-[6] bg-bg_brown'/>
        <img src={NhaThoImg} alt="NhaThoImg" className='w-[458px] h-[260px] absolute bottom-[-60px] z-10 ml-5'/>
    </header>
  )
}

export default Header