import React, { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from './Header'

const MasterLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  return (
    <div className='min-h-screen overflow-x-hidden bg-yellow_FFFBEE'>
      <Header/>
      <main className="w-full flex flex-col items-center justify-center pt-8 ">
        <section className="container-base">
          <Outlet />
        </section>
        <footer className='py-5 px-4 bg-bg_brown text-white w-full'>
          <p className="text-center text-xs flex flex-col md:flex-row gap-1 items-center justify-center">
            <span>Copyright © 2024,</span>
            <span>Tổng biên tập: Ông Nguyễn Đức Nam - Tộc trưởng Phái 4, Chi 3 đời 18</span>
          </p>
        </footer>
      </main>
    </div>
  )
}

export default MasterLayout