import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import moment from "moment";
import { DefaultFormatDate } from "../../../core/constant/app-constant";
import {
    EventParams,
    GetDetailParams,
    useGetDetailEvent,
    useGetEvents,
} from "../../../hooks/useEvents/useGetEvents";

export interface FilterEventActions {
    updateFilter: (data: EventParams) => void;
    updateCalendarMode: (mode: string) => void;
    updateDatePresent: (datePresent: Date) => void;
    updateDetailId: (body: GetDetailParams) => void;
    updateFilterMode: (mode: string) => void;
    refetch: () => void;
}

const FilterEventContext = createContext<any>({});

const FilterEventActionsContext = createContext<FilterEventActions>({
    updateFilter: () => {},
    updateCalendarMode: () => {},
    updateDatePresent: () => {},
    updateDetailId: () => {},
    updateFilterMode: () => {},
    refetch: () => {},
});

export const useFilterEventContext = () => useContext(FilterEventContext);

export const useFilterEventActionsContext = () =>
    useContext(FilterEventActionsContext);

interface FilterEventContextProviderProps {
    children: ReactNode;
}

const FilterEventContextProvider: React.FC<FilterEventContextProviderProps> = ({
                                                                                   children,
                                                                               }) => {
    const [filter, setFilter] = useState<EventParams>({
        FromDate: moment().startOf("month").format(DefaultFormatDate),
        ToDate: moment().endOf("month").format(DefaultFormatDate),
        PageNumber: 1,
        PageSize: 1000,
    });
    const [totalPage, setTotalPage] = useState<number>(0);
    const [mode, setMode] = useState<string>("month");
    const [filterMode, setFilterMode] = useState<string>("month");
    const [dateView, setDateView] = useState<Date>(new Date());
    const [body, setBody] = useState<GetDetailParams>({
        eventId: "",
        onSuccess: () => {},
        onError: () => {},
    });
    const detailEvent = useGetDetailEvent(body);
    const {data: events, refetch} = useGetEvents(filter);

    useEffect(() => {
        if (events?.data?.data?.totalCount === 0) {
            setTotalPage(0);
        } else {
            setTotalPage(
                Math.ceil(Number(events?.data?.data?.totalCount / 10)),
            );
        }
    }, [events?.data?.data]);

    const updateFilter = useCallback((data: EventParams) => {
        
        setFilter(data);
    }, []);
    const updateCalendarMode = useCallback((modeCalendar: any) => {
        setMode(modeCalendar);
    }, []);
    const updateDatePresent = useCallback((dateView: any) => {
        setDateView(dateView);
    }, []);
    const updateDetailId = (bodyGetDetail: GetDetailParams) => {
        setBody(bodyGetDetail);
    };
    const updateFilterMode = useCallback((modeFilter: string) => {
        setFilterMode(modeFilter);
    }, []);

    return (
        <FilterEventContext.Provider
            value={{
                filter,
                mode,
                dateView,
                totalPage,
                detailEvent,
                events,
                filterMode,
                body
            }}
        >
            <FilterEventActionsContext.Provider
                value={{
                    updateFilter,
                    updateCalendarMode,
                    updateDatePresent,
                    updateDetailId,
                    updateFilterMode,
                    refetch
                }}
            >
                {children}
            </FilterEventActionsContext.Provider>
        </FilterEventContext.Provider>
    );
};

export default FilterEventContextProvider;