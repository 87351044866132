import React, {useState, ReactNode, useEffect, useMemo} from 'react';
import {useLocation, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import AuthContext from './AuthContext';
import AuthService from 'core/services/auth.service';
import axiosClient from 'utilities/axios-instance';
import { AxiosError } from 'axios';

type Props = {
    children: ReactNode;
};

const AuthProvider = ({children}: Props) => {
    const [user, setUser] = useState<any>();
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (error) setError(undefined);
        let user: any = localStorage.getItem('user');

        if (user) {
            setUser(JSON.parse(user));
            setLoadingInitial(false);
        } else {
            if (location.pathname !== '/login' 
                && location.pathname !== '/forgot-password' 
                && location.pathname !== '/' 
                && location.pathname !== '/lich-su'
                && location.pathname !== '/su-kien'
                && location.pathname !== '/pha-do'
                && location.pathname !== '/search'
                ) 
            {
                navigate('/login');
            }

            setLoadingInitial(false);
        }
    }, [location, navigate]);

    useEffect(() => {
        axiosClient.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error: AxiosError) {
              const { data, status } =
              error?.response || ({ data: null, status: null } as any);
              if (
                status === 401 || data?.error?.code === 401
              ) {
                  logout();
              }
              if (status === 404 || data?.error?.code === 404) {
                  navigate('/404');
              }
              if (status === 403 || data?.error?.code === 403) {
                  navigate('/forbidden');
              }
              if (status === 500 || data?.error?.code === 500) {
                  navigate('/505');
              }
              // return Promise.reject(error);
            },
        );
    }, [user?.sub]);

    const login = (email: string, password: string) => {
        setLoading(true);
        setError(undefined);
        AuthService.login({email, password})
            .then((res: any) => {
                if (!res?.isSuccess) {
                    setError({
                        error: {
                            message: res?.errors[0]?.message,
                        },
                    });
                    setLoading(false);
                    return;

                } else {
                    const userInfo: any = jwtDecode(res?.data?.accessToken);
                    const user = {
                        ...userInfo,
                        access_token: res?.data?.accessToken,
                    };
                    setUser(user);
                    localStorage.setItem('user', JSON.stringify(user));
                    navigate('/');
                }
            })
            .catch((error: any) => {
                setError({error});
            })
            .finally(() => setLoading(false));
    };
    const forgotPassword = async (email: string) => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await AuthService.forgotPassword({email});
            if (!res?.isSuccess) {
                setError({
                    error: {
                        message: res?.errors[0]?.message,
                    },
                });
                setLoading(false);
                return false;
            } else {
                setLoading(false);
                return res?.isSuccess;
            }
        } catch (error) {
            setError({error});
        } finally {
            setLoading(false);
        }
    };

    const resetPassword = async (oldPassword: string, newPassword: string) => {
        setLoading(true);
        setError(undefined);
        try {
            const res = await AuthService.resetPassword({oldPassword, newPassword});
            if (!res?.isSuccess) {
                setError({
                    error: {
                        message: res?.errors?.[0].message,
                    },
                });
                setLoading(false);
                return false;
            } else {
                setLoading(false);
                return res?.isSuccess;
            }
        } catch (error) {
            setError({error});
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('user');
        setUser(undefined);
        navigate('/login');
    };

    const value = useMemo(
        () => ({
            user,
            loading,
            error,
            login,
            logout,
            forgotPassword,
            resetPassword,
            setError
        }),
        [user, loading, error],
    ) as any;

    return <AuthContext.Provider value={value}>{!loadingInitial && children}</AuthContext.Provider>;
};

export default AuthProvider;
