import React, { Fragment, FunctionComponent, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Stack, Typography } from "@mui/material";
import eventEven from 'assets/images/template-event-png.png';
import eventOdd from 'assets/images/template-event-png-2.png';
import { colorApp } from "../../../core/constant/app-constant";
import { ModalEventDetail } from "./ModalEventDetailComponent";
import moment from "moment";
import {
  useFilterEventActionsContext,
  useFilterEventContext,
} from "../context/FilterEventContextProvider";
import { toast } from "react-toastify";
import { AlertTriangle, CheckCircle, Edit, Trash } from "react-feather";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "components/modal/ConfirmModal";
import { EventService } from "core/services/event.service";
import { useAuth } from "core/contexts/auth/AuthContext";

interface ComponentProps {
  data: any;
  index: number;
  refetch?: any;
}

type Props = ComponentProps;
export const EventItemComponent: FunctionComponent<Props> = ({
  data,
  index,
  refetch
}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { updateDetailId } = useFilterEventActionsContext();
  const { detailEvent, body } = useFilterEventContext();
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [isOnSubmit, setIsOnSubmit] = useState<boolean>(false);
  const auth = useAuth();

  const deleteEvent = async () => {
    try {
        setIsOnSubmit(true);
        const res = await EventService.removeEvent(selectedEvent.id);
        if (res?.data?.isSuccess === true) {
            setIsShowConfirmModal(false);
            setSelectedEvent(undefined);
            toast('Đã xóa sự kiện thành công', {
                type: 'success',
                icon: <CheckCircle className="text-[#007B40]" />
            });
            refetch();
        }else {
            toast(res?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
            });
        }
        setIsOnSubmit(false);
    } catch (error) {
        toast('Lỗi kết nối mạng!', {
            type: 'error',
            icon: <AlertTriangle className="text-[#F03D3E]" />
        });
    }
  };
  
  const handleOnClick = () => {
    if (body?.eventId === data?.id) {
      setOpenModal(true);
      return;
    }
    updateDetailId({
      eventId: data?.id,
      onSuccess: () => {
        setOpenModal(true);
      },
      onError: () => {
        toast("Có lỗi xảy ra", {
          type: "error",
          icon: <AlertTriangle className="text-[#F03D3E]" />,
        });
      },
    });
  };

  const onClickEdit = (event: any, eventId: string) => {
      event.stopPropagation();
      navigate(`/quan-ly/event/${eventId}`);
  };

  const handleClickDeleteEventBtn = (event: any, item: any) => {
    event.stopPropagation();
    setSelectedEvent(item);
    setIsShowConfirmModal(true);
  };

  return (
    <>
      {/* <Stack
        onClick={handleOnClick}
        sx={{
          height: "170px",
          width: "100%",
          backgroundColor: "#F9D876",
          borderRadius: 2,
          position: "relative",
          padding: 0,
          cursor: "pointer",
          "&:after": {
            content: '""',
            position: "absolute",
            bottom: 0,
            left: 0,
            width: "100%",
            height: "50%",
            backgroundImage: "url(./mask.svg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
          },
        }}
      >
        <Grid
          columns={{ xs: 3 }}
          container
          height={"100%"}
          sx={{ cursor: "pointer" }}
        >
          <Grid xs={2} sx={{paddingLeft: '44px', paddingTop: 4}}>
            <Box sx={{ height: "40%", width: "100%" }}>
              <Typography
                sx={{
                  color: colorApp.tabsColor,
                  fontSize: 22,
                  fontWeight: 700,
                }}
              >
                {data?.name}
              </Typography>
            </Box>
            <Box sx={{ height: "60%", width: "100%" }}>
              <Typography
                sx={{
                  color: colorApp.tabsColor,
                  fontSize: 15,
                  fontWeight: 700,
                }}
              >
                {`Ngày ${moment(data?.fromDate).format("DD/MM/YYYY")} (${moment(data?.fromLunarDate).format("DD/MM/YYYY")} ÂL)`}
              </Typography>
              <Typography
                sx={{
                  color: colorApp.tabsColor,
                  fontSize: 15,
                  fontWeight: 400,
                }}
              >
                {data?.organizations?.find((item: any) => item.organizationType === 'Branch')?.organizationName}
              </Typography>
            </Box>
            {auth?.user?.Role && auth?.user?.Role !== 'PeopleAdmin' && 
              <Box sx={{ position: 'absolute', zIndex: 2, left: '44px', bottom: '10px' }}>
                <div className=' flex items-center gap-3'>
                    <Edit 
                    size={20} 
                    color='#461511' 
                    className=' cursor-pointer'
                    onClick={(event) => onClickEdit(event, data.id)}
                    />
                    <Trash 
                    size={20} 
                    color='#461511' 
                    className=' cursor-pointer'
                    onClick={(event) => handleClickDeleteEventBtn(event, data)}
                    />
                </div>
              </Box>
            }
          </Grid>
          <Grid
            xs={1}
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                height: "118px",
                width: "128px",
              }}
            >
              <img
                src={index % 2 === 0 ? eventEven : eventOdd}
                alt=""
                height={"100%"}
                width={"100%"}
                className={index % 2 === 0 ? '' : 'mt-[-8px]'}
              />
            </Box>
          </Grid>
        </Grid>
      </Stack> */}
      <div className="bg-[#F9D876] rounded-lg h-[80px] flex items-center text-black text-[15px] font-normal">
          <div className="w-[37%] flex items-center gap-4 pl-4">
            <img
              src={index % 2 === 0 ? eventEven : eventOdd}
              alt=""
              height={"60px"}
              width={"60px"}
              className={index % 2 === 0 ? '' : 'mt-[4px] ml-[-2px]'}
            />
            <div className=" line-clamp-1 font-bold test-base">{data?.name}</div>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.fromDate ? moment(data?.fromDate).format("DD/MM/YYYY") : "--"}</span>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.fromLunarDate ? moment(data?.fromLunarDate).format("DD/MM/YYYY") : "--"}</span>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.organizations?.find((item: any) => item.organizationType === 'Clan')?.organizationName || "--"}</span>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.organizations?.find((item: any) => item.organizationType === 'Lineage')?.organizationName || "--"}</span>
          </div>
          <div className="flex items-center justify-end gap-2 w-[15%] pr-4">
            <div 
            className="bg-white border border-[#461511] text-[#461511] text-sm font-medium rounded-[10px] px-3 py-1 cursor-pointer"
            onClick={() => handleOnClick()}
            >
              Xem chi tiết
            </div>
            {auth?.user?.Role && auth?.user?.Role !== 'PeopleAdmin' &&
              <Fragment>
                <Edit 
                size={20} 
                color='#461511' 
                className=' cursor-pointer'
                onClick={(event) => onClickEdit(event, data.id)}
                />
                <Trash 
                size={20} 
                color='#461511' 
                className=' cursor-pointer'
                onClick={(event) => handleClickDeleteEventBtn(event, data)}
                />
              </Fragment>
            }
          </div>
      </div>
      {openModal && (
        <ModalEventDetail
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={detailEvent?.data?.data?.data}
        />
      )}
      <ConfirmModal 
        isOpen={isShowConfirmModal} 
        closeModal={() => setIsShowConfirmModal(false)}
        title={selectedEvent ? `Bạn có chắc muốn xóa sự kiện ${selectedEvent?.name} không?` : `Bạn có chắc muốn xóa sự kiện này không?`}
        onSubmit={isOnSubmit}
        handleSubmit={() => deleteEvent()}
      />
    </>
  );
};
