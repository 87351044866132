import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
    toolbar: {
      container: [
        [{ 'header': '1'}, { 'header': '2'}, { 'font': [] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': ['#461511', '#000000', '#e60000', '#ff9900', '#ffff00', '#00e600', '#19b5fe', '#0000ff', '#b200ff', '#ff00ff'] }, { 'background': ['#461511', '#000000', '#e60000', '#ff9900', '#ffff00', '#00e600', '#19b5fe', '#0000ff', '#b200ff', '#ff00ff'] }],  // Thêm các tùy chọn màu sắc
        [{ 'align': [] }],
        ['link', 'image', 'video'],
        ['clean']  // Làm sạch định dạng
      ],
    },
};
  
  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'video',
    'color', 'background',  // Thêm các định dạng màu sắc
    'align'
  ];

const AppEditor = ({
                       initialContent,
                       setContent,
                   }: {
    initialContent: string | undefined;
    setContent?: (e: string) => void;
}) => {
    return (
        <ReactQuill style={{
            height: "86%",
            width: "100%",
            borderBottom: "none",
            minHeight: '260px'
        }} 
        theme="snow" 
        modules={modules}
        formats={formats}
        value={initialContent} 
        onChange={setContent}/>
    );
};

export default AppEditor;
