import { useMutation } from "react-query";
import { GenealogyService } from "../../core/services/genealogy.service";
import { AxiosResponse } from "axios";

export const useUpdateArticle = ({
  content,
  callback,
}: {
  content: string | undefined;
  callback: (data: AxiosResponse<any, any> | undefined) => void;
}) => {
  return useMutation({
    mutationKey: ["article-update"],
    mutationFn: () => GenealogyService.createArticle(content),
    onSuccess: (data) => {
      callback(data);
    }
  });
};
