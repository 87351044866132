import React, { ReactNode } from 'react'
import AdminSideBar from './AdminSideBar'

const AdminManagementLayout = ({children} : {children : ReactNode}) => {
  return (
    <div className='flex gap-3'>
        <div className='w-[244px] h-auto'><AdminSideBar/></div>
        <div className='flex-1 bg-white px-[16px] py-[16px]'>{children}</div>
    </div>
  )
}

export default AdminManagementLayout