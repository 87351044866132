import React from 'react';
import IconIPerson from 'assets/svg/icon_i_person.svg';

type props = {
    maxLevel: any;
    handleClickGererationItem: (generationId: string) => void;
}

const LevelBackground = ({maxLevel, handleClickGererationItem} : props) => {

    const getGenealogyTreeLevelBgStyle = (item: any) => {
      if (item.level === 13) {
        return 'h-[320px]'
      }else if (item.level === 14) {
        return 'h-[410px]'
      }else if (item.numberOfItemsInLevel > 1) {
        return 'h-[190px]'
      }else {
        return 'h-[190px]'
      }
    };

  return (
    <div className={`absolute top-[213px] right-0 left-0 h-auto z-[-1] genealogy-tree-level-bg mx-[-100px]`}>
        {maxLevel?.map((item: any) => (
            <div 
            key={item.level} 
            className={`genealogy-tree-level-bg-item w-full
              bg-slate-500 relative ${getGenealogyTreeLevelBgStyle(item)}`}
            >
              <div className=' absolute h-full top-0 left-2 text-[24px] text-[#461511] font-bold flex flex-col gap-1 justify-center'>
                <div>{`Đời ${item.level}`}</div>
                <div onClick={() => handleClickGererationItem(item.generationId)}>
                  <img src={IconIPerson} className='w-[24px] h-[24px] cursor-pointer ml-5' alt="IconI"/>
                </div>
              </div>
            </div>
        ))}
    </div>
  )
}

export default LevelBackground