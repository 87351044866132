import React from 'react'

const EventSide = ({fillColor} : {fillColor?: string}) => {
  return (
    <>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4551 18.8438C14.7551 18.8438 14.1634 18.6021 13.6801 18.1188C13.1967 17.6354 12.9551 17.0437 12.9551 16.3438C12.9551 15.6438 13.1967 15.0521 13.6801 14.5688C14.1634 14.0854 14.7551 13.8438 15.4551 13.8438C16.1551 13.8438 16.7467 14.0854 17.2301 14.5688C17.7134 15.0521 17.9551 15.6438 17.9551 16.3438C17.9551 17.0437 17.7134 17.6354 17.2301 18.1188C16.7467 18.6021 16.1551 18.8438 15.4551 18.8438ZM5.95508 22.8438C5.40508 22.8438 4.93424 22.6479 4.54258 22.2563C4.15091 21.8646 3.95508 21.3938 3.95508 20.8438V6.84375C3.95508 6.29375 4.15091 5.82292 4.54258 5.43125C4.93424 5.03958 5.40508 4.84375 5.95508 4.84375H6.95508V2.84375H8.95508V4.84375H16.9551V2.84375H18.9551V4.84375H19.9551C20.5051 4.84375 20.9759 5.03958 21.3676 5.43125C21.7592 5.82292 21.9551 6.29375 21.9551 6.84375V20.8438C21.9551 21.3938 21.7592 21.8646 21.3676 22.2563C20.9759 22.6479 20.5051 22.8438 19.9551 22.8438H5.95508ZM5.95508 20.8438H19.9551V10.8438H5.95508V20.8438ZM5.95508 8.84375H19.9551V6.84375H5.95508V8.84375Z" fill={fillColor}/>
        </svg>
    </>
  )
}

export default EventSide