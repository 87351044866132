import { useAuth } from 'core/contexts/auth/AuthContext'
import React, {Fragment, useEffect, useState} from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDown, LogOut, User, Lock, X } from 'react-feather';
import { UserRoleEnum } from 'core/types/user.type';
import { PermissionsGate } from 'components/PermissionsGate';
import NotificationUnReadIcon from 'assets/icons/NotificationUnReadIcon';
import NotiImgDefault from 'assets/images/noti_img_default.png';
import moment from 'moment';
import AvatarDefault from 'assets/images/avatar_default.png';
import ChangePasswordModal from 'pages/resetPassword/ChangePasswordModal';
import { NotificationService } from 'core/services/notification.service';
import NotificationReadIcon from 'assets/icons/NotificationReadIcon';
import { ModalEventDetail } from 'pages/event/component/ModalEventDetailComponent';
import { GenealogyService } from 'core/services/genealogy.service';
import toast, { Toaster } from 'react-hot-toast';
import MenuSearchBar from 'components/uiComponents/MenuSearchBar';

const menuData = [
    {
        id: 1,
        title: 'Trang chủ',
        path: '/',
        roles: []
    },
    {
        id: 2,
        title: 'Phả đồ',
        path: '/pha-do',
        roles: []
    },
    {
        id: 3,
        title: 'Lịch sử',
        path: '/lich-su',
        roles: []
    },
    {
        id: 4,
        title: 'Sự kiện',
        path: '/su-kien',
        roles: []
    },
    {
        id: 5,
        title: 'Quản lý',
        path: '/quan-ly/user-detail',
        roles: [UserRoleEnum.PeopleAdmin, UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
    }
]

const NavBar = () => {
    const {user, logout} = useAuth();
    let location = useLocation();
    const navigate = useNavigate();
    const [isShowChangePassModal, setIsShowChangePassModal] = useState<boolean>(false);
    const [notifications, setNotifications] = useState<any>([]);
    const [isHaveUnreadNotifications, setIsHaveUnreadNotifications] = useState<boolean>(false);
    const [isOpenEventModal, setIsOpenEventModal] = useState<boolean>(false);
    const [eventData, setEventData] = useState<any>();

    const [currPage, setCurrPage] = useState<number>(1);

    const getEventDetail = async (notification: any) => {
        if (!notification?.isRead) {
            readNotificationById(notification?.id);
            setTimeout(() => {
                getNotifications(currPage, false);
            }, 500)
        }
        const res = await GenealogyService.getEventById(notification?.eventId);
        if (res?.data?.isSuccess === true) {
            setEventData(res?.data?.data);
            setIsOpenEventModal(true);
        }
    };

    const readNotificationById = async (id: string) => {
        await NotificationService.readById(id);
    };

    const showAlertNotification = async (id: string, params: any) => {
        await NotificationService.showAlert(id, params);
    };

    const getNotifications = async (pageNumber: number, isLoadMore: boolean) => {
        const params: any = {
            PageNumber: pageNumber,
            PageSize: 100
        }
        const res = await NotificationService.getList(params);

        if (res?.data?.isSuccess === true) {
            let isUnread = false;
            // let isUnreadNoti: any = [];
            const notificationMap = res?.data?.data?.records?.map((notification: any) => {
                const extraData = notification?.extraData ? JSON.parse(notification?.extraData) : undefined;
                const notificationData = {
                    id: notification?.id,
                    eventId: notification?.referenceDocumentId || '0cc8f9fa-6dd0-4c56-a7e4-9946d1e68514',
                    image: notification?.imageUrl || NotiImgDefault,
                    clan: extraData?.ClanName,
                    lineage: extraData?.LineageName,
                    eventname: extraData?.EventName,
                    eventSolarDate: extraData?.EventDate,
                    eventLunarDate: extraData?.EventLunarDate,
                    createdDate: notification?.createdDate,
                    isRead: notification?.isRead,
                    isAlertCreated: notification?.isAlertCreated,
                    isAlertDaysLeft: notification?.isAlertDaysLeft
                };
                if (notification?.isRead === false) {
                    isUnread = true;
                    // isUnreadNoti.push(notificationData);
                }
                return notificationData
            });

            setNotifications([
                ...notificationMap,
            ]);
            setIsHaveUnreadNotifications(isUnread);
            // if (isUnreadNoti?.length > 0) {
            //     setNotificationIsReadFalse(isUnreadNoti);
            // }
        }
    };

    useEffect(() => {
        if(user) {
            getNotifications(currPage, false);
        }
    }, []);

    useEffect(() => {
        if (notifications?.length > 0) {
            const maxNoti = notifications?.length > 3 ? 3 : notifications?.length;
            for (let index = 0; index < maxNoti; index++) {
                const diffDate = notifications[index]?.eventSolarDate ?  daysDifference(notifications[index]?.eventSolarDate) : undefined;
                let isShow = false;
                if ((diffDate !== undefined) && diffDate <= 0) {
                    const day = Math.abs(diffDate);
                    
                    if (day <= 3 && !notifications[index].isAlertDaysLeft) {
                        const params = {
                            isAlertDaysLeft: true,
                            isAlertCreated: notifications[index].isAlertCreated
                        };
                        showAlertNotification(notifications[index].id, params);
                        isShow = true;
                    }else if (day > 3 && !notifications[index].isAlertCreated) {
                        const params = {
                            isAlertDaysLeft: notifications[index].isAlertDaysLeft,
                            isAlertCreated: true
                        };
                        showAlertNotification(notifications[index].id, params);
                        isShow = true;
                    }
                }
                if (isShow) {
                    toast.custom((t: any) => (
                        <div 
                        className='relative flex gap-[22px] cursor-pointer bg-[#FFFBEE] 
                        rounded shadow-lg w-[437px] px-5 pt-7 pb-4 text-[#461511]'
                        onClick={() => getEventDetail(notifications[index])}
                        >
                            <img src={notifications[index]?.image} alt="notiImg" className='w-[117px] h-[78px]'/>
                            <div className='flex-1'>
                                <div className='line-clamp-4'>
                                    {`Phả tộc Nguyễn Đức - ${notifications[index]?.clan} - ${notifications[index]?.lineage} sẽ có sự kiện mới `}
                                    <span className='font-bold'>{notifications[index]?.eventname}</span>
                                    {` được diễn ra vào ngày `}
                                    <span className='font-bold'>{`${moment(notifications[index].eventSolarDate).format('DD/MM/YYYY')} (tức ngày ${moment(notifications[index].eventLunarDate).format('DD/MM/YYYY')} Âm lịch) `}</span>
                                </div>
                                <div className=' text-xs text-[#0849EF] mt-1'>
                                    {`${(daysDifference(notifications[index].createdDate) <= 0) ? 'Hôm nay' : `${daysDifference(notifications[index].createdDate)} ngày`}`}
                                </div>
                            </div>
                            <button 
                            type='button' 
                            className=' absolute top-1 right-1 cursor-pointer p-1 z-10'
                            onClick={(event) => {event.stopPropagation(); toast.dismiss(t.id);}}
                            >
                                <X color='#F2B84F' size={24}/>
                            </button>
                        </div>
                    ))
                }
            }
        }
    }, [notifications])

    // const loadMoreNotis = () => {

    //     const newPage = currPage + 1;
    //     setCurrPage(newPage);

    //     getNotifications(newPage, true);
    // };

    const daysDifference = (createdDate: any) => {
        const today = new Date();
        const createdDateMoment = new Date(createdDate);
        const millisecondsPerDay = 1000 * 60 * 60 * 24;
        const comparisonDateWithoutTime = new Date(createdDateMoment.getFullYear(), createdDateMoment.getMonth(), createdDateMoment.getDate());
        const todayWithoutTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const createdDateMilliseconds = comparisonDateWithoutTime.getTime();
        const todayMilliseconds = todayWithoutTime.getTime();

        const diffDate = Math.floor((todayMilliseconds - createdDateMilliseconds) / millisecondsPerDay);
        return diffDate
    };

    const handleAvatarOnError = (e: any) => {
        e.target.src = AvatarDefault;
    };

  return (
    <div className='mt-[31px] mb-[32px] h-[20px] w-full justify-between text-sm text-secondary font-normal absolute z-20 hidden md:flex'>
        <div className='flex gap-[46px]'>
            {menuData?.map((item: any) => {
                if (item?.roles?.length === 0) {
                    return (
                        <Link to={item.path} key={item.id} className=' cursor-pointer'>
                            <p 
                            className={`${location?.pathname?.includes('/quan-ly') && item.path.includes('/quan-ly')?'text-white font-bold' : location?.pathname === item.path? 'text-white font-bold' : ''}`}>
                                {item.title}
                            </p>
                        </Link>
                    )
                }else {
                    return (
                        <PermissionsGate user={user} scopes={item?.roles} key={item.id}>
                            <Link to={item.path}  className=' cursor-pointer'>
                                <p 
                                className={`${location?.pathname?.includes('/quan-ly') && item.path.includes('/quan-ly')?'text-white font-bold' : location?.pathname === item.path? 'text-white font-bold' : ''}`}>
                                    {item.title}
                                </p>
                            </Link>
                        </PermissionsGate>
                    )
                }
            })}
        </div>
        <div className={`flex-1 flex justify-start items-center ${user ? 'pl-[90px]' : 'pl-[180px]'}`}>
            <span className=' text-sm font-light'>Huyện Yên Thành, Nghệ An</span>
        </div>
        <div className='flex gap-[18px] items-center'>
            <div>
                {!location?.pathname?.includes('/search') &&
                    <MenuSearchBar />
                }
            </div>
            <div className='flex gap-[18px] items-center'>
                {user &&
                    <div>
                        <Menu as="div" className="relative inline-block">
                            <Menu.Button>
                                <div className='flex items-center gap-3 mt-1'>
                                    {isHaveUnreadNotifications ? 
                                        <NotificationUnReadIcon/> : <NotificationReadIcon/>
                                    }
                                </div>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <div className='absolute right-0 !z-50 overflow-auto hidden-scrollbar shadow top-[35px] 
                                w-[427px] h-auto min-h-[220px] max-h-[538px] px-[25px] py-[23px] bg-[#FFFBEE] border border-secondary rounded-md 
                                text-[#461511]'
                                >
                                    <div className='text-[#461511] font-semibold text-lg mb-[22px]'>Thông báo</div>
                                    {notifications?.length === 0 &&
                                        <div>Không có thông báo</div>
                                    }
                                    <div className=' space-y-[26px]'>
                                        {notifications?.length > 0 &&
                                            notifications?.map((notification: any) => (
                                                <div 
                                                key={notification.id} 
                                                className='flex gap-[22px] cursor-pointer'
                                                onClick={() => getEventDetail(notification)}
                                                >
                                                    <img src={notification?.image} alt="notiImg" className='w-[117px] h-[78px]'/>
                                                    <div className={`flex-1 relative ${notification?.isRead ? 'opacity-50' : ''}`}>
                                                        <div className='line-clamp-4'>
                                                            {`Phả tộc Nguyễn Đức - ${notification?.clan} - ${notification?.lineage} sẽ có sự kiện mới `}
                                                            <span className='font-bold'>{notification?.eventname}</span>
                                                            {` được diễn ra vào ngày `}
                                                            <span className='font-bold'>{`${moment(notification.eventSolarDate).format('DD/MM/YYYY')} (tức ngày ${moment(notification.eventLunarDate).format('DD/MM/YYYY')} Âm lịch) `}</span>
                                                        </div>
                                                        <div className=' text-xs text-[#0849EF] mt-1'>
                                                            {`${(daysDifference(notification.createdDate) <= 0) ? 'Hôm nay' : `${daysDifference(notification.createdDate)} ngày`}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </Transition>
                        </Menu>
                    </div>
                }
                {!user && <>
                    <div onClick={() => navigate("/login")} className={`flex items-center cursor-pointer ${user ? 'gap-3' : 'gap-[5px]'}`}>
                        <User size={24} color='#F2B84F'/>
                        <p>Đăng nhập</p>
                    </div>
                </>}
                {user && <>
                    <Menu as="div" className="relative inline-block outline-none">
                        <Menu.Button className={' outline-none'}>
                            <div className='flex items-center gap-3'>
                                <div className='w-[24px] h-[24px] rounded-full border-[2px] border-secondary flex items-center justify-center'>
                                    {user?.Avatar &&
                                        <img 
                                        src={AvatarDefault} 
                                        alt="Avatar" 
                                        className='w-[22px] h-[22px] rounded-full'
                                        onError={handleAvatarOnError}
                                        />
                                    }
                                </div>
                                <div>{`${user?.LastName} ${user?.FirstName}`}</div>
                                <div className='mt-[2px]'>
                                    <ChevronDown size={24} color='#F2B84F'/>
                                </div>
                            </div>
                        </Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <div className='absolute right-0 top-[35px] w-[209px] bg-primary border border-secondary rounded-md'>
                                <ul className=''>
                                    <li
                                    className="cursor-pointer hover:bg-secondary/20"
                                    onClick={() => {
                                        navigate('/thong-tin-tai-khoan');
                                    }}
                                    >
                                        <div className='flex items-center gap-2 py-3 px-4'>
                                            <User size={24} color='#F2B84F'/>
                                            <span className="text-sm flex-1">Thông tin tài khoản</span>
                                        </div>
                                    </li>
                                    <li
                                        className="cursor-pointer hover:bg-secondary/20"
                                        onClick={() => {
                                            setIsShowChangePassModal(true);
                                        }}
                                    >
                                        <div className='flex items-center gap-2 py-3 px-4'>
                                            <Lock size={24} color='#F2B84F'/>
                                            <span className="text-sm flex-1">Đổi mật khẩu</span>
                                        </div>
                                    </li>
                                    <li
                                    className="cursor-pointer hover:bg-secondary/20"
                                    onClick={() => {
                                        logout();
                                    }}
                                    >
                                        <div className='flex items-center gap-2 py-3 px-4'>
                                            <LogOut size={24} color='#F2B84F'/>
                                            <span className="text-sm flex-1">Đăng xuất</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </Transition>
                    </Menu>

                </>}
            </div>
        </div>
        <ChangePasswordModal 
        isOpen={isShowChangePassModal} 
        closeModal={() => setIsShowChangePassModal(false)}
        modalClass=' w-[40vw]'
        />
        {isOpenEventModal && (
            <ModalEventDetail
            open={isOpenEventModal}
            onClose={() => setIsOpenEventModal(false)}
            data={eventData}
            />
        )}
        <Toaster 
        position="bottom-left"
        reverseOrder={false}
        toastOptions={
            {
                className: 'bg-[#FFFBEE] rounded shadow-lg',
                duration: 5000,
            }
        }
        />
    </div>
  )
}

export default NavBar