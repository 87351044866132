import React from 'react';
import { useNavigate } from 'react-router-dom';

export const PageForbidden = () => {
    const navigate = useNavigate();

    const handleClickHome = () => {
        navigate("/");
    };
  return (
    <>
        <div className="flex items-center my-20">
            <div className="w-full pt-10 flex flex-col items-center justify-center">
                <h2 className="text-[80px] font-bold text-primary">403</h2>
                <p className="text-[40px] font-medium text-primary">
                    Không Có Quyền Truy Cập
                </p>
                <p className='text-base'>Vui lòng liên hệ admin</p>
                <div>
                    <button 
                    type='button'
                    onClick={() => handleClickHome()}
                    className="table-primary-btn w-[200px] mt-5">Vể trang chủ</button>
                </div>
            </div>
        </div>
    </>
  );
};
