import React, { useState } from 'react';
import AvatarDefault from 'assets/images/avatar_default_25x35.png';

const AvatarImage = ({imageUrl, className } : {imageUrl : any, className?: string}) => {
    const [error, setError] = useState<boolean>(false);
    const handleError = (event: any) => {
        event.target.src = AvatarDefault;
        setError(true);
    };
  return (
    <img
      src={imageUrl || AvatarDefault}
      alt="Default"
      onError={handleError}
      className={` ${className} ${(error || !imageUrl) ? 'w-[25px] h-auto' : 'w-full h-full'}`}
    />
  )
}

export default AvatarImage