import MasterLayout from 'components/layouts/MasterLayout'
import { LoadingBarProcess } from 'components/loadingProcess/LoadingBarProcess'
import LoginPage from 'pages/login/LoginPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ForgotPasswordPage from "pages/forgotPassword/ForgotPasswordPage";

const ForgotPasswordRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<ForgotPasswordPage />}>
            </Route>
        </Routes>
    )
}

export default ForgotPasswordRoutes