import MasterLayout from 'components/layouts/MasterLayout'
import { LoadingBarProcess } from 'components/loadingProcess/LoadingBarProcess'
import EventPage from 'pages/event/EventPage'
import React from 'react'
import { Route, Routes } from 'react-router-dom'

const EventRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              {/* <RequireAuth> */}
                <EventPage />
              {/* </RequireAuth> */}
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  )
}

export default EventRoutes