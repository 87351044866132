import { useMutation } from "react-query";
import { AlbumsService } from "../../core/services/albums.service";

export const useChangeNameImageAlbum = () => {
  return useMutation({
    mutationKey: ["change-name-image"],
    mutationFn: ({ id, name }: any) =>
      AlbumsService.changeNameImageAlbum(id, name),
  });
};
