import { toast } from "react-toastify";
import { AlertTriangle } from 'react-feather';

const handleErrorToast = (response: any) => {
    if (response.status === 400 && response.errors) {
      Object.keys(response.errors).forEach((field) => {
        response.errors[field].forEach((error: any) => {
          toast(`${field}: ${error}`, {
            type: 'error',
            icon: <AlertTriangle className="text-[#F03D3E]" />
          });
        });
        
      });
    } else {
      toast(`Đã xảy ra lỗi không xác định`, {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
  };

  export default handleErrorToast;