import { LogService } from 'core/services/log.service';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { AlertTriangle, Clock } from 'react-feather';
import DatePicker from "react-datepicker";
import DatePickerIcon from 'assets/images/datepickerIcon.png';
import Select from 'react-select';
import { AdminService } from 'core/services/admin.service';
import { CircleSpin } from 'components/spinner/CircleSpin';
import { PaginationOwn } from 'components/uiComponents/Pagination';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LogActivity from './components/LogActivity';
import { UserService } from 'core/services/user.service';

const LogList = () => {
  const [logs, setLogs] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [itemPerPage, setItemPerPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();

  const [fromDate, setFromDate] = useState<any>();
  const [toDate, setToDate] = useState<any>();

  const [admins, setAdmins] = useState<any>([]);
  const [selectedAdmin, setSelectedAdmin] = useState<any>();
  const [searchParams] = useSearchParams();
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const [filters, setFilters] = useState<any>();

  const page = searchParams.get('page') || 1;

  const getListLog = async (params: any) => {
    setLoading(true);
    const response  = await LogService.getListActivity(params);

    if (response?.data?.isSuccess === true) {
      setLogs(response?.data?.data || []);
      setLoading(false);
      setIsSearch(true);
    }
  };

  const getListAdmin = async () => {
    const response = await UserService.getAdmins();

    if (response?.data?.isSuccess === true) {
      const adminsOption = response?.data?.data?.map((admin: any) => {
        return { value: admin?.id, label: `${admin.firstName} ${admin.lastName}` };
      })
      setAdmins(adminsOption);
   }
  }

  useEffect(() => {
    let params = {
      PageNumber: currentPage,
      PageSize: itemPerPage
    }
    if (page) {
      setCurrentPage(Number(page));
      params = {
        ...params,
        PageNumber: Number(page)
      }
    }
    if (filters) {
      params = {
        ...params,
        ...filters
      }
    }else {
      setIsSearch(false);
    }
    
    getListLog(params);
    getListAdmin();
  }, [page, filters]);

  const handleAdminsChange = (data: any) => {
    setSelectedAdmin(data);
  };

  const onPageChange = useCallback(async (event: number) => {
    navigate(`/quan-ly/log?page=${event}`);
  }, []);

  const handleSubmitFilter = () => {
    let filterParams: any = null;
    if (!fromDate && toDate) {
      // const isToDateToday = moment(toDate).isSame(moment(), 'day');
      // const fromDate = isToDateToday ? new Date() : toDate;
      filterParams = {
        // FromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
        FromDate: "2024-03-01T00:00:00",
        ToDate: moment(toDate).format('YYYY-MM-DDT23:59:59'),
      }
      // setFromDate(fromDate);
    }
    if (fromDate && !toDate) {
      filterParams = {
        FromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
        ToDate: moment(new Date()).format('YYYY-MM-DDT23:59:59'),
      }
      // setToDate(new Date());
    }
    if (fromDate && toDate) {
      const time1 = fromDate.getTime();
      const time2 = toDate.getTime();
      if (time1 > time2) {
        toast('Ngày sau phải lớn hơn ngày  trước!', {
          type: 'error',
          icon: <AlertTriangle className="text-[#F03D3E]" />
        });
        return;
      }else {
        filterParams = {
          FromDate: moment(fromDate).format('YYYY-MM-DDT00:00:00'),
          ToDate: moment(toDate).format('YYYY-MM-DDT23:59:59'),
        }
      }
    }
    if (selectedAdmin) {
      filterParams = {
        ...filterParams,
        AdminId: selectedAdmin.value
      }
    }
    
    if (filterParams) {
      setFilters(filterParams);
      navigate(`/quan-ly/log`);
    }

  };

  const handleCancelFilter = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedAdmin(null);
    setFilters(null);
  }

  return (
    <>
      <div className=' flex justify-between'>
        <div className='flex items-center gap-[11px]'>
          <Clock size={24} color={`#461511`}/>
        <p className=' text-base font-semibold leading-5 text-bg_brown'>Lịch sử hoạt động</p>
        </div>
          <div className=' text-sm font-normal leading-5 text-color_text'></div>
      </div>
      <div className='table-container min-h-[500px] mb-5'>
        <div className='mt-[13px] mb-[23px] flex gap-5'>
          <div className='w-[20%]'>
            <div className="form-label mr-2">Từ ngày</div>
            <div className='relative flex-1'>
              <DatePicker 
                selected={fromDate} 
                onChange={(date: any) => setFromDate(date)} 
                className="form-input w-full"
                dateFormat={"dd/MM/yyyy"}
                showYearDropdown
                dropdownMode="select"
                placeholderText='Từ ngày'
                id='fromdate-id'
                maxDate={new Date()}
                popperPlacement="bottom-end"
              />
              <img 
              onClick={() => document?.getElementById('fromdate-id')?.click()}
              src={DatePickerIcon} 
              alt="DatePickerIcon" 
              className=' absolute top-2 right-2 w-[16px] h-[16px]'/>
            </div>
          </div>

          <div className='w-[20%]'>
            <div className="form-label mr-2">Đến ngày</div>
            <div className='relative flex-1'>
              <DatePicker 
                selected={toDate} 
                onChange={(date: any) => setToDate(date)} 
                className="form-input w-full z-10"
                dateFormat={"dd/MM/yyyy"}
                showYearDropdown
                dropdownMode="select"
                placeholderText='Đến ngày'
                id='todate-id'
                maxDate={new Date()}
              />
              <img 
              onClick={() => document?.getElementById('todate-id')?.click()}
              src={DatePickerIcon} alt="DatePickerIcon" 
              className=' absolute top-2 z-0 right-2 w-[16px] h-[16px]'/>
            </div>
          </div>
          <div className='w-[30%]'>
            <div className="form-label min-w-[60px]">Admin</div>
            <Select
              value={selectedAdmin}
              onChange={handleAdminsChange}
              options={admins}
              className='form-select-32 '
              classNamePrefix={'select'}
              placeholder='Chọn admin'
              isClearable={true}
            />
          </div>
          <div className='w-[30%] flex items-end justify-center gap-3'>
            {(toDate || fromDate || selectedAdmin) &&
              <button className='table-second-btn w-[88px] h-8' type='button' onClick={() => {handleCancelFilter()}}>Xóa lọc</button>
            }
            <button className='table-primary-btn w-[88px] h-8' type='button' onClick={() => {handleSubmitFilter()}}>Lọc</button>
          </div>
        </div>
        {logs?.records?.length > 0 &&
          <table className='table w-full'>
            <thead>
              <tr>
                <th className='w-[20%]'>
                    <span>Admin</span>
                </th>
                <th className='w-[15%]'>
                    <span>Thời gian</span>
                </th>
                <th className='w-[20%]'>
                    <span>Hoạt động</span>
                </th>
                <th className='w-[30%]'>
                    <span>Đối tượng</span>
                </th>
                <th className='w-[15%]'>
                    <span>Địa chỉ IP</span>
                </th>
              </tr>
            </thead>
            {!loading &&
              <tbody>
                {logs?.records?.map((log: any) => (
                  <tr key={log?.id} >
                      <td className='w-[20%]'>
                          <span>{(!log.userFirstName || !log.userLastName) ? "Admin" : `${log.userFirstName} ${log.userLastName}`}</span>
                      </td>
                      <td className='w-[20%]'>
                          {moment(log.actionTime).format('DD/MM/YYYY HH:mm')}
                      </td>
                      <td className='w-[20%]'>
                          <span>{log.action}</span>
                      </td>
                      <td className='w-[25%] max-w-[200px]'>
                        <LogActivity
                          activity={log.activity}
                          logId={log.id}
                        />
                        {/* <div className='w-full line-clamp-1'>
                          {log.activity}
                        </div> */}
                      </td>
                      <td className='w-[15%]'>
                          <span>{log.ipAddress}</span>
                      </td>
                  </tr>
                ))}
              </tbody>
            }
          </table>
        }
        {loading && (
          <>
            <div className="flex justify-center mt-4 items-center text-table_thead_text">
              <CircleSpin color="text-table_primary_btn mr-2" /> Loading...
            </div>
          </>
        )}
        {logs?.records?.length === 0 && filters && isSearch && !loading &&
          <div className='form-normal-text'>{`Không tìm thấy hoạt động nào`}</div>
        }
        {logs?.totalCount > itemPerPage && !loading && (
          <div className="my-6 d-flex text-right">
            <PaginationOwn
              totalItems={logs?.totalCount}
              itemPerPage={itemPerPage}
              pageChange={onPageChange}
              pageCurrent={currentPage}
            />
          </div>
        )}
      </div>
    </>
  )
}

export default LogList