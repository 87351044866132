import React from 'react';

interface AuthContextType {
    user?: any;
    loading: boolean;
    error?: any;
    setError?: any;
    login: (username: string, password: string) => void;
    forgotPassword: (username: string) => Promise<void | Error>;
    resetPassword: (oldPassword: string, newPassword: string) => Promise<void | Error>;
    logout: () => void;
  }

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthContext;