import { LOG } from "core/constant/api.contant"
import axiosClient from "utilities/axios-instance"

const getList = (params: any) => {
    return axiosClient.get(LOG.LIST, { params});
}

const getListActivity = (params: any) => {
    return axiosClient.get(LOG.LIST_ACTIVITY, {params});
}

export const LogService = {
    getList,
    getListActivity
}