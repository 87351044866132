import React, { Fragment } from 'react'
import Img1 from 'assets/images/history/history_1.png';
import Img2 from 'assets/images/history/history_2.png';
import Img3 from 'assets/images/history/history_3.png';
import Img4 from 'assets/images/history/history_4.png';
import Img5 from 'assets/images/history/history_5.png';
import Img6 from 'assets/images/history/history_6.png';
import Img7 from 'assets/images/history/history_7.png';
import Img8 from 'assets/images/history/history_8.png';
import Img9 from 'assets/images/history/history_9.png';
import Img10 from 'assets/images/history/history_10.png';
import Dl_Icon from 'assets/images/history/download_icon.png';
import GiaPhaFile from 'assets/files/Pha_Toc_Nguyen_Duc.pdf';
import { useAuth } from 'core/contexts/auth/AuthContext';

const HistoryPage = () => {
    const auth = useAuth();
  return (
    <div className='mb-20 px-4 md:px-0'>
      <h1 className=' font-medium text-lg leading-5 text-bg_brown'>Lịch sử Họ Nguyễn Đức</h1>
      <div className=' space-y-3 my-7 font-normal text-sm leading-5 text-[#461511] indent-4'>
        <p>Tổ tiên họ Nguyễn Đức, vốn là dòng họ Nguyễn Thế, từng sinh sống bao
          đời tại thôn Yên Thái, xã Đắc Sở, phủ Quốc Oai, tỉnh Sơn Tây nay thuộc xã Đắc
          Sở, huyện Hoài Đức, thành phố Hà Nội. Đời vua Lê Huyền Tông, hiệu Cảnh Trị
          thứ 8, năm Canh Tuất (1670), ông Nguyễn Thế Cấp (đời thứ 9 họ Nguyễn Thế)
          và bà Nguyễn Thị Nghệ đã sinh ra cụ Nguyễn Thế Tài, tại trại của họ Nguyễn
          Thế thuộc địa giới thôn Yên Thái phía hữu đê sông Đáy (cụ Nguyễn Thế Tài
          thuộc đời thứ 10 của dòng họ Nguyễn Thế).
          Cụ Nguyễn Thế Tài thuộc hàng văn khoa, võ sắc. Cụ được nhà Lê trọng
          dụng và làm quan trong một thời gian dài. Nói về chính kiến, cụ làm quan và phục vụ cho nhà Lê nhưng lại căm ghét nhà Trịnh, song thời cuộc đã bắt buộc cụ
          phải bằng mặt và không bằng lòng.</p>
        <p>Vào khoảng năm 1707, đời vua Lê Dụ Tông, niên hiệu Vĩnh Thịnh năm
          thứ 2, chúa Trịnh Căn ốm yếu nằm một chỗ, chính quyền lục đục, thì cụ Nguyễn
          Thế Tài từ quan, về quê gây dựng cơ sở. Rất tiếc những Chỉ dụ, V ăn bằng, các Đạo Sắc phong cả thời gian hàng
          mấy trăm năm, qua hai đợt cháy lớn đã không còn nữa.
          Con trai cụ Nguyễn Thế Tài là Nguyễn Thế Kiền là người thông minh, có
          sức khỏe thuộc hàng văn kbfa2bzfhoa, võ sắc. Cụ làm quan cho nhà Lê, trải qua nhiều
          năm trận mạc cụ được phong chức Tiền phấn lực Tướng quân hiệu lệnh tự
          Tráng sĩ bách hộ Duối tươi Tướng công.
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>Nội bộ xâu xé nhau của dòng họ Trịnh và nhận quyết định đi trấn thủ
        phủ Trấn Ninh của cụ Nguyễn Thế Kiền.</p>
        <div className='flex items-center justify-center gap-[30px]'>
            <div className='flex-1'>
              <img src={Img1} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img2} alt="TopImg" className='w-full h-auto'/>
            </div>
        </div>
        <p>Năm 1704, Trịnh Luân và Trịnh Phất (con Trịnh Bách Tiết chế đã chết)
        làm phản mưu giết Tiết chế Trịnh Cương (con Trịnh Bính, chắt nội của Trịnh
        Căn được phong làm Tiết chế An Quốc công), nhờ mật báo nên qua được, Luân
        và Phất bị giết. Trong vụ lộn xộn này, cụ Kiền đứng ngoài và không tỏ thái độ
        ủng hộ Trịnh Cương, Trịnh Cương đã cảm nhận được cụ Kiền không hài lòng về
        nhà Trịnh.
        </p>
        <p>{`Đến năm 1707, chúa Trịnh Căn (Trịnh Căn lúc này ốm yếu nằm một chỗ,
          nên thực chất công việc do Trịnh Cương thay thế), lấy cớ cử tướng đi trấn ải
          biên giới vùng Trấn Ninh của Nghệ An giáp Lào (cụ thể, đã điều cụ Nguyễn Thế
          Kiền đi đồn trú ở phủ Trấn Ninh. Phủ Trấn Ninh trải dài với biên giới Lào, từ
          Đồn Trà Lân (gần Con Cuông, Nghệ An) cho tới Đồn Quán Lào (phía Nam tỉnh
          Thanh Hóa). Quyết định điều cụ Nguyễn Thế Kiền trấn thủ Trấn Ninh là một
          quyết định có hai mục đích, một phần chính là điều động tướng quân lãnh nhiệm
          vụ trấn thủ biên cương, một phần là mượn bàn tay của các đối thủ, các thế lực,
          các nhóm chống đối để hãm hại Cụ`}</p>
        <p>
          {`Cụ nhận quyết định, cả gia đình cụ họp bàn bạc và từ đó đã đề ra những
          quyết định quan trọng.Trước hết cụ sẽ ra đi một mình, qua chiến đấu có thời
          gian tìm hiểu và chọn địa điểm để đưa gia đình vào định cư sau này.
          `}
        </p>
        <p>
          {`Năm 1707, cụ dẫn một đoàn quân rời kinh thành ra đi.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Cụ Nguyễn Thế Kiền tìm đến vùng đất Giáp Thuần Hậu để chuyển
        gia đình vào định cư.`}</p>
        <p>
          {` Trên đường đi phủ Trấn Ninh, đến Diễn Châu, cụ quyết định đi đường
          thủy (thời đó đường thủy là thuận tiện nhất), từ biển đi vào kênh nhà Lê, đến cầu
          Bùng, ngược lên sông Dinh, đi đến Bến Cầu Dền (Văn Thành), đi tiếp lên khe
          Bình Dương (khe này chảy phía sau Bệnh viện huyện Yên Thành).
          Cụ Nguyễn Thế Kiền tìm địa điểm (dựa vào địa thế nơi cụ ở là làng Đắc
          Sở, làng Đắc Sở lúc đó là một làng chiến đấu thuộc loại ngoại bất nhập, dân làng
          muốn diệt Trịnh phò Lê, đường giao thông hào được đào khắp nơi). Cụ phải
          chọn một địa điểm vừa thuận lợi cho trồng trọt vừa có địa thế của quân sự.
          `}
        </p>
        <p>
          {` Đến khe Bình Dương, cụ dừng lại và tiến hành khảo sát tìm hiểu vùng đất
          và con người nơi đây, cụ nhận thấy đây là vùng đất rất phù hợp với yêu cầu của
          gia đình. Vùng đất này thuộc Giáp Thuần Hậu, huyện Đông Thành, phủ Diễn
          Châu, hiện tại người còn thưa thớt, nhưng đất canh tác thì phì nhiêu. Lợi thế của
          vùng đất này là mặt sau lưng được áp sát vào rừng núi.
          Cụ đi thuyền, đến khe Ký Rượu rồi ngược lên Khe Am (hay còn gọi là
          sông Am), thì cụ dừng lại và tiếp tục khảo sát tìm hiểu vùng đất này. Vùng này
          có chất lượng đất tốt, rất thuận tiện cho trồng trọt, làm ruộng. Đặc biệt ở đây có
          dãy núi cao và bên kia dãy núi cao là Lèn Đồng Cò nơi có nhiều hang động rất
          phù hợp cho quân sự. Như vậy, Khe Am là nơi tăng gia sản xuất, đồng thời về mặt quân sự thì phù hợp với chiến thuật tiến lui rất cơ động. Tuy nhiên, vùng đất
          này hơi xa khu dân cư nên chỉ có thể là khu đất dự phòng.
          Bằng con mắt của người văn võ song toàn cụ quyết định gia đình sẽ định
          cư tại Giáp Thuần Hậu, còn Khe Am là địa điểm dự phòng, cụ đã nghiên cứu kỹ
          địa hình cả về phương diện kinh tế và quân sự.
          `}
        </p>
        <p>
          {`Sau khi đã nghiên cứu địa điểm, từ Khe Am cụ và đoàn quân dùng ngựa
          luồn rừng đi thẳng lên phủ Trấn Ninh.`}
        </p>
        <p>
          {` Từ đồn Trà Lân, qua nhiều năm dẫn quân đi đánh giặc, qua nhiều vùng,
          nhiều địa danh cụ tiếp tục phân tích đánh gia những vùng đất này nhưng cuối
          cùng cụ đã nhận thấy vùng Giáp Thuần Hậu và Khe Am thuộc huyện Đông
          Thành là có thể định cư lâu dài để phát triển kinh tế và xây dựng thành căn cứ
          quân sự.
          `}
        </p>
        <p>{`  Về mặt quân sự, đi đến Khe Am có thể đi bằng đường bộ, đi bằng đường
          thủy đều rất thuận lợi.`}
        </p>
        <p>
          {`Về mặt sản xuất, vùng Giáp Thuần Hậu là vùng đất rất tốt cho trồng cây
          lương thực, những mùa lúa bội thu sẽ giải quyết cái ăn và cái dự trữ.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Gia đình cụ Nguyễn Thế Tài di dời đến Giáp Thuần Hậu.`}</p>
        <p>
          {`Cụ quyết định trở về quê hương đón gia đình vào miền đất mới. Đó là
          năm 1710.`}
        </p>
        <p>
          {`Đoàn gia đình cụ Nguyễn Thế Tài đi Giáp Thuần Hậu, huyện Đông
          Thành, phủ Diễn Châu gồm có: Cụ O út Quỳnh Hoa (em ông Cấp đời 9), cụ
          Nguyễn Thế Tài và vợ cụ bà Hồ Thị Ân, cụ Nguyễn Thế Kiền và vợ là cụ bà
          Nguyễn Thị Cần, vợ kế thất của cụ Nguyễn Thế Cấp và con trai là cụ Nguyễn
          Thế Phát, cùng một đoàn gia nhân, tuỳ tòng đông đảo. Cụ Nguyễn Thế Tài đã
          cùng vợ, con không quản đường sá xa xôi vào vùng đất này để "khai sơn phá
          thạch, dựng làng, lập trại xây dựng cơ đồ lâu dài".
          `}
        </p>
        <p>
          {`Khe Am là vùng đất trù phú, có dòng nước chảy xuống sông Dinh, có
          rừng Thung Lĩnh Sơn3, có con người hiền hậu, thủy chung và rất dũng cảm.
          `}
        </p>
        <p>
          {`Xa xa hơn một tý có (rừng) Gám non nước hữu tình.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Cụ Nguyễn Thế Tài và cụ Nguyễn Thế Kiền bắt đầu xây dựng cơ sở
        dự phòng cho quân sự ở Khe Am.
        `}</p>
        <p>
          {`Ở Giáp Thuần Hậu, cụ Nguyễn Thế Tài đã cùng các con và gia nhân bắt
          đầu vào khai hoang để mở rộng diện tích cày cấy. Cuộc sống dần ổn định.
          Nhưng trong tiềm thức, cụ vẫn nhớ nhiệm vụ là xây dựng cơ sở quân sự. Vì vậy, cụ quyết định lấy vùng đất Khe Am và giao cho người con trai cả là cụ Nguyễn
          Thế Dật chỉ huy.
          `}
        </p>
        <p>
          {` Sau một thời gian ổn định, cụ giao lại cho người con trai trưởng là cụ
          Nguyễn Thế Trì phụ trách, quán xuyến mọi công việc như khai hoang, sản xuất
          trồng trọt đặc biệt là luyện tập quân sự.
          `}
        </p>
        <p>
          {`Sống ở vùng đất Khe Am, chủ yếu là người Mường thuộc dân tộc thiểu
          số. Cụ Nguyễn Thế Trì đã đổi đất, đền bù cho dân dồn họ về ở một khu vực gọi
          là Hòn Mường, để ông lấy đất tập trung một khu rộng lớn làm trại (Hòn Mường
          hiện nay vẫn còn di tích).
          `}
        </p>
        <p>
          {`Cụ và gia đình sau ngày sản xuất và những ngày nghỉ thường đến Đền
          Am làm lễ, Đền Am khi Cải cách ruộng đất đã bị phá đi, nay đang được xây
          dựng lại (Đền Am thuộc xóm Đồng Hoa, xã Đồng Thành) thắp hương, làm lễ.
          Ngoài ra các cụ còn đến Đền Cao Sơn, Đền Cao Sơn khi Hợp tác xã mở rộng,
          khoảng từ năm 1960 thì bị tháo dỡ, nền Đình vẫn còn nhưng cỏ mọc um tùm.
          `}
        </p>
        <p>
          {`Trước cổng trại, cụ Nguyễn Thế Trì cho trồng hai cây đa, gọi là cây đa
          Cửa Cài (hay còn gọi là Cổ Am). Hai cây đa này rất thiêng, cách đây nhiều năm
          hai cây đa đã lần lượt bị đổ, dân làng vẫn để nguyên đó không một ai dám đụng
          đến, dù chỉ cắt một cành lá và để cho cây tự nhiên mục nát dần.`}
        </p>
        <p>
          {` Số ruộng 11 mẫu (cụ Nguyễn Thế Tài, cụ Nguyễn Thế Dật, cụ Nguyễn
          Thế Trì để lại) trước thuộc Khe Am của xã Tiền Thành, nay thuộc xã Đồng
          Thành. Đó là bằng chứng còn lại của một cơ sở quân sự, là cơ sở dự phòng của
          cụ Nguyễn Thế Tài để lại.
          `}
        </p>
        <p>
          {`Khe Am là nguồn nước tưới tiêu cho xã Đồng Thành và các xã lân cận,
          nay được xây dựng thành hồ chứa nước gọi là đập Vệ Vừng.
          `}
        </p>
        <p>
          {`Đất khai hoang được 11 mẫu tại Khe Am, xã Đồng Thành có thời gian
          được bà Thái Thị Vực thu về Trại Mụ Vạc để quản lý, bà còn thu ruộng đất của
          mấy xã lân cận, cả một cánh đồng thẳng cánh cò bay. Bà Thái Thị Vực thường
          dùng ngựa để đi thăm đồng và ngao du các nơi.
          `}
        </p>
        <p>
          {`Khi cải cách giảm tô ruộng đất, tài sản của bà bị thu hết, qúa uất ức bà đã
          thắt cổ tự tử. Sau Cải cách ruộng đất số ruộng đó bị bỏ hoang. Trong thời kỳ bao
          cấp, huyện Đội huyện Yên Thành dùng 11 mẫu ruộng đó để sản xuất lương thực
          tự túc, nay đã chia nhau sản xuất.`}
        </p>
        <div className='flex items-center justify-center gap-[30px]'>
            <div className='flex-1'>
              <img src={Img3} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img4} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img5} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img6} alt="TopImg" className='w-full h-auto'/>
            </div>
        </div>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Cụ Nguyễn Thế Kiền trở lại mặt trận chiến đấu.`}</p>
        <p>
          {`Công việc sản xuất bắt đầu tiến hành có tổ chức và quy củ. Sau một thời
          gian, giúp gia đình ổn định cuộc sống, cụ Nguyễn Thế Kiền lên đường đi phủ
          Trấn Ninh.
          Từ Giáp Thuần Hậu, cụ đi thuyền lên Khe Am, rồi đi bằng ngựa xuyên
          rừng đến đồn Trà Lân, rồi đến phủ Trấn Ninh, quãng đường chỉ vào khoảng 80-
          100 cây số.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Quá trình tìm kiếm cụ Nguyễn Thế Phát và bà mẹ.`}</p>
        <p>
          {`Cụ Nguyễn Thế Phát và cụ bà ở với cụ Nguyễn Thế Tài một thời gian thì
          đi tiếp vào phía trong. Trong Gia phả sao chép năm 1935 không thấy nói đến sự
          kiện này.
          `}
        </p>
        <p>
          {`Khi cụ bà, vợ kế của cụ Nguyễn Thế Cấp (đời thứ 9) đang ở với cụ
          Nguyễn Thế Tài, sau một thời gian thì cụ bà và người con trai là cụ Nguyễn Thế
          Phát nghe tin ở Đàng ngoài lộn xộn về chính trị thì hai mẹ con vội vã đi vào phía
          trong.
          `}
        </p>
        <p>
          {`Vấn đề này chúng tôi cũng suy nghĩ rất nhiều, tuy nhiên cả một quá trình
          lịch sử kéo dài hơn ba trăm năm, có nhiều biến cố như chạy nạn giặc, rồi trải qua
          hai đám cháy lớn thì không còn tư liệu lưu giữ nữa. Ngay như cuốn Gia phả
          được sao chép năm 1935 thì cũng là cuốn sao chép.
          `}
        </p>
        <p>
          {`Nhiều thông tin được đồn thổi, thêu dệt rồi truyền bá lại cho đời sau mà
          không biết chính xác của sự kiện. Theo chúng tôi, sở dĩ cụ bà mẹ kế và người
          em trai là cụ Nguyễn Thế Phát rời bỏ cụ Nguyễn Thế Tài, người con, người anh
          cùng cha khác mẹ, thì đó chỉ là mối quan hệ gia đình không được suôn sẻ, và đó
          cũng có thể là một nguyên nhân. Cho nên, sự mâu thuẫn gia đình kéo dài cho hết
          đời bà mẹ, rồi đến đời cụ Nguyễn Thế Phát không có mối quan hệ, thông tin gì
          với cụ Nguyễn Thế Tài thì đời sau đó sẽ đứt mối liên lạc. Thực tế trong quãng
          thời gian dài này họ không có thông tin đi lại với nhau.
          `}
        </p>
        <p>
          {`Những suy luận trên chúng tôi thấy nó phù hợp, logic của sự kiện có thể
          xẩy ra như thế. Tuy nhiên mong bà con cùng suy nghĩ và đi đến kết luận cho
          riêng mình.
          `}
        </p>
        <p>
          {`Năm 2014, ông Nguyễn Đức Công vào Thanh Chương (vì có ý kiến cho
          rằng cụ Nguyễn Thế Phát và bà mẹ vào Thanh Chương), ở Thanh Chương ông
          Nguyễn Đức Công xác định nguồn gốc họ Nguyễn Đức ở nhà thờ này có từ tỉnh
          Hải Dương.
          `}
        </p>
        <p>
          {`Tháng 1 năm 2016, ông Nguyễn Đức Nam và ông Nguyễn Đức Công đi
          lên huyện Đô Lương và xác định một số điểm như sau:`}
        </p>
        <p>
          {`Tại xã Tràng Sơn, huyện Đô Lương có họ Nguyễn Đức, nhưng từ Bắc
          Ninh vào Đô Lương năm 1789 và thờ Cụ Tổ là cụ Nguyễn Đức Trinh.
          `}
        </p>
        <p>
          {`Cũng tại Tràng Sơn, Đô Lương có nhà thờ Tổ họ Nguyễn Văn, thờ cụ
          Nguyễn Văn Nghi (cụ Nghi có bốn người con trai đều ly tán cả, có người đi về
          định cư ở huyện Diễn Châu và về nhiều nơi khác), con trai trưởng của cụ
          Nguyễn Văn Nghi là cụ Nguyễn Văn Bàn, con trai trưởng của cụ Nguyễn Văn
          Bàn là Nguyễn Thế Học, con trai trưởng của cụ Nguyễn Thế Học là Nguyễn Thế
          Khanh... Nhà thờ này gốc ở huyện Can Lộc, tỉnh Hà Tĩnh, ở đây mang dòng họ
          Nguyễn Văn. Một nhánh của nhà thờ di ra huyện Đô Lương và được đổi thành
          họ Nguyễn Đức. Họ Nguyễn Đức ở xã Đông Sơn, huyện Đô Lương (thuộc Chi 3
          của nhà thờ tổ thuộc xã Tràng Sơn). Tháng 6 năm 2013, một đoàn các Cụ thuộc
          nhà thờ Đông Sơn (thuộc Chi 3) đã về huyện Yên Thành, được họ ta mời cơm
          thân mật, sau đó có đối chiếu Gia phả nhưng không khớp.
          `}
        </p>
        <p>
          {`Khi lên Đô Lương lần này, chúng tôi không đến nhà thờ ở Đông Sơn mà
          đi thẳng đến nhà thờ Tổ của họ Nguyễn Đức ở xã Tràng An. Tại nhà thờ Tổ ở xã
          Tràng An, chúng tôi đã xem lại Gia phả và cả hai bên đều khẳng định là không
          đúng.`}
        </p>
        <p>
          {` Như vậy, các thông tin ông Nguyễn Đức Phát và bà mẹ đi tiếp vào phía
          trong thì hiện nay chúng tôi đã xác định được các địa danh thuộc huyện Can
          Lộc, tỉnh Hà Tĩnh; huyện Thanh Chương và huyện Đô Lương, tỉnh Nghệ An,
          còn những thông tin khác thì chúng tôi chưa được nghe, mong bà con khi biết
          thông tin thì hỏi cặn kẽ để nếu có điều kiện thì ta tiếp cận xác định thông tin.
          `}
        </p>
        <p>
          {`Ngoài ra chúng tôi còn đi tiếp tới huyện Nghĩa Đàn, tỉnh Nghệ An. Đi
          qua ba xã là: xã Nghĩa Mỹ (hay còn gọi là Làng Lụi) vào Uỷ ban xã gặp chủ tịch
          và rà tìm danh sách trong xã. Đi xã Nghĩa Trung ở đây phần đông là người theo
          Công Giáo, vì có tin đồn ông Tuận khi lên làng Lụi thì theo Công Giáo để được
          chia gạo. Chúng tôi ra nghĩa trang lần xem từng ngôi mộ nhưng không thấy. Đi
          tiếp về xã Nghĩa Lộc cũng là làng Công Giáo đặt vấn đề tìm kiếm với giáo dân
          để tìm gốc tích ông Nguyễn Đức Tuận, con thứ ba (con út) cụ Nguyễn Thế Hạnh
          nhưng không tìm được. Sự tìm kiếm đến nay chưa đạt được kết quả, con cháu về
          sau phải tìm kiếm và xác minh thêm.`}
        </p>
        <p>
          {`Cụ Nguyễn Thế Kiền, là một vị tướng trẻ của phủ Trấn Ninh, cụ nhận
          nhiệm vụ trấn giữ biên giới từ đồn Trà Lân tới đồn Quán Lào đã lập được nhiều
          chiến công hiển hách. Bí mật cho những cuộc chiến là sử dụng ngựa và voi, luồn
          rừng, đi tắt đó là lợi thế của quân đội cụ Nguyễn Thế Kiền.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Sự hy sinh của cụ Nguyễn Thế Kiền.`}</p>
        <p>
          {` Trong thời gian đóng quân ở phủ Trấn Ninh, cụ Nguyễn Thế Kiền đã cưới
          vợ hai là cụ bà kế thất Nông Thị Tâm.
          `}
        </p>
        <p>
          {` Cụ Nguyễn Thế Kiền và hai cụ bà Nguyễn Thị Cần, cụ bà Nông Thị Tâm
          đã sinh hạ được ba người con trai, hai người con gái.
          `}
        </p>
        <p>
          {`Ba người con trai là:`}
        </p>
        <p>
          {`   Con trai trưởng cụ Nguyễn Thế Dật (Mạnh đường)`}
        </p>
        <p>
          {`   Con trai thứ hai cụ Nguyễn Thế Mậu (Trọng đường)`}
        </p>
        <p>
          {`    Con trai thứ ba cụ Nguyễn Thế Quýnh (Quý đường)`}
        </p>
        <p>
          {`Hai người con gái:`}
        </p>
        <p>
          {`Một bà lấy chồng họ Nguyễn Danh, người trong thôn, con cháu đông.
          Một bà lấy chồng về Hoàng Mai, Quỳnh Lưu, hiện con cháu đông.`}
        </p>
        <p>
          {`Cụ Nguyễn Thế Kiền, đã sống cùng hai bà vợ và năm người con một thơi
          gian dài, trong một trận chiến đấu cụ đã hy sinh, sau khi cụ hy sinh phủ Trấn
          Ninh đã cử người về báo cho gia đình biết tin. Gia đình đã cử một đoàn lên phủ
          Trấn Ninh dự lễ tang và đưa thi hài Cụ về quê hương, đồng thời đưa vợ con của
          Cụ về Giáp Thuần Hậu, huyện Đông Thành, tỉnh Nghệ An.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Sự chắp nối mối quan hệ giữa Đắc Sở - Yên Thành.`}</p>
        <p>
          {` Trước khi rời khỏi Đắc Sở, cụ Nguyễn Thế Tài đã kịp chép lại một số
          thông tin về các Cụ Tổ đời thứ 9 trở về trước. Mặc dù những thông tin này hết
          sức ít ỏi, đơn sơ nhưng vô cùng quý giá. Chính nhờ những dòng thông tin này
          mà con cháu họ Nguyễn Đức ở Yên Thành mới có cơ sở tìm về Đắc Sở chắp
          mối quan hệ với con cháu họ Nguyễn Thế.
          `}
        </p>
        <p>
          {`Năm 1965, trong lớp học Cơ yếu Trung ương, ông Nguyễn Đức Bảo có
          làm quen với ông Nguyễn Thế Nhiêu (con ông Nguyễn Thế Loan, người đại
          diện cho Tộc trưởng họ Nguyễn Thế) kể lại cho ông Bảo biết là họ Nguyễn Thế
          có ba người con vào Nghệ An, nhưng sau đó ông Nhiêu ra chiến trường và hy
          sinh, câu chuyện chưa chắp nối được.
          `}
        </p>
        <p>
          {`Năm 1968, ông Nguyễn Đức Thiệng có lên xã Đắc Sở tìm hiểu nguồn gốc
          họ Nguyễn Thế, ông vào Ủy ban nhân dân xã, sau đó có gặp một số cụ của họ
          Nguyễn Thế hỏi chuyện và giới thiệu cho các cụ biết họ Nguyễn Đức ở Yên
          Thành có nguồn gốc là họ Nguyễn Thế. Ông trở về, sau đó đi cùng ông Nguyễn
          Đức Dung lên lại xã Đắc Sở tiếp tục tìm hiểu về nguồn gốc dòng họ Nguyễn
          Thế, về sự liên quan và tiếp nối với dòng họ Nguyễn Đức ở Yên Thành. Khi trở
          về, hai ông đã thông báo cho ông Nguyễn Đức Lựu biết thông tin.
          `}
        </p>
        <p>
          {`Thời gian này, chiến tranh ác liệt, nên công việc chắp nối hai họ Nguyễn
          Thế - Nguyễn Đức bị gián đoạn.`}
        </p>
        <p>
          {`
           Sau những năm dài khó khăn của nền kinh tế, đầu năm 1993, ông Nguyễn
          Đức Dung nói lại sự việc kết nối dòng họ ở Đắc Sở - Yên Thành cho ông
          Nguyễn Đức Hoán biết. Đến cuối năm 1995, ông Nguyễn Đức Hoán đã lên Đắc
          Sở, gặp ông Nguyễn Thế Loan (cha ông Nguyễn Thế Nhiêu), ông Nguyễn Thế
          Loan đã gửi cho họ Nguyễn Đức ở Yên Thành một bức thư xác nhận về mốc
          thời gian cụ Nguyễn Thế Tài, cụ Nguyễn Thế Kiền rời quê ra đi đến vùng Giáp
          Thuần Hậu (ông Nguyễn Thế Loan là Đại diện cho họ Nguyễn Thế ở Đắc Sở vì
          ông Tộc trưởng ở tỉnh xa).
          `}
        </p>
        <p>
          {`Sau một thời gian dài, để chắp nối mối quan hệ họ Nguyễn Thế - họ
          Nguyễn Đức, cuối năm 2005, ông Nguyễn Đức Nam nói việc cần thu thập tư
          liệu để soạn thảo Gia phả cho ông Nguyễn Đức Hoán, ông Hoán đã trao lại bức
          thư xác nhận về mốc thời gian cụ Nguyễn Thế Tài rời quê ra đi đến vùng Giáp
          Thuần Hậu. Ông Nguyễn Đức Nam cùng ông Nguyễn Đức Hoán lên xã Đắc Sở
          tìm gặp lại ông Nguyễn Thế Loan, nhưng ông Loan đã mất trước đó mấy tháng.
          `}
        </p>
        <p>
          {`Khi biên soạn gia phả, ông Nguyễn Đức Nam đã nhiều lần lên Yên Thái,
          Đắc Sở để đối chiếu, xác nhận sự kiện cụ Nguyễn Thế Tài cùng vợ, con và
          người thân rời khỏi làng Yên Thái vào đời vua Lê Dụ Tông, niên hiệu Vĩnh
          Thịnh thứ 6 năm 1710.`}
        </p>
        <p>
          {`Năm 2007, đại diện họ Nguyễn Thế đã từ Đắc Sở vào Yên Thành dự ngày
          Đại lễ Cụ Tổ Nguyễn Thế Tài được tổ chức hàng năm (14-02). Đặc biệt, nhân
          ngày kỷ niệm 300 năm cụ Nguyễn Thế Tài định cư ở Giáp Thuần Hậu (ngày 14-
          02-2010), đoàn đại biểu họ Nguyễn Thế do ông tộc trưởng dẫn đầu đã vào dự, từ
          đó mối quan hệ giữa con cháu họ Nguyễn Thế ở Đắc Sở và con cháu ở Yên
          `}
        </p>
        <p>
          {`Thành được thiết lập và ngày càng mật thiết, như một người con ở Đắc Sở đã
          nói:`}
        </p>
        <p className=' text-center'>
          <span>"Ngàn thu về trước xây cơ nghiệp,</span><br/>
          <span className='pl-4'>Để mãi về sau hưởng phước lành.</span><br/>
          <span className='pl-[4px]'>Thế Đức tác cầu Tiên Tổ dạy,</span><br/>
          <span className='pl-4'>Yên Thái-Yên Thành vạn lần yên."</span><br/>
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Luyện võ, rèn vũ khí tham gia vào nghĩa quân Tây Sơn`}</p>
        <p>
          {`Trở lại lịch sử nước nhà, từ cuối những năm 1739 các cuộc khởi nghĩa
          nông dân nổ ra ở khắp Đàng Ngoài. Đứng trước phong trào nông dân rầm rộ đó,
          triều đình Lê - Trịnh hết sức lúng túng. Chế độ phong kiến Đàng Trong bước
          vào giai đoạn suy tàn, chuẩn bị cho cuộc khởi nghĩa nông dân vĩ đại, cuộc khởi
          nghĩa Tây Sơn (1771 - 1802).`}
        </p>
        <p>
          {`Năm 1771, một cuộc khởi nghĩa đã bùng nổ ở đất Tây Sơn, do ba anh em
          Nguyễn Nhạc, Nguyễn Huệ, Nguyễn Lữ lãnh đạo. Lãnh tụ của cuộc khởi nghĩa
          Tây Sơn đều rất trẻ, Nguyễn Huệ khi ấy mới 19 tuổi. Nhưng họ đã nắm đúng
          thời cơ khởi nghĩa và đã chuẩn bị tốt cho cuộc khởi nghĩa.
          `}
        </p>
        <p>
          {` Để tiện việc điều hành, Quang Trung quyết định chọn đất thành lập kinh
          đô mới. Việc xây dựng được xúc tiến ngay sau khi chọn vùng chân núi Dũng
          Quyết (gần Bến Thủy-Nghệ An) làm trung tâm với tên gọi Phượng Hoàng
          Trung đô.
          `}
        </p>
        <p>
          {`Ở Nghệ An, đây là giai đoạn các bậc Trưởng họ, Hào trưởng tự lập các
          đội quân, rèn luyện võ nghệ, dựng lò rèn vũ khí để tham gia vào các phong trào
          khởi nghĩa.`}
        </p>
        <p>
          {`Từ xa xưa, nhân dân làng Thuần Hậu đều có lòng yêu nước nồng nàn, anh
          dũng bất khuất trước kẻ thù. Để bảo vệ mình và bảo vệ thôn xóm, từ lâu ở
          Thuần Hậu đã có các lò luyện võ, từ các lò luyện võ xuất hiện nhiều thanh niên,
          có sức khoẻ, tài trí, họ tham gia chiến đấu bảo vệ đất nước.
          `}
        </p>
        <p>
          {`Họ Nguyễn Thế ở Đồng Thành, Khe Am đã đáp ứng lời kêu gọi của quân
          Tây Sơn, thành lập những đội quân hùng mạnh, luyện tập ngày đêm, đặc biệt là
          đội voi chiến ngày một thuần thục, dưới sự chỉ huy của cụ Nguyễn Thế Trì và
          người con trai là cụ Nguyễn Thế Tứ.
          `}
        </p>
        <p>
          {`Trong khí thế sôi động, cụ Nguyễn Thế Dụ (cụ Dụ ở đời thứ 13, con cụ
          Nguyễn Thế Mậu, tuy đời 13 nhưng cụ Dụ chỉ nhiều hơn cụ Tứ ở đời 14 vài
          tuổi) đã tạm biệt cha mẹ, vợ con lên đường cùng hàng vạn trai tráng Nghệ An đã
          mau chóng tập hợp dưới cờ Tây Sơn trên quê hương mình để lên đường đi chiến
          đấu. Họ ở trong quân đội của nông dân về sau thành quân đội của dân tộc. Vua
          Quang Trung cho phiên chế lại đội ngũ. Số tân binh mới tuyển ở Nghệ An phần
          lớn được đặt trong đạo Trung quân do Ngài trực tiếp chỉ huy. Ngài tổ chức cuộc
          Đại duyệt binh. Từ trên voi chiến, Ngài đọc hịch xuất quân, nói rõ: "trong
          khoảng vũ trụ trời nào sao ấy đã phân biệt rõ ràng, phương Nam, phương Bắc
          chia nhau mà cai trị..."`}
        </p>
        <p>
          {`Cụ Nguyễn Thế Dụ, là một người có học thức, văn võ song toàn, hình
          dáng khôi ngô tuấn tú. Cụ Nguyễn Thế Dụ có hai bà vợ và 9 người con, bà vợ
          chính thất là cụ Nguyễn Thị Đăm sống ở quê, cụ bà kế thất Nguyễn Ngọc Mai
          quê ở Phú Xuân, thành phố Huế, cụ bà sinh được hai con trai là cụ Nguyễn Thế
          Vinh và cụ Nguyễn Thế Hòa.`}
        </p>
        <p>
          {`Ông Nguyễn Đức Dụ khi nhập ngũ thì theo tương Cao Thắng đánh giặc,
          một lần Vua Nguyễn Huệ về Nghệ An làm việc với tướng Cao Thắng găp cụ
          Nguyễn Đức Dụ, thấy cụ có tác phong và tướng mạo ưng ý nên đã đưa cụ Dụ đi
          theo. Sau một thời gian dài ở bên Nguyễn Huệ, ông được vua sủng ái và gả con
          gái nuôi Nguyễn Thị Mai, công chúa của vua Quang Trung làm vợ.`}
        </p>
        <p>
          {`Thời vua Quang Trung nhà cụ có thế lực giàu có, cụ cùng nhiều anh em
          trong họ theo giúp việc cho vua Quang Trung, ở trong đội ngũ cận thần như tham mưu, cố vấn. Cụ có công lớn được vua ban thưởng bổng lộc và chức tước
          là Khả vị Phấn lực tướng quân. Con trai thứ hai của cụ là cụ Nguyễn Thế Xưng
          cũng đầu quân cho vua Quang Trung và giữ chức Đội trưởng. Như vậy họ ta,
          tiền sử đã có hai ông tướng, đó là: Cụ Nguyễn Thế Kiền và cụ Nguyễn Thế Dụ
          (cụ Nguyễn Thế Dụ là cháu cụ Nguyễn Thế Kiền).`}
        </p>
        <p>
          {`Sau khi Gia Long lên ngôi, những người theo vua Quang Trung đều phải
          trốn tránh để tránh chu di tam tộc, vì vậy tiểu sử cụ Dụ và các con không được
          rõ ràng lắm, vì phải xóa sạch dấu vết, chỉ biết ông làm quan trong triều đại
          Quang Trung, có đưa nhiều con cháu cùng hoạt động, có công lao trong khởi
          nghĩa của Quang Trung.`}
        </p>
        <p>
          {`Ông Nguyễn Thế Dụ mất năm 1789 (mất trước vua Quang Trung 3 năm).
          Ông và bà Nguyễn Thị Mai sinh hạ được 2 người con trai là cụ Nguyễn Thế
          Vinh và cụ Nguyễn Thế Hòa ở đời thứ 14.`}
        </p>
        <p>
          {`Trong gia phả của Tổ tiên để lại, có ghi: Cụ Nguyễn Thế Tứ (còn gọi là
          Trùm Tứ) đời thứ 14, đã cùng nhiều sĩ phu tham gia nghĩa quân Tây Sơn.`}
        </p>
        <p>
          {`Trong bản tư liệu gia phả của ông Nguyễn Đức Đôn có ghi chép lại: "Để
          tránh sự truy lùng của giặc làm liên lụy đến người thân, từ đời 14 trở về sau họ
          Nguyễn Thế đổi tên đệm thành họ Nguyễn Đức, cụ Nguyễn Đức Tứ, có tên từ
          khi đó".`}
        </p>
        <p>
          {`Một trong những nguyên nhân chính đẩy cụ Nguyễn Thế Tứ đổi tên lót
            nhanh chóng là do quan hệ với mự Nguyễn Thị Mai (chú Nguyễn Thế Dụ và mự
            Nguyễn Thị Mai), mối quan hệ này sẽ đẩy nhà Nguyễn nhanh chóng đi đến trả
            thù. Việc đổi tên lót là một sự kiện lớn của dòng họ gắn liền với một giai đoạn
            lịch sử chống ách đô hộ của Đàng Ngoài và Đàng Trong.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Đổi tên lót vì sự thất bại của nghĩa quân Tây Sơn. Quan điểm đổi
        tên lót của phong trào Văn Thân.`}</p>
        <p>
          {`Về thời gian lịch sử đổi tên đệm từ Nguyễn Thế sang Nguyễn Đức thì
            trong quyển Gia phả quyển hai (được sao chép ngày 24/9/1941) có ghi: "họ ta
            năm Tự Đức Giáp Tuất (1874) chuyển đổi tên họ từ Nguyễn Thế sang Nguyễn
            Đức",... "họ ta có cụ Nguyễn Thế Tứ tham gia phong trào Văn Thân chống
            Pháp", bản ghi chép này không chính xác về mặt thời gian. Ta biết chính xác
            năm sinh của cụ Nguyễn Thế Tài là năm 1670, đời thứ 10 (Gia phả ở Đắc Sở đã
            ghi chép rõ như vậy). Đến đời cụ Nguyễn Thế Tứ thuộc đời thứ 14, sinh sau cụ Nguyễn Thế Tài bốn đời hơn nữa phong trào Văn Thân chống Pháp ("Văn" có
            nghĩa là "chữ" chỉ những người có học thức, "Thân" nghĩa là dải thắt lưng bằng
            lụa của các viên chức nhỏ thời xưa) xuất phát từ năm 1864, như vậy cụ Nguyễn
            Đức Tứ sinh trước khi phong trào Văn Thân ra đời.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Đổi tên lót, bỏ nơi ở cũ để tránh sự trả thù của nhà Nguyễn.`}</p>
        <p>
          {`Cụ Nguyễn Thế Tứ đổi sang Nguyễn Đức Tứ, một dạng thay tên đổi họ
          để tránh sự truy lùng của nhà Nguyễn. Cụ Tứ là tộc trưởng nên cả họ đã đồng
          loạt đổi từ Nguyễn Thế sang Nguyễn Đức từ đời thứ 15.`}
        </p>
        <p>
          {`Các thế hệ con, cháu của cụ Nguyễn Thế Tài sống quây quần ở Giáp
          Thuần Hậu. Đến đời cụ Tứ mới có sự phân ly.`}
        </p>
        <p>
          {`Sau sự việc đổi tên đệm, họ Nguyễn Đức bị rúng động bởi tình hình chính
          trị bị xáo trộn, phức tạp, họ bắt đầu bàn việc di chuyển nơi ở. Để bảo toàn dòng
          họ trước sự trả thù tàn bạo của vua Gia Long, Minh Mạng, họ Nguyễn Thế đổi
          sang họ Nguyễn Đức và phân tán nơi ở đến các làng xã khác ở Yên Thành và có
          thể ở Thanh Chương, Đô Lương, Nghi Lộc. Một bộ phận lớn của họ Nguyễn
          Đức vẫn trụ lại ở Giáp Thuần Hậu (sau này xã Kim Thành) sinh cơ lập nghiệp
          và phát triển cả về số lượng và vị thế trong xã hội cho đến ngày nay.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Vài nét về địa lý và đồi sống của cư dân họ Nguyễn Đức tại Giáp
        Thuần Hậu.`}</p>
        <p>
          {`Đầu thập niên 60 của thế kỷ 20, những cư dân cuối cùng của họ Nguyễn
          Đức ở Đồng Thành (gia đình ông Nguyễn Đức Khang - tộc trưởng) mới chuyển
          về sống đoàn tụ với các Chi khác của họ Nguyễn Đức tại xã Bắc Thành. Những
          nương, rẫy, ruộng đồng mà các thế hệ từ ông Nguyễn Thế Tài đến đời ông
          Nguyễn Đức Khang khai khẩn tại Đồng Thành được hiến lại cho chính quyền xã
          Đồng Thành để chia cho những gia đình không có ruộng đất canh tác.
          `}
        </p>
        <p>
          {`Chấp nhận rời bỏ đồng ruộng ở Khe Am (giáp với vùng Đồng Thành hiện
          nay) để đi vào Lèn Đồng Cò, nơi có rừng rú âm u, rậm rạp để tránh sự trả thù
          của vua quan nhà Nguyễn. Một bộ phận của họ Nguyễn Đức sống ở Lèn Đồng
          Cò (chủ yếu là con cháu cụ Nguyễn Thế Trì, cụ Nguyễn Thế Truyền) cho đến đời thứ 18 (đời ông Nguyễn Đức Khang - con trưởng) mới trở về nơi ở cũ để
          sinh sống.
          `}
        </p>
        <p>
          {`Vùng đất Giáp Thuần Hậu, huyện Đông Thành, phủ Diễn Châu là vùng
          thuần nông, dưới thời Trần, đơn vị hành chính cấp cơ sở nơi đây gọi là Kẻ Rộc,
          tiếng địa phương gọi là Kẻ Rục.
          `}
        </p>
        <p>
          {`Vùng đất Kẻ Rộc, một nhánh trên đường thiên lý Bắc - Nam của Tổ quốc
            nay có đường tỉnh lộ 7B (trước là đường 538) chạy qua huyện Yên Thành từ
            Tây Nam đến Đông Bắc. Có sông Đào chảy từ ba ra Đô Lương về Quỳnh Lưu
            chia xã làm ba vùng. Phía trên sông là vùng cao; vùng dưới sông và đường tỉnh
            lộ là vùng mưng; vùng dưới đường tỉnh lộ là vùng sâu.
            `}
        </p>
        <p>
          {`Dưới thời vua Lê Thánh Tông, năm Quang Thuận thứ 7 (năm 1466),
          huyện Đông Thành ra đời, thì Kẻ Rộc đổi thành xã Tiền Thành. Đến thời Nguyễn thì dân cư xã Tiền Thành phát triển đông đúc, trở thành một miền quê
          trù phú. Trong đợt cải cách hành chính năm 1831 của vua Minh Mạng, xã Tiền
          Thành đổi thành xã Kim Thành, địa phận rất rộng (chủ yếu mấy làng mà dân cư
          tập trung ở phía Tây đường tỉnh lộ 7B (538). Xã Kim Thành gồm năm làng:
          Thuần Hậu, Thượng Thọ, Thái Bình, Phúc Thành, Cự Phú trong đó làng Thuần
          Hậu có số dân đông nhất, tiềm năng kinh tế cũng mạnh mẽ dồi dào hơn.
          `}
        </p>
        <p>
          {`Sau Cách mạng Tháng Tám, khoảng năm 1946 làng Phúc Thành nhập vào
          làng Thuần Hậu và lấy tên là xã Tân Thành. Khoảng năm 1947 làng Tiên Xá
          nhập vào xã Tân Thành lấy tên là xã Minh Tân. Khoảng năm 1948 xã Đồng
          Thành nhập vào xã Minh Tân lấy tên là xã Quan Thành. Khoảng năm 1955, sau
          giảm tô xã Quan Thành được chia tách thành ba xã mới là xã Bắc Thành, xã
          Trung Thành và xã Nam Thành.`}
        </p>
        <p>
          {`Yên Thành, cơ sở chủ yếu là nền nông nghiệp trồng lúa nước, các Công
          xã nông thôn xuất hiện với những Kẻ Sùng, Kẻ Sàng, Kẻ Dền, Kẻ Rục, Kẻ Sấu,
          Kẻ Rọc, Kẻ Găng, Kẻ Gám, Kẻ Mõ, Kẻ Hòe, Kẻ Vĩnh, Kẻ Mưng, Kẻ Năng,...
          Con người sống trong cộng đồng làng xã với những quan hệ họ hàng, tôn tộc,
          xóm làng với những sinh hoạt văn hóa mang màu sắc của cư dân nông nghiệp
          trồng lúa nước.
          `}
        </p>
        <p>
          {`Trong suốt chiều dài lịch sử của huyện Yên Thành, thiên nhiên và con
          người luôn gắn bó với nhau, nhà yêu nước Nguyễn Xuân Ôn đã viết:`}
        </p>
        <p className=' text-center'>
          <span>"Non nước Hoan Châu đẹp tuyệt vời</span> <br /> <span className='pl-4'>Sinh ra trung nghĩa biết bao người"</span>
        </p>
        <p>
          {`Cư dân Yên Thành xưa không đông lắm, chỉ đến khi công cuộc chuyển
            dân từ ngoài Bắc vào khai dân lập làng, cư dân mới tăng nhanh. Thời tiền Lê có
            Đông Thành Vương Lê Long Ngân; thời Lý có Uy Minh Vương Lý Nhật Quang
            đã đưa từng tốp nông dân, từng dòng họ cùng binh lính từ phía Bắc vào đây khai
            hoang mở thêm nhiều Kẻ, Chạ.`}
        </p>
        <p>
          {`Tộc phả của nhiều dòng họ và lịch sử các làng xã ở Yên Thành đã ghi lại
          nhiều đức Triệu Cơ đã có công khai canh, chiêu dân, lập ấp. Nghề nông trở
          thành nguồn sống chủ yếu, một số giống lúa quí như lúa Hẻo, lúa Chăm, lúa Dự
          hương, nếp Rồng ở Yên Thành thơm ngon nổi tiếng. Bên cạnh các làng, các chợ
          xuất hiện: Chợ Dinh, Chợ Bộng, Chợ Rộc, Chợ An, Chợ Mõ, Chợ Láng, Chợ
          Ong, Chợ Vẹo, Chợ Kê, Chợ Vịnh, Chợ Sàng, Chợ Cuồi, Chợ Gám và nhiều
          chợ khác.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Xây dựng Đình Thuần Hậu.`}</p>
        <p>
          {`Cụ Nguyễn Thế Tài cùng vợ con di dời đến Giáp Thuần Hậu, huyện Đông
          Thành khai khẩn đất hoang, dựng trại, lập ấp phát triển kinh tế, sinh đẻ thêm
          nhiều con cháu, xây dựng cơ sở vững chắc tại vùng đất xã Tiền Thành. Trong
          một thời gian dài, Cụ cùng con cháu họ Nguyễn Đức đã góp nhiều công sức cho
          sự phát triển của xã Tiền Thành, đến khi xã Tiền Thành đổi thành xã Kim Thành
          (1831) thì họ Nguyễn Đức đã trở thành một trong những họ lớn ở địa phương.
          Căn cứ vào Gia phả của hai họ Nguyễn Đức và Nguyễn Duy vào thời điểm 1840
          đều nói đến công sức con cháu hai họ đã tham gia đóng góp cùng dân làng
          Thuần Hậu trong việc xây dựng Đình Thuần Hậu (được xây dựng năm 1840).`}
        </p>
        <p>
          {`Xã Kim Thành gồm 5 làng: Thuần Hậu,Thượng Thọ, Thái Bình, Phúc
          Thành, Cự Phú, trong đó làng Thuần Hậu có số dân đông nhất, tiềm năng kinh tế
          cũng mạnh nhất, dồi dào hơn. Làng Thuần Hậu ra đời được 10 năm (1831 -
          1840) thì Đình Thuần Hậu được dựng lên.`}
        </p>
        <p>
          {`Căn cứ vào gia phả họ Nguyễn Đức và họ Nguyễn Duy cùng với ký ức
          sâu thẳm của các cụ cao tuổi trong làng thì Đình Thuần Hậu được xây dựng dưới sự chỉ đạo của cụ Nguyễn Duy Phủ và 4 người ở 4 giáp là cụ Chắt Giảng ở
          giáp Nhất, cụ Dự ở giáp Nhị, cụ Uynh ở giáp Tam, cụ Cựu Huân ở giáp Tứ.
          Đình Thuần Hậu12 được dựng trên sườn phía nam một ngọn đồi gọi là
          Cồn Thung, tên chữ là Thung Lĩnh Sơn. Thung Lĩnh Sơn là nơi dừng lại của một
          dãy núi chạy từ thượng ngàn về theo hướng Tây Bắc - Đông Nam (hướng Càn
          Hợi) qua dãy Động Xanh, Động Cồn Găng, Đồi Mã Sại, Động Làng Nồi, Đồi
          Cồn Thung. Thung Lĩnh Sơn là khu rừng cây cổ thụ như nguyên sinh, rộng đến
          mấy chục mẫu.`}
        </p>
        <p>
          {`Mặt đình hướng Nam, chếch Tây (hướng Quý Đinh).`}
        </p>
        <p>
          {`Có kiểu kiến trúc mặt bằng không theo kiểu "chuôi vồ" hoặc hình chữ Nhị
          mà bố trí theo kiểu chữ Khẩu, nghĩa là phía trước có bái đình, phía sau có hậu
          cung, hai bên là nhà tả vu, hữu vu, ở giữa là khoảng sân trung thiên.`}
        </p>
        <p>
          {`Chất liệu để cấu tạo nên Đình Thuần Hậu chủ yếu là gỗ lim, gỗ chò do
          dân làng tự khai thác từ những cánh rừng đại ngàn có tên là Hố Chò, ở Khe
          Mài cách làng về phía tây hơn 10 km.`}
        </p>
        <p>
          {`Đình Thuần Hậu được công nhận xếp hạng di tích kiến trúc nghệ thuật
          cấp Quốc gia năm 2002, là một quần thể kiến trúc được bố trí mặt bằng rất độc
          đáo, không giống các đình làng ở Bắc Bộ và cả Xứ Nghệ.`}
        </p>
        <p>
          {`Sự đóng góp của các dòng họ trong đó có họ Nguyễn Đức là rất to lớn.`}
        </p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Phong trào Việt Minh và sự thành lập Đảng Cộng sản Đông Dương.`}</p>
        <p>
          {`Từ khi cụ Nguyễn Thế Tài (đời thứ 10) di dời vào xứ Giáp Thuần Hậu,
          huyện Đông Thành (sau này là Tiền Thành, Kim Thành) năm Canh Dần (1710)
          đến mùa xuân năm Canh Dần (năm 2010), thế thứ kéo dài 300 năm (khi soạn
          thảo Nguyễn Đức tộc phả lần hai đã là năm 2016) đã có sự phát triển kỳ diệu:
          dòng họ đã phát triển được nhiều đời. Trong số những con cháu của dòng họ có
          nhiều người đã trở thành lãnh đạo cao cấp của Đảng, Chính phủ, nhiều người là
          liệt sĩ đã hy sinh trong hai cuộc kháng chiến chống Pháp, chống Mỹ được Nhà
          nước ghi công, được nhân dân kính trọng.`}
        </p>
        <p>
          {` Phong trào Tân Việt trước năm 1930, còn gọi là phong trào Phan Đăng
          Lưu, sục sôi khí thế cách mạng, nhiều cuộc biểu tình, tuần hành nổ ra kéo về huyện đòi lật đổ chế độ thực dân phong kiến. Tri huyện Yên Thành đã đàn áp dã
          man phong trào cách mạng, nhiều người đã bị bắt, bị tù đày và bị bắn giết.`}
        </p>
        <p>
          {`Từ những năm 1940 trở về sau, phong trào Việt Minh lại được nhen nhóm
          ở xã Kim Thành. Một số trí thức yêu nước đã bí mật hoạt động, gây dựng cơ sở
          cách mạng tiêu biểu là ông Nguyễn Đức Thiệng, Bí thư huyện Ủy, Bí thư Chi bộ
          xã, Chủ nhiệm Việt Minh xã.`}
        </p>
        <p>Đêm 22-8-1945, Chi bộ Đảng Cộng sản Đông Dương xã Kim Thành được thành lập tại nhà thờ họ Nguyễn Đức (nay thuộc xóm 3, xã Bắc Thành) gồm có ba đảng viên là: ông Nguyễn Đức Thiệng, ông Trần Nguyên Trinh, ông Nguyễn Doãn Cẩm. Sau Đại hội này ông Phan Thế Nghiện đã được kết nạp vào Đảng. Ông Nguyễn Đức Thiệng giữ cương vị Bí thư Chi bộ đến hết năm 1946 (ông lấy bí danh là Minh Đức).</p>

        <p>Đến năm 1948 khi sát nhập xã Đồng Thành vào xã Minh Tân thì lấy tên là xã Quan Thành, Chi bộ vẫn lấy tên là Chi bộ Hồ Mỹ Xuyên (khi sát nhập, Chi bộ xã Đồng Thành có tên là Chi bộ Trần Phú).</p>

        <p>Sau năm 1945, khí thế cách mạng đi lên đã cuốn hút nhiều người con của Yên Thành tham gia cách mạng, trong đó có những người con ưu tú của dòng họ Nguyễn Đức ta. Đó là ông Nguyễn Đức Thiệng (1922-1998), một trong những người thành lập Chi bộ Đảng Cộng sản đầu tiên của xã Kim Thành, làm Bí thư huyện Ủy Yên Thành, tỉnh Ủy viên Nghệ An rồi ra Trung ương được Nhà nước cử đi làm Đại sứ Đặc mệnh toàn quyền của nước Việt Nam Dân chủ Cộng hòa ở nhiều nước trên thế giới, và sau này làm Phó chủ tịch Ủy ban tố cáo tội ác chiến tranh Việt Nam. Đó là ông Nguyễn Đức Dung (1923-1994), tham gia cách mạng sau năm 1945, làm cán bộ ở tỉnh rồi lênTrung ương làm Phó vụ trưởng Ban Tổ chức Trung ương Đảng Cộng sản Việt Nam. Ông Nguyễn Đức Thăng (1926-1994) vào Đảng năm 1946, là Bí thư Đoàn Thanh niên và Xã đội trưởng đầu tiên của xã Quan Thành, đưa đoàn dân công của xã đi Lào, làm Trưởng Khoa Quản lý kinh tế trường Đảng Trần Phú tỉnh Nghệ Tĩnh.</p>

        <p>Trong chín năm kháng chiến chống Pháp, lớp lớp con cháu họ Nguyễn Đức hăng hái tham gia chính quyền cách mạng non trẻ, tòng quân, đi dân công chiến đấu phục vụ chiến đấu góp phần làm nên chiến thắng Điện Biên Phủ lẫy lừng năm châu, đó là các ông Nguyễn Đức Đào, Nguyễn Đức Kham, Nguyễn Đức Thị (liệt sĩ thời kỳ chống Pháp), Nguyễn Đức Bốn, Nguyễn Đức Trung (bộ đội chống Pháp)...</p>

        <p>Sau hòa bình lập lại 1955 cho đến nay con cháu họ Nguyễn Đức vẫn tiếp nối truyền thống của các bậc tiền nhân đóng góp nhiều bông hoa đẹp trong rừng hoa của dân tộc, nhiều người đã hiến dâng máu xương cho sự nghiệp giải phóng thống nhất đất nước; có người tham gia trong bộ máy chính quyền, đoàn thể từ xã đến Trung ương; nhiều người đỗ đạt với học vị cao là Giáo sư, Phó Giáo sư, là Tiến sĩ, phó Tiến sĩ, Cử nhân...</p>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Sự đoàn kết và hợp kỵ của ba nhà thờ họ Nguyễn Đức.`}</p>
        <p>Nhà thờ họ Nguyễn Đức được xây dựng trên nền đất của tổ tiên từ đời cụ Nguyễn Thế Hạnh, do ông Nguyễn Đức Tân làm từ đường. Do điều kiện lúc đó chưa thể xây nhà thờ Tộc trưởng nên một số Chi, Phái có xây nơi thờ cúng riêng, khi nhà thờ này được xây dựng to đẹp và khang trang thì họ đã lập thành nhà thờ họ Nguyễn Đức. Vào những năm 1970-1980, đáp ứng nguyện vọng của các gia đình thờ cúng tổ tiên, các cụ trong họ quyết định chuyển nhà thờ họ lên Vệ Sim, Chọ Bùi, nhà thờ được chuyển đặt ở vị trí Thượng điện. Đồng thời hợp tế thêm nhà thờ của nhánh ông Nguyễn Đức Lương (đời 17, Chi 4), ở xóm Nam, làng Kim Thành do ông Nguyễn Đức Em (đời 18) làm từ đường lên vị trí Trung điện. Con cháu cụ Nguyễn Thế Cán là các ông Nguyễn Đức Truyền (đời 17), ông Nguyễn Đức Hiến (đời 17), ông Nguyễn Đức Thành (đời 17) cùng đồng lòng đưa nhà thờ của Phái ở xóm Rộc, làng Phúc Thành, xã Trung Thành lên Hạ điện, hợp thành nhà thờ Đại Tôn họ Nguyễn Đức ở Yên Thành khang trang và đẹp đẽ.</p>

        <p>Sau khi làm xong nhà thờ, họ tộc đã cử ông Nguyễn Đức Thụ làm Từ đường trông coi, ông đã trồng được nhiều cây ăn quả như nhãn, bưởi, chuối, cau... tạo quang cảnh nhà thờ thêm xanh đẹp.</p>

        <p>Rằm tháng Giêng (15-1) âm lịch năm 2003 nhà thờ họ được sửa chữa lớn nhờ sự nhiệt tình của các con cháu, trở nên khang trang, vững chãi hơn.</p>

        <p>Năm 2006, đã dựng Văn Bia họ Nguyễn Đức trước sân nhà thờ họ, mở rộng và lát nền sân nhà thờ, làm mái che lâu dài bằng tôn, con cháu đã đóng góp được nhiều ghế đá để ngồi dự giỗ, làm cổng tạo cho khu vực nhà thờ khang trang đẹp đẽ.</p>

        <p>Ngày 10-3-2009, đã xây Lăng Mộ Tổ, Băng thờ có lư hương to đẹp để con cháu thắp hương, xây cổng vào nghĩa trang và khu vực để xe, đặt cống thoát nước, vét mương và làm đường lên Mộ Tổ.</p>

        <p>Năm 2015, làm đường đổ bê tông từ đường cái lên nhà thờ, có bãi đỗ xe thuận lợi.</p>
        <div className='flex items-center justify-center gap-[30px]'>
            <div className='flex-1'>
              <img src={Img7} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img8} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img9} alt="TopImg" className='w-full h-auto'/>
            </div>
            <div className='flex-1'>
              <img src={Img10} alt="TopImg" className='w-full h-auto'/>
            </div>
        </div>
        <p className='text-[16px] text-[#461511] font-bold indent-0'>{`Vài lời về việc ban thảo soạn Gia phả họ Nguyễn Đức lần thứ hai.`}</p>
        <p>Việc biên tập, soạn thảo Tộc Phả dù con cháu đã rất cố gắng nhưng chưa thật sự được đầy đủ, chính xác. Làm công việc này là chúng ta tiến hành khảo cứu lịch sử của dòng họ để hiểu biết, ghi nhớ công đức của tổ tiên, để chúng ta tự hào về sự nghiệp của ông cha, vinh dự được làm con cháu họ Nguyễn Đức và cố gắng học tập, làm việc góp phần làm rạng rỡ truyền thống của họ ta.</p>

        <p>Đó là sự cao truyền, kế tục để không thẹn với công đức sáng ngời của tổ tiên dòng họ. Bởi vậy cho dù tài năng kém cỏi, trình độ có hạn, tư liệu lại thiếu nhưng xuất phát từ cái "tâm" chúng tôi cố gắng sưu tầm, ghi chép, biên soạn lại lịch sử phát triển của dòng họ, với mục đích khi anh em gặp nhau trong ngày giỗ Tổ hoặc nghĩ về cội nguồn ta cũng biết được Chi mình thế thứ ra sao, mình ở ngôi vị nào?</p>

        <p>Vì thiếu tư liệu để lại, chỉ có một số tư liệu ít ỏi, việc truyền khẩu cũng ngày càng rơi rớt do các cụ già yếu mất đi, do công việc làm ăn khẩn trương và bận rộn và do sự lãng quên một phần, cho nên biết gì ghi nấy, nghe gì thì ghi lại tuy nhiên chỉ được một phần rất nhỏ. Chúng tôi nghĩ dù còn thiếu cũng phải tiến hành ghi chép, vì để lâu hơn sợ còn quên hoặc rơi rớt thêm nữa, sẽ có tội với gia tộc, tổ tiên.</p>

        <p>Mong rằng con cháu trong họ ai biết gì thêm về sự tích và công lao của tổ tiên họ Nguyễn Đức, còn lưu giữ tư liệu hoặc sưu tầm được tài liệu nói về họ Nguyễn Đức (hoặc Nguyễn Thế) thì bổ sung đóng góp. Anh chị em qua các thế hệ sau này, với tinh thần uống nước nhớ nguồn, quan tâm hơn nữa viết tiếp gia phả, sưu tầm và giữ gìn những tài liệu lịch sử, ghi chép những mẩu chuyện về hành trạng, sự tích giai thoại thú vị mà sau đây ta còn được mấy lần nghe kể, cũng toàn là chuyện truyền ngôn, chả biết có thật hay không. Nhưng dân gian thì nói thế, tin thế, chẳng biết đó là chuyện văn chương hay chuyện ngoài đời. Hơn thế kỷ nay và chắc còn xa hơn nhiều thế, người ta vẫn truyền khẩu như thế, ghi chép thành sách như thế, kẻ hậu sinh chỉ làm việc sao lục, không dám thêm bớt.</p>

        <p>Gia phả phong phú đầy đủ, không phải chủ yếu do người biên tập, người biên tập chỉ có công tập hợp, ghi chép theo trật tự thời gian những người thật việc thật như đã có của dòng họ, không thể chủ quan thêu dệt thêm bớt. Noi gương người xưa, biết tới đâu chép tới đó, cứ tiếp nối mãi lâu dần con cháu chúng ta sẽ có một cuốn gia phả đầy đủ.</p>

        <p>Đọc lịch sử đất nước để biết tiền nhân đoàn kết nhân dân, mở mang sông núi trở nên Tổ quốc hôm nay. Đọc Gia phả để biết tổ tông cần kiệm, siêng năng gây dựng nên cơ nghiệp để lại cho cháu con hậu thế.</p>

        <p>Họ tộc đã truyền nối nhiều đời, khói mờ nhân ảnh, núi cũ mây che, lòng tưởng nhớ quê hương (ý nói Sơn Tây). Còn trong chiến tranh (ý nói cuộc khởi nghĩa của Nguyễn Huệ) hoặc là đường sá xa xôi mịt mù nhà cũ là đâu? lại vì thời loạn lạc (ý nói giai đoạn Gia Long tàn sát, trả thù) nên trong họ chẳng còn mấy người tỏ tường Gia phả. Nay được xem lại sáng tỏ được những điều ngờ vực, sưu tầm được những sự tích ngày xưa.</p>

        <p>Tinh thần của tổ tiên như còn trước mắt, trong lòng con cháu luôn ấp ủ sao cho Dòng họ Nguyễn Đức được phát triển bền vững lâu dài. Nhà nhà cháu con hiếu thảo, cùng với non sông muôn thuở, mọi người thành đạt trong nghề nghiệp, tạo nên truyền thống của họ tộc (Nguyễn Đức) kế tiếp đời này sang đời khác phát triển giống như Xuân Hạ Thu Đông.</p>

        <p>Dạy con cháu vừa cày ruộng lại vừa đọc sách, giữ lấy trung hậu đức độ làm nếp nhà. Lời dạy ấy đến nay vẫn như tiếng chuông vang lên lay động lòng con cháu.</p>

        <p>Họ tộc được chấn chỉnh, con cháu sẽ được hưởng phúc lộc (cho mà xem). Việc thờ cúng phải được cẩn thận và bày vẽ cho con cháu các thế hệ sau cũng vậy.</p>

        <p>Tổ tông là đèn nhà soi rọi, là phật của nhà ta. Tổ tông là bảo vật của ta.</p>

        <p>Người đọc Gia phả sẽ hình dung lại hình bóng tổ tiên khi còn sống, nghiền ngẫm Gia phả con cháu sẽ có cái tâm tốt đẹp mà để tiếng cho đời.</p>

        <p>Phàm là con cháu (họ Nguyễn Đức), làm người hôm nay phải nhiệt tình ghi chép bổ sung Gia phả. Xem, đọc ghi chép và phổ biến để Gia phả là của quý trong nhà. Ta suy ngẫm:</p>

        <p style={{textAlign: 'center'}}>“Mây lành soi đức tổ,<br/>Trung hiếu sánh vầng dương.<br/>Tâm, Đức tổ để lại,<br/>Trường tồn nơi cháu con.”</p>

        <p>Cuốn "Nguyễn Đức Tộc Phả" được hình thành với ước vọng giúp cho con cháu họ ta có được một cuốn Tộc phả đầy đủ tư liệu có thể dùng để tra cứu, sao chụp lại, để biết vị trí của mình đứng ở đâu trong phả đồ...</p>

        <p>"Nguyễn Đức Tộc Phả" có thể còn nhiều điểm chưa chính xác, rõ ràng, chữ nghĩa, tên húy, ngày mất, tên tuổi cụ bà... còn thiếu, chưa được hoàn hảo, mong con cháu đời sau tinh tường cải sửa.</p>

        <p>Tài liệu có hạn, thời gian không nhiều tất sẽ không tránh khỏi sai sót đáng tiếc trong soạn thảo, chúng tôi rất mong được mọi người trong họ ta nguyên lượng.</p>

        <p>Như lẽ tự nhiên, cái nguồn có sâu thì dòng mới xa, cái gốc có vững thì cành lá hoa quả mới sum suê, họ Nguyễn Đức ta đời đời kế tục trải qua hơn ba trăm năm cho dù cũng lắm bể dâu mà bụi trần chẳng vấy.</p>

        <p>Một lần nữa, nhắc nhở con cháu họ Nguyễn Đức phải lấy việc học hành, lao động chăm chỉ làm nét đẹp truyền gia, lấy đức hiếu thảo, thuận hòa làm đầu cho mọi mục tiêu, ngày càng đôn hậu để không thẹn với các bậc tiền nhân.</p>
        {auth?.user &&
          <Fragment>
            <p className='text-[16px] text-[#461511] font-bold indent-0 !mt-5'>{`Tài liệu gia phả`}</p>
            <a 
            href={GiaPhaFile} 
            target='_blank' 
            className='indent-0 flex items-center gap-2 cursor-pointer'
            download={true}
            rel='noreferrer'
            >
              <div className='text-[#0013F8] font-medium'>Nguyễn Đức Phả Tộc</div>
              <img src={Dl_Icon} alt="Dl_Icon" className='w-6 h-6'/>
            </a>
          </Fragment>
        }
      </div>
    </div>
  )
}

export default HistoryPage