import { useAuth } from 'core/contexts/auth/AuthContext';
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

const MenuSearchBar = () => {
    const navigate = useNavigate();
    const {user} = useAuth();
    const [keyword, setKeyword] = useState<string>("");

    const handleInputChange = (event: any) => {
        setKeyword(event.target.value);
    };

    const handleKeyDown = (event: any) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            if(!keyword) return;
            navigate(`/search?keyword=${keyword}`);
        }
    };

    const handleClickSearchBtn = () => {
        if(!keyword) return;
        navigate(`/search?keyword=${keyword}`);
    }

  return (
    <div className={`${user ? 'w-[200px]' : 'w-[300px]'}`}>
        <div className="relative flex w-full flex-wrap items-stretch">
            <input
                type="search"
                className="relative m-0 block flex-auto rounded-lg border border-solid 
                border-[#E7AE48] bg-transparent bg-clip-padding px-3 py-[3px]
                text-[12px] font-normal text-[#E7AE48] outline-none 
                transition duration-200 ease-in-out focus:outline-none" 
                onChange={handleInputChange}
                value={keyword}
                onKeyDown={handleKeyDown}
            />

            {/* <!--Search icon--> */}
            <span
                className=" absolute top-0 right-0 flex items-center whitespace-nowrap
                rounded px-3 py-1.5 text-center text-[12px] font-normal text-[#E7AE48] cursor-pointer"
                onClick={() => handleClickSearchBtn()}
                id="basic-addon2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="h-4 w-4">
                    <path
                        fillRule="evenodd"
                        d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                        clipRule="evenodd" />
                </svg>
            </span>
        </div>
    </div>
  );
}

export default MenuSearchBar