import React from 'react'

const LogSide = ({fillColor} : {fillColor?: string}) => {
  return (
    <>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9551 21.3008C10.6551 21.3008 8.65091 20.5383 6.94258 19.0133C5.23424 17.4883 4.25508 15.5841 4.00508 13.3008H6.05508C6.28841 15.0341 7.05924 16.4674 8.36758 17.6008C9.67591 18.7341 11.2051 19.3008 12.9551 19.3008C14.9051 19.3008 16.5592 18.6216 17.9176 17.2633C19.2759 15.9049 19.9551 14.2508 19.9551 12.3008C19.9551 10.3508 19.2759 8.69661 17.9176 7.33828C16.5592 5.97995 14.9051 5.30078 12.9551 5.30078C11.8051 5.30078 10.7301 5.56745 9.73008 6.10078C8.73008 6.63411 7.88841 7.36745 7.20508 8.30078H9.95508V10.3008H3.95508V4.30078H5.95508V6.65078C6.80508 5.58411 7.84258 4.75911 9.06758 4.17578C10.2926 3.59245 11.5884 3.30078 12.9551 3.30078C14.2051 3.30078 15.3759 3.53828 16.4676 4.01328C17.5592 4.48828 18.5092 5.12995 19.3176 5.93828C20.1259 6.74661 20.7676 7.69661 21.2426 8.78828C21.7176 9.87995 21.9551 11.0508 21.9551 12.3008C21.9551 13.5508 21.7176 14.7216 21.2426 15.8133C20.7676 16.9049 20.1259 17.8549 19.3176 18.6633C18.5092 19.4716 17.5592 20.1133 16.4676 20.5883C15.3759 21.0633 14.2051 21.3008 12.9551 21.3008ZM15.7551 16.5008L11.9551 12.7008V7.30078H13.9551V11.9008L17.1551 15.1008L15.7551 16.5008Z" fill={fillColor}/>
        </svg>
    </>
  )
}

export default LogSide