// export const HOST_URL = import.meta.env.VITE_API_ENDPOINT;
export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const AUTH = {
  LOGIN: `v1/auth/login`,
  REGISTER: `v1/auth/register`,
  CHANGE_PASSWORD: `v1/auth/password`,
  FORGOT_PASSWORD: `v1/auth/password/forgot`,
  RESET_PASSWORD: `v1/auth/password`,
  LOGIN_i_PASSWORD: `v1/auth/login/i-password`
};

export const USER = {
  LIST: `v1/bo/user`,
  DETAIL_BY_ID: (id: string) => `v1/bo/user/${id}`,
  UPDATE_BY_ID: (id: string) => `v1/bo/user/${id}`,
  DELETE_BY_ID: (id: string) => `v1/bo/user/${id}`,
  UPDATE_AVATAR_BY_ID: (id: string) => `v1/bo/user/${id}/avatar`,
  ADD_ROLE: (id: string) => `v1/bo/user/${id}/role`,
  DELETE_ROLE: (id: string) => `v1/bo/user/${id}/role`,
  REVIEW_USER: (id: string) => `v1/bo/user/${id}/review`,
  ADD_RELATIONSHIP: `v1/bo/user/relationship`,
  ADD: `v1/bo/user`,
  UPLOAD_FILE: `v1/bo/user/file`,
  GET_PERSON_CODE: `v1/bo/user/person-code`,
  LIST_ADMIN: `v1/bo/user/admins`,
  SEED_DATA: `v1/seeddata`,
  SEED_DATA_VALIDATE: `v1/seeddata/validate`,
  GET_EXCEL_DAFAULT: `v1/seeddata`
};

export const ADMIN = {
  LIST: `v1/bo/admin`,
  DETAIL_BY_ID: (id: string) => `v1/bo/admin/${id}`,
  UPDATE_BY_ID: (id: string) => `v1/bo/admin/${id}`,
  CREATE_ADMIN: () => `v1/bo/admin`,
  UPDATE_I_PASSWORD: `v1/bo/admin/i-password`,
  GET_I_PASSWORD: `v1/bo/admin/i-password`
};



export const GENEALOGY = {
  ARTICLE: "v1/genealogy/article",
  CREATE_ARTICLE: "v1/genealogy/article",
  TREE: `v1/genealogy/tree`,
  ORGANIZATION: "v1/genealogy/tree/organization",
  PERSON: `v1/genealogy/tree/person`,
  GENERATION: `v1/genealogy/tree/generation`,
  EVENTS: `v1/genealogy/event`,
  EVENT_DETAIL_BY_ID: (id: string) => `v1/genealogy/event/${id}`,
  EVENTS_UPCOMING: `v1/genealogy/event/upcoming`,
  SEARCH_USERS: `v1/genealogy/user/search`,
};

export const ALBUMS = {
  ALBUMPAGING: `v1/media/album`,
  ALBUMS: "v1/media/album",
  CREATE_ALBUM: "v1/media/album",
  UPLOAD_IMAGES: (idAlbums: string) => `v1/media/album/${idAlbums}/image`,
  IMAGES_FROM_ALBUM: (idAlbums: string) => `v1/media/album/${idAlbums}`,
  CHANGE_IMAGE_NAME: (idAlbums: string) => `v1/media/album/image/${idAlbums}`,
};

export const LOG = {
  LIST: `v1/log`,
  LIST_ACTIVITY: `v1/log/activity`,
};

export const PROVINCES = {
  PROVINCES: `v1/provinces`,
  DISTRICT: (code: string) => `v1/provinces/${code}/districts`,
  WARD: (code: string) => `v1/provinces/districts/${code}/wards`,
};


export const ORGANIZATION = {
  LIST: `v1/organization`,
  ADD: `v1/organization`,
  UPDATE_BY_ID: `v1/organization`,
  DELETE_BY_ID: (id: string) => `v1/organization/${id}`,
}

export const GENERATION = {
  LIST: `v1/generation`,
  ADD: `v1/generation`,
  UPDATE_BY_ID: (id: string) => `v1/generation/${id}`,
  
}

export const EVENTS = {
  ADD_EVENT: `v1/event`,
  GET_EVENTS: `v1/event`,
  ALLEVENT: `v1/event`,
  EVENTDETAIL: (id: string) => `v1/event/${id}`,
  REMOVE_EVENT: (id: string) => `v1/event/${id}`,
  EDIT_EVENT: (id: string) => `v1/event/${id}`,
};

export const MEDIA = {
  POST_MEDIA_IMAGE_FOLDER: (folder: string) => `v1/media/image/${folder}`,
  DELETE_MEDIA_IMAGE: `v1/media/image`
};

export const ORG = {
  GET_ORGANIZATION: `v1/organization`,
};

export const PERSON = {
  PROFILE: `v1/person/profile`
}

export const NOTIFICATION = {
  LIST: `v1/notification`,
  READ: `v1/notification/read`,
  READ_BY_ID: (id: string) => `v1/notification/${id}/read`,
  SHOW_ALERT: (id: string) => `v1/notification/${id}/alert`,
}
