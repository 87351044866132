import {AUTH, HOST_URL, PERSON} from "core/constant/api.contant";
import {AuthLoginType, ForgotPasswordType, ResetPasswordType} from "core/types/auth.type";
import axiosInstance from "utilities/axios-instance";
import axios from "axios";

const axiosNoAuth = axios.create({baseURL: HOST_URL});

const login = (user: AuthLoginType) => {
    return axiosInstance
        .post(AUTH.LOGIN, user)
        .then((response) => {
            return response.data;
        });
};

const loginIPass = (deviceId: string, password: string) => {
    return axiosInstance
        .post(AUTH.LOGIN_i_PASSWORD, {
            deviceId,
            password
        })
        .then((response) => {
            return response.data;
        });
};


const forgotPassword = (user: ForgotPasswordType) => {
    return axiosNoAuth
        .post(AUTH.FORGOT_PASSWORD, user)
        .then((response) => {
            return response.data;
        });
};

const resetPassword = (user: ResetPasswordType) => {
    return axiosInstance
        .put(AUTH.RESET_PASSWORD, user)
        .then((response) => {
            return response.data;
        });
};

const getPersonProfile = () => {
    return axiosInstance.get(PERSON.PROFILE);
}

const AuthService = {
    login,
    forgotPassword,
    resetPassword,
    getPersonProfile,
    loginIPass
};

export default AuthService;