import * as React from "react";
import { FunctionComponent } from "react";
import Modal from "@mui/material/Modal";
import { Box, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import calendarIcon from "../../../assets/svg/calendar.svg";
import eventEven from "assets/images/template-event-png.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ModalContainer } from "components/modal/ModalContainer";

const style = {
  width: '100%',
  height: 'auto',
  minHeight: '394px',
  bgcolor: "#F9D876",
  borderRadius: 3,
  color: "#461511",
  boxShadow: 24,
  p: 5,
  py: 4,
  "&:after": {
    content: '""',
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "663px",
    height: "100px",
    backgroundImage: "url(./mask.svg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

interface ComponentProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

type Props = ComponentProps;

export const ModalEventDetail: FunctionComponent<Props> = ({
                                                             open,
                                                             onClose,
                                                             data,
                                                           }) => {
  const navigate = useNavigate();
  return (
    <ModalContainer isOpen={open} className={"w-[663px] min-h-[394px] h-auto"} closeModal={onClose}>
        <div className="w-full min-h-[394px] max-h-[80vh] overflow-auto hidden-scrollbar">
          <Box sx={style}>
            <Box
                sx={{
                  height: "100%",
                  fontSize: "15px",
                  fontWeight: 400,
                }}
            >
              <Grid container columns={{ xs: 3 }}>
                <Grid xs={2}>
                  <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
                    {data?.name}
                  </Typography>
                  <Box sx={{ display: "flex", mt: 2 }}>
                    <img src={calendarIcon} alt={"calendar"} />
                    <Typography
                        sx={{
                          pl: 1,
                        }}
                    >
                      {`${moment(data?.fromDate).format("DD/MM/YYYY")} (${moment(data?.fromLunarDate).format("DD/MM/YYYY")} Âm lịch)`}
                    </Typography>
                  </Box>
                  <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pr: 16,
                      }}
                  >
                    <Typography>
                      {data?.organizations?.find(
                          (item: any) => item?.organizationType === "Clan",
                      )?.organizationName || ""}
                    </Typography>
                    <Typography>
                      {data?.organizations?.find(
                          (item: any) => item?.organizationType === "Lineage",
                      )?.organizationName || ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                    xs={1}
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                >
                  <Box
                      sx={{
                        height: "138px",
                        width: "126px",
                      }}
                  >
                    <img src={eventEven} alt="" height={"100%"} width={"100%"} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ height: "100%", fontSize: "14px" }}>
              <Stack>
                <div className="mb-2 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: data?.history }}></div>
              </Stack>
              <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
              >
                {data?.images?.map((item: any) => (
                  <div key={item.id}>
                    <img
                        src={item.imageUrl}
                        alt={'eventImage'}
                        style={{
                          borderRadius: "8px",
                          height: "90px",
                          width: "100px",
                          objectFit: "cover",
                        }}
                    />
                  </div>
                ))}
              </Box>
            </Box>
            {/* <div className="flex items-center justify-center">
              <div className="flex gap-[39px] mt-4">
                <button
                    type="button"
                    className="table-primary-btn w-[132px] h-[42px] "
                    onClick={() => navigate(`/quan-ly/event/${data?.id}`)}
                >
                  Chỉnh sửa
                </button>
                <button
                    type="button"
                    className="table-second-btn w-[132px] h-[42px]"
                    onClick={() => onClose()}
                >
                  Hủy
                </button>
              </div>
            </div> */}
          </Box>
        </div>
    </ModalContainer>
  );
};