import React from 'react'

const AddUserSide = ({fillColor} : {fillColor?: string}) => {
  return (
    <>
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9551 14V11H15.9551V9H18.9551V6H20.9551V9H23.9551V11H20.9551V14H18.9551ZM9.95508 12C8.85508 12 7.91341 11.6083 7.13008 10.825C6.34675 10.0417 5.95508 9.1 5.95508 8C5.95508 6.9 6.34675 5.95833 7.13008 5.175C7.91341 4.39167 8.85508 4 9.95508 4C11.0551 4 11.9967 4.39167 12.7801 5.175C13.5634 5.95833 13.9551 6.9 13.9551 8C13.9551 9.1 13.5634 10.0417 12.7801 10.825C11.9967 11.6083 11.0551 12 9.95508 12ZM1.95508 20V17.2C1.95508 16.6333 2.10091 16.1125 2.39258 15.6375C2.68424 15.1625 3.07174 14.8 3.55508 14.55C4.58841 14.0333 5.63841 13.6458 6.70508 13.3875C7.77174 13.1292 8.85508 13 9.95508 13C11.0551 13 12.1384 13.1292 13.2051 13.3875C14.2717 13.6458 15.3217 14.0333 16.3551 14.55C16.8384 14.8 17.2259 15.1625 17.5176 15.6375C17.8092 16.1125 17.9551 16.6333 17.9551 17.2V20H1.95508ZM3.95508 18H15.9551V17.2C15.9551 17.0167 15.9092 16.85 15.8176 16.7C15.7259 16.55 15.6051 16.4333 15.4551 16.35C14.5551 15.9 13.6467 15.5625 12.7301 15.3375C11.8134 15.1125 10.8884 15 9.95508 15C9.02174 15 8.09675 15.1125 7.18008 15.3375C6.26341 15.5625 5.35508 15.9 4.45508 16.35C4.30508 16.4333 4.18424 16.55 4.09258 16.7C4.00091 16.85 3.95508 17.0167 3.95508 17.2V18ZM9.95508 10C10.5051 10 10.9759 9.80417 11.3676 9.4125C11.7592 9.02083 11.9551 8.55 11.9551 8C11.9551 7.45 11.7592 6.97917 11.3676 6.5875C10.9759 6.19583 10.5051 6 9.95508 6C9.40508 6 8.93425 6.19583 8.54258 6.5875C8.15091 6.97917 7.95508 7.45 7.95508 8C7.95508 8.55 8.15091 9.02083 8.54258 9.4125C8.93425 9.80417 9.40508 10 9.95508 10Z" fill={fillColor}/>
        </svg>
    </>
  )
}

export default AddUserSide