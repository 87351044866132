import React from 'react';
import { Tooltip } from 'react-tooltip';

const LogActivity = ({activity, logId} : {activity: string, logId: string}) => {
  return (
    <>
        <div 
        className={`w-full line-clamp-1 log_${logId}`}
        >
            {activity}
        </div>
        <Tooltip 
        id={`log_${logId}`} 
        place='bottom' 
        className='log_tooltip z-10 shadow-xl !bg-opacity-100'
        anchorSelect={`.log_${logId}`}
        style={{ opacity: 1, padding: 0 }}
        >
            <div className=' shadow min-w-[200px] max-w-[575px] h-auto p-3'>
                {activity}
            </div>
        </Tooltip>
    </>
  )
}

export default LogActivity