import { Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import { FunctionComponent, memo, useEffect, useState } from "react";
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
  SingleInputDateRangeField,
} from "@mui/x-date-pickers-pro";
import {
  useFilterEventActionsContext,
  useFilterEventContext,
} from "../context/FilterEventContextProvider";
import {
  colorApp,
  DefaultFormatDate,
} from "../../../core/constant/app-constant";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DatePicker from 'react-datepicker';

const FilterSelectDateComponent: FunctionComponent = () => {
  const { filterMode } = useFilterEventContext();
  const { updateFilterMode } = useFilterEventActionsContext();
  

  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [error, setError] = useState("");

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const { updateFilter } = useFilterEventActionsContext();
  const { filter } = useFilterEventContext();
  const handleAlignment = (e: React.MouseEvent<HTMLElement>, newValue: any) => {
    e.preventDefault();

    if (!newValue) {
      return;
    }

    updateFilterMode(newValue);
    switch (newValue) {
      case "month": {
        const FromDate = moment(new Date())
            .startOf("month")
            .format(DefaultFormatDate);
        const ToDate = moment(new Date())
            .endOf("month")
            .format(DefaultFormatDate);
        updateFilter({ ...filter, FromDate, ToDate });

        break;
      }
      case "year": {
        const FromDate = moment().startOf("year").format(DefaultFormatDate);
        const ToDate = moment().endOf("year").format(DefaultFormatDate);
        updateFilter({ ...filter, FromDate, ToDate, PageNumber: 1 });

        break;
      }
      case "next5year": {
        const FromDate = moment().startOf("day").format(DefaultFormatDate);
        const ToDate = moment()
            .add(5, "years")
            .endOf("day")
            .format(DefaultFormatDate);
        updateFilter({ ...filter, FromDate, ToDate, PageNumber: 1 });

        break;
      }
      case "next10year": {
        const FromDate = moment().startOf("day").format(DefaultFormatDate);
        const ToDate = moment()
            .add(10, "years")
            .endOf("day")
            .format(DefaultFormatDate);
        updateFilter({ ...filter, FromDate, ToDate, PageNumber: 1 });

        break;
      }
      default:
        break;
    }
  };

  const onCloseDateRange = () => {
    updateFilterMode("customDateRange");
  };

  const onClickSearch = () => {
    if (!startDate && !endDate) {
      return;
    }
    let _startDate = undefined;
    let _endDate = undefined;
    if (!startDate && endDate) {
      _startDate = moment('2024-03-01T00:00:00').startOf("day").format(DefaultFormatDate);
      _endDate = moment(endDate).endOf("day").format(DefaultFormatDate);
    }
    if (startDate && !endDate) {
      _startDate = moment(startDate).startOf("day").format(DefaultFormatDate);
      _endDate = moment(new Date()).endOf("day").format(DefaultFormatDate);
    }
    if (startDate && endDate && moment(endDate).isBefore(startDate)) {
      setError("Ngày sau phải lớn hơn ngày trước");
      return;
    } else {
      setError("");
    }
    if (startDate && endDate) {
      _startDate =
          (startDate && moment(startDate).startOf("day").format(DefaultFormatDate)) ||
          null;
      _endDate =
          (endDate &&
              moment(endDate)
                  .endOf("day")
                  .format(DefaultFormatDate)) ||
          null;
    }
    if (_startDate && _endDate) {
      updateFilter({ 
        ...filter, 
        FromDate: _startDate, 
        ToDate: _endDate, 
        PageNumber: 1 
      });
    }
  };

  useEffect(() => {
    if (startDate || endDate) {
      onClickSearch();
    }
  }, [startDate, endDate]);

  const handleClickCancelBtn = (event: any) => {
    handleAlignment(event, 'month');
    setStartDate(null);
    setEndDate(null);
  };

  return (
      <Box>
        <ToggleButtonGroup
            value={filterMode}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
            size="small"
            sx={{
              height: "32px !important",
              color: colorApp.colorFilter,
              backgroundColor: "#FFFFFF",
              "& .Mui-selected": {
                color: colorApp.filterActive,
                border: `2px solid ${colorApp.filterActive}`,
                backgroundColor: "#FFFFFF",
              },
              "& .MuiButtonBase-root": {
                textTransform: "none",
              },
            }}
        >
          <ToggleButton value="month" aria-label="left aligned" type={"button"} sx={{
            borderTopLeftRadius: '10px',
            borderBottomLeftRadius: '10px'
          }}>
            <span className="mt-[1.5px]">{"Tháng này"}</span>
          </ToggleButton>

          <ToggleButton value="year" aria-label="centered" type={"button"}>
            <span className="mt-[1.5px]">{"Năm nay"}</span>
          </ToggleButton>
          <ToggleButton
              value="next5year"
              aria-label="right aligned"
              type={"button"}
          >
            <span className="mt-[1.5px]">{"5 năm tới"}</span>
          </ToggleButton>
          <ToggleButton value="next10year" aria-label="justified" type={"button"}>
            <span className="mt-[1.5px]">{"10 năm tới"}</span>
          </ToggleButton>

          {/* <ToggleButton
              value="customDateRange"
              aria-label="justified"
              type={"button"}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DateRangePicker
                  sx={{
                    height: "32px",
                    width: "250px",
                    fontSize: 5,
                    input: {
                      border: "none",
                      width: "250px",
                      fontSize: "14px",
                      textTransform: "none",
                    },
                    textTransform: "none",
                    "& fieldset": {
                      display: "none",
                    },
                  }}
                  disablePast
                  format={"DD/MM/YYYY"}
                  slots={{ field: SingleInputDateRangeField }}
                  value={value}
                  onChange={(newValue) => {
                    if (
                        !newValue[0] ||
                        !newValue[1] ||
                        !moment(newValue[0]).isValid() ||
                        !moment(newValue[1]).isValid()
                    ) {
                      return;
                    }
                    setValue(newValue);
                    onClickSearch(newValue);
                  }}
                  onClose={onCloseDateRange}
              />
            </LocalizationProvider>
          </ToggleButton> */}
          <ToggleButton
              value="customDateRange"
              aria-label="justified"
              type={"button"}
              style={{
                borderTopRightRadius: '10px',
                borderBottomRightRadius: '10px',
                padding: 0
              }}
          >
            <div className="date-ranger flex items-center w-full h-full">
              <div className="mt-[1.5px]">
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Từ ngày"
                  className="text-center text-sm w-[100px] bg-transparent "
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
              <div className=" text-sm text-[#CAC2C2] mb-[1px]">-</div>
              <div className="mt-[1.5px]">
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Đến ngày"
                  className="text-center text-sm w-[100px] bg-transparent "
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </div>
          </ToggleButton>
          <div className="pl-2 w-auto h-full bg-[#FFFBEE]">
            <div className="table-second-btn h-8"
            onClick={(event) => handleClickCancelBtn(event)}
            >Xóa lọc</div>
          </div>

          {/*<Button*/}
          {/*  onClick={onClickSearch}*/}
          {/*  sx={{*/}
          {/*    color: colorApp.filterActive,*/}
          {/*    backgroundColor: "#FFFBEE",*/}
          {/*    paddingLeft: 0,*/}
          {/*    ":hover": {*/}
          {/*      backgroundColor: "#e5e7eb",*/}
          {/*    },*/}
          {/*  }}*/}
          {/*  disabled={filterMode !== "customDateRange"}*/}
          {/*  endIcon={<SearchIcon />}*/}
          {/*/>*/}
        </ToggleButtonGroup>
        <div className={"text-right text-sm mt-1 text-red-500"}>{error}</div>
      </Box>
  );
};

export default memo(FilterSelectDateComponent);