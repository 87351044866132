import { PermissionsGate } from 'components/PermissionsGate';
import { useAuth } from 'core/contexts/auth/AuthContext';
import { UserRoleEnum } from 'core/types/user.type';
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom';
import AddUserSide from 'assets/icons/AddUserSide';
import ApproveUserSide from 'assets/icons/ApproveUserSide';
import ListUserSide from 'assets/icons/ListUserSide';
import LogSide from 'assets/icons/LogSide';
import EventSide from 'assets/icons/EventSide';
import AdminSide from 'assets/icons/AdminSide';
import { AlertCircle, Layers, Upload } from 'react-feather';



const AdminSideBar = () => {
    const {user} = useAuth();
    let location = useLocation();
    
    const getIconColor = (itemPath: string) => {
        
        if (location?.pathname?.includes(itemPath)) {
            return '#F9D876'
        }else {
            return '#461511'
        }
    }

    const menuData = useMemo(() => {
        return [
            {
                id: 1,
                title: 'Thêm người mới',
                path: '/user-detail',
                activePath: '/quan-ly/user-detail',
                icon: <AddUserSide fillColor={getIconColor('/quan-ly/user-detail')}/>,
                roles: [UserRoleEnum.PeopleAdmin, UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
            },
            {
                id: 2,
                title: 'Phê duyệt người mới',
                path: '/pending-user-approval',
                activePath: '/pending-user-approval',
                icon: <ApproveUserSide fillColor={getIconColor('/pending-user-approval')}/>,
                roles: [UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
            },
            {
                id: 3,
                title: 'Thay đổi thông tin về người',
                path: '/list-users',
                activePath: '/list-users',
                icon: <ListUserSide fillColor={getIconColor('/list-users')}/>,
                roles: [UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
            },
            {
                id: 4,
                title: 'Quản lý đời',
                path: '/gerenation',
                activePath: '/gerenation',
                icon: <Layers size={24} color={getIconColor('/gerenation')}/>,
                roles: [UserRoleEnum.SuperAdmin]
            },
            {
                id: 5,
                title: 'Lịch sử hoạt động',
                path: '/log',
                activePath: '/log',
                icon: <LogSide fillColor={getIconColor('/log')}/>,
                roles: [UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
            },
            {
                id: 6,
                title: 'Quản lý sự kiện',
                path: '/event',
                activePath: '/event',
                icon: <EventSide fillColor={getIconColor('/event')}/>,
                roles: [UserRoleEnum.EditAdmin, UserRoleEnum.SiteAdmin, UserRoleEnum.SuperAdmin]
            },
            {
                id: 7,
                title: 'Quản lý admin',
                path: '/admin',
                activePath: '/admin',
                icon: <AdminSide fillColor={getIconColor('/admin')}/>,
                roles: [UserRoleEnum.SuperAdmin]
            },
            {
                id: 8,
                title: 'Import người mới',
                path: '/import-user',
                activePath: '/import-user',
                icon: <Upload size={23} style={{color: getIconColor('/import-user')}}/>,
                roles: [UserRoleEnum.SuperAdmin]
            },
            {
                id: 9,
                title: 'Quản lý mật khẩu chữ i',
                path: '/ipassword',
                activePath: '/ipassword',
                icon: <AlertCircle size={23} style={{color: getIconColor('/ipassword')}}/>,
                roles: [UserRoleEnum.SuperAdmin]
            }
        ]
    }, []); 
  return (
    <div className=' bg-yellow_F9D876 min-h-[400px] h-full'>
        {menuData?.map((item: any) => (
            <PermissionsGate user={user} scopes={item?.roles} key={item.id}>
                <a href={`/quan-ly${item.path}`} className=' cursor-pointer'>
                    <div className={` px-[12.96px] py-3 flex items-center gap-2 text-sm ${location?.pathname?.includes(item.activePath) ? 'text-white font-bold bg-bg_brown' : ' text-bg_brown font-medium'}`}>
                        <div className={`${item.path === '/user-detail' ? 'ml-[2px]' : ''} w-6 h-6`}>{item.icon}</div>
                        <p className={``}>{item.title}</p>
                    </div>
                </a>
            </PermissionsGate>
        ))}
    </div>
  )
}

export default AdminSideBar