import React, {useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {DevTool} from '@hookform/devtools';
import {FamilyBackground} from '../../assets/icons/FamilyBackground';
import {FamilyName} from '../../assets/icons/FamilyName';
import {schemaResetPassword} from '../../utilities/schemaValidate';
import {useAuth} from 'core/contexts/auth/AuthContext';
import {Eye, EyeOff} from "react-feather";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const methods = useForm({
        defaultValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        resolver: yupResolver(schemaResetPassword),
    });
    const {formState: {errors}, handleSubmit} = methods;
    const {resetPassword, error} = useAuth();

    const onSubmit = async (data: any) => {
        const {newPassword, confirmPassword} = data;
        if (newPassword !== confirmPassword) {
            methods.setError('confirmPassword', {
                type: 'manual',
                message: 'Mật khẩu xác nhận không đúng với mật khẩu mới.',
            });
            return;
        }
        methods.clearErrors('oldPassword');
        await resetPassword(data?.oldPassword, data?.newPassword).then((response) => {
            if (response) {
                navigate("/login");
            }
        }).catch((error) => {
            console.error("error", error);
        })
    };
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [showNewPassword, setShowNewPassword] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    return (
        <FormProvider {...methods}>
            <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen h-full bg-primary">
                <div className="grid-container hidden md:grid justify-items-center items-center">
                    <div className="relative h-full">
                        <FamilyBackground/>
                        <div
                            className="absolute inset-0 flex justify-center items-center h-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/4 left-1/3 ">
                            <div className="flex flex-col justify-center items-center h-full w-full mt-28">
                                <FamilyName/>
                                <div className="flex flex-col items-center">
                                    <img src="./house.png" alt="House"/>
                                    <img src="./house-footer.png" alt="House Footer"
                                         style={{marginBottom: "10px", marginTop: "-30px"}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-base bg-white flex flex-col justify-center items-center rounded-lg mx-auto"
                     style={{
                         maxWidth: '540px',
                         height: '434px',
                         marginTop: 'auto',
                         marginBottom: 'auto',
                     }}>
                    <form className="container-base mx-auto space-y-6 " style={{
                        width: '445px'
                    }} onSubmit={handleSubmit(onSubmit)}>
                        {error && <span className="text-red-500 text-sm">{error?.error?.message}</span>}
                        <div className="space-y-4">
                            <div className=' text-2xl font-medium text-[#5A5A5A]'>Thay đổi mật khẩu</div>
                            <div className="relative">
                                <input
                                    {...methods.register('oldPassword')}
                                    id="oldPassword"
                                    placeholder="Mật khẩu hiện tại"
                                    name="oldPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    className="form-input !h-[46px]"
                                />
                                <div
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                    onClick={togglePasswordVisibility}
                                >
                                    {showPassword ? <EyeOff size={16} color="gray"/> :
                                        <Eye size={16} color="gray"/>}
                                </div>
                                {errors.oldPassword && (
                                    <span className="text-red-500 text-sm">{errors.oldPassword.message}</span>
                                )}
                            </div>
                            <div className="relative">
                                <input
                                    {...methods.register('newPassword')}
                                    id="newPassword"
                                    placeholder="Mật khẩu mới"
                                    name="newPassword"
                                    type={showNewPassword ? 'text' : 'password'}
                                    className="form-input !h-[46px]"
                                />
                                <div
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                    onClick={toggleNewPasswordVisibility}
                                >
                                    {showNewPassword ? <EyeOff size={16} color="gray"/> :
                                        <Eye size={16} color="gray"/>}
                                </div>
                                {errors.newPassword && (
                                    <span className="text-red-500 text-sm">{errors.newPassword.message}</span>
                                )}
                            </div>
                            <div className="relative">
                                <input
                                    {...methods.register('confirmPassword')}
                                    id="confirmPassword"
                                    placeholder="Nhập lại mật khẩu mới"
                                    name="confirmPassword"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className="form-input !h-[46px]"
                                />
                                <div
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                    onClick={toggleConfirmPasswordVisibility}
                                >
                                    {showConfirmPassword ? <EyeOff size={16} color="gray"/> :
                                        <Eye size={16} color="gray"/>}
                                </div>
                                {errors.confirmPassword && (
                                    <span className="text-red-500 text-sm">{errors.confirmPassword.message}</span>
                                )}
                            </div>
                        </div>
                        <div className="flex gap-2">
                            <button onClick={() => {
                                navigate("/login");
                            }}
                                    className="table-second-btn w-full h-[46px] !text-lg">Hủy
                            </button>
                            <button type="submit"
                                    className="table-primary-btn w-full h-[46px] !text-lg">Tiếp
                                tục
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <DevTool control={methods.control} placement="top-left"/>
        </FormProvider>
    );
};

export default ForgotPasswordPage;
