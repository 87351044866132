import { ModalContainer } from 'components/modal/ModalContainer'
import { convertNewlinesToBreaks, getFullName } from 'core/utils/ultils';
import React, { Fragment } from 'react'
import { X } from 'react-feather';
import BgIconBottom from '../../../assets/images/bg_icon_bottom_black.png';

type OrganizationModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    organizationData: any;
    currentOrganizationData: any;
    generationData?: any;
}

const OrganizationModal = ({isOpen, closeModal, organizationData, currentOrganizationData, generationData} : OrganizationModalProps) => {
    const getOrganizationName = (organization: string) => {
        if (organization === 'Branch') {
            return 'Nhánh'
        }
        if (organization === 'Clan') {
            return 'Chi'
        }
        if (organization === 'Lineage') {
            return 'Phái'
        }
    }
  return (
    <ModalContainer isOpen={isOpen} className='w-[617px] min-h-[219px] h-auto !bg-[#F9D876] rounded-[10px]'>
        <div className='py-5 px-10 relative min-h-[260px]'>
            <div 
            className=' absolute top-2 right-2 p-1 cursor-pointer'
            onClick={() => closeModal()}
            >
                <X color='#461511' size={24}/>
            </div>
            {organizationData &&
                <Fragment>
                    <div className='text-[24px] text-[#461511] font-medium'>{organizationData?.name}</div>
                    {organizationData?.leaderFirstName && 
                        <div 
                        className='mt-4 text-[15px] text-black font-bold'>
                            {`Tộc trưởng ${getOrganizationName(currentOrganizationData?.organizationType)}: ${getFullName(organizationData?.leaderFirstName, organizationData?.leaderLastName)}`}
                        </div>
                    }
                    <div 
                    className='text-[15px] text-black mt-4 font-light whitespace-pre-line' 
                    dangerouslySetInnerHTML={{__html: convertNewlinesToBreaks(organizationData?.history)}}>
                    </div>
                </Fragment>
            }
            {generationData &&
                <Fragment>
                    <div className='text-[24px] text-[#461511] font-medium'>{generationData?.name}</div>
                    <div 
                    className='text-[15px] text-black mt-4 font-light whitespace-pre-line'
                    dangerouslySetInnerHTML={{__html: convertNewlinesToBreaks(generationData?.history)}}
                    >
                    </div>
                </Fragment>
            }
            <div className=' absolute bottom-0 right-0 left-0 z-[100]'>
                <img src={BgIconBottom} alt="BgIconBottom" className='w-full h-[90px]'/>
            </div>
        </div>
    </ModalContainer>
  )
}

export default OrganizationModal