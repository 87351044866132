import ConfirmModal from 'components/modal/ConfirmModal';
import { UserService } from 'core/services/user.service';
import { UserDetailData, UserGendersEnum } from 'core/types/user.type';
import React, { useEffect, useState } from 'react';
import { AlertTriangle, CheckCircle, CheckSquare } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Image from 'components/uiComponents/AvatarImage';
import AvatarImage from 'components/uiComponents/AvatarImage';

const PendingUserApprovalList = () => {
  const [users, setUsers] = useState<UserDetailData[]>([]);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<UserDetailData>();
  const [typeModal, setTypeModal] = useState<string>();
  const [onSubmit, setOnSubmit] = useState<boolean>(false);
  const navigate = useNavigate();

  const getListUser = async () => {
    const params: any = {
      PageNumber: 1,
      PageSize: 100,
      Status: 'OnHold'
    }
    const response = await UserService.getList(params);

    if (response?.data?.isSuccess === true) {
      setUsers(response?.data?.data || []);
    }
  }


  useEffect(() => {
    getListUser();
  }, []);

  const handleShowConfirmModal = (type: string, user: UserDetailData) => {
    
    if (type === 'confirm') {
      setModalTitle('Xác nhận chấp nhận người mới này!');
    }else {
      setModalTitle('Xác nhận từ chối người mới này!');
    }
    setTypeModal(type);
    setSelectedUser(user);
    setIsShowConfirmModal(true);
  };

  const handleSubmitReview = async () => {
    setOnSubmit(true);
    let params: any;

    if (typeModal === 'confirm') {
      params = {
        isApprove: true,
        reason: ''
      }
    }else {
      params = {
        isApprove: false,
        reason: ''
      }
    };

    const response = await UserService.submitReview(selectedUser?.id || '', params);

    if (response?.data?.isSuccess === true) {
      let mess: string;
      if (typeModal === 'confirm') {
        mess = 'Phê duyệt người mới thành công';
      }else {
        mess = 'Từ chối người mới thành công';
      }
      toast(mess, {
        type: 'success',
        icon: <CheckCircle className="text-[#007B40]" />
      });
      getListUser();
    }else {
      toast(response?.data?.errors || 'Lỗi kết nối mạng!', {
        type: 'error',
        icon: <AlertTriangle className="text-[#F03D3E]" />
      });
    }
    setOnSubmit(false);
    setIsShowConfirmModal(false); 
  };

  const handleCloseModal = () => {
    setIsShowConfirmModal(false); 
    setSelectedUser(undefined);
    setTypeModal(undefined);
  }

  return (
    <>
      <div className=' flex justify-between'>
        <div className='flex items-center gap-[11px]'>
          <CheckSquare size={24} color={`#461511`}/>
          <p className=' text-base font-semibold leading-5 text-bg_brown'>Phê duyệt người mới</p>
        </div>
        <div className=' text-sm font-normal leading-5 text-color_text'>{users?.length > 0 ? `${users?.length} người`  : ''}</div>
      </div>
      <div className='table-container min-h-[500px]'>
        {users?.length > 0 &&
          <table className='table w-full'>
            <thead>
              <tr>
                <th className='w-[30%]'>
                  <span>Ảnh cá nhân</span>
                </th>
                <th className='w-[15%]'>
                  <span>CNMD / CCCD</span>
                </th>
                <th className='w-[20%]'>
                  <span>Email</span>
                </th>
                <th className='w-[10%]'>
                  <span>Giới tính</span>
                </th>
                <th className='w-[10%]'>
                  <span>Đời</span>
                </th>
                <th className='text-center w-[15%]'>
                  <span>Phê duyệt</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user: UserDetailData) => (
                <tr className=' cursor-pointer' key={user?.id} onClick={(event) => {navigate(`/quan-ly/pending-user-approval/user-detail/${user.id}?type=review`); event.stopPropagation();}}>
                  <td className='w-[200px]'>
                    <div className='flex gap-[10px]'>
                      <div>
                        <div className='w-[50px] h-[50px] flex items-center justify-center rounded-2xl border border-yellow_F9D876'>
                          <AvatarImage
                            imageUrl={user.avatar}
                            className='rounded-2xl'
                          />
                        </div>
                      </div>
                      <div className='flex flex-col justify-between'>
                        <div className=' text-sm font-bold leading-5 text-[#595959]'>{user.personCode || '-'}</div>
                        <div>{`${user.lastName} ${user.firstName}`}</div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span>{user?.identityDocument || '-'}</span>
                  </td>
                  <td >
                    <span>{user.email}</span>
                  </td>
                  <td>
                    <span>{user.gender ? user.gender === UserGendersEnum.Male ? 'Nam' : 'Nữ' : '-'}</span>
                  </td>
                  <td>
                    <span>{user.generationName || '-'}</span>
                  </td>
                  <td>
                    <div className='w-[210px] h-full'>
                      <div className='flex gap-[10px] relative justify-end'>
                        <button className='table-second-btn flex 1' type='button' onClick={(event) => {handleShowConfirmModal('reject', user); event.stopPropagation();}}>Từ chối</button>
                        <button className='table-primary-btn flex 1' type='button' onClick={(event) => {handleShowConfirmModal('confirm', user); event.stopPropagation();}}>Chấp nhận</button>
                        {(user?.numberOfApprovals || 0) > 0 && 
                          <div className=' absolute bottom-[-23px] right-0'>
                            <span className='text-[#461511] test-sm font-bold'>Chấp nhận 1/2</span>
                          </div>
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        {users?.length === 0 &&
            <div className='text-sm font-normal leading-5 text-[#595959]'>Chưa có người mới cần phê duyệt</div>
        }
      </div>
      <ConfirmModal 
      isOpen={isShowConfirmModal} 
      closeModal={() => handleCloseModal()} 
      modalClass='w-full md:w-[500px] h-[240px]'
      title={modalTitle || 'Xác nhận'}
      handleSubmit={() => handleSubmitReview()}
      onSubmit={onSubmit}
      />
    </>
  )
}

export default PendingUserApprovalList