import ImageItems from "./components/ImageItems";
import { Link, Navigate } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetImagesFromAlbum } from "../../hooks/useImages";
import ImageDefaultIcon from "assets/icons/ImageDefaultIcon";

const breadcrumbs = (name: string | undefined) => {
  return [
    <Link to="/images" className="flex gap-1" key={'breadcrumbs_1'}>
      <ImageDefaultIcon/>
      <p className={"text-[#461511] font-[600] text-[16px]"}>
        Hình ảnh phả tộc Nguyễn Đức
      </p>
    </Link>,
    <Link to="/images" key={'breadcrumbs_2'}>
      <p className={"text-[#461511] font-[600] text-[16px]"}>
        {name || "Unknown"}
      </p>
    </Link>,
  ];
};

const ImagePage = () => {
  const { idAlbum, name } = useParams();
  const { data: images } = useGetImagesFromAlbum(idAlbum);
  
  const listImage = images?.data?.data?.images;

  if (!idAlbum) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      <div className={"mt-[75px] flex justify-start"}>
        <Breadcrumbs separator={">>"} aria-label="breadcrumb">
          {breadcrumbs(name)}
        </Breadcrumbs>
      </div>
      {listImage && (
        <div className={"mt-[28px] min-h-[400px] mb-20"}>
          <ImageItems images={listImage} isAlbums={false}/>
        </div>
      )}
    </>
  );
};

export default ImagePage;
