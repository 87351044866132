import React from 'react'

const NotificationReadIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.0298 8C18.0298 6.4087 17.3976 4.88258 16.2724 3.75736C15.1472 2.63214 13.6211 2 12.0298 2C10.4385 2 8.91236 2.63214 7.78714 3.75736C6.66193 4.88258 6.02979 6.4087 6.02979 8C6.02979 15 3.02979 17 3.02979 17H21.0298C21.0298 17 18.0298 15 18.0298 8Z" fill="#F2B84F" stroke="#F2B84F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.7598 21C13.584 21.3031 13.3316 21.5547 13.028 21.7295C12.7244 21.9044 12.3802 21.9965 12.0298 21.9965C11.6794 21.9965 11.3352 21.9044 11.0316 21.7295C10.728 21.5547 10.4756 21.3031 10.2998 21" stroke="#F2B84F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default NotificationReadIcon