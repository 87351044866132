import AES from 'crypto-js/aes';
import Utf8 from 'crypto-js/enc-utf8';

export const saveK = '59beb09c761b0d1a054289b733ee60f2';

export const getFullName = (firstName: string, lastName: string) => {
    return `${lastName} ${firstName}`;
};

export const encryptData = (objectToEncrypt: string, secretKey: string) => {
    
    const encryptedData = AES.encrypt(objectToEncrypt, secretKey).toString();
    
    return encryptedData;
};

export const decryptData = (encryptedData: any, secretKey: any) => {
    
    if (encryptedData) {
      
      const decryptedData = AES.decrypt(encryptedData, secretKey).toString(Utf8);
      
      return JSON.parse(decryptedData);
    }
    return null; 
};

export const generateRandomString = () => {
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let newRandomString = '';
    for (let i = 0; i < 8; i++) {
        const randomIndex = Math.floor(Math.random() * chars.length);
        newRandomString += chars[randomIndex];
    }
    return newRandomString;
};

export const findNullIndexesInArray = (array: any[]) => {
    const nullIndexes = [];
    for (let i = 0; i < array.length; i++) {
        if (array[i] === null) {
        nullIndexes.push(i);
        }
    }
    return nullIndexes;
};

export const findNullIndexesInObject = (object: any) => {
    const fieldsWithNullValues = [];
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] === null) {
      fieldsWithNullValues.push(key);
    }
  }
  return fieldsWithNullValues;
};

export const convertNewlinesToBreaks = (text: string) => {
  if (!text) {
    return '';
  }
  return text.replace(/\\n/g, '<br/>');
};