import React from 'react'

const ApproveUserSide = ({fillColor} : {fillColor?: string}) => {
  return (
    <>
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.95508 21.2148C5.40508 21.2148 4.93424 21.019 4.54258 20.6273C4.15091 20.2357 3.95508 19.7648 3.95508 19.2148V5.21484C3.95508 4.66484 4.15091 4.19401 4.54258 3.80234C4.93424 3.41068 5.40508 3.21484 5.95508 3.21484H19.9551C20.0884 3.21484 20.2134 3.22734 20.3301 3.25234C20.4467 3.27734 20.5634 3.31484 20.6801 3.36484L18.8301 5.21484H5.95508V19.2148H19.9551V12.5648L21.9551 10.5648V19.2148C21.9551 19.7648 21.7592 20.2357 21.3676 20.6273C20.9759 21.019 20.5051 21.2148 19.9551 21.2148H5.95508ZM12.4801 17.2148L6.83008 11.5648L8.23008 10.1648L12.4801 14.4148L21.6551 5.23984L23.0801 6.61484L12.4801 17.2148Z" fill={fillColor}/>
        </svg>
    </>
  )
}

export default ApproveUserSide