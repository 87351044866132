import { ModalContainer } from 'components/modal/ModalContainer';
import { getFullName } from 'core/utils/ultils';
import React from 'react';
import { X } from 'react-feather';
import AvatarDefault from 'assets/images/avatar_default.png';
import moment from 'moment';

type PersonModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  personData: any;
}

const styles = {
  text: 'text-[#461511] text-sm font-normal'
};

const PersonModal = ({isOpen, closeModal, personData} : PersonModalProps) => {
  return (
    <ModalContainer isOpen={isOpen} className='w-[80%] max-w-[1170px] min-h-[360px] max-h-[80vh] overflow-auto h-auto !bg-[#F9D876] rounded-[10px]'>
      <div className='h-auto mt-[-20px] py-5 px-10 relative space-y-5'>
        <div 
          className=' absolute top-7 right-2 p-1 cursor-pointer'
          onClick={() => closeModal()}
        >
            <X color='#461511' size={24}/>
        </div>
        <div 
        className='text-[24px] text-[#461511] font-medium'>
          {`${getFullName(personData?.firstName, personData?.lastName)} - ${personData?.personCode}`}
        </div>
        {(personData?.familyTitle || personData?.familyCouncilTitle) &&
          <div className='text-[15px] text-black font-bold'>
            {`${personData?.familyTitle || ''} - ${personData?.familyCouncilTitle || ''}`}
          </div>
        }
        <div className='flex'>
          <div className='w-1/6'>
            <div className='w-[114px] h-[114px] rounded-2xl border border-bg_brown flex items-center justify-center relative'>
              <img src={personData?.avatar || AvatarDefault} alt="AvatarDefault" className={`${!personData?.avatar ? 'w-[51px] h-[72.39px]' : 'w-[112px] h-[112px]'} rounded-2xl`}/>
            </div>
          </div>
          <div className='w-2/6 flex'>
            <div className='w-2/3 space-y-[11px]'>
              <div className={`${styles.text}`}>{personData?.dateOfBirth ? moment(personData?.dateOfBirth).format('DD/MM/YYYY') : ''}</div>
              <div className={`${styles.text}`}>{personData?.phoneNumber || ''}</div>
              <div className={`${styles.text}`}>{personData?.career || ''}</div>
              <div className={`${styles.text}`}>{personData?.dateOfBirth ? `Ngày sinh: ${moment(personData?.dateOfBirth).format('DD/MM/YYYY')}` : ''}</div>
              <div className={`${styles.text}`}>{personData?.dateOfDeath ? 
                  `Ngày mất: ${moment(personData?.dateOfDeath).format('DD/MM/YYYY')} 
                  ${personData?.lunarDateOfDeath ? `(${moment(personData?.lunarDateOfDeath).format('DD/MM/YYYY')} ÂL)` : ""}` : ''}
              </div>
            </div>
            <div className='w-1/3 space-y-[11px]'>
              <div className={`${styles.text}`}>
                {personData?.organizations?.find((o: any) => o.type === 'Branch')?.name || ''}
              </div>
              <div className={`${styles.text}`}>
                {personData?.organizations?.find((o: any) => o.type === 'Clan')?.name || ''}
              </div>
              <div className={`${styles.text}`}>
                {personData?.organizations?.find((o: any) => o.type === 'Lineage')?.name || ''}
              </div>
              <div className={`${styles.text}`}></div>
            </div>
          </div>
          <div className={`${styles.text} w-3/6 flex`}>
            <div className='w-1/2 space-y-[11px]'>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Bố</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'Father') {
                      return (
                        <div key={relationship.id}>{getFullName(relationship.firstName, relationship.lastName)}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Mẹ</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'Mother') {
                      return (
                        <div key={relationship.id}>{relationship?.fullname || ""}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>{personData?.gender === 'Male' ? 'Vợ' : 'Chồng'}</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === (personData?.gender === 'Male' ? 'Wife' : 'Husband')) {
                      return (
                        <div key={relationship.id}>{relationship.firstName ? getFullName(relationship.firstName, relationship.lastName) : (relationship.fullname || "")}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Con</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'Children') {
                      return (
                        <div key={relationship.id}>{getFullName(relationship.firstName, relationship.lastName)}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
            <div className='w-1/2 space-y-[11px]'>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Anh</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'OlderBrother') {
                      return (
                        <div key={relationship.id}>{getFullName(relationship.firstName, relationship.lastName)}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Chị</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'OlderSister') {
                      return (
                        <div key={relationship.id}>{getFullName(relationship.firstName, relationship.lastName)}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
              <div className='flex'>
                <div className='min-w-[50px] self-start'>Em</div>
                <div className='flex-1 space-y-[11px]'>
                  {personData?.relationships?.map((relationship: any) => {
                    if (relationship.type === 'YoungerBrother') {
                      return (
                        <div key={relationship.id}>{getFullName(relationship.firstName, relationship.lastName)}</div>
                      )
                    }else {
                      return (
                        <></>
                      )
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`${styles.text}`}>
          {`Địa chỉ: 
          ${personData?.street ? `${personData?.street},` : ''} 
          ${personData?.ward ? `${personData?.ward},` : ''} 
          ${personData?.district ? `${personData?.district},` : ''} 
          ${personData?.city || ''}`}
        </div>
        <div className={`${styles.text} whitespace-pre-line`} dangerouslySetInnerHTML={{ __html: personData?.personalHistory }}></div>
      </div>
    </ModalContainer>
  )
}

export default PersonModal