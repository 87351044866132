import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {FamilyBackground} from 'assets/icons/FamilyBackground';
import {FamilyName} from 'assets/icons/FamilyName';
import {useAuth} from 'core/contexts/auth/AuthContext';
import {Eye, EyeOff} from 'react-feather';
import { decryptData, encryptData, saveK } from 'core/utils/ultils';
import House from 'assets/images/house.png';
import HouseFooter from 'assets/images/house-footer.png';


const LoginPage = () => {
    const navigate = useNavigate();
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isSaveAccount, setIsSaveAccount] = useState(false);
    const [emailData, setEmailData] = useState<string>();
    const [passwordData, setPasswordData] = useState<string>();

   

    const {
        watch,
        register,
        setValue, 
        formState: {errors}, 
        handleSubmit
    } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
        mode: 'onChange'
    });;

    const watchEmail = watch('email');
    const watchPassword = watch('password');
    const {login, error} = useAuth();

    useEffect(() => {
        if (emailData && passwordData) {
            setIsDisabledSubmitBtn(false);
        }else {
            setIsDisabledSubmitBtn(true);
        }
    }, [emailData, passwordData]);

    useEffect(() => {
        const account = localStorage.getItem('subb');
        if (account) {

            const decryptedObject: any = decryptData(account, saveK);
            setValue('email', decryptedObject.email);
            setValue('password', decryptedObject.password);
            setEmailData(decryptedObject.email);
            setPasswordData(decryptedObject.password);
            setIsSaveAccount(true);
        }
    }, []);

    const onSubmit = (data: any) => {
        login(data?.email, data?.password);
        if (isSaveAccount) {
            const account = {
                email: watchEmail,
                password: watchPassword,
            };

            const jsonString = JSON.stringify(account);
            const encryptedString = encryptData(jsonString, saveK);
            localStorage.setItem('subb', encryptedString);
        }else {
            localStorage.removeItem('subb');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSubmit(onSubmit)();
        }
    }

    const handleEmailChange = (event: any) => {
        setEmailData(event.target.value);
        setValue("email", event.target.value);
    }

    const handlePasswordChange = (event: any) => {
        setPasswordData(event.target.value);
        setValue("password", event.target.value);
    }

    const onClickImg = () => {
        navigate('/');
    }
    return (
        <div>
            <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen h-full bg-primary">
                <div className="grid-container hidden md:grid justify-items-center items-center">
                    <div className="relative h-full cursor-pointer" onClick={() => onClickImg()}>
                        <FamilyBackground/>
                        <div
                            className="absolute inset-0 flex justify-center items-center h-1/2 top-1/2 transform -translate-y-1/2 -translate-x-1/4 left-1/3 ">
                            <div className="flex flex-col justify-center items-center h-full w-full mt-28 gap-5">
                                <div className='ml-[50px] w-[490px] h-[128px]'>
                                    <FamilyName/>
                                </div>
                                <div className="flex flex-col items-center relative">
                                    <img src={House} alt="House" className=' ml-[60px]'/>
                                    <img src={HouseFooter} alt="House Footer"
                                         style={{marginBottom: "10px", marginTop: "-30px", marginLeft: "42px"}}/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    <div 
                    className="container-base bg-white flex flex-col justify-center items-center rounded-lg mx-auto"
                         style={{
                             maxWidth: '540px',
                             height: '434px',
                             marginTop: 'auto',
                             marginBottom: 'auto',
                         }}>
                        <form className="container-base mx-auto space-y-6 " style={{
                            width: '445px'
                        }} onSubmit={handleSubmit(onSubmit)} onKeyDown={handleKeyDown} autoComplete='off'>
                            <>
                                <div className=' text-2xl font-medium text-[#5A5A5A]'>Đăng nhập</div>
                                {error && <span className="text-red-500 text-sm">{error?.error?.message}</span>}
                                <div>
                                    <label htmlFor="email" className="block text-sm">Email</label>
                                    <div className="mt-2">
                                        <input {...register('email', {
                                            required: 'Vui lòng nhập email',
                                            validate: (value) => {
                                                const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                                if (!regex.test(value)) {
                                                  return 'Vui lòng nhập email hợp lệ';
                                                }
                                            }
                                            })} 
                                            id="email"
                                            autoComplete='off'
                                            className="form-input !h-[46px]"
                                            onKeyDown={handleKeyDown}
                                            onChange={(event) => handleEmailChange(event)}
                                            />
                                        {errors.email &&
                                            <span
                                                className="text-red-500 text-sm">{errors.email.message}</span>}
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center justify-between">
                                        <label htmlFor="password" className="block text-sm ">Mật khẩu</label>
                                    </div>
                                    <div className="mt-2 relative">
                                        <input
                                            {...register('password', {
                                                required: 'Vui lòng nhập mật khẩu',
                                            })}
                                            id="password"
                                            type={showPassword ? 'text' : 'password'}
                                            autoComplete='off'
                                            className="form-input w-full !h-[46px] pr-12"
                                            onKeyDown={handleKeyDown}
                                            onChange={(event) => handlePasswordChange(event)}
                                        />
                                        <div
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <EyeOff size={16} color="gray"/> :
                                                <Eye size={16} color="gray"/>}
                                        </div>
                                        
                                    </div>
                                    {errors.password &&
                                            <span
                                                className="text-red-500 text-sm">{errors.password.message}</span>}
                                    <div className="mt-2 flex items-center justify-between">
                                        <div className="text-sm flex items-center">
                                            <input  id="default-checkbox" 
                                                    type="checkbox" 
                                                    checked={isSaveAccount} onChange={() => setIsSaveAccount(!isSaveAccount)}
                                                    name="keepLogin"
                                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"/>
                                            <label 
                                            htmlFor="default-checkbox" 
                                            className="ms-2 text-sm">Duy trì đăng nhập</label>
                                        </div>

                                        <div className="text-sm">
                                            <button
                                                type='button'
                                                className="button text-indigo-600  hover:text-indigo-500 focus:outline-none"
                                                style={{ color: '#2D7DFF' }}
                                                onClick={() => {
                                                    navigate("/forgot-password");
                                                }}>
                                                Quên mật khẩu?
                                            </button>
                                           
                                        </div>
                                    </div>
                                </div>
                            </>
                            <div>
                                <button type="submit" disabled={isDisabledSubmitBtn}
                                        className="table-primary-btn w-full h-[46px] !text-lg">
                                    Đăng nhập
                                </button>
                                <div 
                                className='mt-3 text-center font-medium text-gray-500 cursor-pointer'
                                onClick={() => {
                                    navigate("/");
                                }}
                                >
                                    <span className=' text-sm'>Về trang chủ</span>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                }

            </div>
        </div>
    );
};

export default LoginPage;