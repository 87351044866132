import React from 'react';
import moment from 'moment';
import AvatarImage from 'components/uiComponents/AvatarImage';

type props = {
    data: any,
    handleClickDetailBtn: (user: any) => void;
}

const SearchItem = ({data, handleClickDetailBtn} : props) => {

  return (
    <div className="bg-[#F9D876] rounded-lg h-[80px] flex items-center text-black text-[15px] font-normal">
          <div className="w-[25%] flex items-center gap-4 pl-4">
            <div>
                <div className='w-[50px] h-[50px] flex items-center justify-center rounded-2xl border border-[#461511]'>
                    <AvatarImage
                        imageUrl={data.avatar}
                        className='rounded-2xl'
                    />
                </div>
            </div>
            <div className=" line-clamp-1 font-bold test-base">
                {`${data?.lastName} ${data?.firstName}`}
            </div>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.fromDate ? moment(data?.dateOfBirth).format("DD/MM/YYYY") : "--"}</span>
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.fromLunarDate ? moment(data?.dateOfDeath).format("DD/MM/YYYY") : "--"}</span>
            {/* <div>{data?.fromLunarDate ? moment(data?.fromLunarDate).format("DD/MM/YYYY") : "--"}</div> */}
          </div>
          <div className="w-[12%] text-center">
            <span>{data?.organizations?.find((item: any) => item.organizationType === 'Branch')?.organizationName || "--"}</span>
          </div>
          <div className="w-[10%] text-center">
            <span>{data?.organizations?.find((item: any) => item.organizationType === 'Clan')?.organizationName || "--"}</span>
          </div>
          <div className="w-[10%] text-center">
            <span>{data?.organizations?.find((item: any) => item.organizationType === 'Lineage')?.organizationName || "--"}</span>
          </div>
          <div className="w-[9%] text-center">
            <span>{data?.generationName ?? "--"}</span>
          </div>
          <div className="flex items-center justify-end gap-2 w-[10%] pr-4">
            <div 
            className="bg-white border border-[#461511] text-[#461511] text-sm font-medium rounded-[10px] px-3 py-1 cursor-pointer"
            onClick={() => handleClickDetailBtn(data?.id)}
            >
              Xem chi tiết
            </div>
          </div>
    </div>
  )
}

export default SearchItem