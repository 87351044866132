import { FunctionComponent, memo } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { EventItemComponent } from "./EventItemComponent";
import { useFilterEventActionsContext, useFilterEventContext } from "../context/FilterEventContextProvider";
import FilterSelectDateComponent from "./FilterSelectDateComponent";
const ListEventComponent: FunctionComponent = () => {
    const { events } = useFilterEventContext();
    const {refetch} = useFilterEventActionsContext();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <>  
            {!isSmallScreen &&
                <Grid columns={{ xs: 5 }} container>
                    <Grid xs={5}>
                        <Stack
                            sx={{ color: "#595959", fontWeight: 500 }}
                            direction={"row"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                        >
                            {/*<PagingEventComponent sx={{ marginTop: 1 }} />*/}
                            <FilterSelectDateComponent />
                            <Typography>
                                {events?.data?.data?.records?.length > 0 &&
                                    `${events?.data?.data?.records?.length} sự kiện`}
                            </Typography>
                        </Stack>
                    </Grid>
                </Grid>
            }
            {/* {events?.isLoading && (
                <h1 className={"h-[400px] w-full flex justify-center items-center"}>
                    Loading
                </h1>
            )} */}
            {/* <Grid
                paddingTop={"10px"}
                paddingBottom={0}
                margin={0}
                spacing={2}
                columns={{ xs: 2 }}
                container
                sx={{marginLeft: '-16px'}}
            >
                {events?.data?.data?.records?.map((item: any, index: number) => (
                    <Grid xs={1} key={`${item.id}_${index}`}>
                        <EventItemComponent index={index} data={item} refetch={refetch}/>
                    </Grid>
                ))}
                {events?.data?.data?.records?.length === 0 &&
                    <div className="pl-4">Không tìm thấy sự kiện nào</div>
                }
            </Grid> */}
            <div className="mt-4 overflow-hidden">
                <div className="overflow-auto">
                    <div className="flex text-black/60 text-[15px] min-w-[800px]">
                        <div className="w-[37%] text-center">Tên sự kiện</div>
                        <div className="w-[12%] text-center">Ngày dương</div>
                        <div className="w-[12%] text-center">Ngày âm</div>
                        <div className="w-[12%] text-center">Chi</div>
                        <div className="w-[12%] text-center">Phái</div>
                        <div className="w-[15%] text-center"></div>
                    </div>
                    <div className=" space-y-2 min-w-[800px]">
                        {events?.data?.data?.records?.map((item: any, index: number) => (
                            <EventItemComponent index={index} data={item} refetch={refetch}/>
                        ))}
                        {events?.data?.data?.records?.length === 0 &&
                            <div className="pl-4">Không tìm thấy sự kiện nào</div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(ListEventComponent);