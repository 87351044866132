import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from "@mui/material";
import { Link, useNavigate } from 'react-router-dom';
import { AlbumsService } from 'core/services/albums.service';
import ImageItems from './ImageItems';
import ImageDefaultIcon from 'assets/icons/ImageDefaultIcon';
import { useAuth } from 'core/contexts/auth/AuthContext';

const ImageAlbum = () => {
    const [listAlbum, setListAlbum] = useState<any>([]);
    const navigate = useNavigate();
    const auth = useAuth();

    const getAlbum = async () => {
        const res = await AlbumsService.getAlbumsPaging({
            PageNumber: 1,
            PageSize: 20
        });

        if (res?.data?.isSuccess) {
            setListAlbum(res?.data?.data?.records);
        }
    };

    useEffect(() => {
        getAlbum();
    }, [])

  return (
    <>
        <div className={"mt-[75px] flex justify-between"}>
            <Breadcrumbs separator={">>"} aria-label="breadcrumb">
                <Link to="/images" className='flex gap-1'>
                    <ImageDefaultIcon/>
                    <p className={"text-[#461511] font-[600] text-[16px]"}>
                        Hình ảnh phả tộc Nguyễn Đức
                    </p>
                </Link>
            </Breadcrumbs>
            {auth?.user?.Role &&
                <div>
                    <button className='table-primary-btn w-[108px]' type='button' onClick={() => navigate('/images/images-add')}>Thêm album</button>
                </div>
            }
        </div>
        {(listAlbum?.length > 0) && (
            <div className={"mt-[28px] mb-20"}>
                <ImageItems images={listAlbum} isAlbums={true}/>
            </div>
        )}
        {listAlbum?.length === 0 &&
            <div className={"mt-[28px] mb-20"}>Chưa có album</div>
        }
    </>
  )
}

export default ImageAlbum