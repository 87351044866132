import React, { useState } from 'react';
import { GenealogyItemType } from 'core/types/genealogy.type';
import { getFullName } from 'core/utils/ultils';
import IconIGenea from 'assets/svg/icon_i_genea.svg';
import IconIPerson from 'assets/svg/icon_i_person.svg';
import { AlertCircle, MinusCircle, PlusCircle } from 'react-feather';
import { FamilyTitleType } from 'core/types/user.type';

type GenealogyItemProps = {
    ItemData: GenealogyItemType;
    handleClickItem: (item: any) => void;
    collapsed: boolean;
    toggleCollapsed: (item: any) => void;
    isShowcollapse: boolean;
    isAloneNode?: boolean;
    handleClickGererationItem: (generationId: string) => void;
}

const GenealogyItem = ({
    ItemData, 
    handleClickItem,
    collapsed,
    toggleCollapsed,
    isShowcollapse,
    isAloneNode = false,
    handleClickGererationItem
} : GenealogyItemProps) => {
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const getItemStyle = (item: GenealogyItemType) => {
        if (item.personId) {
          return 'w-[70px] h-[131px] px-3 pb-3 py-10 bg-[#FFEAAE] text-[14px] rounded-lg text-[#461511] font-medium mx-auto'
        }
        if (item.organizationType === 'Branch') {
            return 'w-[280px] h-[64px] mx-auto bg-[url("assets/images/branch-bg.png")] bg-no-repeat bg-center bg-contain text-[#EDC584] font-bold text-[24px]';
        }
        if (item.organizationType === 'Clan') {
            return 'w-[138px] h-[54px] mx-auto bg-[#B9752A] rounded-lg text-[#FFEEBE] font-bold text-[20px]';
        }
        if (item.organizationType === 'Lineage') {
            return 'w-[138px] h-[44px] bg-[#F2B84C] mx-auto rounded-lg text-[#461511] font-bold text-[16px]';
        }
    };

    const getFamalyTitleStyle = (type: string) => {
        if (type === FamilyTitleType.ClanLeader) {
            return 'bg-[#461511] text-white text-[10px] tracking-[-0.15px]';
        }
        if (type === FamilyTitleType.SurnameLeader) {
            return 'bg-[#701811] text-white text-[10px] tracking-[-0.15px]';
        }
        if (type === FamilyTitleType.LineageLeader) {
            return 'bg-[#A50C00] text-white text-[9px] tracking-[-0.15px]';
        }
    };

    const getGenerationNumber14Style = (ItemData: GenealogyItemType) => {
        if ((ItemData.generationNumber === 14 && ItemData.organizationType !== "Lineage")) {
            if (isAloneNode) {
                return 'genealogy-tree-container-14-alone'
            }else {
                return 'genealogy-tree-container-14'
            }
        }else {
            return ''
        }
    }

  return (
    <>
        <div 
        className={`relative flex items-center justify-center mb-5 
        ${isAloneNode ? 'genealogy-tree-container' : ''} 
        ${getItemStyle(ItemData)}
        ${getGenerationNumber14Style(ItemData)}
        `}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        >
            {ItemData.familyTitle &&
                <div className={` absolute top-0 right-0 left-0 h-[22px] rounded-t-lg rounded-tr-lg flex item-center
                 justify-center ${getFamalyTitleStyle(ItemData.familyTitle)}`}>
                    <span className='mt-[4px]'>{ItemData.familyTitleStr === 'Tộc trưởng chi' ? 'Tộc trưởng' : ItemData.familyTitleStr}</span>
                </div>
            }
            {ItemData.personId ? getFullName(ItemData.personFirstName, ItemData.personLastName) : ItemData.organizationName}
            {!ItemData.personId &&
                <div 
                className={`absolute flex items-center ${ItemData.organizationType === "Branch" ? 'right-5' : 'right-2'}`}
                onClick={() => handleClickItem(ItemData)}
                >
                    <img src={IconIGenea} className='w-[24px] h-[24px] cursor-pointer' alt="IconI"/>
                </div>
            }
            {ItemData.personId &&
                <div 
                className={`absolute flex items-center top-[21px]`}
                onClick={() => handleClickItem(ItemData)}
                >
                    <img src={IconIPerson} className='w-[24px] h-[24px] cursor-pointer' alt="IconI"/>
                </div>
            }
            {isShowcollapse &&
                <div 
                onClick={() => toggleCollapsed(ItemData)} 
                className=' absolute bottom-[-20px] flex items-center justify-center mt-[3px] cursor-pointer z-[200]'
                >
                    {collapsed ? <PlusCircle size={20} className='text-[#461511] text-opacity-80 cursor-pointer'/> : 
                    <MinusCircle size={20} className='text-[#461511] text-opacity-80 cursor-pointer'/>}
                </div>
            }
            {isHovered && ItemData.personId && (
                <div 
                className=' absolute bottom-0 right-[-90px] z-[201] pl-[10px] h-[131px] flex items-center'
                onMouseEnter={() => setIsHovered(true)}
                >
                    <div className='w-[80px] h-[40px] bg-slate-500 text-[14px] rounded-[4px] text-white 
                    font-medium flex items-center justify-center gap-1'>
                        <span>{`${ItemData?.generationName}`}</span>
                        <AlertCircle size={20} 
                        className='text-white cursor-pointer' 
                        onClick={() => handleClickGererationItem(ItemData.generationId)}
                        />
                    </div>
                    <div className="triangle"></div>
                </div>
            )}
            
        </div>
    </>
  )
}

export default GenealogyItem