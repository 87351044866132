import { Box, Stack, Tab } from "@mui/material";
import { FunctionComponent, useState } from "react";
import FilterEventContextProvider, {
    useFilterEventActionsContext,
    useFilterEventContext,
} from "../context/FilterEventContextProvider";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { colorApp } from "../../../core/constant/app-constant";
import ListEventComponent from "./ListEventComponent";
import Grid from "@mui/material/Unstable_Grid2";
import EventCalendarScheduleComponent from "./EventCalendarScheduleComponent";
import { useNavigate } from "react-router-dom";
import { useAuth } from "core/contexts/auth/AuthContext";

export const FilterTabsComponent: FunctionComponent = (props) => {
    const [value, setValue] = useState("1");
    const { filter } = useFilterEventContext();
    const { updateFilter } = useFilterEventActionsContext();
    const navigate = useNavigate();
    const auth = useAuth();
    const handleChange = (event: any, newValue: any) => {
        
        if (newValue === "1") {
            updateFilter({
                FromDate: filter?.FromDate,
                ToDate: filter?.ToDate,
                PageNumber: 1,
                PageSize: 10,
            });
            setValue(newValue);
        } else {
            setValue(newValue);
            updateFilter({ FromDate: filter?.FromDate, ToDate: filter?.ToDate });
        }
    };
    
    return (
        <Box
            sx={{
                width: "100%",
                typography: "body1",
                paddingLeft: 0,
                paddingRight: 0,
                marginBottom: 20,
                marginTop: "20px"
            }}
        >
            <TabContext value={value}>
                <Grid container columns={{ xs: 10 }}>
                    <Grid xs={9}>
                        <TabList
                            sx={{
                                borderBottom: "none",
                                paddingBottom: 0,
                                "& .MuiTabs-indicator": {
                                    display: "none",
                                },
                                "& .MuiTab-root": {
                                    textTransform: "none",
                                    color: colorApp.tabsColor,
                                },
                                "& .MuiButtonBase-root:first-child": {
                                    borderRight: "3px solid",
                                },
                                "& .Mui-selected": {
                                    color: `${colorApp.tabsActive} !important`,
                                }
                            }}
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                        >
                            <Tab sx={{padding: 0, minHeight: "22px", paddingRight: "20px", fontSize: "18px"}} label="Danh sách sự kiện" value="1" />
                            <Tab sx={{padding: 0, minHeight: "22px", paddingLeft: "20px", fontSize: "18px"}} label="Xem theo lịch biểu" value="2" />
                        </TabList>
                    </Grid>
                    <Grid
                        xs={1}
                        display={"flex"}
                        alignItems={"start"}
                        justifyContent={"flex-end"}
                        sx={{
                            marginTop: "-3px",
                        }}
                    >
                        {auth?.user?.Role && auth?.user?.Role !== 'PeopleAdmin' &&
                            // <Button
                            //     sx={{
                            //         color: colorApp.tabsColor,
                            //         backgroundColor: "#F2B84F",
                            //         ":hover": {
                            //             backgroundColor: "#F2B84F",
                            //         },
                            //         width: 120,
                            //         textTransform: 'none',
                            //         borderRadius: '10px'
                            //     }}
                            //     // variant="contained"
                            //     onClick={() => navigate("/quan-ly/event")}
                            // >
                            //     Thêm sự kiện
                            // </Button>
                            <button className='table-primary-btn w-[120px] h-8' type='button'  onClick={() => navigate("/quan-ly/event")}>
                                Thêm sự kiện
                            </button>
                        }
                    </Grid>
                </Grid>
                <Stack padding={0}>
                    <FilterEventContextProvider>
                        <TabPanel sx={{ padding: "0px" }} value="1" id={"tab-list"}>
                            <ListEventComponent />
                        </TabPanel>
                        <TabPanel sx={{ padding: "0px" }} value="2" id={"tab-calendar"}>
                            <EventCalendarScheduleComponent />
                        </TabPanel>
                    </FilterEventContextProvider>
                </Stack>
            </TabContext>
        </Box>
    );
};