import { ModalContainer } from 'components/modal/ModalContainer'
import { UserService } from 'core/services/user.service';
import React, { useEffect } from 'react';
import { AlertTriangle, CheckCircle } from 'react-feather';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

type AddGenerationModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    modalClass?: string;
    contentClass?: string;
    gererationData?: any;
    handleAddGenerationSuccess?: (data: any) => void;
}

const AddGenerationModal = ({
    isOpen,
    closeModal,
    modalClass,
    contentClass,
    gererationData,
    handleAddGenerationSuccess
} : AddGenerationModalProps) => {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors }
    } = useForm({
        mode: 'onChange'
    });

    const watchName = watch('name'); 

    useEffect(() => {
        if(gererationData) {
            setValue('name', gererationData.name);
            setValue('history', gererationData.history);
        }else {
            reset();
        }
    }, [gererationData]);

    const handleCloseModal = () => {
        closeModal();
        setTimeout(() => {
            reset();
        }, 300);
    }

    const onSubmit = async (formValue: any) => {
        if (gererationData) {
            try {
                const response = await UserService.updateGeneration(gererationData.id, formValue);
    
                if (response?.data?.isSuccess === true) {
                    handleCloseModal();
                    reset();
                    toast('Cập nhật thông tin đời thành công', {
                        type: 'success',
                        icon: <CheckCircle className="text-[#007B40]" />
                    });
                }else {
                    toast(response?.data?.errors || 'Lỗi kết nối mạng!', {
                        type: 'error',
                        icon: <AlertTriangle className="text-[#F03D3E]" />
                      });
                }
            } catch (error) {
                toast('Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            }
        }else {
            try {
                const response = await UserService.addGeneration(formValue);
    
                if (response?.data?.isSuccess === true) {
                    if (handleAddGenerationSuccess) {
                        handleAddGenerationSuccess(response?.data?.data);
                    }
                    handleCloseModal();
                    reset();
                    toast('Đã thêm mới đời thành công', {
                        type: 'success',
                        icon: <CheckCircle className="text-[#007B40]" />
                    });
                }else {
                    toast(response?.data?.errors || 'Lỗi kết nối mạng!', {
                        type: 'error',
                        icon: <AlertTriangle className="text-[#F03D3E]" />
                      });
                }
            } catch (error) {
                toast('Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            }
        }
    }

  return (
    <ModalContainer isOpen={isOpen} className={modalClass}>
        <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete='off'
        >
        <div className={`flex flex-col gap-5 p-6 h-full ${contentClass}`}>
            <h1>{gererationData ? 'Cập nhật thông tin đời' : 'Thêm Đời'}</h1>
            <div className=' font-medium text-center flex-1'>
                {gererationData &&
                    <div className='mb-5'>
                        <div className="form-label min-w-[50px] text-start required-after">ID</div>
                        <input 
                        type="text" 
                        className='form-input flex-1' 
                        placeholder='Id'
                        value={gererationData.id}
                        disabled
                        readOnly
                        />
                    </div>
                }
                <div className='mb-5'>
                    <div className="form-label min-w-[50px] text-start required-after">Tên đời</div>
                    <input 
                    type="text" 
                    className='form-input flex-1' 
                    placeholder='Nhập tên đời'
                    {...register('name', {
                        required: 'Vui lòng nhập tên đời'
                    })}
                    />
                    {errors?.name &&
                        <div className="text-red-500 text-sm text-start mt-1">{errors?.name?.message as string}</div>}
                </div>
                <div className=''>
                    <div className="form-label min-w-[110px] text-start">Lịch sử</div>
                    <textarea 
                    className='form-input-area flex-1 h-[126px]' 
                    placeholder='Nhập lịch sử cá nhân'
                    {...register('history', {
                        required: false,
                    })}
                    />
                </div>
            </div>
            <div className='flex gap-5'>
                <button className='button table-second-btn w-1/2 h-[44px]' type='button' onClick={() => handleCloseModal()}>Hủy</button>
                <button className='button table-primary-btn w-1/2 h-[44px]' type='submit' disabled={!watchName}>Chấp nhận</button>
            </div>
        </div>
      </form>
    </ModalContainer>
  )
}

export default AddGenerationModal