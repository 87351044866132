import { UserService } from 'core/services/user.service';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Edit, Layers } from 'react-feather';
import AddGenerationModal from '../user/components/AddGenerationModal';

const ListGeneration = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [generations, setGenerations] = useState<any>();
    const [isShowAddGenerationModal, setIsShowAddGenerationModal] = useState<boolean>(false);
    const [selectedGeneration, setSelectedGeneration] = useState<any>();

    const getGenerations = async () => {
        const params: any = {
            PageNumber: 1,
            PageSize: 100
        };

        const response = await UserService.getGeneration(params);

        if (response?.data?.isSuccess === true) {
            setGenerations(response?.data?.data);
            setLoading(false);
        }
    };

    useEffect(() => {
        getGenerations();
    }, []);

    const handleCloseGenerationModal = () => {
        setIsShowAddGenerationModal(false);
        setSelectedGeneration(undefined);
        getGenerations();
    };

    const handleClickEditBtn = (generationItem: any) => {
        setSelectedGeneration(generationItem);
        setIsShowAddGenerationModal(true);
    }

  return (
    <>
        <div className=' flex justify-between'>
            <div className='table-title !gap-[11px]'>
                <Layers size={24} color={`#461511`}/>
                <p className=' text-base font-semibold leading-5 text-bg_brown'>Quản lý đời</p>
            </div>
            <div className=' text-sm font-normal leading-5 text-color_text'></div>
        </div>
        <div className='flex justify-between items-center mt-5 mb-3'>
            <div className='text-sm text-[#595959]'>{`${generations?.length} đời`}</div>
            <div>
                <button
                    onClick={() => setIsShowAddGenerationModal(true)}
                    className="table-primary-btn">
                    Thêm mới
                </button>
            </div>
        </div>
        <div className='table-container min-h-[500px] mb-5'>
            <table className='table w-full'>
                <thead>
                    <tr>
                        {/* <th className='w-[15%]'>
                            <span>Id</span>
                        </th> */}
                        <th className='w-[50%]'>
                            <span>Tên đời</span>
                        </th>
                        <th className='w-[15%]'>
                            <span>Ngày tạo</span>
                        </th>
                        <th className='w-[15%]'>
                            <span>Người tạo</span>
                        </th>
                        <th className='w-[20%]'>
                            <span></span>
                        </th>
                    </tr>
                </thead>
                {!loading &&
                    <tbody>
                    {generations?.map((generation: any) => (
                        <tr key={generation?.id} >
                            {/* <td className='w-[20%] '>
                                <div className='line-clamp-1'>{generation.id}</div>
                            </td> */}
                            <td className='w-[50%]'>
                                <span>{generation.name}</span>
                            </td>
                            <td className='w-[20%]'>
                                <span>{moment(generation.createdDate).format('DD/MM/YYYY HH:mm')}</span>
                            </td>
                            <td className='w-[20%]'>
                                <span>{(!generation.creatorFirstName || !generation.creatorLastName) ? "Admin" : `${generation.creatorFirstName} ${generation.creatorLastName}`}</span>
                            </td>
                            <td className='w-[5%]'>
                                <div className='flex justify-center w-full cursor-pointer' onClick={(event) => {handleClickEditBtn(generation)}}>
                                    <Edit size={20} className="text-[#461511]"/>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                }
            </table>
        </div>
        <AddGenerationModal
            isOpen={isShowAddGenerationModal}
            closeModal={() => handleCloseGenerationModal()}
            modalClass=' w-[40vw]'
            gererationData={selectedGeneration}
        />
    </>
  )
}

export default ListGeneration