import React, { ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

type ModalProps = {
  isOpen: boolean;
  className?: string;
  children: ReactNode;
  closeModal?: () => void;
};

export const ModalContainer = ({
  isOpen,
  className,
  children,
  closeModal
}: ModalProps) => {
  const handleKeyDown = (event: any) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      if (closeModal) {
        closeModal();
      }
      return;
    }
  };

  const handleClose = (event?: any) => {
    if (closeModal) {
      closeModal();
    }
    return;
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[100]"
          onClose={() => {
            handleClose();
          }}
          onClick={(e: any) => e.stopPropagation()}
          onKeyDown={handleKeyDown}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000] bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={` relative transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all ${className}`}
                >
                  <div className="w-full h-full">{children}</div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
