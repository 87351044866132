import React, {useState, useEffect} from 'react'
import {AlertTriangle, CheckCircle, Eye, EyeOff, Info, Users} from 'react-feather'
import {useParams, useNavigate} from 'react-router-dom'
import {AdminService} from "core/services/admin.service";
import {FormProvider, useForm} from "react-hook-form";
import Select from 'react-select';
import { UserRoleOptions, UserTooltipData } from 'core/types/user.type';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

const DetailAdmin = () => {
    const navigate = useNavigate();
    const {id}: any = useParams();
    const [adminDetail, setAdminDetail] = useState<any>();
    const [selectedRole, setSelectedRole] = useState<any>();
    const methods = useForm({
        defaultValues: {
            lastName: adminDetail?.lastName || '',
            firstName: adminDetail?.firstName || '',
            email: adminDetail?.email || '',
            password: adminDetail?.password || '',
            // iPassword: '',
            role: adminDetail?.role || '',
            isActive: true
        }
    });
    const [showPassword, setShowPassword] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<string>('Active');
    const [isDisabledSubmitBtn, setIsDisabledSubmitBtn] = useState<boolean>(true);

    const {setValue, watch, formState: {errors}} = methods;

    const wathchLastName = watch('lastName') || undefined;
    const wathchFirstName = watch('firstName') || undefined;
    const wathchEmail = watch('email') || undefined;
    const wathchPassword = watch('password') || undefined;
    // const wathchIPassword = watch('iPassword') || undefined;
    
    useEffect(() => {
        if (id) {
            const fetchAdminDetails = async () => {
                try {
                    const res = await AdminService.getAdminById(id);
                    if (res?.data?.isSuccess) {
                        const userDetail = res?.data?.data;
                        setAdminDetail(userDetail);
                        methods.reset({
                            lastName: userDetail?.lastName || '',
                            firstName: userDetail?.firstName || '',
                            email: userDetail?.email || '',
                            password: userDetail?.password || '',
                            // iPassword: userDetail?.iPassword || '',
                            role: userDetail?.roleName || '',
                            isActive: userDetail?.status || false
                        });
                        if (userDetail.roleName) {
                            setSelectedRole(UserRoleOptions?.find((userRole: any) => userRole.value === userDetail.roleName));
                        };
                        if (userDetail.status) {
                            setSelectedStatus(userDetail.status);
                        };
                    }
                } catch (err) {
                    console.log(err);
                }
            };
            fetchAdminDetails();
        }
    }, [id, methods]);

    useEffect(() => {
        
        if (selectedRole && selectedRole.value === 'SuperAdmin' && wathchEmail 
        && wathchFirstName && wathchLastName && wathchPassword && (Object.keys(errors).length === 0)) {
            setIsDisabledSubmitBtn(false);
        }else if (selectedRole && selectedRole.value !== 'SuperAdmin' && wathchEmail 
        && wathchFirstName && wathchLastName && wathchPassword && (Object.keys(errors).length === 0)) {
            setIsDisabledSubmitBtn(false);
        }else {
            setIsDisabledSubmitBtn(true);
        }

    }, [wathchEmail, wathchFirstName, wathchLastName, wathchPassword, selectedRole, errors]);

    // const getIPassword = async () => {
    //     const res = await AdminService.getIPassword();
    //     if (res?.data?.isSuccess) {
    //         setValue('iPassword', res?.data?.data);
    //     }
    // };

    // const updateIPassword = async (iPassword: string) => {
    //     const params = {
    //         password: iPassword
    //     }
    //     const res = await AdminService.updateIPassword(params);
    //     return res
    // };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    // const [showiPassword, setShowiPassword] = useState(false);

    // const toggleiPasswordVisibility = () => {
    //     setShowiPassword(!showiPassword);
    // };

    const onSubmit = async (data: any) => {
        if (id) {
            await AdminService.updateAdminById(id, {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                role: data.role,
                isActive: selectedStatus === 'Active' ? true : false,
            }).then((res: any) => {
                
                if (res?.data?.isSuccess) {
                    // if (data.role === 'SuperAdmin') {
                    //     const resUpdateIPass = updateIPassword(data.iPassword);
                    //     resUpdateIPass.then((resultUpdatePass: any) => {
                    //         if (resultUpdatePass?.data?.isSuccess) {
                    //             toast('Đã cập nhật thông tin admin thành công', {
                    //                 type: 'success',
                    //                 icon: <CheckCircle className="text-[#007B40]" />
                    //             });
                    //             navigate('/quan-ly/admin');
                    //         }else {
                    //             toast(resultUpdatePass?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                    //                 type: 'error',
                    //                 icon: <AlertTriangle className="text-[#F03D3E]" />
                    //             });
                    //         }
                    //     })
                    // }else {
                    //     toast('Đã cập nhật thông tin admin thành công', {
                    //         type: 'success',
                    //         icon: <CheckCircle className="text-[#007B40]" />
                    //     });
                    //     navigate('/quan-ly/admin');
                    // }
                    toast('Đã cập nhật thông tin admin thành công', {
                        type: 'success',
                        icon: <CheckCircle className="text-[#007B40]" />
                    });
                    navigate('/quan-ly/admin');
                }else {
                    toast(res?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                        type: 'error',
                        icon: <AlertTriangle className="text-[#F03D3E]" />
                    });
                }
            }).catch((err) => {
                toast('Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            })
        }else {
            await AdminService.createAdmin({
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                password: data.password,
                role: data.role,
                isActive: selectedStatus === 'Active' ? true : false,
            }).then((res: any) => {
                if (res?.data?.isSuccess) {
                    // if (data.role === 'SuperAdmin') {
                    //     const resUpdateIPass = updateIPassword(data.iPassword);
                    //     resUpdateIPass.then((resultUpdatePass: any) => {
                    //         if (resultUpdatePass?.data?.isSuccess) {
                    //             toast('Admin đã được thêm mới thành công', {
                    //                 type: 'success',
                    //                 icon: <CheckCircle className="text-[#007B40]" />
                    //             });
                    //             navigate('/quan-ly/admin');
                    //         }else {
                    //             toast(resultUpdatePass?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                    //                 type: 'error',
                    //                 icon: <AlertTriangle className="text-[#F03D3E]" />
                    //             });
                    //         }
                    //     })
                    // }else {
                    //     toast('Admin đã được thêm mới thành công', {
                    //         type: 'success',
                    //         icon: <CheckCircle className="text-[#007B40]" />
                    //     });
                    //     navigate('/quan-ly/admin');
                    // }
                    toast('Admin đã được thêm mới thành công', {
                        type: 'success',
                        icon: <CheckCircle className="text-[#007B40]" />
                    });
                    navigate('/quan-ly/admin');
                    
                }else {
                    toast(res?.data?.errors[0]?.message || 'Lỗi kết nối mạng!', {
                        type: 'error',
                        icon: <AlertTriangle className="text-[#F03D3E]" />
                    });
                }
            }).catch((err) => {
                toast('Lỗi kết nối mạng!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            })
        }
    };

    const handleStatusChange = (data: any) => {
        setSelectedStatus(data);
        if (data === 'active') {
          setValue('isActive', true);
        }else {
          setValue('isActive', false);
        }
    };

    const handleRoleChange = (role: any) => {
        setSelectedRole(role);
        setValue('role', role?.value, {shouldValidate: true});
    };

    return (
        <div className='table-container min-h-[500px]'>
            <FormProvider {...methods}>
                <div className="mt-3">
                    <div className='flex items-center gap-2'>
                        <Users size={24} color={`#461511`}/>
                        <p className='text-base font-semibold leading-5 text-bg_brown'>{"Quản lý admin"}</p>
                        <span className="font-medium">&gt;&gt;</span>
                        <p className='text-base font-semibold leading-5 text-bg_brown'>{id ? "Chỉnh sửa admin" : "Thêm mới admin"}</p>
                    </div>

                </div>
                <div className="mt-3">
                    <form onSubmit={methods?.handleSubmit(onSubmit)}>
                        <div className="grid gap-6 mb-6 md:grid-cols-2">
                            <input type="hidden" {...methods.register('isActive')}/>
                            <div >
                                <div className="flex items-center">
                                    <label htmlFor="lastName" style={{
                                        width: '25%'
                                    }}
                                        className="flex items-center text-sm font-medium text-gray-900 required-after">Họ 
                                    </label>
                                    <input {...methods.register('lastName', {
                                        required: 'Vui lòng nhập họ'
                                    })} type="text"  id="lastName"
                                        name="lastName" style={{
                                        width: '75%'
                                    }}
                                        className="form-input w-full pr-12"
                                        placeholder="Nhập họ"/>
                                </div>
                                {errors?.lastName &&
                                    <div className="text-red-500 text-sm text-end mt-1">{errors?.lastName?.message as string}</div>}
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <label htmlFor="email"
                                        className="flex items-center min-w-[97px] text-sm font-medium text-gray-900  required-after">Email</label>
                                    <input {...methods.register('email', {
                                        required: 'Vui lòng nhập email',
                                        validate: (value) => {
                                            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                                            if (!regex.test(value)) {
                                            return 'Email không đúng, vui lòng nhập địa chỉ email khác';
                                            }
                                        }
                                    })} type="text" id="email" name="email"
                                        className="form-input flex-1 pr-12"
                                        placeholder="Nhập email" required/>
                                </div>
                                {errors?.email &&
                                    <div className="text-red-500 text-sm text-end mt-1">{errors?.email?.message as string}</div>}
                            </div>
                            <div >
                                <div className="flex items-center">
                                    <label htmlFor="firstName" style={{
                                        width: '25%'
                                    }}
                                        className="flex items-center text-sm font-medium text-gray-900  required-after">Tên</label>
                                    <input {...methods.register('firstName', {
                                        required: 'Vui lòng nhập tên'
                                    })} type="text" id="firstName"
                                        style={{
                                            width: '75%'
                                        }}
                                        className="form-input w-full pr-12"
                                        placeholder="Nhập tên" required/>
                                </div>
                                {errors?.firstName &&
                                    <div className="text-red-500 text-sm text-end mt-1">{errors?.firstName?.message as string}</div>}
                            </div>
                            <div >
                                <div className="flex items-center">
                                    <label 
                                    htmlFor="role" 
                                    className="flex items-center text-sm font-medium min-w-[97px] text-gray-900  required-after">Vai
                                        trò</label>
                                    <Select
                                    value={selectedRole}
                                    onChange={handleRoleChange}
                                    options={UserRoleOptions}
                                    className='form-select-32 '
                                    classNamePrefix={'select'}
                                    placeholder='Chọn vai trò'
                                    isClearable={true}
                                    />
                                </div>
                                <input type="hidden" {...methods.register('role', {
                                        required: 'Vui lòng chọn vai trò'
                                    })}/>
                                {errors?.role &&
                                    <div className="text-red-500 text-sm text-end mt-1">{errors?.role?.message as string}</div>}
                            </div>
                            <div >
                                <div className="flex items-center relative">
                                    <label htmlFor="password" style={{
                                        width: '25%'
                                    }} className="text-sm font-medium flex items-center text-gray-900  required-after">Mật
                                        khẩu</label>
                                    <div className='relative flex-1'>
                                        <input 
                                            {...methods.register('password', {
                                                required: 'Vui lòng nhập Mật khẩu',
                                                validate: (value) => {
                                                    if (!value) return true;
                                                    const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.{8,})`);
                                                    if (!regex.test(value)) {
                                                    return 'Mật khẩu không đúng định dạng';
                                                    }
                                                    return true;
                                                }
                                            })} type={showPassword ? 'text' : 'password'} 
                                            className="form-input w-full pr-12"
                                            placeholder="Nhập mật khẩu" 
                                            required
                                        />
                                        <div
                                            className="absolute inset-y-0 right-[14px] flex items-center text-sm leading-5"
                                            onClick={togglePasswordVisibility}
                                        >
                                            {showPassword ? <EyeOff size={20} color="gray"/> :
                                                <Eye size={20} color="gray"/>}
                                        </div>
                                    </div>
                                    <div className='w-[33px] flex justify-end'>
                                        <Info 
                                        size={20} 
                                        className={"ml-1 cursor-pointer"} 
                                        color="gray"
                                        data-tooltip-id="admin-password-tooltip" 
                                        data-tooltip-content={UserTooltipData.UserPasswordTip}
                                        />
                                        <Tooltip id="admin-password-tooltip" place='bottom' className='z-10'/>
                                    </div>
                                </div>
                                {errors?.password &&
                                    <div className="text-red-500 text-sm text-end mt-1">{errors?.password?.message as string}</div>}
                            </div>
                            <div className='h-[32px]'>
                                <div className="flex gap-6 items-center h-full">
                                    <label htmlFor="firstName"
                                        className="text-sm font-medium min-w-[97px] flex items-center text-gray-900  md:w-1/4 lg:w-auto">Trạng
                                        thái</label>
                                    <div className="flex items-center">
                                        <label className='custom-radio'>
                                            <input
                                                type="radio"
                                                name="statusActive"
                                                value="active"
                                                checked={selectedStatus === 'Active'}
                                                onChange={() => handleStatusChange('Active')}
                                                className='mr-2'
                                            />
                                            Đang hoạt động
                                        </label>
                                    </div>
                                    <div className="flex items-center">
                                        <label className='custom-radio'>
                                            <input
                                                type="radio"
                                                name="statusInactive"
                                                value="inactive"
                                                checked={selectedStatus === 'Deactive'}
                                                onChange={() => handleStatusChange('Deactive')}
                                                className='mr-2'
                                            />
                                            Ngừng hoạt động
                                        </label>
                                    </div>
                                </div>
                            </div>
                            {/* {selectedRole?.value === "SuperAdmin" &&
                                <div >
                                    <div className="flex items-center relative">
                                        <label htmlFor="iPassword" style={{
                                            width: '25%'
                                        }} className="mb-2 text-sm font-medium text-gray-900  required-after">Mật
                                            khẩu chữ i </label>
                                        <div className='relative flex-1'>
                                            <input {...methods.register('iPassword', {
                                                    required: 'Vui lòng nhập Mật khẩu chữ i',
                                                    validate: (value) => {
                                                        if (!value) return true;
                                                        const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;'?/>.<,])(?=.{8,})`);
                                                        if (!regex.test(value)) {
                                                        return 'Mật khẩu không đúng định dạng';
                                                        }
                                                        return true;
                                                    }
                                                })}
                                                type={showiPassword ? 'text' : 'password'}
                                                id="iPassword" name="iPassword"
                                                className="form-input  w-full pr-12"
                                                placeholder="Nhập mật khẩu" required/>
                                            <div
                                                className="absolute inset-y-0 right-[14px] flex items-center text-sm leading-5"
                                                onClick={toggleiPasswordVisibility}
                                            >
                                                {showiPassword ? <EyeOff size={20} color="gray"/> :
                                                    <Eye size={20} color="gray"/>}
                                            </div>
                                        </div>
                                        <div className='w-[33px] flex justify-end'>
                                            <Info 
                                            size={20} 
                                            className={"ml-1 cursor-pointer"} 
                                            color="gray"
                                            data-tooltip-id="admin-password-i-tooltip" 
                                            data-tooltip-content={UserTooltipData.UserPasswordTip}
                                            />
                                            <Tooltip id="admin-password-i-tooltip" place='bottom' className='z-10'/>
                                        </div>
                                    </div>
                                    {errors?.iPassword &&
                                        <div className="text-red-500 text-sm text-end mt-1">{errors?.iPassword?.message as string}</div>}
                                </div>
                            } */}
                        </div>
                    </form>
                </div>
            </FormProvider>
            <div className={"flex gap-[35px] items-center justify-center mt-[15px]"}>
                <button 
                type="submit"
                onClick={methods.handleSubmit(onSubmit)}
                className="table-primary-btn w-[105px] h-[42px]"
                disabled={isDisabledSubmitBtn}
                >Lưu
                </button>
                <button
                    onClick={() => {
                        navigate('/quan-ly/admin');
                    }}
                    type="button"
                    className="table-second-btn w-[127px] h-[42px]"
                >
                    Hủy
                </button>
                
            </div>
        </div>
    )
}

export default DetailAdmin