import PersonProfileIcon from 'assets/icons/PersonProfileIcon';
import AuthService from 'core/services/auth.service';
import React, { useEffect, useState } from 'react';
import AvatarDefault from 'assets/images/avatar_default.png';
import moment from 'moment';
import { FileText } from 'react-feather';
import { FamilyCouncilTitleOptions, FamilyTitleOptions, RelationshipType } from 'core/types/user.type';
import { getFullName } from 'core/utils/ultils';
import { convertSolar2Lunar } from 'assets/javacripts/lunarCalendar';

const styles = {
    text: 'text-[#595959] text-sm leading-5 font-normal'
};

const Profile = () => {
    const [person, setPerson] = useState<any>();
    const [isAvatarError, setIsAvatarError] = useState<boolean>(false);
    const [selectedFather, setSelectedFather] = useState<any>();
    const [selectedMother, setSelectedMother] = useState<any>();
    const [selectedHusbandORWife, setSelectedHusbandORWife] = useState<any>(['']);
    const [selectedOlderBrother, setSelectedOlderBrother] = useState<any>(['']);
    const [selectedYoungerBrother, setSelectedYoungerBrother] = useState<any>(['']);
    const [selectedChildrent, setSelectedChildrent] = useState<any>(['']);
    const [selectedSister, setSelectedSister] = useState<any>(['']);

    const [selectedBranch, setSelectedBranch] = useState<any>();
    const [selectedClan, setSelectedClan] = useState<any>();
    const [selectedLineage, setSelectedLineage] = useState<any>();

    const getPersonData = async () => {
        const response = await AuthService.getPersonProfile();

        if (response?.data?.isSuccess === true) {
            const personData = response?.data?.data;
            setPerson(personData);
            const father = personData.relationships?.find((user: any) => user.type === RelationshipType.Father);
            const mother = personData.relationships?.find((user: any) => user.type === RelationshipType.Mother);
            const husbandORWife = personData.relationships?.filter((user: any) => user.type === (personData.gender === 'Male' ? RelationshipType.Wife : RelationshipType.Husband));
            const olderBrother = personData.relationships?.filter((user: any) => user.type === RelationshipType.OlderBrother);
            const youngerBrother = personData.relationships?.filter((user: any) => user.type === RelationshipType.YoungerBrother);
            const olderSister = personData.relationships?.filter((user: any) => user.type === RelationshipType.OlderSister);
            const children = personData.relationships?.filter((user: any) => user.type === RelationshipType.Children);

            if(father) setSelectedFather(father);
            if(mother) setSelectedMother(mother);
            if(husbandORWife?.length > 0) {
                setSelectedHusbandORWife(husbandORWife);
            };
            if(olderBrother?.length > 0) {
                setSelectedOlderBrother(olderBrother);
            };
            if(youngerBrother?.length > 0) {
                setSelectedYoungerBrother(youngerBrother);
            };
            if(olderSister?.length > 0) {
                setSelectedSister(olderSister);
            };
            if(children?.length > 0) {
                setSelectedChildrent(children);
            };

            const Lineage = personData.organizations?.find((org: any) => org.type === 'Lineage');
            const Clan = personData.organizations?.find((org: any) => org.type === 'Clan');
            const Branch = personData.organizations?.find((org: any) => org.type === 'Branch');

            if(Branch) setSelectedBranch(Branch);
            if(Clan) setSelectedClan(Clan);
            if(Lineage) setSelectedLineage(Lineage);
        }
    };

    useEffect(() => {
        getPersonData();
    }, []);

    const handleAvatarOnError = (e: any) => {
        e.target.src = AvatarDefault;
        setIsAvatarError(true);
    };

    const handleConvertSolarToLunar = (date: any) => {
        const solarDay = date.getDate();
        const solarMonth = date.getMonth() + 1;
        const solarYear = date.getFullYear();
        const formattedDay = solarDay < 10 ? `0${solarDay}` : solarDay.toString();
        const formattedMonth = solarMonth < 10 ? `0${solarMonth}` : solarMonth.toString();
        return convertSolar2Lunar(parseInt(formattedDay), parseInt(formattedMonth), parseInt(solarYear), 7);
    }

    const getLunarDate = (date: any) => {
        const lunarDay = handleConvertSolarToLunar(new Date(date));
        if (lunarDay?.length > 0) {
          return `${lunarDay[0]}/${lunarDay[1]}/${lunarDay[2]}`;
        }
    };

    const handleDownload = async (fileUrl: string, fileName: string) => {
        try {
          const response = await fetch(fileUrl);
          
          if (!response.ok) {
            throw new Error('Network response was not ok.');
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
        }
    };

  return (
    <div className='min-h-[400px]'>
        <div className='flex items-center gap-2 mb-[30px]'>
            <PersonProfileIcon fillColor='#461511'/>
            <span className=" flex-1 text-lg font-medium leading-5 text-bg_brown">Hồ sơ cá nhân</span>
        </div>
        <div className='flex'>
            <div className='w-[56%]'>
                <div className='flex gap-[18px]'>
                    <div className='flex items-center justify-center'>
                        <div className='w-[114px] h-[114px] rounded-2xl border border-bg_brown flex items-center justify-center relative'>
                            {!person?.avatar &&
                                <img src={AvatarDefault} alt="AvatarDefault" width={51} height={72.39}/>
                            }
                            {person?.avatar &&
                                <img 
                                src={person?.avatar} 
                                alt="avatarUrl" 
                                className={`${isAvatarError ? 'w-[51px] h-[72.39px]' : 'w-[112px] h-[112px]'} rounded-2xl`}
                                onError={handleAvatarOnError}
                                />
                            }
                        </div>
                    </div>
                    <div className='flex-1 space-y-6'>
                        <div className='form-group'>
                            <div className="form-label min-w-[60px]">Mã số</div>
                            <div className={styles.text}>{person?.personCode}</div>
                        </div>
                        <div className='form-group'>
                            <div className="form-label min-w-[60px]">Tên</div>
                            <div className={styles.text}>{person?.firstName}</div>
                        </div>
                        <div className='form-group'>
                            <div className="form-label min-w-[60px]">Họ</div>
                            <div className={styles.text}>{person?.lastName}</div>
                        </div>
                    </div>
                </div>
                <div className='space-y-6 mt-6'>
                    <div className='form-group'>
                        <div className="form-label min-w-[168px]">Giới tính</div>
                        <div className={styles.text}>{person?.gender === 'Male' ? 'Nam' : 'Nữ'}</div>
                    </div>
                    <div className='form-group'>
                        <div className="form-label min-w-[168px]">Ngày sinh</div>
                        <div className={styles.text}>{person?.dateOfBirth ? moment(person?.dateOfBirth).format('DD/MM/YYYY') : ''}</div>
                    </div>
                    <div className='form-group'>
                        <div className="form-label min-w-[168px]">CMND / CCCD</div>
                        <div className={styles.text}>{person?.identityDocument}</div>
                    </div>
                    <div className='form-group'>
                        <div className="form-label min-w-[168px]">Giấy khai sinh</div>
                        <div className={`${styles.text} flex items-center justify-center gap-2`}>
                            {person?.birthDocument &&
                                <>
                                    <FileText size={20} className=' text-table_primary_btn'/>
                                    <button onClick={() => handleDownload(person?.birthDocument, person?.birthDocumentName)} type='button' 
                                    className='text-[#0031AF] text-sm font-normal leading-4 max-w-[240px] line-clamp-1' 
                                    // href={person?.birthDocument} 
                                    // target='_blank' 
                                    // download={person?.birthDocumentName}
                                    rel='noreferrer'>{person?.birthDocumentName}</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[44%] space-y-6'>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Email</div>
                    <div className={styles.text}>{person?.email}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Số điện thoại</div>
                    <div className={styles.text}>{person?.phoneNumber}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Nghề nghiệp</div>
                    <div className={styles.text}>{person?.career}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Địa chỉ</div>
                    <div className={styles.text}>{person?.street}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Tỉnh / Thành phố</div>
                    <div className={styles.text}>{person?.city}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Quận / Huyện</div>
                    <div className={styles.text}>{person?.district}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Phường / Xã</div>
                    <div className={styles.text}>{person?.ward}</div>
                </div>   
            </div>
        </div>
        <div className='form-group mt-6'>
            <div className="form-label min-w-[168px]">Lịch sử cá nhân</div>
            <div className={`${styles.text} whitespace-pre-line`} dangerouslySetInnerHTML={{__html: person?.personalHistory || ''}}></div>
        </div>
        <div className='mt-[60px] mb-[30px] text-sm font-medium text-bg_brown leading-5'>
            Thông tin phả tộc
        </div>
        <div className='flex'>
            <div className='w-[56%] space-y-6'>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Bố</div>
                    <div className={styles.text}>
                        {selectedFather ? getFullName(selectedFather.firstName, selectedFather.lastName) : ''}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Nhánh</div>
                    <div className={styles.text}>
                        {selectedBranch?.name}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Chi</div>
                    <div className={styles.text}>
                        {selectedClan?.name}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Phái</div>
                    <div className={styles.text}>
                        {selectedLineage?.name}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Đời thứ</div>
                    <div className={styles.text}>
                        {person?.generation?.name}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[168px]">Con thứ</div>
                    <div className={styles.text}>
                        {person?.positionInFamily ? `Con thứ ${person?.positionInFamily}` : ''}
                    </div>
                </div>
            </div>
            <div className='w-[44%] space-y-6'>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Mẹ</div>
                    <div className={styles.text}>
                        {selectedMother ? selectedMother.fullname : ''}
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Ngày mất</div>
                    <div className={`${styles.text} flex gap-3`}>
                        <div>{person?.dateOfDeath ? moment(person?.dateOfDeath).format('DD/MM/YYYY') : '' }</div>
                        <div>{person?.lunarDateOfDeath ? `(${getLunarDate(new Date(person?.lunarDateOfDeath))} ÂL)` : '' }</div>
                    </div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Liệt sĩ</div>
                    <div className={styles.text}>{person?.isMartyr ? 'Liệt sĩ' : 'Không'}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Chức năng trong họ</div>
                    <div className={styles.text}>{FamilyTitleOptions?.find((familyTitle: any) => familyTitle.value === person?.familyTitle)?.label}</div>
                </div>
                <div className='form-group'>
                    <div className="form-label min-w-[184px]">Chức năng trong gia tộc</div>
                    <div className={styles.text}>{FamilyCouncilTitleOptions?.find((familyCouncilTitle: any) => familyCouncilTitle.value === person?.familyCouncilTitle)?.label}</div>
                </div>
            </div>
        </div>
        <div className='mt-[60px] mb-[30px] text-sm font-medium text-bg_brown leading-5'>
            Mối quan hệ khác
        </div>
        <div className='flex mb-[80px]'>
            <div className='w-[56%] space-y-6'>
                <div className='form-group'>
                    <div 
                    className={`form-label min-w-[168px] 
                    ${selectedHusbandORWife?.length > 1 ? ' self-start mt-1' : ''}`}
                    >Chồng / Vợ</div>
                    <div className='flex-1 space-y-6'>
                        {selectedHusbandORWife?.map((husbandORWife: any) => (
                            <div className={styles.text} key={husbandORWife?.id}>
                                {husbandORWife ? husbandORWife?.fullname : ''}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='form-group'>
                    <div 
                    className={`form-label min-w-[168px] 
                    ${selectedOlderBrother?.length > 1 ? ' self-start mt-1' : ''}`}
                    >Anh</div>
                    <div className='flex-1 space-y-6'>
                        {selectedOlderBrother?.map((olderBrother: any) => (
                            <div className={styles.text} key={olderBrother?.id}>
                                {olderBrother ? getFullName(olderBrother?.firstName, olderBrother?.lastName) : ''}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='form-group'>
                    <div 
                    className={`form-label min-w-[168px] 
                    ${selectedYoungerBrother?.length > 1 ? ' self-start mt-1' : ''}`}
                    >Em</div>
                    <div className='flex-1 space-y-6'>
                        {selectedYoungerBrother?.map((youngerBrother: any) => (
                            <div className={styles.text} key={youngerBrother?.id}>
                                {youngerBrother ? getFullName(youngerBrother?.firstName, youngerBrother?.lastName) : ''}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='w-[44%] space-y-6'>
                <div className='form-group'>
                    <div 
                    className={`form-label min-w-[184px] 
                    ${selectedChildrent?.length > 1 ? ' self-start mt-1' : ''}`}
                    >Con</div>
                    <div className='flex-1 space-y-6'>
                        {selectedChildrent?.map((childrent: any) => (
                            <div className={styles.text} key={childrent?.id}>
                                {childrent ? getFullName(childrent?.firstName, childrent?.lastName) : ''}
                            </div>
                        ))}
                    </div>
                </div>
                <div className='form-group'>
                    <div 
                    className={`form-label min-w-[184px] 
                    ${selectedSister?.length > 1 ? ' self-start mt-1' : ''}`}
                    >Chị</div>
                    <div className='flex-1 space-y-6'>
                        {selectedSister?.map((sister: any) => (
                            <div className={styles.text} key={sister?.id}>
                                {sister ? getFullName(sister?.firstName, sister?.lastName) : ''}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Profile