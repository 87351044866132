import MasterLayout from "components/layouts/MasterLayout";
import { LoadingBarProcess } from "components/loadingProcess/LoadingBarProcess";
import React from "react";
import { Route, Routes } from "react-router-dom";
import ImagePage from "../../pages/home/ImagePage";
import AddImagePage from "../../pages/home/AddImagePage";
import ImageAlbum from "pages/home/components/ImageAlbum";

const ImageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MasterLayout />}>
      <Route
          index
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ImageAlbum />
            </React.Suspense>
          }
        />
        <Route
          path="/:idAlbum/:name"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <ImagePage />
            </React.Suspense>
          }
        />
        <Route
          path="/images-add"
          element={
            <React.Suspense fallback={<LoadingBarProcess />}>
              <AddImagePage />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
};

export default ImageRoutes;
