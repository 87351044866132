import * as yup from 'yup';

export const schemaLogin = yup.object().shape({
    email: yup.string().email('Vui lòng nhập email hợp lệ').required('Vui lòng nhập email'),
    password: yup.string().required('Vui lòng nhập mật khẩu'),
});

export const schemaForgotPassword = yup.object().shape({
    emailReset: yup.string().email('Vui lòng nhập email hợp lệ').required('Vui lòng nhập email'),
});

export const schemaResetPassword = yup.object().shape({
    oldPassword: yup.string().required('Vui lòng nhập mật khẩu'),
    newPassword: yup.string().required('Vui lòng nhập mật khẩu'),
    confirmPassword: yup.string().required('Vui lòng nhập mật khẩu'),
});

export const schemaCreateAdmin = yup.object().shape({
    lastName: yup.string().required('Vui lòng nhập họ'),
    firstName: yup.string().required('Vui lòng nhập tên'),
    password: yup.string().required('Vui lòng nhập mật khẩu'),
    iPassword: yup.string().required('Vui lòng nhập mật khẩu'),
    email: yup.string().email('Vui lòng nhập email hợp lệ').required('Vui lòng nhập email'),
    role: yup.string().required('Vui lòng chọn vai trò'),
    isActive: yup.boolean().optional(),
});