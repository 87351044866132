import { GENERATION, ORGANIZATION, PROVINCES, USER } from "core/constant/api.contant";
import axiosClient from "utilities/axios-instance";


const getList = (params: any) => {
    return axiosClient.get(USER.LIST, { params});
}

const addUser = (params: any) => {
    return axiosClient.post(USER.ADD, params);
}

const updateUser = (params: any, userId: string) => {
    return axiosClient.put(USER.UPDATE_BY_ID(userId), params);
};

const deleteUser = (userId: string) => {
    return axiosClient.delete(USER.DELETE_BY_ID(userId));
};

const getDetailById = (userId: string) => {
    return axiosClient.get(USER.DETAIL_BY_ID(userId));
};

const getPersonCode = (params: any) => {
    return axiosClient.get(USER.GET_PERSON_CODE, { params});
}

const submitReview = (id: string, params: any) => {
    return axiosClient.post(USER.REVIEW_USER(id), params);
}

const getProvinces = () => {
    return axiosClient.get(PROVINCES.PROVINCES)
}

const getDistricts = (provinceId: string) => {
    return axiosClient.get(PROVINCES.DISTRICT(provinceId));
}

const getOrganization = (params: any) => {
    return axiosClient.get(ORGANIZATION.LIST, { params});
}

const getWards = (districtId: string) => {
    return axiosClient.get(PROVINCES.WARD(districtId));
}

const getGeneration = (params: any) => {
    return axiosClient.get(GENERATION.LIST, { params});
}

const addGeneration = (params: any) => {
    return axiosClient.post(GENERATION.ADD, params);
}

const updateGeneration = (generationId: string, params: any) => {
    return axiosClient.put(GENERATION.UPDATE_BY_ID(generationId), params);
}

const getAdmins = () => {
    return axiosClient.get(USER.LIST_ADMIN);
}

const uploadFile = (file: any, params: any) => {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.put(USER.UPLOAD_FILE, formData, {params, headers: {
        'Content-Type':'multipart/form-data'
    }});
};

const validateSeedData = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(USER.SEED_DATA_VALIDATE, formData, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    });
};

const getExcelDefaul = () => {
    return axiosClient.get(USER.GET_EXCEL_DAFAULT, {
        headers: {'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'},
        responseType: 'blob',
    });
};

const seedData = (file: any) => {
    const formData = new FormData();
    formData.append('file', file);
    return axiosClient.post(USER.SEED_DATA, formData, {
        headers: {
            'Content-Type':'multipart/form-data'
        }
    });
};

export const UserService = {
    getList,
    getDetailById,
    submitReview,
    getProvinces,
    getDistricts,
    getWards,
    getOrganization,
    getGeneration,
    addUser,
    addGeneration,
    uploadFile,
    getPersonCode,
    updateUser,
    updateGeneration,
    getAdmins,
    validateSeedData,
    seedData,
    getExcelDefaul,
    deleteUser
}