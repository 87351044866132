import React from "react";
import "./App.css";
import AuthProvider from "core/contexts/auth/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import { QueryClient, QueryClientProvider } from "react-query";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme } from "@mui/material";
import PageRoutes from "./core/routes/Routes";

const theme = createTheme({
  components: {
    MuiGrid2: {
      defaultProps: {
        disableEqualOverflow: true,
      },
    },
  },
});

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider theme={createTheme(theme)}>
          <PageRoutes />
          <ToastContainer hideProgressBar={true} position={"bottom-right"} newestOnTop={true} limit={3}/>
        </ThemeProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default App;
