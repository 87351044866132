import { NOTIFICATION } from "core/constant/api.contant"
import axiosClient from "utilities/axios-instance"

const getList = (params: any) => {
    return axiosClient.get(NOTIFICATION.LIST, { params});
}

const readNotification = () => {
    return axiosClient.post(NOTIFICATION.READ);
}

const readById = (id: string) => {
    return axiosClient.post(NOTIFICATION.READ_BY_ID(id));
}

const showAlert = (id: string, params: any) => {
    return axiosClient.put(NOTIFICATION.SHOW_ALERT(id), params);
}

export const NotificationService = {
    getList,
    readNotification,
    readById,
    showAlert
}