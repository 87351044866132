import { CircleSpin } from 'components/spinner/CircleSpin';
import { UserService } from 'core/services/user.service';
import React, { useRef, useState } from 'react';
import { AlertTriangle, CheckCircle, Download, Trash, Upload, X } from 'react-feather';
import { toast } from 'react-toastify';

type valicateError = {
    rowIndex: number;
    personCode: string;
    errorMessages: string[];
}

const ImportUser = () => {
    const [currentFile, setCurrentFile] = useState<any>();
    const [onSubmit, setOnSubmit] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [errors, setErrors] = useState<any>([]);

    const handleClickInputFile = () => {
        document.getElementById('UserFileInputExcel')?.click();
    };

    const handleFileChange = (event: any) => {
        setCurrentFile(event.target.files[0]);
    };

    const handleClearFile = () => {
        setCurrentFile(undefined);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleClearErrors = () => {
        setErrors([]);
        handleClearFile();
    }

    const handleSubmitFile = async (event: any) => {
        try {
            setOnSubmit(true);
            const file = currentFile;
            const allowedExtensions = ['xls', 'xlsx', 'csv'];
            const fileExtension = file.name.split('.').pop();
            if (!allowedExtensions.includes(fileExtension)) {
                toast('File không đúng định dạng', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
                setOnSubmit(false);
                return;
            }
            const responseUpload = await UserService.seedData(file);
            if (responseUpload.data.length === 0) {
                toast('Thêm người mới thành công', {
                    type: 'success',
                    icon: <CheckCircle className="text-[#007B40]" />
                });
                handleClearFile();
            }else {
                setErrors(responseUpload.data);
            }
            // const responseValidate = await UserService.validateSeedData(file);
            // if (responseValidate.data.length === 0) {
            //     const responseUpload = await UserService.seedData(file);
            //     if (responseUpload.data.isSuccess) {
            //         toast('Tải file lên thành công!', {
            //             type: 'success',
            //             icon: <CheckCircle className="text-[#007B40]" />
            //         });
            //     }else {
            //         toast('Tải file lên thất bại!', {
            //             type: 'error',
            //             icon: <AlertTriangle className="text-[#F03D3E]" />
            //         });
            //     }
            // }else {
            //     setErrors(responseValidate.data);
            //     toast("Có lỗi khi tải file lên", {
            //         type: 'error',
            //         icon: <AlertTriangle className="text-[#F03D3E]" />
            //     });
            // }
            setOnSubmit(false);
        } catch (error) {
            toast('Tải file lên thất bại!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
            });
        }
        
    };

    const handleClickDownloadExcelDdefault = async () => {
        try {
            const res = await UserService.getExcelDefaul();
            if (res?.data) {
                const file = new Blob([res?.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                const fileURL = URL.createObjectURL(file);
                const link = document.createElement('a');
                link.href = fileURL;
                link.download = 'InputPersonDataExcel.xlsx';
                link.click();
            }else {
                toast('Tải file thất bại!', {
                    type: 'error',
                    icon: <AlertTriangle className="text-[#F03D3E]" />
                });
            }
        } catch (error) {
            toast('Tải file thất bại!', {
                type: 'error',
                icon: <AlertTriangle className="text-[#F03D3E]" />
            });
        }
        
    };
    console.log('errors', errors);
  return (
    <div className='min-h-[500px]'>
        <div className=' flex justify-between'>
            <div className='flex items-center gap-[11px]'>
                <Upload size={24} color={`#461511`}/>
                <p className=' text-base font-semibold leading-5 text-bg_brown'>Import người mới</p>
            </div>
            <div className=' text-sm font-normal leading-5 text-color_text'></div>
        </div>
        <div className='flex mt-5'>
            <div className='w-full h-auto flex flex-col'>
                <div className='flex-1 mt-5 h-[200px] border border-table_primary_btn rounded-10px relative'>
                    {!currentFile && errors.length === 0 &&
                        <div className='w-full h-[200px] flex items-center justify-center cursor-pointer' onClick={handleClickInputFile}>
                            <div className='text-center'>Bấm để chọn file Excel</div>
                        </div>
                    }
                    {currentFile && errors.length === 0 &&
                        <div className='w-full h-[200px] flex items-center justify-center gap-3'>
                            <div 
                            className='text-center cursor-pointer' 
                            onClick={handleClickInputFile}>{currentFile.name}</div>
                            <div 
                            className='cursor-pointer text-red-500 font-bold'
                            onClick={handleClearFile}
                            >
                                <Trash size={20} className="text-table_primary_btn cursor-pointer"/>
                            </div>
                        </div>
                    }
                    {errors.length > 0 &&
                        <div className='w-full h-[200px] relative flex flex-col  '>
                            <div className='font-medium text-[16px] text-red-400 pl-3 pt-3'>
                                <span>Danh sách import thất bại:</span>
                            </div>
                            <div 
                            className=' absolute right-3 top-3 cursor-pointer'
                            onClick={handleClearErrors}
                            >
                                <X size={20}/>
                            </div>
                            <div className='text-left overflow-auto mini-scrollbar px-3 mt-5'>
                                <div className='flex-1 overflow-auto'>
                                    <ul className='list-disc pl-5 pb-5'>
                                        {errors.map((error: valicateError, index: number) => (
                                            <li key={index} className='text-sm'>
                                                {`Hàng ${error.rowIndex}: ${error?.errorMessages?.join(', ')}`}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    <input
                    type="file"
                    accept=".xls,.xlsx,.csv"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    id='UserFileInputExcel'
                    ref={fileInputRef}
                    />
                </div>
                <div className='mt-5 bg-white flex justify-between items-center'>
                    <div className='flex gap-1 cursor-pointer' onClick={() => handleClickDownloadExcelDdefault()}>
                        <Download size={20}/>
                        <span>Tải file mẫu</span>
                    </div>
                    <button 
                    className='button table-primary-btn w-[160px] h-[40px]' 
                    type='button'
                    onClick={handleSubmitFile}
                    disabled={onSubmit || !currentFile || (errors.length > 0)}
                    >
                        {onSubmit && <CircleSpin color="text-table_primary_btn mr-2" />}
                        <span>Tải lên</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ImportUser