import { MEDIA } from "core/constant/api.contant";
import axiosClient from "utilities/axios-instance";

const deleteImages = (params: any) => {
    return axiosClient.delete(MEDIA.DELETE_MEDIA_IMAGE, {
        data: params,
        headers: {
            "Content-Type": "application/json",
        }
    });
}

export const MediaService = {
    deleteImages,
}